import React, { Component, Fragment, useState, useRef, useEffect, createRef  } from "react";
import ReactDOM from "react-dom";
import { UncontrolledCollapse } from 'reactstrap';
import classnames from 'classnames';
import Overview from '../Overview/Overview';
import NFTDocumentation from '../NFTDocumentation/nftbenifits';
import DeveloperProgram from '../DeveloperProgram/DeveloperProgram';
import {
	BrowserRouter, Navigate, Route, Routes, Link
 } from 'react-router-dom';
 import ScrollSpy from "react-ui-scrollspy";
import Header from "../../common/Header/Header";
import Footer from "../../common/Footer/Footer";

import downimg from '../../assets/images/pics/downimg.png';


const Home = (props) => {  
    const [isActive, setActive] = useState("false");
    const ToggleClass = () => {
        setActive(!isActive); 
    };

    const parentScrollContainerRef = useRef(null);
    const onPress = (e) => {
        e.preventDefault();
        const target = window.document.getElementById(
          e.currentTarget.href.split("#")[1]
        );
        if (target) {
          var headerOffset = 97;
          var elementPosition = target.getBoundingClientRect().top;
          var offsetPosition = elementPosition - headerOffset;
    
          window.scrollBy({
            top: offsetPosition,
            behavior: "smooth",
          });
        }
      };

    return (
        <Fragment>
            <Header />
            <div className="pkchinsec">
                <div className="container">
                    <div className="d-flex sidebarCnt">
                        <div className={isActive ? "pksidebr" : "pksidebr SideMenuOpen"}>
                            <div className="sidenav">
                                <ul className="ml-0 pl-0">
                                    <li><a data-to-scrollspy-id="parkchain1" onClick={(e) => onPress(e)} href={"#parkchain1"}>The initial stage of the internet</a></li>
                                    <li><a data-to-scrollspy-id="parkchain2" onClick={(e) => onPress(e)} href={"#parkchain2"}>Web 1.0: Read-Only</a></li>
                                    <li><a data-to-scrollspy-id="parkchain3" onClick={(e) => onPress(e)} href={"#parkchain3"}>Web 2.0: Read-Write (2004-now)</a></li>
                                    <li><a data-to-scrollspy-id="parkchain4" onClick={(e) => onPress(e)} href={"#parkchain4"}>Web 3.0: Read-Write-Own</a></li>
                                    <li><a data-to-scrollspy-id="parkchain5" onClick={(e) => onPress(e)} href={"#parkchain5"}>What is the meaning of the term "Web3"?</a></li>
                                    <li><a data-to-scrollspy-id="parkchain6" onClick={(e) => onPress(e)} href={"#parkchain6"}>Key concepts of Web3</a></li>
                                    <li><a data-to-scrollspy-id="parkchain7" onClick={(e) => onPress(e)} href={"#parkchain7"} className="ml-3">Decentralization:</a></li>
                                    <li><a data-to-scrollspy-id="parkchain8" onClick={(e) => onPress(e)} href={"#parkchain8"} className="ml-3">Permissionlessness:</a></li>
                                    <li><a data-to-scrollspy-id="parkchain9" onClick={(e) => onPress(e)} href={"#parkchain9"} className="ml-3">Native payments:</a></li>
                                    <li><a data-to-scrollspy-id="parkchain10" onClick={(e) => onPress(e)} href={"#parkchain10"} className="ml-3">Trustlessness:</a></li>
                                    <li><a data-to-scrollspy-id="parkchain11" onClick={(e) => onPress(e)} href={"#parkchain11"}>What is the importance of Web3?</a></li>
                                    <li><a data-to-scrollspy-id="parkchain12" onClick={(e) => onPress(e)} href={"#parkchain12"}>Ownership</a></li>
                                    <li><a data-to-scrollspy-id="parkchain13" onClick={(e) => onPress(e)} href={"#parkchain13"}>Resistance to censorship</a></li>
                                    <li><a data-to-scrollspy-id="parkchain14" onClick={(e) => onPress(e)} href={"#parkchain14"}>Decentralized autonomous organizations (DAOs)</a></li>
                                    <li><a data-to-scrollspy-id="parkchain15" onClick={(e) => onPress(e)} href={"#parkchain15"}>Identity</a></li>
                                    <li><a data-to-scrollspy-id="parkchain16" onClick={(e) => onPress(e)} href={"#parkchain16"}>Native payments</a></li>
                                    <li><a data-to-scrollspy-id="parkchain17" onClick={(e) => onPress(e)} href={"#parkchain17"}>Limitations of Web3</a></li>
                                    <li><a data-to-scrollspy-id="parkchain18" onClick={(e) => onPress(e)} href={"#parkchain18"}>Accessibility</a></li>
                                    <li><a data-to-scrollspy-id="parkchain19" onClick={(e) => onPress(e)} href={"#parkchain19"}>User experience</a></li>
                                    <li><a data-to-scrollspy-id="parkchain20" onClick={(e) => onPress(e)} href={"#parkchain20"}>Education</a></li>
                                    <li><a data-to-scrollspy-id="parkchain21" onClick={(e) => onPress(e)} href={"#parkchain21"}>Centralized infrastructure</a></li>
                                    <li><a data-to-scrollspy-id="parkchain22" onClick={(e) => onPress(e)} href={"#parkchain22"}>The decentralized future</a></li>
                                </ul>
                            </div>                            
                        </div>
                        <svg id="hamburger" viewBox="0 0 100 100" onClick={ToggleClass}>
                            <rect className={isActive ? "hamburger-line hamburger-line-1" : "hamburger-line hamburger-line-1 active"} x="20" y="50" rx="1" width="60" height="2"></rect>
                            <rect className={isActive ? "hamburger-line hamburger-line-2" : "hamburger-line hamburger-line-2 active"} x="20" y="50" rx="1" width="60" height="2"></rect>
                            <rect className={isActive ? "hamburger-line hamburger-line-3" : "hamburger-line hamburger-line-3 active"} x="20" y="50" rx="1" width="60" height="2"></rect>
                        </svg>

                        <div className="pkmainsec" useRef={parentScrollContainerRef}>                        
                            <ScrollSpy scrollThrottle={100} useBoxMethod={true}>
                                <h2 className="kphedone">An Introduction to Web3 </h2>
                                <p className="kpparaone">The advent of centralization has played a crucial role in bringing billions of people onto the World Wide Web and establishing a solid and dependable infrastructure for its existence. However, this centralized approach has also resulted in a small number of dominant entities having excessive control over substantial portions of the web, determining what is permissible and what is not without any collaborative decision-making.</p>
                                <p className="kpparaone">Web3 presents a solution to this predicament. Web3 is designed to counter the dominance of major tech companies by championing decentralization, with users taking the lead in its construction, operation, and ownership. By shifting power from corporations to individuals, Web3 empowers individuals to a greater extent. Before delving into Web3, it is essential to delve into the journey that led us to this point.</p>

                                <h4 className="kphedtwo" id="parkchain1">The initial stage of the internet</h4>
                                <p className="kpparaone">When people reflect on the internet, they usually consider it an integral part of contemporary existence, assuming it has always been present. Nonetheless, the internet we are familiar with today has undergone significant changes from its original conception. To gain a deeper comprehension, it is beneficial to divide the relatively brief history of the internet into distinct eras—Web 1.0 and Web 2.0.</p>

                                <h4 className="kphedtwo" id="parkchain2">Web 1.0: Read-Only</h4>
                                <p className="kpparaone">Web 1.0, (1990-2004) also referred to as the initial phase of the World Wide Web, spanned from 1990 to 2004. During this period, Tim Berners-Lee, while working at CERN in Geneva in 1989, formulated the protocols that would eventually give rise to the Internet as we know it today. His vision was to establish open and decentralized protocols that facilitated the sharing of information across the globe.</p>
                                <p className="kpparaone">Web 1.0, the earliest manifestation of Berners-Lee's creation, primarily consisted of static websites that belonged to various companies. Notably, there was minimal user interaction, with individuals rarely generating their own content. Consequently, this era became known as the read-only web, where users primarily consumed information without actively contributing to it.</p>

                                <h4 className="kphedtwo" id="parkchain3">Web 2.0: Read-Write (2004-now)</h4>
                                <p className="kpparaone">The era of Web 2.0 commenced in 2004 when social media platforms emerged, transforming the web from a read-only platform to a read-write platform. Rather than solely delivering content to users, companies also started providing platforms for users to share their own content and engage in interactions with one another. With the increasing number of internet users, a small number of dominant companies came to wield a disproportionate amount of web traffic and value. Additionally, Web 2.0 introduced the advertising-centric revenue model. Although users could generate content, they lacked ownership and the ability to derive financial benefits from it.</p>

                                <h4 className="kphedtwo" id="parkchain4">Web 3.0: Read-Write-Own</h4>
                                <p className="kpparaone">The concept of 'Web 3.0' was introduced by Gavin Wood, one of the co-founders of Parkchain, shortly after the launch of Parkchain in 2014. Wood articulated a solution to a concern shared by many early cryptocurrency users: the excessive reliance on trust within the existing Web. Presently, the majority of the Web that people are familiar with depends on placing trust in a small number of private companies to prioritize the public's welfare.</p>

                                <h2 className="kphedtwo" id="parkchain5">What is the meaning of the term "Web3"?</h2>
                                <p className="kpparaone">Web3 encompasses the idea of a revamped internet that aims to be superior to its predecessors. At its core, Web3 utilizes blockchains, cryptocurrencies, and NFTs to empower users through ownership. While Web1 was limited to read-only functionality and Web2 introduced read-write capabilities, Web3 takes it a step further by enabling read-write-own interactions.</p>
                                
                                <h2 className="kphedtwo" id="parkchain6">Key concepts of Web3</h2>
                                <p className="kpparaone">While providing an exact definition of Web3 can be challenging, several fundamental principles shape its development.</p>

                                <h2 className="kphedthree ml-3" id="parkchain7">Decentralization:</h2>
                                <p className="kpparaone ml-3">Web3 aims to distribute ownership among its creators and users, moving away from a model where centralized entities control significant portions of the internet.</p>
                                
                                <h2 className="kphedthree ml-3" id="parkchain8">Permissionlessness:</h2>
                                <p className="kpparaone ml-3">Web3 advocates for equal access, allowing anyone to participate without exclusion.</p>
                                
                                <h2 className="kphedthree ml-3" id="parkchain9">Native payments:</h2>
                                <p className="kpparaone">Web3 relies on cryptocurrencies for online transactions, replacing the outdated infrastructure of banks and payment processors.</p>
                                
                                <h2 className="kphedthree ml-3" id="parkchain10">Trustlessness:</h2>
                                <p className="kpparaone ml-3">Web3 operates on incentives and economic mechanisms, eliminating the need for reliance on trusted third parties.</p>

                                <h4 className="kphedtwo" id="parkchain11">What is the importance of Web3?</h4>
                                <p className="kpparaone">While the standout features of Web3 are not neatly categorized, we have attempted to separate them for better comprehension.</p>
                                
                                <h4 className="kphedtwo" id="parkchain12">Ownership</h4>
                                <p className="kpparaone">Web3 revolutionizes digital asset ownership. Take the example of a web2 game where if you purchase an in-game item, it becomes linked to your account. However, if the game creators delete your account, you lose those items. Similarly, if you cease playing the game, the value you invested in your in-game items is lost.</p>
                                <p className="kpparaone">Web3 introduces direct ownership through non-fungible tokens (NFTs). Your ownership cannot be revoked, not even by the game's creators. Moreover, if you decide to stop playing, you can sell or trade your in-game items on open markets and regain their value.</p>
                                
                                <h4 className="kphedtwo" id="parkchain13">Resistance to censorship</h4>
                                <p className="kpparaone">There is a significant power imbalance between platforms and content creators.</p>
                                <p className="kpparaone">OnlyFans, a platform where users create adult content, has over 1 million content creators who rely on it as their main source of income. In August 2021, OnlyFans announced its intention to prohibit sexually explicit content. This decision caused an uproar among creators on the platform, who felt that they were being deprived of income on a platform they had helped establish. Due to the backlash, the decision was quickly reversed. Although the creators emerged victorious in this conflict, it highlights a problem faced by creators on Web 2.0: if they leave a platform, they lose the reputation and following they have built.</p>
                                <p className="kpparaone">In Web3, your data is stored on the blockchain. When you choose to switch platforms, you can transfer your reputation to another interface that better aligns with your values.</p>
                                <p className="kpparaone">Web 2.0 necessitates content creators placing trust in platforms to maintain consistent rules, whereas censorship resistance is an inherent characteristic of a Web3 platform.</p>

                                <h4 className="kphedtwo" id="parkchain14">Decentralized autonomous organizations (DAOs)</h4>
                                <p className="kpparaone">DAOs, or decentralized autonomous organizations, enable individuals to have control over both their personal data within the Web3 ecosystem and shared ownership of platforms through tokens that operate similarly to company shares. With DAOs, you can collaboratively manage the ownership of a platform in a decentralized manner and participate in making decisions regarding its future.</p>
                                <p className="kpparaone">DAOs are technically defined as smart contracts that facilitate decentralized decision-making regarding a pool of resources (tokens) through agreed-upon rules. Token holders vote on resource allocation, and the code automatically executes the outcome of the voting process.</p>
                                <p className="kpparaone">However, various Web3 communities are referred to as DAOs, each with varying degrees of decentralization and automation through code. Currently, we are in the process of exploring the concept of DAOs and contemplating their potential evolution in the future.</p>
                                
                                <h4 className="kphedtwo" id="parkchain15">Identity</h4>
                                <p className="kpparaone">In the past, it was customary to create separate accounts for each platform you used. For instance, you would have a Twitter account, a YouTube account, and a Reddit account. If you wanted to change your display name or profile picture, you had to make the modifications on each account. Although social sign-ins were available in some cases, they brought along a familiar issue—censorship. With just a single click, these platforms could lock you out of your entire online presence. To make matters worse, many platforms demanded your trust by requiring personally identifiable information for creating an account.</p>
                                <p className="kpparaone">Web3 resolves these challenges by granting you control over your digital identity through an Parkchain address and ENS profile. By utilizing an Parkchain address, you gain a unified login across platforms that guarantees security, resistance to censorship, and anonymity.</p>

                                <h4 className="kphedtwo" id="parkchain16">Native payments</h4>
                                <p className="kpparaone">Payments in the realm of Web2 heavily depend on banks and payment processors, which poses a limitation for individuals without bank accounts or those residing in unfavorable countries. In contrast, Web3 utilizes tokens such as ETH to facilitate direct money transfers within the browser, eliminating the need for intermediaries or trusted third parties.</p>

                                <h4 className="kphedtwo" id="parkchain17">Limitations of Web3 </h4>
                                <p className="kpparaone">Despite the various advantages of Web3 in its present state, there remain several constraints that need to be addressed for the ecosystem to thrive.</p>
                                
                                <h4 className="kphedtwo" id="parkchain18">Accessibility</h4>
                                <p className="kpparaone">While certain key Web3 features, such as Sign-in with Parkchain, are freely available for anyone to use, the cost of transactions can still be a hindrance. This makes Web3 less feasible for individuals in less affluent, developing nations due to high transaction fees. To overcome this, Parkchain is actively working on addressing these challenges through the implementation of a roadmap and layer 2 scaling solutions. While the technology is ready, broader adoption on layer 2 is necessary to ensure accessibility to Web3 for all.</p>
                                
                                <h4 className="kphedtwo" id="parkchain19">User experience</h4>
                                <p className="kpparaone">The current technical barriers to entry for utilizing Web3 are excessively high. Users must grasp security concerns, understand complex technical documentation, and navigate through unintuitive user interfaces. Wallet providers, in particular, are striving to solve these issues, but further advancements are required before Web3 can be adopted on a large scale.</p>
                                
                                <h4 className="kphedtwo" id="parkchain20">Education</h4>
                                <p className="kpparaone">Web3 introduces new concepts and paradigms that necessitate a different understanding from those utilized in Web2.0. Similar to the educational initiatives undertaken during the rise of Web1.0 in the late 1990s, efforts are needed to educate the public about Web3 through various means. This could involve using simple metaphors (e.g., the information highway, browsing, surfing the web) or even broadcasting educational programs. While Web3 is not inherently difficult, it does require a different approach. Educational initiatives aimed at informing Web2 users about these Web3 paradigms are crucial for its success.</p>
                                <p className="kpparaone">To contribute to Web3 education, Parkchain.org has established a Translation Program, aiming to translate important Parkchain content into as many languages as possible.</p>
                                
                                <h4 className="kphedtwo" id="parkchain21">Centralized infrastructure</h4>
                                <p className="kpparaone">Being a young and rapidly evolving ecosystem, Web3 currently relies heavily on centralized infrastructure platforms such as GitHub, Twitter, Discord, and others. Many Web3 companies are actively working to fill these gaps, but developing high-quality and reliable infrastructure takes time.</p>
                                
                                <h4 className="kphedtwo" id="parkchain22">The decentralized future</h4>
                                <p className="kpparaone">Decentralization on the rise Web3, a burgeoning and dynamic ecosystem, holds promise for the future. Coined by Gavin Wood in 2014, the ideas associated with Web3 have started materializing only recently. Over the past year, the fascination with cryptocurrencies has soared, layer 2 scaling solutions have witnessed remarkable enhancements, novel models of governance have been extensively explored, and digital identity has undergone transformative revolutions.</p>
                                <p className="kpparaone">While we are merely embarking on the journey of crafting a superior Web through Web3, the prospects ahead appear immensely promising. As we continue refining the underlying infrastructure that will underpin it, the Web's future shines brightly.</p>

                            </ScrollSpy>    
                            <div class="bnrLks text-center">
               <a href="https://web3js.readthedocs.io/en/v1.10.0/web3.html" target="blank" class="btn btn-12040 btn-bluFl text-center">
                  Explore more
               </a>
            </div>           
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
   
}

export default Home;