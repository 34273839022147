// import { BrowserRouter, Link, Navigate, Route, Routes } from "react-router-dom";
// import React, {
//   Component,
//   Fragment,
//   createRef,
//   useEffect,
//   useRef,
//   useState,
// } from "react";

// import Footer from "../../common/Footer/Footer";
// import Header from "../../common/Header/Header";
// import ReactDOM from "react-dom";
// import ScrollSpy from "react-ui-scrollspy";
// import { UncontrolledCollapse } from "reactstrap";
// import business from "../../assets/images/devdoc/business.svg";
// import classnames from "classnames";
// import clipBoard from "../../assets/images/devdoc/clipboard-tick.svg";
// import core4 from "../../assets/images/devdoc/core-4.svg";
// import daaps from "../../assets/images/devdoc/daaps.svg";
// import devdoc from "../../assets/images/devdoc/devdoc-1.png";
// import devdocOl from "../../assets/images/devdoc/devdocOl.png";
// import development from "../../assets/images/devdoc/development.svg";
// import donation from "../../assets/images/devdoc/wallet.svg";
// import monitor from "../../assets/images/devdoc/monitor.svg";
// import verify from "../../assets/images/devdoc/verify.svg";
// import wallet from "../../assets/images/devdoc/wallet.svg";

// const DevDocument = (props) => {
//   return (
//     <Fragment>
//       <Header />

//       <div className="devdocBnr">
//         <div className="container">
//           <div className="row devdocBnrR">
//             <div className="col-lg-6 col-xl-5 devdocBnrC lt">
//               <div className="bnrTx devdocBnrTx">
//                 <h2>The official documentation for</h2>
//                 <h1> Parkchain Wiki </h1>
//                 <p>
//                   The best-performing decentralized ecosystem
//                   Ready for massive user access
//                 </p>

//                 <div className="bnrLks">
//                   <Link to="/developerprogram" class="btn btn-12040 btn-bluFl">
//                     Start Building
//                   </Link>

//                   <Link to="https://tutorial.parkchain.org/parkchain-protocol/" target="_blank" class="btn btn-12040 btn-wtnOl">
//                     Read Docs
//                   </Link>
//                 </div>
//               </div>
//             </div>
//             <div className="col-lg-6 col-xl-6 devdocBnrC rt">
//               <div className="devdocBnrIg">
//                 <img src={devdoc} alt="devdoc" />
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="devdocMtd">
//         <div className="devdocMtdOl">
//           <img src={devdocOl} alt="devdocOl" />
//         </div>
//         <div className="container">
//           <div class="kyFtrR devdocMtdR row">
//             <div class="col-sm-12 col-md-6 col-lg-4 kyFtrC devdocMtdC">
//               <div class="kyFtrCon">
//                 <div class="kyFtrIc">
//                   <img src={daaps} alt="daaps" />
//                 </div>
//                 <h2>Build a Dapps</h2>
//                 <p>
//                   DApps are applications that don’t rely on a centralized server or backend but instead make
//                   use of Web3 technologies such as blockchains and oracles to contain their logic and backend
//                   functions, making them tamper-proof and secure.
//                 </p>
//               </div>
//             </div>

//             <div class="col-sm-12 col-md-6 col-lg-4 kyFtrC devdocMtdC">
//               <div class="kyFtrCon">
//                 <div class="kyFtrIc">
//                   <img src={core4} alt="core4" />
//                 </div>
//                 <h2>Core Concepts</h2>
//                 <p>
//                   Governance of ParkChain - POSA
//                   The ParkChain network is not only a Proof of Staked Authority (PoSA) blockchain network
//                   but also one that utilizes on-chain governance. Ideally, such governance logic should be built into
//                   the blockchain and automatically executed as the blocking happens.
//                 </p>
//               </div>
//             </div>

//             <div class="col-sm-12 col-md-6 col-lg-4 kyFtrC devdocMtdC">
//               <div class="kyFtrCon">
//                 <div class="kyFtrIc">
//                   <img src={clipBoard} alt="core4" />
//                 </div>
//                 <h2>Deploy Contracts</h2>
//                 <p>
//                   There are 3 type of environments Remix can be plugged for deploy the smart-contracts :


//                   <ul className="developerdoc-ul">
//                     <li>Javascript VM</li>
//                     <li>Injected Web3</li>
//                     <li>Web3 Provider</li>
//                   </ul>




//                   Both Injected Web3 and Web3 Provider require the use of an external tool.
//                   An external tool for Injected provider is Metamask. Some external tools used withWeb3 provider
//                   are a Truffle Ganache-CLI, Hardhat node, or an Ethereum node itself.
//                   The JavaScript VM is convenient because it is a blockchain that runs in your browser.
//                 </p>
//               </div>
//             </div>

//             <div class="col-sm-12 col-md-6 col-lg-4 kyFtrC devdocMtdC">
//               <div class="kyFtrCon">
//                 <div class="kyFtrIc">
//                   <img src={development} alt="development" />
//                 </div>
//                 <h2>Develop</h2>
//                 <p>
//                   ParkChain is an 100% EVM compatible blockchain, and is very good at decentralized
//                   application (Dapp) development within many possible verticals including DeFi, NFT, Gaming,
//                   Metaverse and many others.A guide to available tools, components, and platforms for developing
//                   applications on ParkChain.
//                 </p>
//               </div>
//             </div>

//             <div class="col-sm-12 col-md-6 col-lg-4 kyFtrC devdocMtdC">
//               <div class="kyFtrCon">
//                 <div class="kyFtrIc">
//                   <img src={verify} alt="verify" />
//                 </div>
//                 <h2>Validator</h2>
//                 <p>
//                   Parkchain relies on a set of validators who are responsible for committing new blocks in the
//                   blockchain. These validators participate in the consensus protocol by signing blocks that contain
//                   cryptographic signatures signed by each validator's private key. The validator set is determined by a
//                   staking module built on ParkChain Protocol for ParkChain, and propagated every day 00:00 UTC
//                   via Cross-Chain communication on Parkchain.
//                 </p>
//               </div>
//             </div>

//             <div class="col-sm-12 col-md-6 col-lg-4 kyFtrC devdocMtdC">
//               <div class="kyFtrCon">
//                 <div class="kyFtrIc">
//                   <img src={monitor} alt="verify" />
//                 </div>
//                 <h2>Migrating Parkchain</h2>
//                 <p>
//                   Migration refers to the process of moving tokens from one blockchain to another as a result
//                   of a change in the blockchain.
//                   Here Token migration (also known as token swaps) occurs when a token is transferred from other
//                   blockchain’s to ParkChain during a blockchain changeover. It is crucial to remember that token
//                   migrations aren't always associated with new blockchain launches; they may also occur when
//                   projects simply switch from one protocol to another.
//                   Here we have a Token Migration Tool simplifies the Token swap from other Blockchain to
//                   Parkchain’s easily.
//                 </p>
//               </div>
//             </div>

//             <div class="col-sm-12 col-md-6 col-lg-4 kyFtrC devdocMtdC">
//               <div class="kyFtrCon">
//                 <div class="kyFtrIc">
//                   <img src={wallet} alt="wallet" />
//                 </div>
//                 <h2>Wallet</h2>
//                 <p>
//                   A ParkChain wallet is a device or program used for transfer and storage of cryptocurrency.
//                   There are also several smartphone mobile apps and computer programs that provide a user-friendly
//                   way to create and manage wallets. Along with cryptocurreny, ParkChain wallets store a collection
//                   of crypto keys that are used for sending, receiving, and taracking ownership of cryptocurrencies.
//                 </p>
//               </div>
//             </div>

//             <div class="col-sm-12 col-md-6 col-lg-4 kyFtrC devdocMtdC">
//               <div class="kyFtrCon">
//                 <div class="kyFtrIc">
//                   <img src={business} alt="wallet" />
//                 </div>
//                 <h2>Future Development</h2>
//                 <p>
//                   ParkChain development teams and community pioneers would like to share the outlook for
//                   the future development direction of the ParkChain blockchain ecosystem and to demonstrate the
//                   resilience and persistence of the KPK spirit: for the builders and users and target to onboard 10B
//                   users.
//                   With this name change also comes more advancements to benefit users, projects and developers
//                   connected to the ParkChain community. ParkChain will embrace large-scale applications, including
//                   GameFi, SocialFi and the Metaverse. In particular:

//                   <ul className="developerdoc-ul">
//                     <li>Scaling from one chain to multi-chain</li>
//                     <li>Boosting the throughput of ParkChain</li>
//                     <li>Improving scaling solutions and an expansion of the validator set of ParkChain from 21 to 51 (with 51 validators functioning as candidate block producers)</li>
//                   </ul>




//                 </p>
//               </div>
//             </div>

//             <div class="col-sm-12 col-md-6 col-lg-4 kyFtrC devdocMtdC">
//               <div class="kyFtrCon">
//                 <div class="kyFtrIc">
//                   <img src={donation} alt="wallet" />
//                 </div>
//                 <h2>Contribute</h2>
//                 <p>
//                   Your contributions to the ParkChain will help build a fast and secure decentralized digital
//                   assets exchange.
//                   We want to make it as easy as possible to contribute changes that help the ParkChain grow and
//                   thrive. There are a few guidelines that we ask contributors to follow so that we can merge your
//                   changes quickly.
//                 </p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       <Footer />
//     </Fragment>
//   );
// };

// export default DevDocument;



import { BrowserRouter, Link, Navigate, Route, Routes, useNavigate } from "react-router-dom";
import React, {
  Component,
  Fragment,
  createRef,
  useEffect,
  useRef,
  useState,
} from "react";

import Footer from "../../common/Footer/Footer";
import Header from "../../common/Header/Header";
import HtmlParser from "react-html-parser";
import ReactDOM from "react-dom";
import ScrollSpy from "react-ui-scrollspy";
import { UncontrolledCollapse } from "reactstrap";
import business from "../../assets/images/devdoc/business.svg";
import classnames from "classnames";
import clipBoard from "../../assets/images/devdoc/clipboard-tick.svg";
import core4 from "../../assets/images/devdoc/core-4.svg";
import daaps from "../../assets/images/devdoc/daaps.svg";
import devdoc from "../../assets/images/devdoc/devdoc-1.png";
import devdocOl from "../../assets/images/devdoc/devdocOl.png";
import development from "../../assets/images/devdoc/development.svg";
import donation from "../../assets/images/devdoc/wallet.svg";
import monitor from "../../assets/images/devdoc/monitor.svg";
import { useSelector } from "react-redux";
import verify from "../../assets/images/devdoc/verify.svg";
import wallet from "../../assets/images/devdoc/wallet.svg";

const DevDocument = (props) => {
  const [developerProgram, setDeveloperProgram] = useState("");

  var Res = useSelector((state) => state.user);
  useEffect(() => {
    if (Res !== undefined) {
      if (Res?.data?.status == 200) {
        Res = Res.data.data;
        setDeveloperProgram(Res);
      }
    }
  }, [Res]);
  return (
    <Fragment>
      <Header />
      <div className="devdocBnr">
        <div className="container">
          <div className="row devdocBnrR">
            <div className="col-lg-6 col-xl-5 devdocBnrC lt">
              <div className="bnrTx devdocBnrTx">
                <h2>The official documentation for</h2>
                <h1> Parkchain Wiki </h1>
                <p>
                  The best-performing decentralized ecosystem
                  Ready for massive user access
                </p>

                <div className="bnrLks">
                  <Link to="/developerprogram" class="btn btn-12040 btn-bluFl">
                    Start Building
                  </Link>

                  <Link to="/document" class="btn btn-12040 btn-wtnOl">
                    Read Docs
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-6 devdocBnrC rt">
              <div className="devdocBnrIg">
                <img src={devdoc} alt="devdoc" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="devdocMtd">
        {/* <div className="devdocMtdOl">
          <img src={devdocOl} alt="devdocOl" />
        </div> */}
        <div className="container" >
          <div class="kyFtrR devdocMtdR row">
            {developerProgram &&
              developerProgram.map((item) => {
                if (item.heading == "Developer programs") {
                  console.log("item", item);

                  return (
                    <div class="col-sm-12 col-md-6 col-lg-4 kyFtrC devdocMtdC">
                      <div class="kyFtrCon" style={{ cursor: "pointer" }}
                        onClick={() => window.open(item.link)}>

                        <h2 className="text-left">{item.title}</h2>
                        <div className="pd-para" >
                          <p className="text-left">{HtmlParser(item.content_description)}</p>
                        </div>


                        <div class="kyFtrIc">
                          <img src={item.image} alt="daaps" />
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
          </div>
        </div>
      </div>

      <Footer />
    </Fragment>
  );
};

export default DevDocument;