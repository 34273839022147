// import React, { Component, Fragment, useState, useRef, useEffect, createRef } from "react";
// import ReactDOM from "react-dom";
// import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
// import Header from "../../common/Header/Header";
// import Footer from "../../common/Footer/Footer";
// import { useSelector } from "react-redux";
// import ReactHtmlParser from 'react-html-parser';

// const Overview = (props) => {
//     const [getData, setData] = useState('');
//     const [status, setStatus] = useState('1');
//     let data = useSelector(state => (state.user));
//     let data1;
//     console.log(data)
//     if (data.data.status == 200) {
//         data = (data.data.data)
//         data1 = (data.find(elem => elem.title == 'NFT Documentation'))
//         // setData(data1);
//     }
    

//     console.log(getData, data1)
//     return (
//         <Fragment>
            
//             {/* <h2 class="kphedone">NFT Documentation</h2>
//             <h2 class="kphedtwo">Introduction</h2>
//             <p class="kpparaone">Parkchain revolutionizes blockchain technology by addressing the lack of a reliable and trusted source of time in existing blockchains. With its Proof of Authority consensus mechanism and verifiable passage of time, Parkchain establishes a robust and consistent timeline for events, enhancing the overall reliability and trustworthiness of blockchain-based systems.</p>
//             <h2 class="kphedtwo">What is Parkchain?</h2>
//             <p class="kpparaone">Parkchain is built on the Parkchain protocol, serving as a full implementation that runs multiple nodes on the Parkchain mainnet. By leveraging the Parkchain network effects, Parkchain benefits from the established
//             community, robust security guarantees, and continuous innovation that Parkchain offers. Parkchain is a
//             widely adopted blockchain platform renowned for its versatility and robust features. It serves as the
//             foundation for a multitude of decentralized applications (DApps) and is particularly well-suited for the
//             creation and management of non-fungible tokens (NFTs). With its scalable infrastructure and extensive
//             developer community, Parkchain has established itself as a leading platform for launching NFTs and
//             fostering a vibrant ecosystem around digital collectibles and unique digital assets. Its rich set of tools and
//             smart contract capabilities enable developers to create engaging and immersive NFT experiences, driving
//             innovation in the space. Parkchain's proven track record and commitment to providing a best-in-class NFT
//             creator experience have solidified its position as a go-to platform for NFT enthusiasts and creators alike.</p>
//             <h2 class="kphedtwo">What is Parkchain?</h2>
//             <p class="kpparaone">Parkchain is an innovative blockchain platform that aims to transform the finance industry by placing utmost importance on innovation, security, and user experience. Our platform empowers individuals and businesses to engage in financial transactions that are not only secure and efficient but also transparent. By harnessing the power of the Parkchain protocol, Parkchain seamlessly combines the strengths of the mainnet community with state-of-the-art advancements in privacy, permissioning, and consensus protocols. Our ultimate goal is to democratize finance and ensure that it is accessible to everyone, fostering trust, privacy, and innovation within the decentralized ecosystem.</p>
//             <h2 class="kphedtwo">Thankyou - Connect With Us.</h2>
//             <p class="kpparaone">Your invaluable support has made this project possible. We deeply appreciate your utilization of Parkchain and remain committed to enhancing them further to meet your needs. In case of any inquiries, we encourage you to connect with us on Discord. Alternatively, we invite you to contribute by opening a pull request, as your input would be highly valued and beneficial to our collective progress.</p> */}
//             {data1 ? ReactHtmlParser(data1.content_description) : <></>}

//         </Fragment>
//     );

// }

// export default Overview;
import React, { Component, Fragment, useState, useRef, useEffect, createRef  } from "react";
import ReactDOM from "react-dom";
import { UncontrolledCollapse } from 'reactstrap';
import classnames from 'classnames';
import Overview from '../Overview/Overview';
import NFTDocumentation from '../NFTDocumentation/NFTDocumentation';
import DeveloperProgram from '../DeveloperProgram/DeveloperProgram';
import {
	BrowserRouter, Navigate, Route, Routes, Link
 } from 'react-router-dom';
 import ScrollSpy from "react-ui-scrollspy";
import Header from "../../common/Header/Header";
import Footer from "../../common/Footer/Footer";

import downimg from '../../assets/images/pics/downimg.png';


const Home = (props) => {  
    const [isActive, setActive] = useState("false");
    const ToggleClass = () => {
        setActive(!isActive); 
    };

    const parentScrollContainerRef = useRef(null);
    const onPress = (e) => {
        e.preventDefault();
        const target = window.document.getElementById(
          e.currentTarget.href.split("#")[1]
        );
        if (target) {
          var headerOffset = 97;
          var elementPosition = target.getBoundingClientRect().top;
          var offsetPosition = elementPosition - headerOffset;
    
          window.scrollBy({
            top: offsetPosition,
            behavior: "smooth",
          });
        }
      };

    return (
        <Fragment>
            <Header />
            <div className="pkchinsec">
                <div className="container">
                    <div className="d-flex sidebarCnt">
                        <div className={isActive ? "pksidebr" : "pksidebr SideMenuOpen"}>
                            <div className="sidenav">
                                <ul className="ml-0 pl-0">
                                    <li><a data-to-scrollspy-id="parkchain1" onClick={(e) => onPress(e)} href={"#parkchain1"}>Introduction</a></li>
                                    {/* <li><a data-to-scrollspy-id="parkchain2" onClick={(e) => onPress(e)} href={"#parkchain2"}>What is Parkchain?</a></li> */}
                                    <li><a data-to-scrollspy-id="parkchain3" onClick={(e) => onPress(e)} href={"#parkchain3"}>What is Parkchain?</a></li>
                                    <li><a data-to-scrollspy-id="parkchain4" onClick={(e) => onPress(e)} href={"#parkchain4"}>Thankyou - Connect With Us.</a></li>
                                </ul>
                            </div>                            
                        </div>
                        <svg id="hamburger" viewBox="0 0 100 100" onClick={ToggleClass}>
                            <rect className={isActive ? "hamburger-line hamburger-line-1" : "hamburger-line hamburger-line-1 active"} x="20" y="50" rx="1" width="60" height="2"></rect>
                            <rect className={isActive ? "hamburger-line hamburger-line-2" : "hamburger-line hamburger-line-2 active"} x="20" y="50" rx="1" width="60" height="2"></rect>
                            <rect className={isActive ? "hamburger-line hamburger-line-3" : "hamburger-line hamburger-line-3 active"} x="20" y="50" rx="1" width="60" height="2"></rect>
                        </svg>

                        <div className="pkmainsec" useRef={parentScrollContainerRef}>                        
                            <ScrollSpy scrollThrottle={100} useBoxMethod={true}>
                                <h2 className="kphedone">NFT Documentation</h2>
                                <h2 className="kphedtwo" id="parkchain1">Introduction</h2>
                                <p className="kpparaone">Parkchain revolutionizes blockchain technology by addressing the lack of a reliable and trusted source of time in existing blockchains. With its Proof of Authority consensus mechanism and verifiable passage of time, Parkchain establishes a robust and consistent timeline for events, enhancing the overall reliability and trustworthiness of blockchain-based systems.</p>                            

                                {/* <h2 className="kphedtwo" id="parkchain2">What is Parkchain?</h2>
                                <p className="kpparaone">Parkchain is built on the Parkchain protocol, serving as a full implementation that runs multiple nodes on the
                                Parkchain mainnet. By leveraging the Parkchain network effects, Parkchain benefits from the established
                                community, robust security guarantees, and continuous innovation that Parkchain offers. Parkchain is a
                                widely adopted blockchain platform renowned for its versatility and robust features. It serves as the
                                foundation for a multitude of decentralized applications (DApps) and is particularly well-suited for the
                                creation and management of non-fungible tokens (NFTs). With its scalable infrastructure and extensive
                                developer community, Parkchain has established itself as a leading platform for launching NFTs and
                                fostering a vibrant ecosystem around digital collectibles and unique digital assets. Its rich set of tools and
                                smart contract capabilities enable developers to create engaging and immersive NFT experiences, driving
                                innovation in the space. Parkchain's proven track record and commitment to providing a best-in-class NFT
                                creator experience have solidified its position as a go-to platform for NFT enthusiasts and creators alike.</p> */}

                                <h2 className="kphedtwo" id="parkchain3">What is Parkchain?</h2>
                                <p className="kpparaone">Parkchain is an innovative blockchain platform that aims to transform the finance industry by placing utmost importance on innovation, security, and user experience. Our platform empowers individuals and businesses to engage in financial transactions that are not only secure and efficient but also transparent. By harnessing the power of the Parkchain protocol, Parkchain seamlessly combines the strengths of the mainnet community with state-of-the-art advancements in privacy, permissioning, and consensus protocols. Our ultimate goal is to democratize finance and ensure that it is accessible to everyone, fostering trust, privacy, and innovation within the decentralized ecosystem.</p>

                                <h2 className="kphedtwo" id="parkchain4">Thankyou - Connect With Us.</h2>
                                <p className="kpparaone">Your invaluable support has made this project possible. We deeply appreciate your utilization of Parkchain and remain committed to enhancing them further to meet your needs. In case of any inquiries, we encourage you to connect with us on Discord. Alternatively, we invite you to contribute by opening a pull request, as your input would be highly valued and beneficial to our collective progress.</p>
    
                            </ScrollSpy>               
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
   
}

export default Home;