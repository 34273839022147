import "./scss/nft.scss";

import HtmlParser, { htmlparser2 } from "react-html-parser";
import React, {
  Component,
  Fragment,
  createRef,
  useEffect,
  useRef,
  useState,
} from "react";

import Footer from "../../common/Footer/Footer";
import Header from "../../common/Header/Header";
import ReactDOM from "react-dom";
import bnr from "./images/bnr.png";
import classnames from "classnames";
import cryptonft from "./images/cryptonft.png";
import metaverse from "./images/metaverse.png";
import nft from "./images/nft.png";
import nftcase from "./images/nftcase.png";
import typesofnft from "./images/typesofnft.png";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import whynft from "./images/whynft.png";

/**   nft docs content  **/

const NFTdocument = (props) => {
  const navigate = useNavigate();

  const [nftDocs, setNftDocs] = useState("");
  var Res = useSelector((state) => state.user);
  useEffect(() => {
    if (Res?.data?.status == 200) {
      Res = Res.data.data;
      setNftDocs(Res);
    }
  }, [Res, nftDocs]);
  return (
    <Fragment>
      <Header />
      <div className="nftdocument-pagecnt">
        {nftDocs &&
          nftDocs.map((item) => {
            if (item.heading == "nft docs heading") {
              return (
                <section className="kpkbanner-sec bluebgbnr-sec">
                  <div className="container">
                    <div className="row align-items-center">
                      <div className="col-lg-6">
                        <div className="cmnbnr-txt">
                          <h1>
                            NFT Creation <br /> <span>Documentation</span>
                          </h1>
                          <p>{HtmlParser(item.subcontentdescription)}</p>
                        </div>
                      </div>
                      <div className="col-lg-6 text-center mt-5 mt-lg-0">
                        <div>
                          <img src={item.image} alt="" className="img-fluid" />
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              );
            }
          })}

        {nftDocs &&
          nftDocs.map((item) => {
            if (item.heading == "nft docs content") {
              console.log("nft---", item);
              return (
                <section className="benifitparkchain-sec pb-5">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-6 col-lg-4 mt-5">
                        <div
                          className="benefit-inbox"
                          onClick={() => navigate("/nft")}
                          style={{ cursor: "pointer" }}
                        >
                          <h5>{item.title}</h5>
                          <div className="benefitpara-cnt">
                            <p>{HtmlParser(item.content_description)}</p>
                          </div>
                          <div className="benift-img">
                            <img
                              src={item.image}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                          {/* <div className="text-center">
                        <button className="btn btn-primary">Explore</button>
                     </div> */}
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-4 mt-5">
                        <div
                          className="benefit-inbox"
                          onClick={() => navigate("/typesofnft")}
                          style={{ cursor: "pointer" }}
                        >
                          <h5>{item.Overview}</h5>
                          <div className="benefitpara-cnt">
                            {/* <p>
                              There are various types of NFTs, representing
                              different categories of digital assets. Here are
                              some common types of NFTs:
                            </p>

                            <ul className="bullet-ul">
                              <li>Art NFTs</li>
                              <li>Collectibles NFTs</li>
                              <li>Gaming NFTs</li>
                              <li>Music NFTs</li>
                              <li>Virtual Real Estate NFTs</li>
                            </ul> */}

                            {HtmlParser(item.OverviewDes)}
                          </div>
                          <div className="benift-img">
                            <img
                              src={item.sub_image}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                          {/* <div className="text-center">
                        <button className="btn btn-primary">Explore</button>
                     </div> */}
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-4 mt-5">
                        <div
                          className="benefit-inbox"
                          style={{ cursor: "pointer" }}
                          onClick={() => navigate("/metaversenft")}
                        >
                          <h5>{item.Transactions}</h5>
                          <div className="benefitpara-cnt">
                            <p>{HtmlParser(item.TransactionDesc)}</p>
                          </div>
                          <div className="benift-img">
                            <img
                              src={item.sub_image1}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                          {/* <div className="text-center">
                        <button className="btn btn-primary">Explore</button>
                     </div> */}
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-4 mt-5">
                        <div
                          className="benefit-inbox"
                          onClick={() => navigate("/nftcase")}
                          style={{ cursor: "pointer" }}
                        >
                          <h5>{item.Blocks}</h5>
                          <div className="benefitpara-cnt">
                            {/* <p>
                              NFTs (Non-Fungible Tokens) have a wide range of
                              use cases across various industries and sectors.
                              Here are some notable use cases for NFTs:
                            </p>

                            <ul className="bullet-ul">
                              <li>Games</li>
                              <li>Metaverse</li>
                              <li>Art's</li>
                              <li>Realestates</li>
                              <li>Shopping Malls</li>
                            </ul> */}

                            {HtmlParser(item.BlockDes)}
                          </div>
                          <div className="benift-img">
                            <img
                              src={item.subimage2}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                          {/* <div className="text-center">
                        <button className="btn btn-primary">Explore</button>
                     </div> */}
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-4 mt-5">
                        <div
                          className="benefit-inbox"
                          onClick={() => navigate("/cryptonft")}
                          style={{ cursor: "pointer" }}
                        >
                          <h5>{item.savings}</h5>
                          <div className="benefitpara-cnt">
                            <p>{HtmlParser(item.savings_dec)}</p>
                          </div>
                          <div className="benift-img">
                            <img
                              src={item.subimage3}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                          {/* <div className="text-center">
                        <button className="btn btn-primary">Explore</button>
                     </div> */}
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-4 mt-5">
                        <div
                          className="benefit-inbox"
                          onClick={() => navigate("/whynft")}
                          style={{ cursor: "pointer" }}
                        >
                          <h5>{item.security}</h5>
                          <div className="benefitpara-cnt">
                            {/* <p>
                              Parkchain is a blockchain platform developed by
                              the cryptocurrency exchange Koinpark. While
                              Parkchain was primarily designed for the trading
                              of cryptocurrencies, it has also gained popularity
                              as a platform for NFTs.
                            </p>
                            <ul className="bullet-ul">
                              <li>Unique Digital Assets</li>
                              <li>Blockchain Technology</li>
                              <li>Ownership and Provenance</li>
                              <li>Diverse Use Cases</li>
                              <li>Digital Art Revolution</li>
                              <li>Value and Market Dynamics</li>
                            </ul> */}

                            {HtmlParser(item.security_dec)}
                          </div>
                          <div className="benift-img">
                            <img
                              src={item.subimage4}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                          {/* <div className="text-center">
                        <button className="btn btn-primary">Explore</button>
                     </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              );
            }
          })}
      </div>
      <Footer />
    </Fragment>
  );
};
export default NFTdocument;
