import "./kphckcr.scss";

import { BrowserRouter, Link, Navigate, Route, Routes } from "react-router-dom";
import {
  Button,
  Col,
  CustomInput,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { CONST, KOIN_PARK_API, PARKCHAIN_API } from "../../_config";
import React, {
  Component,
  Fragment,
  createRef,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ToastContainer, toast } from "react-toastify";
import { browserName, browserVersion, deviceType } from "react-device-detect";

import Countdown from "react-countdown";
import Logo from "./assets/logo.svg";
import ReactDOM from "react-dom";
import axios from "axios";
import classnames from "classnames";
import closeCircle from "./assets/close-circle.svg";
import laptop from "./assets/laptop.png";
import success from "./assets/success.gif";

const OTP = ({ numInputs = 6 }) => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [keys, setKeys] = useState(0);

  const [time, setTime] = useState();

  const toggle2 = () => setModal(!modal);
  const inputRefs = useRef([]);
  const [otp, setOtp] = useState(Array(numInputs).fill(""));
  var userID = localStorage.getItem("user_id");
  const handleInputChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Move focus to the next input when typing
    if (value && index < numInputs - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text").slice(0, numInputs);
    const newOtp = pastedData.split("");
    setOtp(newOtp);
  };

  const handleKeyDown = (e, index) => {
    switch (e.key) {
      case "ArrowLeft":
        e.preventDefault();
        // Move focus to the previous input
        if (index > 0) {
          inputRefs.current[index - 1].focus();
        }
        break;
      case "ArrowRight":
        e.preventDefault();
        // Move focus to the next input
        if (index < numInputs - 1) {
          inputRefs.current[index + 1].focus();
        }
        break;
      case "Backspace":
        e.preventDefault();
        // Delete the current input and move focus to the previous input
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
        if (index > 0) {
          inputRefs.current[index - 1].focus();
        }
        break;
      default:
        break;
    }
  };

  const VerifyOtp = () => {
    setLoading(true);

    const data = {
      otp: otp.join(""),
      tfa_or_mob_status: 1,
      login_verify_token: userID.login_verify_token,

      device_type: userID.device_type,
      device_IMEI: userID.device_IMEI,
      device_model: userID.device_model,
      device_browser: userID.device_browser,
      id: userID.user_id,
    };
    axios
      .post(PARKCHAIN_API.parkChain + "auth/verify_login", data)
      .then((res) => {
        setLoading(false);

        if (res.data.responseCode == 200) {
          setLoading(false);

          const authToken = {
            jwt: res.data.Jtoken,
            token: res.data.token,
          };

          localStorage.setItem("auth", JSON.stringify(authToken));

          toast.success(res.data.message);
        } else {
          setLoading(false);
          console.log(res.data.message);
          toast.error(res.data.message);
        }
      });
  };

  const ResendOtp = () => {
    localStorage.setItem("time", Date.now() + 60000);
    setKeys((c) => c + 1);

    const resend = {
      device_type: userID?.device_type,
      device_IMEI: userID?.device_IMEI,
      device_model: userID?.device_model,
      device_browser: userID?.device_browser,
      id: userID?.user_id,
    };

    axios
      .post(PARKCHAIN_API.parkChain + "auth/resend_login_otp  ", resend)
      .then((res) => {
        console.log(res);
      });
  };

  const Completionist = () => {
    return (
      <div className="text-center mt-2">
        <p className="receive-msg">
          Didn't receive code ?{" "}
          <span type="submit" onClick={ResendOtp} className="otp-seconds">
            Resend
          </span>
        </p>
      </div>
    );
  };

  //localStorage.setItem("time", time);

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist />;
    } else {
      return (
        <span style={{ fontSize: "13px", color: "gray" }}>
          Receive OTP in:
          <p
            style={{
              color: "blue",
              display: "contents",
              fontSize: "12px",
              fontWeight: "400",
            }}
          >
            <span>
              {minutes} : {seconds}
            </span>
          </p>
        </span>
      );
    }
  };

  const timer = useMemo(
    () => (
      <Countdown
        date={+localStorage.getItem("time") || Date.now() + 10000}
        renderer={renderer}
        key={keys}
      />
    ),
    [keys]
  );
  return (
    <Fragment>
      {loading == true ? (
        <div className="pageloade">
          <div className="page-loader">
            <div class="loader-12">
              <span class="loader_arrow"></span>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className="hckcrPg hackathonotp-page">
        <div className="container">
          <div className="hckcrPgCon">
            <div className="row hckcrPgR">
              <div className="col-sm-12 col-md-12 col-lg-7 col-xl-6 hckcrPgC lt">
                <div className="hckcrPgInrC">
                  <div className="hckcrPgLgo">
                    <img src={Logo} alt="Logo" />
                  </div>
                  <div className="otp-time">
                    <h5>
                      We have sent you <span>One Time password</span> to your
                      Mobile Number
                    </h5>
                  </div>
                  <div className="hckcrFrm">
                    <div className="row hckcrFrmR">
                      <div className="col-12 hckcrFrmC">
                        <FormGroup>
                          <Label for="">
                            Please Enter OTP<span>*</span>
                          </Label>

                          <div className="otp-input">
                            {Array.from({ length: numInputs }).map(
                              (_, index) => (
                                <input
                                  key={index}
                                  ref={(ref) =>
                                    (inputRefs.current[index] = ref)
                                  }
                                  type="text"
                                  value={otp[index]}
                                  maxLength="1"
                                  onChange={(e) =>
                                    handleInputChange(index, e.target.value)
                                  }
                                  onPaste={handlePaste}
                                  onKeyDown={(e) => handleKeyDown(e, index)}
                                />
                              )
                            )}
                          </div>

                          {/* <div className="otp-input">
                            <Input maxLength="1" />
                    
                            <Input maxLength="1" />
                          </div> */}
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                  {/* <p className="otp-seconds"  >2:52s</p> */}
                  {timer};
                  <div className="hckcrFrmSbmRd pt-4">
                    <button
                      class="btn btn-17442 btn-bluFl v2"
                      onClick={VerifyOtp}
                    >
                      Verify OTP
                    </button>
                  </div>
                  {/* <div className="resendotp">
                    <button onClick={ResendOtp}>Resend OTP</button>
                  </div> */}
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-5 hckcrPgC rt">
                <div className="hckcrPgInrIgTk">
                  <div className="hckCrPgIg">
                    <img src={laptop} alt="laptop" />
                  </div>
                  <div className="hckCrPgTx">
                    <h1>
                      Welcome to <br /> Login
                    </h1>
                    <p> &copy; 2023 Parkchain - All rights reserved.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default OTP;
