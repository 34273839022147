import React, { Component, Fragment, useState, useRef, useEffect, createRef } from "react";
import ReactDOM from "react-dom";
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import Overview from '../Overview/Overview';
import NFTDocumentation from '../NFTDocumentation/NFTDocumentation';
import DeveloperProgram from '../DeveloperProgram/DeveloperProgram';
import {
    BrowserRouter, Navigate, Route, Routes, Link
} from 'react-router-dom';

import Header from "../../common/Header/Header";
import Footer from "../../common/Footer/Footer";

import mt2 from '../../assets/images/pics/mt2.png';
import kpmintimg1 from '../../assets/images/pics/kpmintimg1.png';
import mintimg1 from '../../assets/images/pics/mintimg1.png';
import mintimg2 from '../../assets/images/pics/mintimg2.png';
import kpmintimg2 from '../../assets/images/pics/kpmintimg2.png';
import mintimg3 from '../../assets/images/pics/mintimg3.png';
import mintimg4 from '../../assets/images/pics/mintimg4.png';
import rightBanner from '../../assets/images/pics/rightBanner.png';
import newcameImg from '../../assets/images/gaming/nft.png'
import { useSelector } from "react-redux";
import HtmlParser from "react-html-parser";


const Home = (props) => {
    const [activeTab, setActiveTab] = useState('1');
    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }
    let data, data1, data2, data3, data4, data5, data6, data7, data8;
    data = useSelector(state => state.user);
    if (data.data.status == 200) {
        data = data.data.data;
        data1 = data.find(elem => elem.title == 'Scale Your NFT Universe');
        data2 = data.find(elem => elem.title == 'banner header - NFT');
        data3 = data.find(elem => elem.title == 'banner content1 - NFT');
        data4 = data.find(elem => elem.title == 'banner content2 - NFT');
        data5 = data.find(elem => elem.title == 'body content1 - NFT');
        data6 = data.find(elem => elem.title == 'body content2 - NFT');
        data7 = data.find(elem => elem.title == 'body content3 - NFT');
        data8 = data.find(elem => elem.title == 'body content4 - NFT');
    }
    const [classIsActive, setClassIsActive] = useState(true);
    const smClose = () => {
        setClassIsActive(!classIsActive);
        classIsActive ? document.body.classList.add('sbMenu') : document.body.classList.remove('sbMenu');
    }
    return (
        <Fragment>
            <Header />
            <div class="pkchinsec">
                <section class="kpmintbanner">
                    {/* <div class="mintbg"><img src={mt2} alt="" class="img-fluid" /></div> */}
                    <div class="container">
                        <div class="row align-items-start">
                            <div class="col-lg-7">
                                <h5 class="defihd">{data1 ? HtmlParser((data1.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</h5>
                                <h2 class="kpgamehead">{data2 ? HtmlParser((data2.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</h2>
                                <p class="kpgamepara">{data3 ? HtmlParser((data3.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                <p class="kpgamepara">{data4 ? HtmlParser((data4.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                            </div>
                            <div class="col-lg-5">
                                <div class="mintglimg"><img src={kpmintimg1} alt="" class="img-fluid" /></div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="">
                    <div class="container">
                        <div class="row align-items-center justify-content-center mt-5">
                            <div class="col-lg-4 col-md-12 mb-4">
                                <div class="kpmintbx kpvioletbg">
                                    <div class="mb-2"><img src={mintimg1} alt="" class="img-fluid" /></div>
                                    {/* <h4>Always On-chain, Forever Immutable</h4>
                                    <p>Parkchain ensures a fully decentralized on-chain experience for artists and collectors, where everything from auctions to perpetual royalties is securely encoded within the NFT. Embrace the power of Parkchain, where immutability reigns and creativity thrives in a trustless ecosystem.</p> */}
                                    {data5 ? HtmlParser((data5.content_description)) : <></>}
                                </div>
                            </div>
                            <div class="col-lg-7 col-md-12 mb-4">
                                <div class="textcenter"><img src={newcameImg} alt="" class="img-fluid" /></div>
                            </div>
                            <div class="col-lg-7 col-md-12 mb-4">
                                <div class="kpmintbx kpbluebg">
                                    <div class="mb-2"><img src={mintimg2} alt="" class="img-fluid" /></div>
                                    {/* <h4>Secure and Immutable Storage Solutions</h4>
                                    <p>Tailor your project's web3 storage to perfection by choosing the optimal option for permanence. Embrace the Dedicate your energy to creating exceptional artwork, not writing a new smart contract, as the Parkchain NFT standard and minting program handle the technical complexities. Experience unparalleled customizability, backed by widespread ecosystem support, as you seamlessly navigate the world of NFTs on Parkchain. storage offered by ARweave or leverage other renowned standards like IPFS, ensuring your data remains secure and immutable. Empower your project with the ideal storage solution, harmonizing with Parkchain's robust ecosystem.</p> */}
                                    {data6 ? HtmlParser((data6.content_description)) : <></>}
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mb-4">
                                <div class="kpmintbx kpgreenbg">
                                    <div class="mb-2"><img src={mintimg3} alt="" class="img-fluid" /></div>
                                    {/* <h4>NFT Standard</h4>
                                    <p>Dedicate your energy to creating exceptional artwork, not writing a new smart contract, as the Parkchain NFT standard and minting program handle the technical complexities. Experience unparalleled customizability, backed by widespread ecosystem support, as you seamlessly navigate the world of NFTs on Parkchain.</p> */}
                                    {data7 ? HtmlParser((data7.content_description)) : <></>}
                                </div>
                            </div>
                            <div class="col-lg-11 col-md-12 mb-4">
                                <div class="kpmintbx kpsandalbg">
                                    <div class="mb-2"><img src={mintimg4} alt="" class="img-fluid" /></div>
                                    {/* <h4>Cost-Effective Scalability</h4>
                                    <p>Forge meaningful and cost-efficient customer connections on a grand scale. With state compression, minting thousands or millions of NFTs becomes incredibly affordable, amounting to just a fraction of the cost, potentially totaling a few hundred dollars. Embrace the advantage of cost-effective scalability, empowering you to build captivating experiences while optimizing your resources.</p> */}
                                    {data8 ? HtmlParser((data8.content_description)) : <></>}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="text-center py-2">
                    <a href="https://parkwallet.net/" target="_blank" class="btn btn-primary px-4">
                        Explore
                    </a>
{/* 
                    <div class="mobMenu d-lg-none" >
                        <div class="smClose" onClick={smClose} >
                            <svg id="closeicon" viewBox="0 0 800 600">
                                <path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" id="top"></path>
                                <path d="M300,320 L460,320" id="middle"></path>
                                <path d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190" id="bottom" transform="translate(480, 320) scale(1, -1) translate(-480, -318) "></path>
                            </svg>
                        </div>
                    </div> */}

                </div>
                
            </div>
            <Footer />
        </Fragment>
    );

}

export default Home;