import React, { Component, Fragment, useState, useRef, useEffect, createRef  } from "react";
import ReactDOM from "react-dom";
import classnames from 'classnames';
import Header from "../../common/Header/Header";
import Footer from "../../common/Footer/Footer";


const Comingsoon = (props) => 
{
return (
<Fragment>
   <Header />

   <div className="comingsoon-page">
        
        <h1>Comming Soon</h1>

   </div>

 
   <Footer />
</Fragment>
);
}
export default Comingsoon;