import {
  TimeConverter,
  dashboard,
  eventsInfo,
  getUserList,
  getUserProfile,
  localUserId,
  profileInfo,
  siteInform,
} from "./action";

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  data1: [],
  data2: [],
  profile: [],
  isLoading: false,
  isSuccess: false,
  errorMessage: "",
  userId: null,
  userProfile: "",
  dashboardInfo: "",
  timeValue: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  extraReducers: {
    [getUserList.pending]: (state) => {
      state.isLoading = true;
    },
    [getUserList.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },

    [getUserList.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
    [siteInform.fulfilled]: (state, { payload }) => {
      state.data1 = payload;
    },

    [eventsInfo.fulfilled]: (state, { payload }) => {
      state.data2 = payload;
    },
    [profileInfo.fulfilled]: (state, { payload }) => {
      state.profile = payload;
    },

    [profileInfo.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },

    [localUserId.fulfilled]: (state, { payload }) => {
      state.userId = payload;
    },

    [getUserProfile.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.userProfile = payload;
    },

    [getUserProfile.rejected]: (state, { payload }) => {
      state.errorMessage = payload;
      state.isLoading = false;
    },

    [getUserProfile.pending]: (state, { payload }) => {
      state.isLoading = true;
    },

    [dashboard.pending]: (state, { payload }) => {
      state.isLoading = true;
    },

    [dashboard.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.dashboardInfo = payload;
    },

    [dashboard.rejected]: (state, { payload }) => {
      state.errorMessage = payload;
      state.isLoading = false;
    },

    [TimeConverter.fulfilled]: (state, { payload }) => {
      state.timeValue = payload;
    },
  },
});

export default userSlice.reducer;
