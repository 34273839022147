export const CONST = {
  // BACKEND_URL: 'https://bckpkcnliv.parkchain.org/'
  // BACKEND_URL:'http://localhost:6400/'
  //BACKEND_URL: "https://parkchainback.osiztech.com/",
  BACKEND_URL: "https://bckpkcnliv.parkchain.org/",
  //BACKEND_URL: "http://172.16.16.169:6400/",
  chainId:'1397'

   
};

export const PARKCHAIN_API = {
  // parkChain: "http://172.16.16.139:9201/",
  // parkChain: "https://konprkusrbakkweb.koinpark.com/",
  parkChain:"https://knprklvusrbknd.koinpark.com/"
};
