import "react-toastify/dist/ReactToastify.css";

import { Bounce, Flip, Slide, Zoom } from "react-toastify";
import {
  BrowserRouter,
  Link,
  Navigate,
  Route,
  Routes,
  useParams,
} from "react-router-dom";
import React, {
  Component,
  Fragment,
  createRef,
  useEffect,
  useRef,
  useState,
} from "react";
import { ToastContainer, toast } from "react-toastify";

import { CONST } from "../../../_config";
import HtmlParser from "react-html-parser";
import ai from "../../../assets/images/hackathonList/ai.svg";
import artistry from "../../../assets/images/hackathonList/artistry.svg";
import axios from "axios";
import builder from "../../../assets/images/hackathonList/builder.svg";
import defi from "../../../assets/images/hackathonList/defi.svg";
import web3 from "../../../assets/images/hackathonList/web3.svg";

const HackathonThemes = (props) => {
  const params = useParams();
  console.log(params);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDd = () => setDropdownOpen((prevState) => !prevState);

  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const [HackathonDetails, setHackathonDetails] = useState([]);

  useEffect(() => {
    SingleHcakdetails();
  }, []);

  const SingleHcakdetails = async () => {
    let hackdata = {
      _id: params.id,
    };

    let getdata = await axios.post(
      CONST.BACKEND_URL + "listHackthonById",
      hackdata
    );
    console.log(getdata.data.data, "Listhacksddddddd");
    if (getdata.data.status == true) {
      // toast.success(getdata.data.message,
      //     {
      //         transition: Flip,
      //         className: 'foo-bar'
      //     })
      setHackathonDetails(getdata.data.data);
    }
  };

  return (
    // <Fragment>
    //     {/* <div className="hckDtCms"> */}
    //         <h6>Themes</h6>
    //         {/* <div className="hckDtThmR">

    //             <div className="hckDtThmC">
    //                 <div className="hckDtThmCd">
    //                     <div className="hckDtThmCdIc">
    //                         <img src={web3} alt="web3" />
    //                     </div>
    //                     <div className="hckDtThmCdTl">
    //                         Web3
    //                     </div>
    //                     <div className="hckDtThmCdTx">
    //                         Contribute to the transition of Web 2.0 to Web 3.0 by introducing useful DAO tools, SocialFi, blockchain/web infrastructure, SDKs and other Dapps. Applications that are not centered around NFTs, Finance or gaming.
    //                     </div>
    //                 </div>
    //             </div>

    //             <div className="hckDtThmC">
    //                 <div className="hckDtThmCd">
    //                     <div className="hckDtThmCdIc">
    //                         <img src={defi} alt="defi" />
    //                     </div>
    //                     <div className="hckDtThmCdTl">
    //                         DeFi
    //                     </div>
    //                     <div className="hckDtThmCdTx">
    //                         Transforming finance through decentralization. Empowering individuals with transparent, accessible, and inclusive protocols for asset management and wealth creation.
    //                     </div>
    //                 </div>
    //             </div>

    //             <div className="hckDtThmC">
    //                 <div className="hckDtThmCd">
    //                     <div className="hckDtThmCdIc">
    //                         <img src={artistry} alt="artistry" />
    //                     </div>
    //                     <div className="hckDtThmCdTl">
    //                         Artistry
    //                     </div>
    //                     <div className="hckDtThmCdTx">
    //                         Unleash your creative prowess in visual projects that embrace GameFi, NFTs, assets, and ventures related to the Metaverse. Showcasing your submission’s blockchain.
    //                     </div>
    //                 </div>
    //             </div>

    //             <div className="hckDtThmC">
    //                 <div className="hckDtThmCd">
    //                     <div className="hckDtThmCdIc">
    //                         <img src={builder} alt="artistry" />
    //                     </div>
    //                     <div className="hckDtThmCdTl">
    //                     Builder
    //                     </div>
    //                     <div className="hckDtThmCdTx">
    //                     For teams who have previously participated in TRON hackathon and have a working project that they would like to improve upon and update with new features.
    //                     </div>
    //                 </div>
    //             </div>

    //             <div className="hckDtThmC">
    //                 <div className="hckDtThmCd">
    //                     <div className="hckDtThmCdIc">
    //                         <img src={ai} alt="ai" />
    //                     </div>
    //                     <div className="hckDtThmCdTl">
    //                     A.I.
    //                     </div>
    //                     <div className="hckDtThmCdTx">
    //                     Embrace the power of Artificial Intelligence to make a positive impact. These projects should leverage the capabilities of the TRON/BTTC blockchain to craft solutions that promote the usage of AI.
    //                     </div>
    //                 </div>
    //             </div>

    //         </div> */}

    //         {HackathonDetails && HackathonDetails.length > 0 ?
    //             HackathonDetails.map((data) => {

    //                 <div className="hckDtThmR">
    //                     <p>{data ? HtmlParser(data.theme_description) : <></>}</p>
    //                 </div>
    //             }) : (
    //                 <><h3>No data found</h3></>
    //             )
    //         }
    //     {/* </div> */}
    // </Fragment>
    <Fragment>
      {HackathonDetails && HackathonDetails.length > 0 ? (
        HackathonDetails.map((data, index) => (
          <div className="hckDtCms">
            {props?.feature == true ? <> </> : <h6>{data.sub_content}</h6>}

            {/* <ul>
                        <li>Residents from Brazil, Crimea, Cuba, Iran, North Korea, Syria, Quebec and Nigeria are not eligible to participate in the hackathon.</li>
                        <li>Individuals who are at least the age of majority in the country where they reside as of the time of entry Teams or Organizations of any size are welcome to join.</li>
                        <li>Entrants must create (or significantly update) a working software application/dApp/website that integrates with the TRON or BitTorrent blockchain that fits into one of the hackathon tracks</li>
                        <li>Entrants must register not only on HackerEarth but also register on the TRON DAO Forum and create a post for the TRON Community under the season 5 category for TRON community voting.</li>
                    </ul>

                    <br />

                    <p>Your submission should include the following items by the end of the submission period:</p>
                    <ol>
                        <li>Complete project description.</li>
                        <li>Valid personal contact</li>
                        <li>a TRON DAO Forum Submission link, following TRON DAO Forum Submission Requirements below.</li>
                        <li>A demo video that is publicly visible on the Internet and explains Entrant’s track and includes footage that explains your project’s features and functionality comprehensively. No less than 3 minutes.</li>
                        <li>The Smart Contracts deployment links on TRON/BTTC (Mainnet / Testnet).</li>
                        <li>A detailed description of how your Project integrates with TRON/BTTC.</li>
                        <li>If the Project has been submitted in any form to another hackathon, include which Hackathons.</li>
                        <li>Links to your code repository, website and socials to help judges review your submission. If Entrant’s website is private, Entrant must include login credentials in its testing instructions.</li>
                    </ol> */}
            {/* <p>{data ? HtmlParser(data.theme_description) : <></>}</p> */}
            <p>{data ? HtmlParser(data.content_description) : <></>}</p>
          </div>
        ))
      ) : (
        <></>
      )}
    </Fragment>
  );
};

export default HackathonThemes;
