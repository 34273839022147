import { CONST, PARKCHAIN_API } from "../_config";

import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

export const getUserList = createAsyncThunk(
  "user/getUserList",
  async (rejectWithValue) => {
    try {
      let { data } = await axios.get(CONST.BACKEND_URL + "users/getallcms");
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const siteInform = createAsyncThunk("user/siteinform", async () => {
  try {
    let { data } = await axios.get(CONST.BACKEND_URL + "users/site_inform");
    return data;
  } catch (err) {}
});

export const eventsInfo = createAsyncThunk(
  "user/geteventCollection",
  async () => {
    try {
      let { data } = await axios.get(CONST.BACKEND_URL + "geteventCollection");
      return data;
    } catch (err) {}
  }
);

export const profileInfo = createAsyncThunk("dashboard/profile", async () => {
  var userIdData = localStorage.getItem("user_id");
  console.log("-----", userIdData?.user_id);

  var auth = localStorage.getItem("auth");
  auth = JSON.parse(auth);

  console.log("-----", auth);

  try {
    const config = {
      headers: {
        Authorization: auth?.jwt,
        validtoken: auth?.token,
      },
    };

    const userId = {
      userId: userIdData?.user_id,
    };

    let { data } = await axios.post(
      PARKCHAIN_API.parkChain + "dashboard/profile",
      userId,
      config
    );
    return data;
  } catch (err) {}
});

export const dashboard = createAsyncThunk(
  "users/userProfileCreated",

  async (data) => {
    console.log("data---", data);
    const parkchainDb = {
      user_id: data.user_id,
      userName: data?.username,
      first_name: data?.first_name,
      last_name: data?.last_name,
      email: data?.email,
      country: data?.user_country_fullname,
      country_id: data?.country_id,
      facebook: data?.facebook,
      telegram: data?.telegram,
      instagram: data?.instagram,
      linkedIn: data?.Linkedin,
      twitter: data?.twitter,
      medium: data?.medium,
      mobile: Number(data?.mobile_number),
      referal_code: data?.referal_code,
      profile_img: "",
      otp_verified: data?.Is_otp_verified,
    };

    try {
      let { data } = await axios
        .post(CONST.BACKEND_URL + "users/userProfileCreated", parkchainDb)
        .then((res) => {
          if (res.data.status == false) {
            // toast.error(res.data.message);
          }
        });

      return data;
    } catch (err) {}
  }
);

export const localUserId = createAsyncThunk("user_id", async () => {
  var userId = localStorage.getItem("user_id");
  return userId;
});

export const getUserProfile = createAsyncThunk(
  "user/getUserProfile",

  async (payload) => {
    console.log("value------");

    var config = {
      header: {
        orgin: "http://localhost:3000",
      },
    };

    try {
      let { data } = await axios.post(
        CONST.BACKEND_URL + "users/getUserProfile",
        payload,
        config
      );
      return data;
    } catch (err) {}
  }
);

export const TimeConverter = createAsyncThunk("time", async (time) => {
  var lastUpdateDate = new Date(time).toLocaleString("en-US", {
    weekday: "short",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    //timeZoneName: "short",
  });

  return lastUpdateDate;
});
