import React, { Component, Fragment, useState, useRef, useEffect, createRef  } from "react";
import ReactDOM from "react-dom";
import { Table } from 'reactstrap';
import classnames from 'classnames';
import Header from "../../common/Header/Header";
import Footer from "../../common/Footer/Footer";
import './scss/stake.scss';
/** Images  **/
import stakebnr from './images/stakebnr.png';
import discover from './images/discover.png';
import comstructure from './images/structure.png';
import network from './images/network.png';
import compliance from './images/compliance.png';
import vision from './images/vision.png';
import metapark from './images/metapark.png';
import plan from './images/plan.png';
import reward from './images/rewards.png';
import growth from './images/growth.png';
import stakeplans from './images/plan1.png';

import { useSelector, useDispatch } from 'react-redux';
import HtmlParser from "react-html-parser";

const Stake = (props) => {  
   let data = useSelector(state => (state.user));

   let data1, data2, data3, data4, data5, data6, data7, data8, data9, data10,data11;

   if (data.data.status == 200) {
      data = (data.data.data)
      data1 = (data.find(elem => elem.title == 'Heading Staking'))
      data2 = (data.find(elem => elem.title == "Discover Passive Income with Our KPK Park's Staking Platform"))
      data3 = (data.find(elem => elem.title == 'The Staking Program Compensation Structure'))
      data4 = (data.find(elem => elem.title == "KPK Park's Network of Educators and Influencers"))
      data5 = (data.find(elem => elem.title == 'Commitment to Compliance and Transparency'))
      data6 = (data.find(elem => elem.title == 'Our Vision and Mission'))
      data7 = (data.find(elem => elem.title == 'Join Our Metapark Club and Book Your Plot with KPK Coins!'))
      data8 = (data.find(elem => elem.title == 'The Staking Plan'))
      data9 = (data.find(elem => elem.title == 'Unlimited Referral Rewards'))
      data10 = (data.find(elem => elem.title == 'Growth Level Commission'))
      data11 = (data.find(elem => elem.title == 'Growth Level Commission new'))



   }
   console.log(data1, "data1");
return (
<Fragment>
   <Header />
   <div className="stakepage-content">
      <section className="stakebnr-sec">
         <div className="container">
            <div className="row align-items-center">
               <div className="col-lg-7">
                  <div className="stakebnr-leftcnt">
                     <h1>{data1?HtmlParser(data1.subtitle):<></>}</h1>
                     <p>{data1?HtmlParser((data1.content_description).replace(/(<([^>]+)>)/gi, "")):<></>}</p>
                     <p>{data1?HtmlParser((data1.subcontentdescription).replace(/(<([^>]+)>)/gi, "")):<></>}</p>
                  </div>
               </div>
               <div className="col-lg-5 text-center mt-4">
                  <div className="stakebnr-rightcnt">
                     <img src={stakebnr} alt="" className="img-fluid" />
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section className="stakeleftright-sec pt-4">
         <div className="container">
            <div className="row align-items-center">
               <div className="col-lg-8">
                  <div className="stakelr-cnt">
                     <h2>{data2?HtmlParser(data2.subtitle):<></>}</h2>
                     <p>{data2?HtmlParser((data2.content_description).replace(/(<([^>]+)>)/gi, "")):<></>}</p>
                     <p>{data2?HtmlParser((data2.subcontentdescription).replace(/(<([^>]+)>)/gi, "")):<></>}</p>
                  </div>
               </div>
               <div className="col-lg-4">
                  <div className="stakelr-img mt-3 mt-lg-0">
                     <img src={data2?HtmlParser(data2.image):<></>} alt="" className="img-fluid" />
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section className="stakeleftright-sec">
         <div className="container">
            <div className="stake-bgbox">
               <div className="row align-items-center">
                  <div className="col-lg-4 orderImg mt-3 mt-lg-0">
                     <div className="stakelr-img">
                        <img src={data3?HtmlParser(data3.image):<></>} alt="" className="img-fluid" />
                     </div>
                  </div>
                  <div className="col-lg-8">
                     <div className="stakelr-cnt">
                        <h2>{data3?HtmlParser(data3.title):<></>}</h2>
                        <p>{data3?HtmlParser((data3.content_description).replace(/(<([^>]+)>)/gi, "")):<></>}</p>
                        <p>{data3?HtmlParser((data3.subcontentdescription).replace(/(<([^>]+)>)/gi, "")):<></>}</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section className="stakeleftright-sec pt-5">
         <div className="container">
            <div className="row align-items-center">
               <div className="col-lg-8">
                  <div className="stakelr-cnt">
                     <h2>{data4?HtmlParser(data4.title):<></>}</h2>
                     <p>{data4?HtmlParser((data4.content_description).replace(/(<([^>]+)>)/gi, "")):<></>}</p>
                     <p>{data4?HtmlParser((data4.subcontentdescription).replace(/(<([^>]+)>)/gi, "")):<></>}</p>
                  </div>
               </div>
               <div className="col-lg-4">
                  <div className="stakelr-img mt-3 mt-lg-0">
                     <img src={data4?HtmlParser(data4.image):<></>} alt="" className="img-fluid" />
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section className="stakeleftright-sec pt-4">
         <div className="container">
            <div className="stake-bgbox">
               <div className="row align-items-center">
                  <div className="col-lg-4 orderImg mt-3 mt-lg-0">
                     <div className="stakelr-img">
                        <img src={data5?HtmlParser(data5.image):<></>} alt="" className="img-fluid" />
                     </div>
                  </div>
                  <div className="col-lg-8">
                     <div className="stakelr-cnt">
                        <h2>{data5?HtmlParser(data5.title):<></>}</h2>
                        <p>{data5?HtmlParser((data5.content_description).replace(/(<([^>]+)>)/gi, "")):<></>}</p>
                        <p>{data5?HtmlParser((data5.subcontentdescription).replace(/(<([^>]+)>)/gi, "")):<></>}</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section className="stakeleftright-sec pt-5">
         <div className="container">
            <div className="row align-items-center">
               <div className="col-lg-8">
                  <div className="stakelr-cnt">
                     <h2>{data6?HtmlParser(data6.title):<></>}</h2>
                     <p>{data6?HtmlParser((data6.content_description).replace(/(<([^>]+)>)/gi, "")):<></>}</p>
                     <p>{data6?HtmlParser((data6.subcontentdescription).replace(/(<([^>]+)>)/gi, "")):<></>}</p>
                  </div>
               </div>
               <div className="col-lg-4">
                  <div className="stakelr-img mt-3 mt-lg-0">
                     <img src={data6?HtmlParser(data6.image):<></>} alt="" className="img-fluid" />
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section className="stakeleftright-sec pt-3">
         <div className="container">
            <div className="stake-bgbox">
               <div className="row align-items-center">
                  <div className="col-lg-4 orderImg mt-3 mt-lg-0">
                     <div className="stakelr-img">
                        <img src={data7?HtmlParser(data7.image):<></>} alt="" className="img-fluid" />
                     </div>
                  </div>
                  <div className="col-lg-8">
                     <div className="stakelr-cnt">
                        <h2>{data7?HtmlParser(data7.title):<></>}</h2>
                        <p>{data7?HtmlParser((data7.content_description).replace(/(<([^>]+)>)/gi, "")):<></>}</p>
                        <p>{data7?HtmlParser((data7.subcontentdescription).replace(/(<([^>]+)>)/gi, "")):<></>}</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section className="stakeleftright-sec pt-5">
         <div className="container">
            <div className="row align-items-center">
               <div className="col-lg-8">
                  <div className="stakelr-cnt">
                     <h2>{data8?HtmlParser(data8.title):<></>}</h2>
                     <p>{data8?HtmlParser((data8.content_description).replace(/(<([^>]+)>)/gi, "")):<></>}</p>
                     <p>{data8?HtmlParser((data8.subcontentdescription).replace(/(<([^>]+)>)/gi, "")):<></>}</p>
                  </div>
               </div>
               <div className="col-lg-4">
                  <div className="stakelr-img mt-3 mt-lg-0">
                     <img src={data8?HtmlParser(data8.image):<></>} alt="" className="img-fluid" />
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section className="pb-4">
        <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <img src={stakeplans} alt="" className="img-fluid" />
                    </div>
                </div>
        </div>
        </section>
       <section className="stakebox-tbl">
         <div className="container">
            <div className="stakeinr-cnt">
               <div className="row py-4">
                  <div className="col-lg-4">
                     <div className="staketbl-leftcnt">
                        <img src={data9?HtmlParser(data9.image):<></>} alt="" className="img-fluid"/>
                     </div>
                  </div>
                  <div className="col-lg-8 mt-3">
                     <div className="staketbl-rightcnt">
                     <h2>{data9?HtmlParser(data9.title):<></>}</h2>
                     <p>{data9?HtmlParser((data9.content_description).replace(/(<([^>]+)>)/gi, "")):<></>}</p>
                     <p>{data9?HtmlParser((data9.subcontentdescription).replace(/(<([^>]+)>)/gi, "")):<></>}</p>
                        <div className="stake-tbl my-4">
                           <Table borderless  responsive>
                              <thead>
                                 <tr>
                                    <th>Levels</th>
                                    <th>Percentages</th>
                                    <th>5 Matrix</th>
                                 </tr>
                              </thead>
                              <tbody>
                                 <tr>
                                    <td>Levels 1</td>
                                    <td>10%</td>
                                    <td>250</td>
                                 </tr>
                                 <tr>
                                    <td>Levels 2</td>
                                    <td>5%</td>
                                    <td>1,250</td>
                                 </tr>
                                 <tr>
                                    <td>Levels 3</td>
                                    <td>3%</td>
                                    <td>6250</td>
                                 </tr>
                                 <tr>
                                    <td>Levels 4</td>
                                    <td>2%</td>
                                    <td>31,250</td>
                                 </tr>
                                 <tr>
                                    <td>Levels 5</td>
                                    <td>1%</td>
                                    <td>1,56,250</td>
                                 </tr>
                                 <tr>
                                    <td>Levels 6</td>
                                    <td>1%</td>
                                    <td>7,81,250</td>
                                 </tr>
                                 <tr>
                                    <td>Levels 7</td>
                                    <td>1%</td>
                                    <td>3,906,250</td>
                                 </tr>
                                 <tr>
                                    <td>Levels 8</td>
                                    <td>1%</td>
                                    <td>19,531,250</td>
                                 </tr>
                              </tbody>
                           </Table>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section className="stakebox-tbl py-5">
         <div className="container">
            <div className="stakeinr-cnt">
               <div className="row py-4">
                  <div className="col-lg-4">
                     <div className="staketbl-leftcnt">
                        <img src={data10?HtmlParser(data10.image):<></>} alt="" className="img-fluid"/>
                     </div>
                  </div>
                  <div className="col-lg-8 mt-3">
                     <div className="staketbl-rightcnt">
                     <h2>{data10?HtmlParser(data10.title):<></>}</h2>
                     <p>{data10?HtmlParser((data10.content_description).replace(/(<([^>]+)>)/gi, "")):<></>}</p>
                     <p>{data10?HtmlParser((data10.subcontentdescription).replace(/(<([^>]+)>)/gi, "")):<></>}</p>
                        <div className="stake-tbl my-4">
                           <Table borderless  responsive>
                              <thead>
                                 <tr>
                                    <th>Levels</th>
                                    <th>Percentages</th>
                                    <th>Compulsory</th>
                                 </tr>
                              </thead>
                              <tbody>
                                 <tr>
                                    <td>Levels 1</td>
                                    <td>25%</td>
                                    <td>3</td>
                                 </tr>
                                 <tr>
                                    <td>Levels 2</td>
                                    <td>20%</td>
                                    <td>5</td>
                                 </tr>
                                 <tr>
                                    <td>Levels 3</td>
                                    <td>15%</td>
                                    <td>7</td>
                                 </tr>
                                 <tr>
                                    <td>Levels 4</td>
                                    <td>10%</td>
                                    <td>9</td>
                                 </tr>
                                 <tr>
                                    <td>Levels 5</td>
                                    <td>5%</td>
                                    <td>11</td>
                                 </tr>
                              </tbody>
                           </Table>
                        </div>
                        <p>{data11?HtmlParser((data11.content_description).replace(/(<([^>]+)>)/gi, "")):<></>}</p>
                     <p>{data11?HtmlParser((data11.subcontentdescription).replace(/(<([^>]+)>)/gi, "")):<></>}</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
   </div>
   <Footer />
</Fragment>
);
}
export default Stake;