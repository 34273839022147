import "react-toastify/dist/ReactToastify.css";

import { Bounce, Flip, Slide, Zoom } from "react-toastify";
import {
  BrowserRouter,
  Link,
  Navigate,
  Route,
  Routes,
  useParams,
} from "react-router-dom";
import React, {
  Component,
  Fragment,
  createRef,
  useEffect,
  useRef,
  useState,
} from "react";
import { ToastContainer, toast } from "react-toastify";

import { CONST } from "../../../_config";
import HtmlParser from "react-html-parser";
import axios from "axios";

const HackathonDetailsPrizeEligibility = (props) => {
  const params = useParams();
  console.log(params);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDd = () => setDropdownOpen((prevState) => !prevState);

  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const [HackathonDetails, setHackathonDetails] = useState([]);

  useEffect(() => {
    SingleHcakdetails();
  }, []);

  const SingleHcakdetails = async () => {
    let hackdata = {
      _id: params.id,
    };

    let getdata = await axios.post(
      CONST.BACKEND_URL + "listHackthonById",
      hackdata
    );
    console.log(getdata.data.data, "Listhacksddddddd");
    if (getdata.data.status == true) {
      // toast.success(getdata.data.message,
      //     {
      //         transition: Flip,
      //         className: 'foo-bar'
      //     })
      setHackathonDetails(getdata.data.data);
    }
  };

  return (
    <Fragment>
      {HackathonDetails && HackathonDetails.length > 0 ? (
        HackathonDetails.map((data, index) => (
          <div className="hckDtCms">
            {props?.Benefits == true ? <></> : <h6>Prize Eligibility</h6>}

            {/* <ol>
                    <li>prizes are delivered in milestones.</li>
                    <li>On November 10th when qualifiers are announced, 30% of the qualifiers total prize will start being processed for payout. Projects only need to be on TRON and BitTorrent test net at this time.</li>
                    <li>Projects must have their applications live on TRON or BitTorrent Chain main-net by November 30th to receive the remaining 70%.</li>
                    <li>
                        <span>IMPORTANT NOTES ON MULTIPLE PRIZE ELIGIBILITY :</span>

                        <ul>
                            <li>A project can win a maximum of either one (1) Track OR one (1) additional Bonus prize. This means a Project cannot win a Track prize and the Determination Prize or the Project Engagement prize.</li>
                            <li>A unique project can only win one (1) TRON DAO Forum Prize.</li>
                            <li>If there are no eligible submissions or participants for a prize, that prize will not be awarded.</li>
                            <li>Projects that won a prize in TRON Grand Hackathon Season 4 are NOT eligible to win prizes in Season 5. Entrants who submitted winning projects may submit and win with NEW projects or by submitting their existing project into the Builders Track and clearly stating their significant changes within the Hackathon time period.</li>
                        </ul>
                        <ol>
                            <li>Substitutions & Changes: Prizes are non-transferable by the winner. Sponsor in its sole discretion has the right to make a prize substitution of equal value. Sponsor will not award a prize if there are no eligible submissions entered in the Hackathon, or if there are no eligible Entrants or submissions for a specific prize.</li>
                            <li>Verification Requirement: THE AWARD OF A PRIZE TO A POTENTIAL WINNER IS SUBJECT TO VERIFICATION OF IDENTITY, QUALIFICATIONS AND ROLE OF THE POTENTIAL WINNER IN THE CREATION OF THE SUBMISSION. No submission or submitter shall be deemed a winning submission or winner until their post-competition prize affidavits have been completed and verified, even if prospective winners have been announced verbally or on the competition website. The final decision to designate a winner shall be made by the Sponsor and/or Administrator. It should be noted that all non-US based winners will be required to establish a verified account on Huobi, attain Level 3 status and provide their TRC20 USDD address from said platform in order to receive their prizes. Winner who are U.S. persons are required to provide a KYC verified Coinbase or Kraken wallet address and accept an equivalent prize distribution in the form of USDT or USDC.</li>
                            <li>Prize Delivery: Prizes will be payable to the Entrant, if an individual; to the Entrant’s Representative, if a Team; or to the Organization, if the Entrant is an Organization. It is the responsibility of the winning Entrant’s Representative to allocate the Prize among their Team or Organization’s participating members, as the Representative deems appropriate. Sponsor and Administrator disclaim any responsibility as to the distribution or allocation of the Prize amongst Team and Organization Entrants. A monetary Prize will be mailed to the winning Entrant’s address (if an individual) or the Representative’s address (if a Team or Organization), or sent electronically to the Entrant, Entrant’s Representative, or Organization’s bank account, only after receipt of the completed winner affidavit and other required forms (collectively the “Required Forms”), if applicable. Failure to provide correct information on the Required Forms, or other correct information required for the delivery of a Prize, may result in delayed Prize delivery, disqualification or the Entrant, or forfeiture of a Prize. Prizes will be delivered within 60 days of the Sponsor or Hackathon platform’s receipt of the completed Required Forms. Additionally, it should be noted that non-US based winners will be required to provide a verified TRC20 USDD address from Huobi, attained by creating an individual account on the platform and reaching Level 3 status, in order to receive their prizes. US-based winners will only be required to provide their verified TRC20 USDD wallet address for prize disbursement.</li>
                        </ol>
                    </li>
                </ol> */}
            <p>{data ? HtmlParser(data.price_description) : <></>}</p>
          </div>
        ))
      ) : (
        <></>
      )}
    </Fragment>
  );
};

export default HackathonDetailsPrizeEligibility;
