import React, { Component, Fragment, useState, useRef, useEffect, createRef  } from "react";
import ReactDOM from "react-dom";
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import Overview from '../Overview/Overview';
import NFTDocumentation from '../NFTDocumentation/NFTDocumentation';
import DeveloperProgram from '../DeveloperProgram/DeveloperProgram';
import {
	BrowserRouter, Navigate, Route, Routes, Link
 } from 'react-router-dom';

import Header from "../../common/Header/Header";
import Footer from "../../common/Footer/Footer";

import discord from '../../assets/images/discord.svg';
import insta from '../../assets/images/insta.svg';
import twitter from '../../assets/images/twitter.svg';
import fb from '../../assets/images/fb.svg';
import linkedin from '../../assets/images/linkedin.svg';
import telegram from '../../assets/images/telegram.svg';
import kpnews1 from '../../assets/images/pics/kpnews1.png';
import communitySpring from '../../assets/images/communitySpring.png';
import { useSelector, useDispatch } from 'react-redux';
import HtmlParser from "react-html-parser";

const Home = (props) => {  
    const [activeTab, setActiveTab] = useState('1');
    const toggle = tab => {
      if(activeTab !== tab) setActiveTab(tab);
    }
    let data, data1, data2, data3, data4, data5, data6, data7, data8;

    data = useSelector(state => state.user);
    if (data.data.status == 200) {
        data = data.data.data;

        data1 = data.find(elem => elem.title == 'navbar heading - community');
        data2 = data.find(elem => elem.title == 'navbar content - community');
        data3 = data.find(elem => elem.title == 'body title1 - community');
        data4 = data.find(elem => elem.title == 'body content1 - community');
        data5 = data.find(elem => elem.title == 'body title2 - community');
        data6 = data.find(elem => elem.title == 'body content2 - community');
        data7 = data.filter(elem =>elem.heading == 'News');
        data7.reverse();
    }
    console.log(data7)
    return (
        <Fragment>
            <Header />
            <div className="pkchinsec cmsCnt cmsHome">
                <section className="cmsBannerSec kpCommunityBanner d-flex align-items-center justify-content-start">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4">
                                <h3 className="cmsTitle">{data1 ? HtmlParser((data1.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</h3>
                                <p>{data2 ? HtmlParser((data2.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="container">
                    <div className="row justify-content-center my-5">
                        <div className="col-12 col-lg-6 text-center">
                            <h3 className="ignHead">{data3 ? HtmlParser((data3.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</h3>
                            <p>{data4 ? HtmlParser((data4.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                        </div>
                    </div>
                    <div className="row justify-content-center my-5">
                        <div className="col-12 col-lg-7 text-center">
                            <div className="row">
                                <div className="col-sm-6 col-lg-4">
                                    <div className="communityCnt">
                                        <div className="d-flex flex-column align-items-center">
                                            <img src={discord} className="icon" />
                                            <h3>Discord</h3>
                                            <p>~3,200 members</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-6 col-lg-4">
                                    <div className="communityCnt">
                                        <div className="d-flex flex-column align-items-center">
                                            <img src={insta} className="icon" />
                                            <h3>Instagram</h3>
                                            <p>~4,510 members</p>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-sm-6 col-lg-4">
                                    <div className="communityCnt">
                                        <div className="d-flex flex-column align-items-center">
                                            <img src={twitter} className="icon" />
                                            <h3>Twitter</h3>
                                            <p>~7,200 members</p>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-sm-6 col-lg-4">
                                    <div className="communityCnt">
                                        <div className="d-flex flex-column align-items-center">
                                            <img src={fb} className="icon" />
                                            <h3>Facebook</h3>
                                            <p>~15,032 members</p>
                                        </div>
                                    </div>
                                </div>
                                
                                
                                <div className="col-sm-6 col-lg-4">
                                    <div className="communityCnt">
                                        <div className="d-flex flex-column align-items-center">
                                            <img src={linkedin} className="icon" />
                                            <h3>LinkedIn</h3>
                                            <p>~85,032 members</p>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-sm-6 col-lg-4">
                                    <div className="communityCnt">
                                        <div className="d-flex flex-column align-items-center">
                                            <img src={telegram} className="icon" />
                                            <h3>Telegram</h3>
                                            <p>~12,011 members</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row my-5 align-items-center justify-content-center">
                        <div className="col-lg-10 col-xl-9">
                            <div className="ethgamekit">
                                <div className="ethgamecnt communityTxtCnt">
                                    <div className="row">
                                        <div className="col-lg-4 d-flex d-lg-block">
                                            <img src={communitySpring} className="img-fluid communityImg" />
                                        </div>  
                                        <div className="col-lg-8 col-xl-7 ml-auto text-center text-lg-left">
                                            <h5>Parkchain</h5>
                                            <h3>{data5 ? HtmlParser((data5.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</h3>
                                            <p className="my-3">{data6 ? HtmlParser((data6.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                            <a href="#" className="submitbtn submitbtn1">Apply Now</a>
                                        </div>
                                    </div>
                                </div>                                    
                            </div>
                        </div>
                    </div>
                    <h4 className="ignHead mb-md-4">News</h4>
                    <div className="row">
                        {data7 && data7.length > 0 ? 
                        data7.map((data,index) => (
                            <div className="col-lg-4 col-md-6 mb-5">
                            <div className="kpnwChkBx">
                                <div><img src={data ? data.image : <></>} alt="" className="w-100" /></div>
                                <div className="kpnwctbt1 ">
                                    <h3>{data ? data.title : <></>}
                                    </h3>
                                    <div><Link to={`/newsdetails/${data._id}`} className="submitbtn submitbtn1">Read Article </Link></div>
                                </div>
                            </div>
                        </div>
                        )):
                        <>No Data Found</>
                    }
                        {/* <div className="col-lg-4 col-md-6 mb-5">
                            <div className="kpnwChkBx">
                                <div><img src={kpnews1} alt="" className="w-100" /></div>
                                <div className="kpnwctbt1 ">
                                    <h3>Check out games you can 
                                    experience at Play
                                    </h3>
                                    <div><a href="#" className="submitbtn submitbtn1">Read Article </a></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-5">
                            <div className="kpnwChkBx">
                                <div><img src={kpnews1} alt="" className="w-100" /></div>
                                <div className="kpnwctbt1 ">
                                    <h3>Check out games you can 
                                    experience at Play
                                    </h3>
                                    <div><a href="#" className="submitbtn submitbtn1">Read Article </a></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-5">
                            <div className="kpnwChkBx">
                                <div><img src={kpnews1} alt="" className="w-100" /></div>
                                <div className="kpnwctbt1 ">
                                    <h3>Check out games you can 
                                    experience at Play
                                    </h3>
                                    <div><a href="#" className="submitbtn submitbtn1">Read Article </a></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-5">
                            <div className="kpnwChkBx">
                                <div><img src={kpnews1} alt="" className="w-100" /></div>
                                <div className="kpnwctbt1 ">
                                    <h3>Check out games you can 
                                    experience at Play
                                    </h3>
                                    <div><a href="#" className="submitbtn submitbtn1">Read Article </a></div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
   
}

export default Home;