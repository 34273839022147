import "./kphckcr.scss";

import {
  BrowserRouter,
  Link,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import {
  Button,
  Collapse,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import React, {
  Component,
  Fragment,
  createRef,
  useEffect,
  useRef,
  useState,
} from "react";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import { CONST } from "../../_config";
import Footer from "../../common/Footer/Footer";
import Header from "../../common/Header/Header";
import ReactDOM from "react-dom";
import axios from "axios";
import camera from "./assets/camera.svg";
import classnames from "classnames";
import { getUserProfile } from "../../redux/action";
import laptopCoffee from "../../assets/images/hackathonList/laptopCoffee.png";
import profile from "./assets/profile.png";
import tron from "./assets/tron.png";

const HackathonForm2 = (props) => {
  const [stackList, setStackList] = useState([
    { stack: "Backend Development", check: false },
    { stack: "Frontend Development", check: false },
    { stack: "Database Development", check: false },
    { stack: "Full Stack Development", check: false },
    { stack: "Network Architecture", check: false },
    { stack: "Android Development", check: false },
    { stack: "iOS Development", check: false },
    { stack: "System Administration", check: false },
    { stack: "Quality Assurance", check: false },
    { stack: "Data Architecture", check: false },
    { stack: "Game Development", check: false },
    { stack: "Cyber Security", check: false },
    { stack: "ERP", check: false },
    { stack: "UI/UX", check: false },
    { stack: "Graphic Designing", check: false },
    { stack: "Machine Learning", check: false },
    { stack: "Data Engineer", check: false },
    { stack: "DevOps", check: false },
    { stack: "Cloud Computing", check: false },
    { stack: "AR/VR", check: false },
    { stack: "IoT", check: false },
    { stack: "Blockchain", check: false },
    { stack: "System Design", check: false },
  ]);

  const [userDetails, setUserDetails] = useState({});
  const [profileDetails, setProfileDetails] = useState({});

  const [locationData, setLocationData] = useState("");

  const [loading, setLoading] = useState(false);

  const location = useLocation();

  const navigate = useNavigate();

  const dispatch = useDispatch();
  var reduxRes = useSelector((state) => state);

  reduxRes = reduxRes?.user;

  useEffect(() => {
    setUserDetails(location?.state?.userDetails);
    setLocationData(location?.state?.location);
  }, []);

  const GetUserProfile = () => {
    dispatch(
      getUserProfile({
        userId: reduxRes?.userId,
      })
    );

    if (reduxRes?.userProfile?.data) {
      setProfileDetails({
        first_name: reduxRes.userProfile.data.first_name,
        last_name: reduxRes.userProfile.data.last_name,
        email: reduxRes.userProfile.data.email,
        profile: reduxRes.userProfile.data.profile,
        mobile: reduxRes.userProfile.data.mobile,
        country_id: reduxRes.userProfile.data.country_id,
        country: reduxRes.userProfile.data.country,
        userName: reduxRes.userProfile.data.userName,
        profile_img: reduxRes.userProfile.data.profile_img,
        gender: reduxRes.userProfile.data.gender,
        referer_code: reduxRes.userProfile.data.referer_code,
        investor_referer_id: reduxRes.userProfile.data.investor_referer_id,
      });
    }
  };

  useEffect(() => {
    if (reduxRes?.userId) {
      GetUserProfile();
    }
  }, [reduxRes?.userId, reduxRes?.userProfile?.data?.referer_code]);

  const filteredList = [];
  const filterList = (key) => {
    var index = filteredList.indexOf(key);
    if (index !== -1) {
      filteredList.splice(index, 1);
    } else {
      filteredList.push(key);
    }
    console.log("filteredList", filteredList);
  };
  var userID = localStorage.getItem("user_id");

  const userEntrollFun = () => {
    setLoading(true);
    const data = {
      userName: userDetails.firstName,
      gender: userDetails.gender,
      location: locationData,
      email: userDetails.email,
      discordId: "4545435",
      telegramId: "943543545",
      hack_id: location?.state?.hackathonId,
      tech_stack: filteredList,
      userId: reduxRes?.userId,
      referer_code: location?.state?.refererCode
        ? location?.state?.refererCode
        : "",
      loginId: location?.state?.investorId ? location?.state?.investorId : "",
    };

    const config = {
      headers: {
        Origin: "http://localhost:3000",
      },
    };

    // const headers = {
    //   Origin: "http://localhost:3000",
    // };

    axios
      .post(
        CONST.BACKEND_URL + "users/UserEnrollInvitationAccept",
        data,
        config
      )
      .then((res) => {
        console.log("res", res);
        setLoading(false);

        if (res.data.status == true) {
          toast.success(res.data.message);
          localStorage.removeItem("pathName");
          navigate(`/hackathon-details/${location?.state._id}`);
        } else {
          toast.error(res.data.message);
        }
      })

      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <Fragment>
      <Header />

      {loading == true ? (
        <div className="pageloade">
          <div className="page-loader">
            <div class="loader-12">
              <span class="loader_arrow"></span>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className="hckPrfPg">
        <div className="hckLsBnr">
          <div className="container">
            <div className="hckLsBnrR row">
              <div className="hckLsBnrC col-lg-auto">
                <div className="hckLsBnrIcTx">
                  <div className="hckLsBnrIc">
                    <img src={tron} alt="tron" />
                  </div>
                  <div className="hckLsBnrTx">
                    <h1> {location?.state?.title}</h1>
                    <p>
                      ENDS ON:{" "}
                      {new Date(location?.state?.endTime).toLocaleString()}
                    </p>
                  </div>
                </div>
              </div>
              <div className="hckLsBnrC col-lg">
                <div className="hckLsBnrIg v2">
                  <img src={laptopCoffee} alt="laptopCoffee" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="hckPrfEd">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-xl-6 mx-auto">
                <div className="hckEdtPrfIp">
                  <div className="hckEdtPrfIpTl">
                    <h4>Choose Skills</h4>
                    {/* <button className="btn">Skip</button> */}
                  </div>

                  <div className="hckcrFrm">
                    <div className="row no-lg-gutters hckcrFrmR">
                      {stackList.map((item, i) => {
                        return (
                          <div className="col-lg-6 hckcrFrmC">
                            <>
                              <div class="custom-checkbox custom-control">
                                <input
                                  type="checkbox"
                                  id={`skills_1 ${i}`}
                                  //class="custom-control-input"
                                  key={i}
                                  onChange={() => filterList(item)}
                                />
                                <label class="formTwoLabel" for={`skills_1 ${i}`}>
                                  {item.stack}
                                </label>
                              </div>
                            </>
                          </div>
                        );
                      })}

                      {/* <div className="col-lg-4 col-md-6">
                        <div className="check-box-1 d-flex mx-3 my-2">
                          <label className="check-con check-con-singin">
                            <input
                              type="checkbox"
                              onChange={() => filterList(item)}
                            />
                            <span className="checkmark checkmark-1"></span>
                          </label>
                          <p className="mb-0 pl-2 remb">{item}</p>
                        </div>
                      </div> */}

                      {/* 
                      <div className="col-lg-6 hckcrFrmC">
                        <FormGroup>
                          <div class="custom-checkbox custom-control">
                            <input
                              type="checkbox"
                              id="skills_2"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="skills_2">
                              Frontend Development
                            </label>
                          </div>
                        </FormGroup>
                      </div>

                      <div className="col-lg-6 hckcrFrmC">
                        <FormGroup>
                          <div class="custom-checkbox custom-control">
                            <input
                              type="checkbox"
                              id="skills_3"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="skills_3">
                              Database Development
                            </label>
                          </div>
                        </FormGroup>
                      </div>

                      <div className="col-lg-6 hckcrFrmC">
                        <FormGroup>
                          <div class="custom-checkbox custom-control">
                            <input
                              type="checkbox"
                              id="skills_4"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="skills_4">
                              Full Stack Development
                            </label>
                          </div>
                        </FormGroup>
                      </div>

                      <div className="col-lg-6 hckcrFrmC">
                        <FormGroup>
                          <div class="custom-checkbox custom-control">
                            <input
                              type="checkbox"
                              id="skills_5"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="skills_5">
                              Network Architecture
                            </label>
                          </div>
                        </FormGroup>
                      </div>

                      <div className="col-lg-6 hckcrFrmC">
                        <FormGroup>
                          <div class="custom-checkbox custom-control">
                            <input
                              type="checkbox"
                              id="skills_6"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="skills_6">
                              Android Development
                            </label>
                          </div>
                        </FormGroup>
                      </div>

                      <div className="col-lg-6 hckcrFrmC">
                        <FormGroup>
                          <div class="custom-checkbox custom-control">
                            <input
                              type="checkbox"
                              id="skills_7"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="skills_7">
                              iOS Development
                            </label>
                          </div>
                        </FormGroup>
                      </div>

                      <div className="col-lg-6 hckcrFrmC">
                        <FormGroup>
                          <div class="custom-checkbox custom-control">
                            <input
                              type="checkbox"
                              id="skills_8"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="skills_8">
                              System Administration
                            </label>
                          </div>
                        </FormGroup>
                      </div>

                      <div className="col-lg-6 hckcrFrmC">
                        <FormGroup>
                          <div class="custom-checkbox custom-control">
                            <input
                              type="checkbox"
                              id="skills_9"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="skills_9">
                              Quality Assurance
                            </label>
                          </div>
                        </FormGroup>
                      </div>

                      <div className="col-lg-6 hckcrFrmC">
                        <FormGroup>
                          <div class="custom-checkbox custom-control">
                            <input
                              type="checkbox"
                              id="skills_10"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="skills_10">
                              Data Architecture
                            </label>
                          </div>
                        </FormGroup>
                      </div>

                      <div className="col-lg-6 hckcrFrmC">
                        <FormGroup>
                          <div class="custom-checkbox custom-control">
                            <input
                              type="checkbox"
                              id="skills_11"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="skills_11">
                              Game Design and Development
                            </label>
                          </div>
                        </FormGroup>
                      </div>

                      <div className="col-lg-6 hckcrFrmC">
                        <FormGroup>
                          <div class="custom-checkbox custom-control">
                            <input
                              type="checkbox"
                              id="skills_12"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="skills_12">
                              Cyber Security
                            </label>
                          </div>
                        </FormGroup>
                      </div>

                      <div className="col-lg-6 hckcrFrmC">
                        <FormGroup>
                          <div class="custom-checkbox custom-control">
                            <input
                              type="checkbox"
                              id="skills_13"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="skills_13">
                              ERP
                            </label>
                          </div>
                        </FormGroup>
                      </div>

                      <div className="col-lg-6 hckcrFrmC">
                        <FormGroup>
                          <div class="custom-checkbox custom-control">
                            <input
                              type="checkbox"
                              id="skills_14"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="skills_14">
                              UI/UX
                            </label>
                          </div>
                        </FormGroup>
                      </div>

                      <div className="col-lg-6 hckcrFrmC">
                        <FormGroup>
                          <div class="custom-checkbox custom-control">
                            <input
                              type="checkbox"
                              id="skills_15"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="skills_15">
                              Graphic Designing
                            </label>
                          </div>
                        </FormGroup>
                      </div>

                      <div className="col-lg-6 hckcrFrmC">
                        <FormGroup>
                          <div class="custom-checkbox custom-control">
                            <input
                              type="checkbox"
                              id="skills_16"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="skills_16">
                              Machine Learning
                            </label>
                          </div>
                        </FormGroup>
                      </div>

                      <div className="col-lg-6 hckcrFrmC">
                        <FormGroup>
                          <div class="custom-checkbox custom-control">
                            <input
                              type="checkbox"
                              id="skills_17"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="skills_17">
                              Data Engineer
                            </label>
                          </div>
                        </FormGroup>
                      </div>

                      <div className="col-lg-6 hckcrFrmC">
                        <FormGroup>
                          <div class="custom-checkbox custom-control">
                            <input
                              type="checkbox"
                              id="skills_18"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="skills_18">
                              DevOps
                            </label>
                          </div>
                        </FormGroup>
                      </div>

                      <div className="col-lg-6 hckcrFrmC">
                        <FormGroup>
                          <div class="custom-checkbox custom-control">
                            <input
                              type="checkbox"
                              id="skills_19"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="skills_19">
                              Cloud Computing
                            </label>
                          </div>
                        </FormGroup>
                      </div>

                      <div className="col-lg-6 hckcrFrmC">
                        <FormGroup>
                          <div class="custom-checkbox custom-control">
                            <input
                              type="checkbox"
                              id="skills_20"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="skills_20">
                              AR/VR
                            </label>
                          </div>
                        </FormGroup>
                      </div>

                      <div className="col-lg-6 hckcrFrmC">
                        <FormGroup>
                          <div class="custom-checkbox custom-control">
                            <input
                              type="checkbox"
                              id="skills_21"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="skills_21">
                              IoT
                            </label>
                          </div>
                        </FormGroup>
                      </div>

                      <div className="col-lg-6 hckcrFrmC">
                        <FormGroup>
                          <div class="custom-checkbox custom-control">
                            <input
                              type="checkbox"
                              id="skills_22"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="skills_22">
                              Blockchain
                            </label>
                          </div>
                        </FormGroup>
                      </div>

                      <div className="col-lg-6 hckcrFrmC">
                        <FormGroup>
                          <div class="custom-checkbox custom-control">
                            <input
                              type="checkbox"
                              id="skills_23"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="skills_23">
                              System Design
                            </label>
                          </div>
                        </FormGroup>
                      </div>
                    </div> */}
                    </div>
                  </div>

                  <div className="prfEdtSbm">
                    <button
                      class="btn btn-17442 btn-bluFl v2"
                      onClick={userEntrollFun}
                    >
                      Done
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Fragment>
  );
};

export default HackathonForm2;
