import {
  Button,
  Card,
  CardText,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import React, {
  Component,
  Fragment,
  createRef,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import io from "socket.io-client";

import { CONST } from "../../_config";
import Footer from "../../common/Footer/Footer";
import Header from "../../common/Header/Header";
import ReactDOM from "react-dom";
import axios from "axios";
import classnames from "classnames";
import showImg from "../../views/KPHackathonUser/assets/showImg.png";
import { useForm } from "react-hook-form";
import { useStopwatch } from "react-timer-hook";
import { useParams } from "react-router-dom";
import Cookies from 'universal-cookie';
import uploadwhite from "../KPHackathonUser/assets/upload-white.svg";



// import moment from "moment";

/** Images **/
// import showImg from "../../assets/images/hackathon/showImg.png";


const Submission = (props) => {
  //Stopwatch
  const stopwatchOffset = new Date();
  // stopwatchOffset.setMinutes(stopwatchOffset.setMinutes());
  const { seconds, minutes, hours, isRunning, start, pause, reset } =
    useStopwatch({ autoStart: false, offsetTimestamp: stopwatchOffset });
  const hourTime = hours < 10 ? `0${hours}` : `${hours}`;
  const secondTime = seconds < 10 ? `0${seconds}` : `${seconds}`;
  const minuteTime = minutes < 10 ? `0${minutes}` : `${minutes}`;
  console.log(
    hourTime,
    "hourTime",
    secondTime,
    "secondTime",
    minuteTime,
    "minuteTime"
  );

  const Timer = hours + ":" + minutes + ":" + seconds;
  console.log(Timer)


  const cookies = new Cookies();
  const location = useLocation();
  var navigate = useNavigate();
  const params = useParams();
  console.log(params, location.state._id, "paramsparamsparams");


  var reduxRes = useSelector((state) => state);

  reduxRes = reduxRes?.user;

  console.log(isRunning, "isRunningisRunning");

  const [ideaRes, setIdeaRes] = useState();
  const [loading, setLoading] = useState(false);
  const [giturl, setGiturl] = useState("");
  const [gitStatus, setGitStatus] = useState("");
  const [gitbranch, setGitBranch] = useState("")
  const [Message, setMessage] = useState("")
  console.log(Message, "gitStatus");

  const [isChecked, setIsChecked] = useState(false);
  const [HackathonDetails, setHackathonDetails] = useState([]);


  const [submission, setSubmission] = useState({
    githubLink: "http://githublink.com",
    title: "",
    project_description: "",
    documentation_link: "",
    project_detail: "",
    demo_link: "",
    video_link: "",
    github_commit_id: "",
  });
  const [videoLink, setVideoLink] = useState("");
  const [demoLink, setDemoLink] = useState("");
  const [image, setImage] = useState({
    img1: "",
  });

  const { register, handleSubmit, errors, trigger } = useForm({
    mode: "onBlur",
  });
  var hackathonId = location?.state?.hackathonId;
  var form_type = location?.state?.type;
  console.log(form_type, "form_typeform_type");
  var userID = localStorage.getItem("user_id");


  let socket = {};
  if (!!window.chrome) {
    socket = io.connect(CONST.BACKEND_URL);
  } else {
    socket = io.connect(CONST.BACKEND_URL);
  }

  socket.connect();

  console.log(socket, "socket")

  socket.emit('Notification', 'Socket Running')



  useEffect(() => {


    setLoading(true);
    const ideaList = {
      userId: reduxRes?.userId,
      hackathonId: hackathonId,
    };

    const config = {
      header: "https://parkchain.org",
    };

    axios
      .post(CONST.BACKEND_URL + "users/getIdeaList", ideaList, config)
      .then((res) => {
        // console.log("res", res.data);
        if (res.data.status == false && res.data.message == "No idea found") {
          setLoading(false);
        } else if (res.data.status == true) {
          setLoading(false);
          setIdeaRes(res.data.data);
          // console.log("ideaRes", res.data.data.attachment);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }, [reduxRes?.userId]);

  useEffect(() => {
    setLoading(true);
    const submissionList = {
      userId: reduxRes?.userId,
      hackathon_id: hackathonId,
    };
    console.log(userID?.user_id, "userIduserId");
    const config = {
      header: "https://parkchain.org",
    };

    axios

      .post(
        CONST.BACKEND_URL + "users/projectSubmissionByHackathon",
        submissionList,
        config
      )
      .then((res) => {
        setLoading(false);

        console.log("ressaaaaaaaaaaaaaaaaaaaaa", res.data);
        if (res.data.status == false) {
          // setAddIdea(false);
        } else if (res.data.status == true) {
          setLoading(false);

          // setAddIdea(true);
          console.log(
            res.data.data[0].github_link,
            "res.data.data[0].github_linkres.data.data[0].github_link"
          );

          setSubmission({
            title: res.data.data[0].project_title,
            project_description: res.data.data[0].project_description,
            documentation_link: res.data.data[0].documentation_link,
            demo_link: res.data.data[0].demo_link,
            video_link: res.data.data[0].video_link,
            project_detail: res.data.data[0].project_detail,
            github_commit_id: res.data.data[0].github_commit_id,
          });

          // setGiturl(res.data.data[0].github_link);

          // console.log("ideaRes", res.data.data.attachment);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }, [reduxRes?.userId]);
  const onFileChange = (e, type) => {
    console.log("====================================", image, e);

    e.preventDefault();
    var file = e.target.files[0];
    var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.svg)$/i;
    var n = e.target.files[0].type.includes("image");

    console.log(
      "e.target.files[0].name",
      e.target.files[0].name,
      n,
      e.target.files[0].type
    );

    if (!allowedExtensions.exec(e.target.files[0].name) || !n) {
    } else {
      ImageUpload(file, type);
    }
  };

  const ImageUpload = (file, type) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("uploads", file);

    const options = {
      method: "POST",
      body: formData,
    };

    fetch(CONST.BACKEND_URL + "img", options)
      .then((response) => response.json())
      .then((res) => {
        if (res.status == true) {
          setLoading(false);

          setImage({ ...image, [type]: res.data.Location });
          console.log("location", res.data.Location);
          toast.success("File Upload Successfully");
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };





  const CurrentDate = new Date();

  const FinalDate = CurrentDate + " " + Timer;
  console.log(FinalDate, "FinalDate");
  // var year = CurrentDate.getFullYear();
  // var month = CurrentDate.getMonth() + 1; // Jan is 0, dec is 11
  // var day = CurrentDate.getDate();
  // var dateString = "" + year + "-" + month + "-" + day;
  // var datec = dateString + "T" + Timer;
  // console.log(Timer,"dateStringdateString")
  // console.log(datec,"datecdatec")
  // var combined = new Date(datec);
  // console.log(combined, "combinedcombined");

  const Submit = (e) => {

    if (form_type == "add") {
      if (minutes != 0 || hours != 0 || seconds != 0) {
        setLoading(true);
        // setSubmission(e)
        console.log(ideaRes.description, "ideaRes.descriptionideaRes.description");
        var formDetails = {
          project_description: ideaRes[0]?.description,
          video_link: submission.video_link,
          demo_link: submission.demo_link,
          github_link: giturl,
          github_commit_id: submission.github_commit_id,
          hackathon_id: hackathonId,
          project_title: ideaRes[0]?.title,
          documentation_link: image.img1
            ? image.img1
            : submission?.documentation_link,
          userId: reduxRes?.userId,
          project_detail: e.dec,
          timer_seconds: Timer,
        };
        axios
          .post(CONST.BACKEND_URL + "users/formSubmission", formDetails)

          .then((res) => {
            if (res.data.status == true) {
              setLoading(false);
              toast.success(res.data.message);
              navigate("/hackathonlist");
            } else {
              setLoading(false);
              toast.error(res.data.message);
            }
          })
          .catch((err) => {
            setLoading(false);
            toast.error(err);
          });
      }
      else {
        toast.error("Kindly Start The Timer")
      }
    }
    else {
      setLoading(true);
      // setSubmission(e)
      console.log(ideaRes.description, "ideaRes.descriptionideaRes.description");
      var formDetails = {
        project_description: ideaRes[0]?.description,
        video_link: submission.video_link,
        demo_link: submission.demo_link,
        github_link: giturl,
        github_commit_id: submission.github_commit_id,
        hackathon_id: hackathonId,
        project_title: ideaRes[0]?.title,
        documentation_link: image.img1
          ? image.img1
          : submission?.documentation_link,
        userId: reduxRes?.userId,
        project_detail: e.dec

      };
      axios
        .post(CONST.BACKEND_URL + "users/formSubmission", formDetails)

        .then((res) => {
          if (res.data.status == true) {
            setLoading(false);
            toast.success("Hackathon Edited Successfully");
            navigate("/hackathon-main", {
              state: {
                hackathonId: location?.state?.hackathonId,
              },
            });
          } else {
            setLoading(false);
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err);
        });
    }
  };

  useEffect(() => {
    SingleHcakdetails();
    ListHackathon();
  }, []);

  const SingleHcakdetails = async () => {
    setLoading(true);
    let hackdata = {
      hackathon_id: hackathonId,
      userId: reduxRes?.userId
    };

    let getdata = await axios.post(
      CONST.BACKEND_URL + "users/listGitUrlByUserWise",
      hackdata
    );
    setMessage(getdata.data.message)
    console.log(getdata, getdata.data.message, "getdatatataaaa");
    if (getdata.data.status == true && getdata.data.data != "" && getdata.data.data != null && getdata.data.data != "") {
      setLoading(false);
      setGiturl(getdata.data.data[0].github_link_user);
      setGitStatus(getdata.data.data[0].git_status)
      setGitBranch(getdata.data.data[0].github_branch)
      console.log(Message, giturl, gitStatus, gitbranch, "hgggggggggggggggg");
    }
  };
  console.log(typeof hours + ":" + minutes + ":" + seconds);

  // let date = FinalDate; //12 January 2016
  // let parsedDate = new Date(date);
  // console.log(parsedDate.toISOString(),"iiiiiiiii");

  // const isoDate = moment.utc(Timer, "HH:mm:ss");
  // console.log(isoDate, "isoDateisoDateisoDateisoDate");
  // console.log(isoDate.toISOString(), "isodateeeeeeeeeeeeeee");


  const ListHackathon = () => {

    let hackdata = {
      _id: location.state._id,
    };

    axios
      .post(CONST.BACKEND_URL + "listHackthonById", hackdata)

      .then((res) => {
        if (res.data.status == true) {
          console.log(res.data.data, "Listhacksddddddd");
          setLoading(false);
          setHackathonDetails(res.data.data[0].title);

        }
      })
      .catch((err) => {
        setLoading(false);

        console.log(err);
      });

  }


  const TimerSettings = async () => {

    // const TimerStart= 
    localStorage.setItem('Timer', hours + ":" + minutes + ":" + seconds,)

    let hackdata = {

      hackathon_id: hackathonId,
      userId: reduxRes?.userId,
      timer_seconds_running: isRunning
    };

    let SocketData = {
      hackathon_id: hackathonId,
      userId: reduxRes?.userId,
    }
    socket.emit('Notifications', SocketData)

    let getdata = await axios.post(
      CONST.BACKEND_URL + "users/createGitUrlUser",
      hackdata
    );
    console.log(getdata, "getdata");
    if (getdata.data.status == true) {
    }
  };

  const TimerEnd = localStorage.getItem("Timer")
  console.log(TimerEnd, "TimerEnd");


  const EndUpdate = () => {
    SingleHcakdetails()
  }

  console.log(HackathonDetails,"HackathonDetails");

  return (
    <div>
      <Fragment>
        <Header />
        {loading == true ? (
          <div className="pageloade">
            <div className="page-loader">
              <div class="loader-12">
                <span class="loader_arrow"></span>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="newpage-main">
          <section className="container">
            <div className="ideateamsub-main">
              <div className="ideatabcnt-sec">
                {form_type === "add" ? (
                  <div style={{ textAlign: "center" }}>
                    <h5>Timer For Hackathon</h5>
                    <div style={{ fontSize: "25px" }}>
                      <span>{hourTime}</span>:<span>{minuteTime}</span>:
                      <span>{secondTime}</span>
                    </div>
                    <p>{isRunning ? "Running" : "Not running"}</p>
                    {isRunning == true ?
                      <button disabled
                        className="btn btn-primary ml-2"
                        onClick={() => {
                          TimerSettings();
                          start();
                        }}>
                        Start
                      </button> : <button
                        className="btn btn-primary ml-2"
                        onClick={() => {
                          TimerSettings();
                          start();
                        }}>
                        Start
                      </button>}

                    <button
                      className="btn btn-primary ml-2"
                      onClick={() => {
                        pause()
                        SingleHcakdetails()
                        window.scrollTo(0, 1000)
                      }}
                    >
                      Stop
                    </button>
                    {/* <button onClick={getTime(minutes)}>Get seconds</button> */}
                    {/* <button className="btn btn-primary ml-2" onClick={reset}>Reset</button> */}
                  </div>
                ) : (
                  <></>
                )}

                <div className="hackathon-submissiontabcnt mt-3">
                  {ideaRes && ideaRes.length > 0 ? (
                    ideaRes &&
                    ideaRes.map((item) => {
                      return (
                        <div className="mt-5">
                          <label>Hackathon Name <span>*</span> : </label><h5><span>{HackathonDetails}</span></h5>

                          <Label className="mt-2">Idea Name <span>*</span> : </Label>
                          <h5 className="ideateam-head">{item?.title}</h5>
                          <Label className="mt-2">Idea Description <span>*</span> : </Label>
                          <p>{item?.description}</p>
                        </div>
                      );
                    })
                  ) : (
                    <></>
                  )}
                  <form class="mt-4" onSubmit={handleSubmit(Submit)}>
                    <FormGroup className="">
                      <Label>
                        Description <span>*</span>
                      </Label>
                      {/* <textArea className='cus-textarea' type="text" placeholder="Enter your message" /> */}
                      <textarea
                        className="cus-textarea"
                        type="textarea"
                        name="dec"
                        ref={register({
                          required: "Description is required",
                        })}
                        onKeyUp={() => trigger("dec")}
                        placeholder="Description"
                        // defaultValue={userIdea.dec}
                        defaultValue={submission?.project_detail}
                      />
                      {errors.dec && (
                        <div style={{ color: "red" }}>{errors.dec.message}</div>
                      )}
                    </FormGroup>
                    <FormGroup className="">
                      <Label>Video Link</Label>
                      <Input
                        type="text"
                        placeholder="Enter your message"
                        // onChange={(e) => {
                        //   setVideoLink(
                        //     // ...videoLink,
                        //     e.target.value,
                        //   );
                        // }}
                        onChange={(e) => {
                          setSubmission({
                            ...submission,
                            video_link: e.target.value,
                          });
                        }}
                        defaultValue={submission?.video_link}
                      />
                    </FormGroup>

                    <FormGroup className="">
                      <Label>Demo Link</Label>
                      <Input
                        type="text"
                        placeholder="Enter your message"
                        // onChange={(e) => {
                        //   setDemoLink(
                        //     // ...videoLink,
                        //     e.target.value,
                        //   );
                        // }}
                        onChange={(e) => {
                          setSubmission({
                            ...submission,
                            demo_link: e.target.value,
                          });
                        }}
                        defaultValue={submission?.demo_link}
                      />
                    </FormGroup>
                    <h5 className="fs-16-500">Video and Presentation</h5>

                    <div className="row">
                      <div className="col-lg-4">
                        <FormGroup className="cus-fileupload">
                          {/* <Input type="file" id="fileupload" /> */}

                          <input
                            className="mb-3 videoINput"
                            type="file"
                            id="fileupload"
                            onChange={(e) => {
                              onFileChange(e, "img1");
                              // setPreviewImage4(URL.createObjectURL(e.target.files[0]))
                            }}
                            defaultValue={submission?.documentation_link}
                          />
                          <Label for="fileupload" className="videolable">

                            <div
                              input type="file"
                              className="fileupload-flex "
                              style={{ cursor: "pointer" }}>
                              <div>
                                <div className="atchtex">
                                  <h3>Attatchment</h3>
                                </div>
                                <img
                                  className="img-fluid"
                                  src={
                                    image.img1
                                      ? image.img1
                                      : submission?.documentation_link
                                  }
                                  style={{ width: "200px", }}
                                />
                              </div>

                              <h5 className="btn btn-primary hytj" style={{ cursor: "pointer", marginLeft: "10px" }}> <span></span> Choose File</h5>
                              {/* <img src={showImg} alt="" className="img-fluid" /> */}
                            </div>
                          </Label>
                        </FormGroup>
                      </div>
                    </div>

                    {/* <FormGroup className="">
                      <Label>Presentation</Label>
                      <Input type="text" placeholder="Enter your message" />
                    </FormGroup> */}

                    <FormGroup>
                      <Label>
                        GitHub Commit Id <span>*</span>
                      </Label>
                      <input
                        type="text"
                        placeholder="github commit Id"
                        name="github_commit_id"
                        // value={giturl}
                        ref={register({
                          required: "github commit Id is required",
                        })}
                        onKeyUp={() => trigger("github_commit_id")}
                        onChange={(e) => {
                          setSubmission({
                            ...submission,
                            github_commit_id: e.target.value,
                          });
                        }}
                        defaultValue={submission?.github_commit_id}
                      />
                      {errors.github_commit_id && (
                        <div style={{ color: "red" }}>
                          {errors.github_commit_id.message}
                        </div>
                      )}
                      {/* <Input
                        type="text"
                        placeholder="Enter your message"
                        onChange={(e) => {
                          setSubmission({
                            ...submission,
                            GitHubLink: e.target.value,
                          });
                        }}
                      /> */}
                      <br></br>
                    </FormGroup>
                    {Message === "No Git list found" ? <></> : <div>
                      <FormGroup>
                        <Label>
                          GitHub Link <span>*</span>
                        </Label>
                        <input
                          type="text"
                          placeholder="github link"
                          name="github"
                          value={giturl}
                        />
                        <br></br>
                        <span style={{ color: "red" }}>Note:</span>Kindly push
                        your source code in above link.
                      </FormGroup>

                      <FormGroup>
                        <Label>
                          Git Branch <span>*</span>
                        </Label>
                        <input
                          type="text"
                          placeholder="Git Branch"
                          name="gitbranch"
                          value={gitbranch}
                        />
                        <br></br>
                        <span>
                          {gitStatus === "Pending" ? <div> <span style={{ color: "red" }}>Note:</span> We Will Send Git URL Soon.</div> : <></>}
                        </span>
                      </FormGroup> </div>
                    }


                    <FormGroup check className="p-0">
                      {/* <h6>Publish Idea</h6> */}
                      <input
                        type="checkbox"
                        name="check"
                        // id="exampleCheck"
                        onChange={(e) => {
                          setIsChecked(e.target.checked);
                        }}
                        checked={isChecked}
                        ref={register({
                          required: "Please check Terms and Conditions",
                        })}
                        onKeyUp={() => {
                          trigger("check");
                        }}
                      />

                      <Label for="exampleCheck" check className="ml-2">
                        I accepted source code is pushed in above mentioned
                        github link.
                      </Label>

                      {errors.check && (
                        <div style={{ color: "red" }}>
                          {errors.check.message}
                        </div>
                      )}
                    </FormGroup>
                    {isRunning === false ? (
                      <button
                        //  className="publishidea-btn"
                        className="btn btn-13738 btn-bluFl v2 mt-3"
                      // onClick={Submit}
                      >
                        Submit
                      </button>
                    ) : (
                      <button
                        disabled
                        //  className="publishidea-btn"
                        className="btn btn-13738 btn-bluFl v2 mt-3"
                      // onClick={Submit}
                      >
                        Submit
                      </button>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </Fragment>
    </div>
  );
};
export default Submission;