import React, { Component, Fragment, useState, useRef, useEffect, createRef } from "react";
import ReactDOM from "react-dom";
import { TabContent, TabPane, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody } from 'reactstrap';
import classnames from 'classnames';
import Overview from '../Overview/Overview';
import NFTDocumentation from '../NFTDocumentation/NFTDocumentation';
import DeveloperProgram from '../DeveloperProgram/DeveloperProgram';
import {
    BrowserRouter, Navigate, Route, Routes, Link
} from 'react-router-dom';

import Header from "../../common/Header/Header";
import Footer from "../../common/Footer/Footer";

import createJobIcon from '../../assets/images/createJobIcon.svg';
import jobIcon from '../../assets/images/jobIcon.png';
import axios from "axios";
import { CONST } from "../../_config";
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Home = (props) => {

    const [activeTab, setActiveTab] = useState('1');
    const [jobdetails, setJobDetails] = useState([]);
    const [jobName, setJobName] = useState('Select Designation');
    const [email, setEmail] = useState('')
    const [ListedJobs, setListJobs] = useState('')
    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    const [modal, setModal] = useState(false);
    const toggle1 = () => setModal(!modal);

    const [modal2, setModal2] = useState(false);
    const toggle2 = () => setModal2(!modal);

    useEffect(() => {
        getJobName();
        // AllJobAlert();
        ListJobs();
    }, [])

    const getJobName = async () => {
        let data = await axios.get(CONST.BACKEND_URL + 'users/getallDescription');
        setJobDetails(data.data.data);
    }

    const emailChange = (e) => {
        setEmail(e.target.value);
    }

    const handleChange = (data) => {
        console.log(data)
        setJobName(data)
    }

    const AllJobAlert = async (e) => {
        // e.preventDefault();
        let data = {
            email: "yazhni.d@osiztechnologies.in",
            type: "all",
        }
        let getdata = await axios.post(CONST.BACKEND_URL + 'users/sendAlertForAllUser', data);
        console.log(getdata.data.data, "sendAlertForAllUser");
        if (getdata.data.status == 200) {
            toast.success(getdata.data.message,
                {
                    transition: Flip,
                    className: 'foo-bar'
                })
            setListJobs(getdata.data.data)

        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let data = {
            email: email,
            location: "madurai",
            type: "techmail",
            techStack: jobName
        }
        let getdata = await axios.post(CONST.BACKEND_URL + 'users/sendAlert', data);
        console.log(getdata.data.data, "sendalert");
        if (getdata.data.status == 200) {
            toast.success(getdata.data.message,
                {
                    transition: Flip,
                    className: 'foo-bar'
                })
            toggle1()

        }
        else {
            toast.error(getdata.data.message,
                {
                    transition: Flip,
                    className: 'foo-bar'
                })
        }
    }
    const ListJobs = async (e) => {
        let data = {
            skip: 1,
            search: ""
        }
        let getdata = await axios.post(CONST.BACKEND_URL + 'users/getJobAlertDetails', data);
        console.log(getdata.data.data, "Lists");
        if (getdata.data.status == true) {
            // toast.success(getdata.data.message,
            //     {
            //         transition: Flip,
            //         className: 'foo-bar'
            //     })
            setListJobs(getdata.data.data)
        }
    }

    return (
        <Fragment>
            <Header />
            <ToastContainer limit={1} />
            <div className="jobsHeader pb-0 d-flex">
                <div className="container mt-auto">
                    <div className="row">
                        <div className="col-12">
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === '1' })}
                                        onClick={() => { toggle('1'); }}
                                    >
                                        Search Jobs
                                        <span>0</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === '2' })}
                                        onClick={() => { toggle('2'); }}
                                    >
                                        Explore Companies<span>0</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem className="ml-auto">
                                    <NavLink className="link" onClick={toggle1}>
                                        My Jobs Alert
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </div>
                    </div>
                </div>
            </div>
            <div className="jobsContent">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-11">
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="1">
                                    <div className="shadowCnt">
                                        <input type="text" className="fom-control searchInput mb-3" placeholder="Search by title or keyword" />
                                        <div className="d-flex align-items-center flex-wrap">
                                            <UncontrolledDropdown className="mr-3">
                                                <DropdownToggle caret>
                                                    Company
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem tag="a">Company 1</DropdownItem>
                                                    <DropdownItem tag="a">Company 2</DropdownItem>
                                                    <DropdownItem tag="a">Company 3</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>

                                            <UncontrolledDropdown className="mr-3">
                                                <DropdownToggle caret>
                                                    Job Function
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem tag="a">Job Function 1</DropdownItem>
                                                    <DropdownItem tag="a">Job Function 2</DropdownItem>
                                                    <DropdownItem tag="a">Job Function 3</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>

                                            <UncontrolledDropdown className="mr-3">
                                                <DropdownToggle caret>
                                                    Location
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem tag="a">Location 1</DropdownItem>
                                                    <DropdownItem tag="a">Location 2</DropdownItem>
                                                    <DropdownItem tag="a">Location 3</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>

                                            <button className="btn ml-auto my-2"><img src={createJobIcon} className="mr-1" />Create Job Alert</button>
                                        </div>
                                    </div>
                                    {ListedJobs && ListedJobs.length > 0 ?
                                        ListedJobs.map((data, index) => (
                                            <div className="jobCnt">
                                                <div className="jobRow">
                                                    <div>
                                                        <img src={jobIcon} className="jobIcon mr-3" />
                                                    </div>
                                                    <div className="d-flex flex-column">
                                                        <h3>{data.job_title}</h3>
                                                        <p>{data.job_type}</p>
                                                        <p>{data.tech_stack}</p>
                                                    </div>
                                                    <a className="ml-auto" href="">Read More </a>
                                                </div>

                                            </div>))
                                        : <span>No Records Found</span>

                                    }
                                    <div className="shadowCnt mt-5">
                                        <h3 className="text-center heading">Subscribe to New Opportunities</h3>
                                        <p className="text-center">Be the first to know about opportunities in the Parkchain Network Opportunities network, including open jobs that match your search criteria.</p>
                                        <form onSubmit={handleSubmit}>
                                            <div class="form-row align-items-center">
                                                <div class="col-md-5">
                                                    <input type="email" class="form-control" id="inlineFormInput" placeholder="Enter Your Email" onChange={(e) => emailChange(e)} />
                                                </div>
                                                <div class="col-md-4 dropdown">
                                                    <UncontrolledDropdown>
                                                        <DropdownToggle caret >
                                                            {jobName == 'Select Designation' ? <>Select Designation</> : jobName}
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                            {jobdetails && jobdetails.length > 0 ?
                                                                jobdetails.map((data, index) => (
                                                                    <DropdownItem key={data.description} onClick={() => handleChange(data.description)}>{data.description}</DropdownItem>
                                                                )) :
                                                                <span>No Records Found</span>
                                                            }
                                                            {/* <DropdownItem tag="a">Job Function 2</DropdownItem>
                                                            <DropdownItem tag="a">Job Function 3</DropdownItem> */}
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                    {/* <select  >

                                                        {jobdetails.map((option,index) => (

                                                            <option key={index} value={option.description}>

                                                                {option.description}

                                                            </option>

                                                        ))}

                                                    </select> */}
                                                </div>

                                                <div class="col-md-3">
                                                    {
                                                        jobName == 'Select Designation' || email == '' ?
                                                            <button type="submit" className="submitbtn submitbtn1 disabled" disabled>Get Alerts </button> :
                                                            <button type="submit" className="submitbtn submitbtn1">Get Alerts </button>


                                                    }
                                                </div>
                                            </div>
                                        </form>
                                    </div>

                                    {/* <div className="jobCnt">
                                            <div className="jobRow">
                                                <div>
                                                    <img src={jobIcon} className="jobIcon mr-3" />
                                                </div>
                                                <div className="d-flex flex-column">
                                                    <h3>Quantitative Researcher</h3>
                                                    <p>Dispatch</p>
                                                    <p>New York, NY, USA · Today</p>
                                                </div>
                                                <a className="ml-auto" href="">Read More ></a>
                                            </div>

                                            <div className="jobRow">
                                                <div>
                                                    <img src={jobIcon} className="jobIcon mr-3" />
                                                </div>
                                                <div className="d-flex flex-column">
                                                    <h3>Quantitative Researcher</h3>
                                                    <p>Dispatch</p>
                                                    <p>New York, NY, USA · Today</p>
                                                </div>
                                                <a className="ml-auto" href="">Read More ></a>
                                            </div>

                                            <div className="jobRow">
                                                <div>
                                                    <img src={jobIcon} className="jobIcon mr-3" />
                                                </div>
                                                <div className="d-flex flex-column">
                                                    <h3>Quantitative Researcher</h3>
                                                    <p>Dispatch</p>
                                                    <p>New York, NY, USA · Today</p>
                                                </div>
                                                <a className="ml-auto" href="">Read More ></a>
                                            </div>

                                            <div className="jobRow">
                                                <div>
                                                    <img src={jobIcon} className="jobIcon mr-3" />
                                                </div>
                                                <div className="d-flex flex-column">
                                                    <h3>Quantitative Researcher</h3>
                                                    <p>Dispatch</p>
                                                    <p>New York, NY, USA · Today</p>
                                                </div>
                                                <a className="ml-auto" href="">Read More ></a>
                                            </div>

                                            <div className="jobRow">
                                                <div>
                                                    <img src={jobIcon} className="jobIcon mr-3" />
                                                </div>
                                                <div className="d-flex flex-column">
                                                    <h3>Quantitative Researcher</h3>
                                                    <p>Dispatch</p>
                                                    <p>New York, NY, USA · Today</p>
                                                </div>
                                                <a className="ml-auto" href="">Read More ></a>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <button className="btn my-2">Load More</button>
                                        </div> */}
                                </TabPane>
                                <TabPane tabId="2">
                                    Explore Companies
                                </TabPane>
                            </TabContent>
                        </div>
                        <Modal isOpen={modal} toggle={toggle1} className="modal-lg modal-dialog-centered">
                            <ModalHeader toggle={toggle1}>My Job Alerts</ModalHeader>
                            <ModalBody className="py-4">
                                <form onSubmit={handleSubmit}>
                                    <p> {jobName == 'Select Designation' ? <>Select Designation</> : jobName}</p>
                                    <div className="row justify-content-center">
                                        <div className="col-lg-5">
                                            <input type="text" className="form-control my-1" placeholder="Enter your email" onChange={(e) => emailChange(e)} />
                                        </div>
                                        <UncontrolledDropdown>
                                            <DropdownToggle caret >
                                                {jobName == 'Select Designation' ? <>Select Designation</> : jobName}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {jobdetails && jobdetails.length > 0 ?
                                                    jobdetails.map((data, index) => (
                                                        <DropdownItem key={data.description} onClick={() => handleChange(data.description)}>{data.description}</DropdownItem>
                                                    )) :
                                                    <span>No Records Found</span>
                                                }
                                                {/* <DropdownItem tag="a">Job Function 2</DropdownItem>
                                                            <DropdownItem tag="a">Job Function 3</DropdownItem> */}
                                            </DropdownMenu>
                                        </UncontrolledDropdown>

                                        <div className="col-lg-2 d-flex justify-content-center">
                                            <button className="btn btn-primary">Subscribe</button>
                                        </div>
                                    </div>
                                    <div className="p-2 py-2 alert-warning mt-3">
                                        <p className="mb-0">Get the job you want - for more relavant jobs, refine your search before creating job alerts.</p>
                                    </div>
                                </form>

                            </ModalBody>
                        </Modal>

                        <Modal isOpen={modal2} toggle={toggle2} className="modal-lg modal-dialog-centered">
                            <ModalHeader toggle={toggle2}>My Job Alerts</ModalHeader>
                            <ModalBody className="py-4">
                                <form onSubmit={AllJobAlert}>
                                    <p> {jobName == 'Select Designation' ? <>Select Designation</> : jobName}</p>
                                    <div className="row justify-content-center">
                                        <div className="col-lg-5">
                                            <input type="text" className="form-control my-1" placeholder="Enter your email" onChange={(e) => emailChange(e)} />
                                        </div>
                                        <div className="col-lg-2 d-flex justify-content-center">
                                            <button className="btn btn-primary">Subscribe</button>
                                        </div>
                                    </div>
                                    <div className="p-2 py-2 alert-warning mt-3">
                                        <p className="mb-0">Get the job you want - for more relavant jobs, refine your search before creating job alerts.</p>
                                    </div>
                                </form>

                            </ModalBody>
                        </Modal>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );

}

export default Home;