import React, { Component, Fragment, useState, useRef, useEffect, createRef } from "react";
import ReactDOM from "react-dom";
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import Overview from '../Overview/Overview';
import NFTDocumentation from '../NFTDocumentation/NFTDocumentation';
import DeveloperProgram from '../DeveloperProgram/DeveloperProgram';
import {
    BrowserRouter, Navigate, Route, Routes, Link
} from 'react-router-dom';

import Header from "../../common/Header/Header";
import Footer from "../../common/Footer/Footer";

import hkimg from '../../assets/images/pics/hkimg.png';
import hkpf1 from '../../assets/images/pics/hkpf1.png';
import hkpf2 from '../../assets/images/pics/hkpf2.png';
import hkpf3 from '../../assets/images/pics/hkpf3.png';
import hkpf4 from '../../assets/images/pics/hkpf4.png';
import hk1 from '../../assets/images/pics/hk1.png';
import hk2 from '../../assets/images/pics/hk2.png';
import hk3 from '../../assets/images/pics/hk3.png';
import hk4 from '../../assets/images/pics/hk4.png';
import hk5 from '../../assets/images/pics/hk5.png';
import hk6 from '../../assets/images/pics/hk6.png';
import hk7 from '../../assets/images/pics/hk7.png';
import hk8 from '../../assets/images/pics/hk8.png';
import hk9 from '../../assets/images/pics/hk9.png';
import hk10 from '../../assets/images/pics/hk10.png';
import spsr1 from '../../assets/images/pics/spsr1.png';
import spsr2 from '../../assets/images/pics/spsr2.png';
import spsr3 from '../../assets/images/pics/spsr3.png';
import spsr4 from '../../assets/images/pics/spsr4.png';
import spsr5 from '../../assets/images/pics/spsr5.png';
import prevhk1 from '../../assets/images/pics/prevhk1.png';
import prevhk2 from '../../assets/images/pics/prevhk2.png';
import prevhk3 from '../../assets/images/pics/prevhk3.png';
import prevhk4 from '../../assets/images/pics/prevhk4.png';
import ov1 from '../../assets/images/pics/ov1.png';
import ov2 from '../../assets/images/pics/ov2.png';
import ov3 from '../../assets/images/pics/ov3.png';
import ov4 from '../../assets/images/pics/ov4.png';

import user from '../../assets/images/circleimg.png';



import CountUp from 'react-countup';
import { useSelector, useDispatch } from 'react-redux';
import HtmlParser from "react-html-parser";
import axios from "axios";
const Home = (props) => {
    const [activeTab, setActiveTab] = useState('1');
    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }
    let data, data1, data2, data3, data4, data5, data6, data7, data8;

    data = useSelector(state => state.user);
    if (data.data.status == 200) {
        data = data.data.data;

        data1 = data.find(elem => elem.title == 'header heading1 - hackathon');
        data2 = data.find(elem => elem.title == 'header heading2 - hackathon');
        data3 = data.find(elem => elem.title == 'header content - hackathon');
        data4 = data.find(elem => elem.title == 'overview content - hackathon');
       
    }

    useEffect(()=>{
        getHackathon();
    },[])

    const getHackathon=()=>{
       
        axios.get(` https://bckpkcnliv.parkchain.org/hackthonListData`)
        .then((response)=>{
            console.log(response,"response");
        })
    }
    return (
        <Fragment>
            <Header />
            <div className="pkchinsec">
                <section className="kpHCkbg">
                    <div className="container">
                        <div className="row KphKRW align-items-center">
                            <div className="col-lg-7">
                                <div className="kphKhead">
                                    <h2>{data1 ? HtmlParser((data1.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>} <span>{data2 ? HtmlParser((data2.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</span></h2>
                                    <p>{data3 ? HtmlParser((data3.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                    <form className="kphkFrm">
                                        <div className="d-flex align-items-center flex-wrap">
                                            <input type="text" className="form-control mb-lg-0 mb-3" placeholder="" />
                                            <button className="btn  sigupbtn ml-2 mb-lg-0 mb-3">Sign Up</button>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="col-lg-5">
                                <div><img src={hkimg} className="img-fluid" /></div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="kpHCkbg1 d-none">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div>
                                    <div className="kphKhd2 d-flex justify-content-between flex-wrap">
                                        <div>
                                            <h6>Know About </h6>
                                            <h5>Parkchain</h5>
                                        </div>
                                        <div className="hkUsrPf">
                                            <div className="">
                                                <img src={hkpf1} className="img-fluid  hkim1" />
                                                <img src={hkpf2} className="img-fluid  hkim2" />
                                                <img src={hkpf3} className="img-fluid  hkim3" />
                                                <img src={hkpf4} className="img-fluid  hkim4" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="kphKhd3 d-flex align-items-center justify-content-between">
                                        <div className="kphkLine kphkLineaft">
                                            <h6>Developers</h6>
                                            <h3><CountUp end={1000} />+</h3>
                                        </div>
                                        <div className="kphkLine kphkLineaft">
                                            <h6>Projects Launched</h6>
                                            <h3><CountUp end={600} />+</h3>
                                        </div>
                                        {/* <div className="kphkLine">
                                            <h6>in Venture Funding</h6>
                                            <h3>$<CountUp end={600} />m+</h3>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                            </div>
                        </div>
                    </div>
                </section>

                <section className="projecdet-sec">
      <div className="container">
         <div className="row">
            <div className="col-lg-6">

               <div className="hackathon-leftcnt">
                  <div className="tophack-cnt">
                     <div>
                        <h5>Know About</h5>
                        <h3>Parkchain</h3>
                     </div>
                     <div>
                           <img src={user} alt="" className="img-fluid" />
                     </div>
                        
                           
                  </div>
                  <div className="prodet-cnt">

                     <div className="proinr-cnt">
                        <p>Developers</p>
                        <h2><CountUp end={48000} />+</h2>
                     </div>
                     <div className="proinr-cnt my-3">
                        <p>Projects Launched</p>
                        <h2><CountUp end={3000} />+</h2>
                     </div>
                     <div className="proinr-cnt">
                        <p>in Venture Funding</p>
                        <h2>$<CountUp end={600} />m+</h2>
                     </div>

                  </div>
               </div>
               
            </div>
            <div className="col-lg-6">
               <div className="hackright-img"></div>
            </div>
         </div>
      </div>
   </section>




                {/* <section>
                    <div className="container kpHckWaveimg">
                        <div className="kpHckWave">
                            <div className="row align-items-center">
                                <div className="col-lg-7">
                                    <h3><span>Previous</span> Winners, Honorable <span>Mentions</span>, & Participants</h3>
                                </div>
                            </div>
                            <div className="row align-items-center justify-content-center my-4">
                                <div className="col-lg-11">
                                    <div className="">
                                        <div className="row justify-content-center mb-3">
                                            <div className="col-auto mb-3">
                                                <div className="hkimage">
                                                    <img src={hk1} className="img-fluid" />
                                                </div>
                                            </div>
                                            <div className="col-auto   mb-3">
                                                <div className="hkimage">
                                                    <img src={hk2} className="img-fluid" />
                                                </div>
                                            </div>
                                            <div className="col-auto   mb-3">
                                                <div className="hkimage">
                                                    <img src={hk3} className="img-fluid" />
                                                </div>
                                            </div>
                                            <div className="col-auto   mb-3">
                                                <div className="hkimage">
                                                    <img src={hk4} className="img-fluid" />
                                                </div>
                                            </div>
                                            <div className="col-auto   mb-3">
                                                <div className="hkimage">
                                                    <img src={hk5} className="img-fluid" />
                                                </div>
                                            </div>
                                            <div className="col-auto   mb-3">
                                                <div className="hkimage">
                                                    <img src={hk6} className="img-fluid" />
                                                </div>
                                            </div>
                                            <div className="col-auto   mb-3">
                                                <div className="hkimage">
                                                    <img src={hk7} className="img-fluid" />
                                                </div>
                                            </div>
                                            <div className="col-auto   mb-3">
                                                <div className="hkimage">
                                                    <img src={hk8} className="img-fluid" />
                                                </div>
                                            </div>
                                            <div className="col-auto   mb-3">
                                                <div className="hkimage">
                                                    <img src={hk9} className="img-fluid" />
                                                </div>
                                            </div>
                                            <div className="col-auto   mb-3">
                                                <div className="hkimage">
                                                    <img src={hk10} className="img-fluid" />
                                                </div>
                                            </div>
                                            <div className="col-auto   mb-3">
                                                <div className="hkimage">
                                                    <img src={hk6} className="img-fluid" />
                                                </div>
                                            </div>
                                            <div className="col-auto   mb-3">
                                                <div className="hkimage">
                                                    <img src={hk7} className="img-fluid" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="HkSpncrs">
                    <div className="container">
                        <div className="text-center">
                            <div>
                                <h5 className="prevhd">Previous</h5>
                                <h3 className="prevbld"> Sponsors</h3>
                            </div>
                            <div className="row align-items-center justify-content-center my-4">
                                <div className="col-lg-10">
                                    <div className="d-flex align-items-center justify-content-center flex-wrap">
                                        <div className="mx-3">
                                            <img src={spsr1} className="img-fluid" />
                                        </div>
                                        <div className="mx-3">
                                            <img src={spsr2} className="img-fluid" />
                                        </div>
                                        <div className="mx-3">
                                            <img src={spsr3} className="img-fluid" />
                                        </div>
                                        <div className="mx-3">
                                            <img src={spsr4} className="img-fluid" />
                                        </div>
                                        <div className="mx-3">
                                            <img src={spsr5} className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}

                <section className="py-4">
                    <div className="container">
                        <div>
                            <h5 className="prevhd">Previous</h5>
                            <h3 className="prevbld">Hackathons</h3>
                        </div>

                        <div className="row my-4">
                            <div className="col-lg-4 col-md-6 mb-3 odr1">
                                <div>
                                    <img src={prevhk1} className="img-fluid" />
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-12 mb-3 odr2">
                                <div className="mb-4 ">
                                    <div className="previmg">
                                        <img src={prevhk2} className="" />
                                        <div className="prevcntn">
                                            <h3>
                                                imple method to assess your next
                                                crypto investment
                                            </h3>
                                            <p>cryptocurrency is fascinating to look at from the outside, but it's a bit like the wild west in some ways. </p>
                                            <h3>Jan 22, 2023</h3>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <img src={prevhk3} className="" />
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 mb-3 odr3">
                                <div>
                                    <img src={prevhk4} className="img-fluid" />
                                </div>
                            </div>
                        </div>

                        <div className="row align-items-center my-5">
                            <div className="col-xl-5 col-lg-12">
                                <div>
                                    <h5 className="prevhd">Parkchain </h5>
                                    <h3 className="prevbld">Overview</h3>
                                    <p className="prevpar">{data4 ? HtmlParser((data4.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                </div>
                            </div>
                            <div className="col-xl-7 col-lg-12">
                                <div>
                                    <svg className="hksvgcls" width="671" height="405" viewBox="0 0 671 405" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                        <path d="M667 405C669.209 405 671 403.209 671 401L671 262C671 259.791 669.209 258 667 258L384 258C381.791 258 380 259.791 380 262V401C380 403.209 381.791 405 384 405H667Z" fill="#D7FFD0" fillOpacity="0.9926" />
                                        <path d="M667 405C669.209 405 671 403.209 671 401L671 262C671 259.791 669.209 258 667 258L384 258C381.791 258 380 259.791 380 262V401C380 403.209 381.791 405 384 405H667Z" fill="url(#pattern0)" />
                                        <path d="M251 147C253.209 147 255 145.209 255 143L255 4.00001C255 1.79087 253.209 4.35139e-06 251 4.31282e-06L4 0C1.79086 -3.85733e-08 0 1.79086 0 4L-1.52588e-05 143C-1.52588e-05 145.209 1.79085 147 3.99998 147H251Z" fill="#D7FFD0" fillOpacity="0.9926" />
                                        <path d="M251 147C253.209 147 255 145.209 255 143L255 4.00001C255 1.79087 253.209 4.35139e-06 251 4.31282e-06L4 0C1.79086 -3.85733e-08 0 1.79086 0 4L-1.52588e-05 143C-1.52588e-05 145.209 1.79085 147 3.99998 147H251Z" fill="url(#pattern1)" />
                                        <rect y="155" width="370" height="250" rx="4" fill="#C2E9FF" />
                                        <rect y="155" width="370" height="250" rx="4" fill="url(#pattern2)" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M268 0C265.791 0 264 1.79086 264 4V140C264 142.761 266.239 145 269 145H375C377.761 145 380 147.239 380 150V238C380 240.761 382.239 243 385 243H667C669.209 243 671 241.209 671 239V4C671 1.79086 669.209 0 667 0H268Z" fill="#E8D6FF" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M268 0C265.791 0 264 1.79086 264 4V140C264 142.761 266.239 145 269 145H375C377.761 145 380 147.239 380 150V238C380 240.761 382.239 243 385 243H667C669.209 243 671 241.209 671 239V4C671 1.79086 669.209 0 667 0H268Z" fill="url(#pattern3)" />
                                        <defs>
                                            <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                                                <use xlinkHref="#image0_803_5244" transform="matrix(0.00171821 0 0 0.00340136 0 -0.158163)" />
                                            </pattern>
                                            <pattern id="pattern1" patternContentUnits="objectBoundingBox" width="1" height="1">
                                                <use xlinkHref="#image1_803_5244" transform="matrix(0.00196078 0 0 0.00340136 0 -0.0782313)" />
                                            </pattern>
                                            <pattern id="pattern2" patternContentUnits="objectBoundingBox" width="1" height="1">
                                                <use xlinkHref="#image2_803_5244" transform="matrix(0.0013587 0 0 0.00201087 0 -0.0268478)" />
                                            </pattern>
                                            <pattern id="pattern3" patternContentUnits="objectBoundingBox" width="1" height="1">
                                                <use xlinkHref="#image3_803_5244" transform="matrix(0.0012285 0 0 0.00205761 0 -0.0576132)" />
                                            </pattern>
                                            <image id="image0_803_5244" width="582" height="387" xlinkHref={ov4} />
                                            <image id="image1_803_5244" width="510" height="340" xlinkHref={ov1} />
                                            <image id="image2_803_5244" width="736" height="524" xlinkHref={ov3} />
                                            <image id="image3_803_5244" width="814" height="542" xlinkHref={ov2} />
                                        </defs>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </Fragment>
    );

}

export default Home;