import React, { Component, Fragment, useState, useRef, useEffect, createRef  } from "react";
import ReactDOM from "react-dom";
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import Overview from '../Overview/Overview';
import NFTDocumentation from '../NFTDocumentation/NFTDocumentation';
import DeveloperProgram from '../DeveloperProgram/DeveloperProgram';
import {
	BrowserRouter, Navigate, Route, Routes, Link, useParams
 } from 'react-router-dom';

import Header from "../../common/Header/Header";
import Footer from "../../common/Footer/Footer";

import newsdet1 from '../../assets/images/pics/newsdet1.png';
import newsdet2 from '../../assets/images/pics/newsdet2.png';
import newsdet3 from '../../assets/images/pics/newsdet3.png';
import newsdet4 from '../../assets/images/pics/newsdet4.png';
import newsdet5 from '../../assets/images/pics/newsdet5.png';
import newsdet6 from '../../assets/images/pics/newsdet6.png';
import kpnewsdet1 from '../../assets/images/pics/kpnewsdet1.png';
import eventicon1 from '../../assets/images/pics/eventicon1.png';
import eventicon2 from '../../assets/images/pics/eventicon2.png';
import eventicon3 from '../../assets/images/pics/eventicon3.png';
import eventicon4 from '../../assets/images/pics/eventicon4.png';
import eventicon5 from '../../assets/images/pics/eventicon5.png';
import eventicon6 from '../../assets/images/pics/eventicon6.png';
import axios from "axios";
import { CONST } from "../../_config";
import HtmlParser from "react-html-parser";
const Home = (props) => {  
    const [activeTab, setActiveTab] = useState('1');
    const [newsDetails, setNews] = useState('');
    const params = useParams();
    console.log(params)
    const toggle = tab => {
      if(activeTab !== tab) setActiveTab(tab);
    }

    useEffect(() => {
        getNewsDetails();
    }, [])

    const getNewsDetails = async () => {
        let data = {
            "route_url": params.id
        }
        let getnews = await axios.post('https://usrbaklifhbeje.koinpark.com/coin_detail/get_single_blog_detail', data);
        console.log(getnews)
        if (getnews.data.status == 1) {
            setNews(getnews.data.data)
        }
    }
    
    return (
        <Fragment>
            <Header />
            <div class="pkchinsec">
                <section class="kpnwsdetbanr">
                    <div class="container">
                    <div class="row align-items-center justify-content-center kpnwdetRw">
                        <div class="col-lg-8">
                            <h2>{newsDetails ? newsDetails.title : <></>}</h2>
                            <p>Friday, June 9, 2023</p>
                            {/* <ul class="eventiconmenu pl-0">
                                <li>
                                <a href="#">
                                <img src={newsdet1} alt="" />
                                </a>
                                </li>
                                <li>
                                <a href="#">
                                <img src={newsdet2} alt="" />
                                </a>
                                </li>
                                <li>
                                <a href="#">
                                <img src={newsdet3} alt="" />
                                </a>
                                </li>
                                <li>
                                <a href="#">
                                <img src={newsdet4} alt="" />
                                </a>
                                </li>
                                <li>
                                <a href="#">
                                <img src={newsdet5} alt="" />
                                </a>
                                </li>
                                <li>
                                <a href="#">
                                <img src={newsdet6} alt="" />
                                </a>
                                </li>
                            </ul> */}
                        </div>
                    </div>
                    </div>
                </section>
                <div class="container">
                    <div class="row align-items-center justify-content-center my-5 kpnwdetRw1">
                    <div class="col-lg-8">
                        <div> <img src={newsDetails ? newsDetails.image : <></>} alt="" /></div>
                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                        <h4>Lorem ipsum dolor</h4>
                        <p class="pt-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p> */}
                        {newsDetails ? HtmlParser(newsDetails.content) : <></>}
                        <div class="horizonline "></div>
                        <div class=" pl-0 pt-3">
                            <h6 class="kpshrart">SHARE ARTICLE</h6>
                        </div>
                        <ul class="eventiconmenu pl-0">
                            <li>
                                <a href="#">
                                <img src={eventicon1} alt="" />
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                <img src={eventicon2} alt="" />
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                <img src={eventicon3} alt="" />
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                <img src={eventicon4} alt="" />
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                <img src={eventicon5} alt="" />
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                <img src={eventicon6} alt="" />
                                </a>
                            </li>
                        </ul>
                    </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
   
}

export default Home;