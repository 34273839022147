import { BrowserRouter, Link, Navigate, Route, Routes } from "react-router-dom";
import React, {
  Component,
  Fragment,
  createRef,
  useEffect,
  useRef,
  useState,
} from "react";

import { CONST } from "../../_config";
import Footer from "../../common/Footer/Footer";
import Header from "../../common/Header/Header";
import HtmlParser from "react-html-parser";
import ReactDOM from "react-dom";
import ScrollSpy from "react-ui-scrollspy";
import { UncontrolledCollapse } from "reactstrap";
import { axios } from "axios";
import business from "../../assets/images/devdoc/business.svg";
import classnames from "classnames";
import clipBoard from "../../assets/images/devdoc/clipboard-tick.svg";
import core4 from "../../assets/images/devdoc/core-4.svg";
import daaps from "../../assets/images/devdoc/daaps.svg";
import devdoc from "../../assets/images/devdoc/devdoc-1.png";
import devdocOl from "../../assets/images/devdoc/devdocOl.png";
import development from "../../assets/images/devdoc/development.svg";
import donation from "../../assets/images/devdoc/wallet.svg";
import monitor from "../../assets/images/devdoc/monitor.svg";
import { useSelector } from "react-redux";
import verify from "../../assets/images/devdoc/verify.svg";
import wallet from "../../assets/images/devdoc/wallet.svg";

const DevDocument = (props) => {
  const [smartContract, setSmartContract] = useState("");
  var Res = useSelector((state) => state.user);
  useEffect(() => {

    if (Res?.data?.status == 200) {
      Res = Res.data.data;
      setSmartContract(Res)
    }


  }, [Res, smartContract]);

  return (
    <Fragment>
      <Header />
      <div className="devdocBnr">
        <div className="container">
          <div className="row devdocBnrR">
            <div className="col-lg-6 col-xl-5 devdocBnrC lt">
              <div className="bnrTx devdocBnrTx">
                <h2>The official documentation for</h2>
                <h1> Token Standards </h1>
                <p>
                  The best-performing decentralized ecosystem
                  Ready for massive user access
                </p>

                <div className="bnrLks">
                  <Link to="/developerprogram" class="btn btn-12040 btn-bluFl">
                    Start Building
                  </Link>

                  <Link to="/document" class="btn btn-12040 btn-wtnOl">
                    Read Docs
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-6 devdocBnrC rt">
              <div className="devdocBnrIg">
                <img src={devdoc} alt="devdoc" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="devdocMtd">
        <div className="devdocMtdOl">
          <img src={devdocOl} alt="devdocOl" />
        </div>
        <div className="container">
          <div class="kyFtrR devdocMtdR row">
            {smartContract &&
              smartContract.map((item) => {
                if (item.heading == "smart contract") {
                  console.log("item", item);

                  return (
                    <div class="col-sm-12 col-md-6 col-lg-4 kyFtrC devdocMtdC">
                      <div
                        class="kyFtrCon"
                        style={{ cursor: "pointer" }}
                        onClick={() => window.open(item.token_link)}
                      >
                        <div class="kyFtrIc">
                          <img
                            src={item.image}
                            style={{ width: 50, height: 50 }}
                            alt="daaps"
                          />
                        </div>
                        <h2>{item.title}</h2>
                        <p>{HtmlParser(item.content_description)}</p>
                      </div>
                    </div>
                  );
                }
              })}
          </div>
        </div>
      </div>

      <Footer />
    </Fragment>
  );
};

export default DevDocument