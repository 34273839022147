import React, { Component, Fragment, useState, useRef, useEffect, createRef } from "react";
import ReactDOM from "react-dom";
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import Overview from '../Overview/Overview';
import NFTDocumentation from '../NFTDocumentation/NFTDocumentation';
import DeveloperProgram from '../DeveloperProgram/DeveloperProgram';
import {
    BrowserRouter, Navigate, Route, Routes, Link
} from 'react-router-dom';

import Header from "../../common/Header/Header";
import Footer from "../../common/Footer/Footer";

import kpimg2 from '../../assets/images/pics/kpimg2.png';
import grywave from '../../assets/images/pics/grywave.png';
import ignim1 from '../../assets/images/pics/ignim1.png';
import ignim2 from '../../assets/images/pics/ignimg2.png';
import ignim3 from '../../assets/images/pics/ignimg3.png';
import ignim4 from '../../assets/images/pics/ignimg4.png';
import { useSelector } from "react-redux";
import HtmlParser from "react-html-parser";


const Home = (props) => {
    const [activeTab, setActiveTab] = useState('1');
    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }
    let data, data1, data2, data3, data4, data5, data6, data7, data8, data9;
    data = useSelector(state => state.user);
    if (data.data.status == 200) {
        data = data.data.data;
        data1 = data.find(elem => elem.title == 'banner header - gaming');
        data2 = data.find(elem => elem.title == 'banner content - gaming');
        data3 = data.find(elem => elem.title == 'Parkchain Games Kit');
        data4 = data.find(elem => elem.title == 'body content2 - gaming');
        data5 = data.find(elem => elem.title == 'body content3 - gaming');
        data6 = data.find(elem => elem.title == 'body content4 - gaming');
        data7 = data.find(elem => elem.title == 'body content5 - gaming');
        data8 = data.find(elem => elem.title == 'body content6 - gaming');
        data9 = data.find(elem => elem.title == 'body content7 - gaming');
    }

    const [classIsActive, setClassIsActive] = useState(true);
    const smClose = () => {
        setClassIsActive(!classIsActive);
        classIsActive ? document.body.classList.add('sbMenu') : document.body.classList.remove('sbMenu');
    }

    return (
        <Fragment>
            <Header />
            <div className="pkchinsec">
                <section className="kpgamebanner">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <h2 className="kpgamehead">{data1 ? HtmlParser((data1.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</h2>
                                <p className="kpgamepara">{data2 ? HtmlParser((data2.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                            </div>

                            <div className="col-lg-6">
                                <div className="d-flex justify-content-center align-items-center"><img src={kpimg2} alt="" className="img-fluid" /></div>
                            </div>
                        </div>


                    </div>
                </section>
                <div className="container">
                    <div className="row my-4 align-items-center justify-content-center">
                        <div className="col-lg-9">
                            <div className="ethgamekit">
                                <div className="ethgamecnt">
                                    {/* <h2 className="kpkithead"> Parkchain Games Kit</h2>
                                    <p className="kpkitpara">A compilation of tools and services being developed within the Parkchain ecosystem, empowering game developers and expediting the creation of incredible Web3 games on Parkchain.</p> */}
                                     <h2 className="kpkithead">  {data3 ? HtmlParser((data3.title)):<></>}</h2>
                                        
                                        {data3 ? HtmlParser((data3.content_description)):<></>}

                                </div>

                            </div>
                        </div>
                    </div>

                </div>
                <section className="">

                    <div className="container">
                        <div className="row align-items-center pdbtm">
                            <div className="col-lg-8">
                                <div className="whyeth">
                                    <h4>{data4 ? HtmlParser((data4.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}
                                    </h4>
                                    <h3>{data5 ? HtmlParser((data5.content_description).replace(/(<([^>]+)>)/gi, "")):<></>}</h3>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="text-center yetherium">
                                    <img src={data4 ? HtmlParser(data4.image):<></>} alt="" className="img-fluid" />
                                </div>
                            </div>
                        </div>

                        <div className="row pdbtm mt-5">
                            <div className="col-lg-6">
                                <div>
                                    {/* <h3 className="ignHead">Seamlessly Uniting
                                        Communities</h3>

                                    <p className="ignPara">Parkchain emerges as a platform developed for seamless interoperability. It effortlessly achieves horizontal scalability while preserving a shared state, facilitating the effortless connection between Parkchain and gaming communities.</p> */}
                                        {data3 ? HtmlParser((data3.content_description)):<></>}

                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="ignImage">
                                    <img src={ignim1} alt="" className="img-fluid" />
                                </div>
                            </div>
                        </div>

                        <div className="row pdbtm">

                            <div className="col-lg-6">
                                <div className="ignImage1">
                                    <img src={ignim2} alt="" className="img-fluid" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div>
                                    {/* <h3 className="ignHead text-right">Unbeatable Cost <br />
                                        Efficiency</h3>

                                    <p className="ignPara text-right">Experience unparalleled cost efficiency on Parkchain, where each transaction costs merely a fraction of a cent. Leave behind concerns about fees hindering your experience, as parkchain ensures a streamlined and affordable ecosystem for uninterrupted transactions.</p> */}
                                        {data7 ? HtmlParser((data7.content_description)):<></>}

                                </div>
                            </div>


                        </div>

                        <div className="row pdbtm">
                            <div className="col-lg-6">
                                <div>
                                    {/* <h3 className="ignHead">Unmatched Speed
                                        and Efficiency</h3>

                                    <p className="ignPara">Experience the epitome of speed with parkchain, as it swiftly reaches consensus and finality without the delay typically associated with other blockchains. Bid farewell to long wait times and embrace parkchain's unrivaled efficiency, ensuring an instant and seamless blockchain experience.</p> */}
                                        {data8 ? HtmlParser((data8.content_description)):<></>}

                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="ignImage">
                                    <img src={ignim3} alt="" className="img-fluid" />
                                </div>
                            </div>
                        </div>

                        <div className="row pdbtm">

                            <div className="col-lg-6">
                                <div className="ignImage1">
                                    <img src={ignim4} alt="" className="img-fluid" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div>
                                    {/* <h3 className="ignHead text-right">Upgrade Your<br />
                                        Game</h3>

                                    <p className="ignPara text-right">Embark on a journey to build the metaverse, leveraging decentralized marketplaces, cross-game items, and fully-on-chain elements. Rise above the ordinary and unlock the boundless potential of a connected virtual universe, where your creativity knows no bounds.</p> */}
                                        {data9 ? HtmlParser((data9.content_description)):<></>}


                                </div>
                            </div>


                        </div>


                    </div>

                </section>
                <div class="text-center py-2">
                        <a href="https://parkwallet.net/" target="_blank" class="btn btn-primary px-4">
                            Explore
                        </a>
{/* 
                        <div class="mobMenu d-lg-none" >
                            <div class="smClose" onClick={smClose} >
                                <svg id="closeicon" viewBox="0 0 800 600">
                                    <path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" id="top"></path>
                                    <path d="M300,320 L460,320" id="middle"></path>
                                    <path d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190" id="bottom" transform="translate(480, 320) scale(1, -1) translate(-480, -318) "></path>
                                </svg>
                            </div>
                        </div> */}

                    </div>
            </div>
            <Footer />
        </Fragment>
    );

}

export default Home;