import React, { Component, Fragment, useState, useRef, useEffect, createRef } from "react";
import ReactDOM from "react-dom";
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import Overview from '../Overview/Overview';
import NFTDocumentation from '../NFTDocumentation/NFTDocumentation';
import DeveloperProgram from '../DeveloperProgram/DeveloperProgram';
import {
    BrowserRouter, Navigate, Route, Routes, Link
} from 'react-router-dom';

import Header from "../../common/Header/Header";
import Footer from "../../common/Footer/Footer";

import kpnews1 from '../../assets/images/pics/kpnews1.png';
import kpnews2 from '../../assets/images/pics/kpnews2.png';
import kpnews3 from '../../assets/images/pics/kpnews3.png';
import uparrow from '../../assets/images/pics/uparrow.png';
import downarrow from '../../assets/images/pics/downarrow.png';
import axios from "axios";
import { CONST } from "../../_config";
const Home = (props) => {
    const [activeTab, setActiveTab] = useState('1');
    const [newsdetails, setNews] = useState([]);
    const [moreOption, setMoreOption] = useState('');
    // const [pageNo, setpageNo] = useState(0);

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    useEffect(() => {
        getNews();
    }, [])

    const getNews = async () => {
        sessionStorage.removeItem('count')
        let data = {
            "pageNo": 0,
            "pageSize": 10,
            "type": "Blog",
            "sub_type": "ALL"
        }

        // let getnews = await axios.post(CONST.BACKEND_URL + 'users/getsinglecms ', data);
        let getnews = await axios.post('https://usrbaklifhbeje.koinpark.com/coin_detail/get_all_blog_detail_check', data);
        console.log(getnews);
        if (getnews.data.status == 1) {
            setNews(getnews.data.data);
            setMoreOption(getnews.data.moreOption)

        }
    }
    

    const getNewsDetails = async (pageNo) => {

        if (pageNo == '1') {
            sessionStorage.setItem('count', Number(sessionStorage.getItem('count')) - 1)
        }
        else if (pageNo == '2') {
            sessionStorage.setItem('count', Number(sessionStorage.getItem('count')) + 1)
        }
       
        let data = {
            "pageNo": Number(sessionStorage.getItem('count')),
            "pageSize": 10,
            "type": "Blog",
            "sub_type": "ALL"
        }

        let getnews = await axios.post('https://usrbaklifhbeje.koinpark.com/coin_detail/get_all_blog_detail_check', data);
        console.log(getnews);
        if (getnews.data.status == 1) {
            setNews(getnews.data.data);
            setMoreOption(getnews.data.moreOption)
        }
    }

    return (
        <Fragment>
            <Header />
            <div className="pkchinsec">
                <section className="kpnwsbanner">
                    <div className="container">
                        <div className="row align-items-center kpnwRw">
                            <h2 className="kpbloghead">News</h2>
                        </div>
                    </div>
                </section>
                <div className="container">
                    <div className="row my-5">
                        {newsdetails && newsdetails.length > 0 ?
                            newsdetails.map((data, index) => (
                                <div className="col-lg-12 mb-5">
                                    <div className="kpnwChkBx">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div><img src={data ? data.image : <></>} alt="" className="w-100" /></div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="kpnwctbt">
                                                    <h2>{data ? data.title : <></>}
                                                    </h2>
                                                    <p>{data ? (data.content).replace(/(<([^>]+)>)/gi, "").slice(0, 250) + '...' : <></>}</p>
                                                    <div><Link to={`/newsdetails/${data.route_url}`} className="submitbtn submitbtn1">Read Article </Link></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )) : <></>
                        }
                        {/* <div className="col-lg-12 mb-5">
                            <div className="kpnwChkBx">
                                <div className="row">
                                    <div className="col-lg-6">
                                    <div><img src={kpnews1} alt="" className="w-100" /></div>
                                    </div>
                                    <div className="col-lg-6">
                                    <div className="kpnwctbt">
                                        <h2>Check out games you can <br />
                                            experience at Play
                                        </h2>
                                        <p>Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit amet consectetur.</p>
                                        <div><a href="#" className="submitbtn submitbtn1">Read Article </a></div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 mb-5">
                            <div className="kpnwChkBx">
                                <div className="row">
                                    <div className="col-lg-6">
                                    <div><img src={kpnews2} alt="" className="w-100" /></div>
                                    </div>
                                    <div className="col-lg-6">
                                    <div className="kpnwctbt">
                                        <h2>Announcing the Breakpoint 
                                            Global Fellows Program
                                        </h2>
                                        <p>Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit amet consectetur.</p>
                                        <div><a href="#" className="submitbtn submitbtn1">Read Article </a></div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 mb-5">
                            <div className="kpnwChkBx">
                                <div className="row">
                                    <div className="col-lg-6">
                                    <div><img src={kpnews3} alt="" className="w-100" /></div>
                                    </div>
                                    <div className="col-lg-6">
                                    <div className="kpnwctbt">
                                        <h2>Blockchain is the 
                                            technology powering   
                                        </h2>
                                        <p>Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit amet consectetur.</p>
                                        <div><a href="#" className="submitbtn submitbtn1">Read Article </a></div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className="horizonline"></div>
                    <div className="blogpagnt d-flex align-items-center justify-content-between ">
                        <div>
                            <h3>You're reading 1 - 3 of 165</h3>
                        </div>
                        <div className="d-flex align-items-center">
                            <button className="btn prevbtn ml-0" onClick={() => getNewsDetails('1')} disabled={Number(sessionStorage.getItem('count'))<=0 || !sessionStorage.getItem('count')}><span className="px-2"><img src={uparrow} alt="" /></span>Previous Page</button>
                            <button className="btn prevbtn" onClick={() => getNewsDetails('2')} disabled={moreOption==false}><span className="px-2"><img src={downarrow} alt="" /></span>Next Page</button>
                        </div>
                    </div>
                    {/* <h4 className="kpnewRtTop">News</h4>
                    <div className="row">
                        <div className="col-lg-4 col-md-6 mb-5">
                            <div className="kpnwChkBx">
                                <div><img src={kpnews1} alt="" className="w-100" /></div>
                                <div className="kpnwctbt1 ">
                                    <h3>Check out games you can 
                                    experience at Play
                                    </h3>
                                    <div><a href="#" className="submitbtn submitbtn1">Read Article </a></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-5">
                            <div className="kpnwChkBx">
                                <div><img src={kpnews1} alt="" className="w-100" /></div>
                                <div className="kpnwctbt1 ">
                                    <h3>Check out games you can 
                                    experience at Play
                                    </h3>
                                    <div><a href="#" className="submitbtn submitbtn1">Read Article </a></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-5">
                            <div className="kpnwChkBx">
                                <div><img src={kpnews1} alt="" className="w-100" /></div>
                                <div className="kpnwctbt1 ">
                                    <h3>Check out games you can 
                                    experience at Play
                                    </h3>
                                    <div><a href="#" className="submitbtn submitbtn1">Read Article </a></div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
            <Footer />
        </Fragment>
    );

}

export default Home;