import React, { Component, Fragment, useState, useRef, useEffect, createRef } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route, NavLink, useParams } from "react-router-dom";
import { InputGroupAddon, Button, InputGroup, Input } from 'reactstrap';
import logoImg from '../../assets/images/logo-footer.svg';
import facebookImg from '../../assets/images/facebook.svg';
import twitterImg from '../../assets/images/icons/twitter.svg';
import youtubeImg from '../../assets/images/youtube.svg';
import instagramImg from '../../assets/images/instagram.svg';
import DiscordImg from '../../assets/images/discord-1.svg'
import TelegramImg from '../../assets/images/telegram.svg'
import eventicon5 from '../../assets/images/pics/Telegramnew.png';
import koinpark from '../../assets/images/pics/koinpark.png';
import Mediumnew from '../../assets/images/pics/mediumnew.png';
import linkedin from '../../assets/images/pics/linkedin.png';

import ftrmetamask from '../../assets/images/metamaskIcon.svg';

import { CONST } from "../../_config";
import Web3 from 'web3';
import axios from "axios";

import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import HtmlParser from "react-html-parser";
import { ToastContainer, toast } from 'react-toastify';
import { services } from '../connectwallet/connectwalletfooter';

const Footer = (props) => {

    var reduxRes = useSelector((state) => state);

  let web3;
  let providerType = '';

    React.useEffect(() => { window.scrollTo(0, 0); }, []);

    let data, data1;
    const params = useParams();

    data = useSelector(state => state.user);
    if (data?.data?.status == 200) {
        data = data.data.data;

        data1 = data.find(elem => elem.title == 'footer content - home');
        // console.log(data1)
    }
    let site_inform = useSelector(state => (state.user));
    if (site_inform?.data1?.status == true) {
        site_inform = site_inform.data1.data
    }

    const metamaskconnect = async function () {
        if (!window.ethereum) {
          toast.error('Please Install Metamask');
        }
        else if (window.ethereum.providers) {
          if (window.ethereum.providers.length) {
            let Metamask = window.ethereum.providers.find(p => p.isMetaMask === true);
            console.log(Metamask)
            providerType = Metamask;
            ConnectWallet(providerType)
          }
          else {
            toast.error('Please Install Metamask')
          }
        } else {
    
          providerType = window.ethereum;
          console.log(window.ethereum)
          ConnectWallet(providerType)
        }
    
      }

    const ConnectWallet = async (wallet) => {
        console.log(wallet, "wallet");
    
        try {
          // await wallet.enable();
          if (typeof window !== undefined) {
            web3 = new Web3(window.ethereum);
            if (typeof window.web3 !== undefined) {
              await wallet.enable();
    
              try {
                // await wallet.enable();
    
                await services.networkChange(wallet)
                const accounts = await web3.eth.getAccounts();
                const chainId = await web3.eth.getChainId();
                // sessionStorage.setItem("parkAccount", accounts[0]);
                const balance = await web3.eth.getBalance(accounts[0]);
                let address = accounts[0]
                // balanceWallet = balance / Math.pow(10, 18);
                console.log(chainId)
    
                let first = accounts[0].substring(0, 3);
                let last = accounts[0].substring(38, 42);
                let shortAccount = `${first}...${last}`;
                if (chainId == CONST.chainId) {
                  sessionStorage.setItem('parkAccount', (accounts[0]).toLowerCase())
                //   let data = await axios.post(CONST.BACKEND_URL + 'users/editProfile', { userAddress: (accounts[0]).toLowerCase(), userId: reduxRes?.userId, })
                //   console.log((accounts[0]), data, "(accounts[0])");
                //   toast.success('Wallet Connected');
                //   window.location.href = '/'
                }
                else {
                //   toast.error('Kindly Connect KPK Network Testnet')
                }
              }
    
              catch (error) {
                console.log(error)
                // toast.error('Please Install Metamask')
    
              }
    
            }
          }
        }
        catch (err) {
    
          if (window.ethereum == undefined) {
            // setModal(false)
            toast.error('Please Install Metamask');
          }
          else {
            console.log(err)
            // setModal(false)
            // toast.error('Please Install Metamask')Edit Profile
    
          }
    
        }
    
    
    
    
        return (
          <>
            <div><ToastContainer /></div>
          </>
        )
      }


    return (
        <Fragment>
            <footer class="indFtr">
                <div class="container">
                    <div class="row ftRow">
                        <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3 ftCol">
                            <div class="ftCon">
                                <div class="ftLogo">
                                    <Link to="#">
                                        <img src={logoImg} alt="logo-footer" />
                                    </Link>
                                </div>
                                <p>{data1 ? HtmlParser(data1.content_description) : <></>}</p>
                            </div>

                            <div className="ftr-metamask" onClick={metamaskconnect}>
                                <button>
                                    <img src={ftrmetamask} onClick={metamaskconnect} className="img-fluid" alt="MetaMask" />
                                    Add KPK Network
                                </button>
                            </div>    

                            <div class="ftScl">
                                <h6>Follow Us</h6>
                                <ul>
                                    <li>
                                        <a href={site_inform ? site_inform.facebook : <></>} target="_blank" >
                                            <img src={facebookImg} alt="facebook" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href={site_inform ? site_inform.twitter : <></>} target="_blank">
                                            <img src={twitterImg} alt="twitter" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href={site_inform ? site_inform.youtube : <></>} target="_blank">
                                            <img src={youtubeImg} alt="youtube" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href={site_inform ? site_inform.instagram : <></>} target="_blank">
                                            <img src={instagramImg} alt="instagram" />
                                        </a>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <a href={site_inform ? site_inform.telegram : <></>} target="_blank">
                                            <img src={eventicon5} className="newimg" alt="instagram" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href={site_inform ? site_inform.reddit : <></>} target="_blank">
                                            <img src={koinpark} className="newimg" alt="instagram" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href={site_inform ? site_inform.medium : <></>} target="_blank">
                                            <img src={Mediumnew} className="newimg" alt="instagram" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href={site_inform ? site_inform.linkedin : <></>} target="_blank">
                                            <img src={linkedin} className="newimg" alt="instagram" />
                                        </a>
                                    </li>
                                </ul>
                            </div>

                             

                        </div>

                        <div class="col-sm-6 col-md-6 col-lg-auto ftCol">
                            <div class="ftMenu">
                                <h6>Build</h6>
                                <ul>
                                    <li>
                                        <Link to="/parkdocumentation">Build & Deploy</Link>
                                    </li>
                                    <li>
                                        <a href="/ecosystem">Ecosystem</a>
                                    </li>
                                    <li>
                                        <Link to="/kpgaming">Use Cases</Link>
                                    </li>
                                    <li>
                                        <Link to="/community">Community</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="col-sm-6 col-md-6 col-lg-auto ftCol">
                            <div class="ftMenu">
                                <h6>Explore</h6>
                                <ul>
                                    <li>
                                        <a href="/exploreguide">Explorer</a>
                                    </li>
                                    <li>
                                        <Link to="/parkwallet">Wallet</Link>
                                    </li>
                                    <li>
                                        <a href="/koinpark_exchange">Exchange</a>
                                    </li>
                                    <li>
                                        <Link to="/koinparkstake">Staking</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="col-sm-6 col-md-6 col-lg-auto ftCol">
                            <div class="ftMenu">
                                <h6>About Park</h6>
                                <ul>
                                    {/* <li>
                                        <Link to="/jobs">Jobs</Link>
                                    </li> */}
                                    <li>
                                        <Link to="/privacy">Privacy Policy</Link>
                                    </li>
                                    <li>
                                        <Link to="/terms">Terms of Use</Link>
                                    </li>
                                    <li>
                                        <Link to="/verification">Verification</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ftCp">
                    &copy; 2024 Parkchain - All rights reserved.
                </div>
            </footer>
        </Fragment>
    );

}

export default Footer;