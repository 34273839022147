import "./kphckcr.scss";
import "./kphckcr-dev.scss";
import "react-phone-number-input/style.css";
import "react-phone-input-2/lib/style.css";

import { BrowserRouter, Link, Navigate, Route, Routes } from "react-router-dom";
import {
  Button,
  Col,
  CustomInput,
  Form,
  FormGroup,
  FormText,
  Input,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import React, {
  Component,
  Fragment,
  createRef,
  useEffect,
  useRef,
  useState,
} from "react";
import { ToastContainer, toast } from "react-toastify";
import { browserName, browserVersion, deviceType } from "react-device-detect";

import { CONST } from "../../_config";
import DeviceDetector from "device-detector-js";
import { Icon } from "react-icons-kit";
import Logo from "./assets/logo.svg";
import { PARKCHAIN_API } from "../../_config";
import PhoneInput from "react-phone-input-2";
//import PhoneInput from "react-phone-number-input";
import ReactDOM from "react-dom";
import axios from "axios";
import classnames from "classnames";
import closeCircle from "./assets/close-circle.svg";
import { eye } from "react-icons-kit/feather/eye";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import laptop from "./assets/laptop.png";
import success from "./assets/success.gif";

const HackathonRegister = (props) => {
  const [modal, setModal] = useState(false);
  const [passType, setPassType] = useState("password");
  const [conPassType, setConPassType] = useState("password");

  const [icon, setIcon] = useState(eyeOff);
  const [icon1, setIcon1] = useState(eyeOff);

  const toggle2 = () => setModal(!modal);
  const [value, setValue] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [countryCode, setCountryCode] = useState({
    id: 0,
    country_fullname: "India",
    dial_code: "+91",
  });

  const { register, handleSubmit, errors, watch, trigger, control } = useForm({
    mode: "onBlur",
  });

  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    isChecked: false,
  });
  const [countryData, setCountryData] = useState("");
  useEffect(() => {
    axios
      .get(PARKCHAIN_API.parkChain + "auth/countryDetails")
      .then((countryDataRes) => {
        if (countryDataRes.data.responseCode == 200) {
          setCountryData(countryDataRes.data.data);
        }
      })

      .catch((err) => {});
  }, []);

  const Submit = (e) => {
    setLoading(true);
    console.log(e, "userDetails");

    setUserDetails(e);

    const data = {
      first_name: e.firstName,
      last_name: e.lastName,
      mobile_number: e.mobileNumber,
      dial_code: countryCode.dial_code,
      country_code: countryCode.dial_code,
      country_fullname: countryCode.country_fullname,
      password: e.Password,
      cpassword: e.conPassword,
      email: e.email,
      device_type: "web",
    };

    console.log("data", data);
    //172.16.16.139:9201/auth/user_register

    axios
      .post(PARKCHAIN_API.parkChain + "auth/user_register", data)

      .then((res) => {
        if (res.data.responseCode == "success") {
          setLoading(false);
          // toast.success(res.data.message);
          toggle2();
        } else {
          setLoading(false);
          toast.error(res.data.message);
        }
      })

      .catch((err) => {
        setLoading(false);
        console.log("err", err);
      });
  };

  const validation = (value, data, event, formattedValue) => {
    console.log(value, data, formattedValue, "formatedvalue");

    setValue(formattedValue);

    // this.setState({ rawPhone: value.slice(data.dialCode.length) });
  };

  const PassToggle = () => {
    if (passType === "password") {
      setIcon(eye);
      setPassType("text");
    } else {
      setIcon(eyeOff);
      setPassType("password");
    }
  };

  const ConpassToggle = () => {
    if (conPassType === "password") {
      setIcon1(eye);
      setConPassType("text");
    } else {
      setIcon1(eyeOff);
      setConPassType("password");
    }
  };

  return (
    <Fragment>
      {loading == true ? (
        <div className="pageloade">
          <div className="page-loader">
            <div class="loader-12">
              <span class="loader_arrow"></span>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className="hckcrPg">
        <ToastContainer />
        <div className="container">
          <div className="hckcrPgCon">
            <form class="mx-1 mx-md-4" onSubmit={handleSubmit(Submit)}>
              <div className="row hckcrPgR">
                <div className="col-sm-12 col-md-12 col-lg-7 col-xl-6 hckcrPgC lt">
                  <div className="hckcrPgInrC">
                    <div className="hckcrPgLgo">
                      <img src={Logo} alt="Logo" />
                    </div>

                    <div className="hckcrFrm">
                      <div className="row hckcrFrmR">
                        <div className="col-md-6 hckcrFrmC">
                          <FormGroup>
                            <Label for="">
                              First Name<span>*</span>
                            </Label>

                            <input
                              className="form-control"
                              type="text"
                              placeholder="firstName"
                              ref={register({
                                required: "First Name is required",

                                minLength: {
                                  value: 3,
                                  message:
                                    "You need to enter at least 3 characters!",
                                },

                                maxLength: {
                                  value: 25,
                                  message:
                                    "You need to enter at max 25 characters!",
                                },
                              })}
                              autoComplete="off"
                              name="firstName"
                              onKeyUp={() => {
                                trigger("firstName");
                              }}
                            />

                            {errors.firstName && (
                              <div style={{ color: "red" }}>
                                {errors.firstName.message}.
                              </div>
                            )}
                          </FormGroup>
                        </div>

                        <div className="col-md-6 hckcrFrmC">
                          <FormGroup>
                            <Label for="*">
                              Last Name<span>*</span>
                            </Label>

                            <input
                              className="form-control"
                              type="text"
                              placeholder="lastName"

                              ref={register({
                                required: "Last Name is required",

                                minLength: {
                                  value: 1,
                                  message:
                                    "You need to enter at least 1 characters!",
                                },

                                maxLength: {
                                  value: 25,
                                  message:
                                    "You need to enter at max 25 characters!",
                                },
                              })}
                              autoComplete="off"
                              name="lastName"
                              onKeyUp={() => {
                                trigger("lastName");
                              }}
                            />

                            {errors.lastName && (
                              <div style={{ color: "red" }}>
                                {errors.lastName.message}.
                              </div>
                            )}
                          </FormGroup>
                        </div>

                        {/* <div className="col-md-6 hckcrFrmC">
                          <FormGroup>
                            <Label for="*">
                              Mobile Number<span>*</span>
                            </Label>

                            <input
                              className="form-control"
                              type="number"
                              // placeholder="mobile_number"
                              ref={register({
                                required: "Mobile Number is required",
                                minLength: {
                                  value: 8,
                                  message:
                                    "You need to enter at least 8 numbers!",
                                },

                                maxLength: {
                                  value: 18,
                                  message:
                                    "You need to enter at max 18 numbers!",
                                },
                              })}
                              autoComplete="off"
                              name="mobileNumber"
                              onKeyUp={() => {
                                trigger("mobileNumber");
                              }}
                            />

                            {errors.mobileNumber && (
                              <div style={{ color: "red" }}>
                                {errors.mobileNumber.message}.
                              </div>
                            )}
                          </FormGroup>
                        </div> */}

                        <div className="col-md-12 hckcrFrmC">
                          <FormGroup>
                            <Label for="*">
                              Email<span>*</span>
                            </Label>

                            <input
                              className="form-control"
                              type="text"
                              placeholder="Email"
                              ref={register({
                                required: "Email is required",
                                pattern: {
                                  value:
                                    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                  message: "Invalid email address",
                                },
                              })}
                              autoComplete="off"
                              name="email"
                              onKeyUp={() => {
                                trigger("email");
                              }}
                            />

                            {errors.email && (
                              <div style={{ color: "red" }}>
                                {errors.email.message}.
                              </div>
                            )}
                          </FormGroup>
                        </div>

                        <div className="col-md-6 hckcrFrmC">
                          <FormGroup>
                            <Label for="*">
                              Password<span>*</span>
                            </Label>

                            <InputGroup className="align-items-center">
                              <input
                                className="form-control"
                                type={passType}
                                placeholder="Password"
                                ref={register({
                                  required: "Password is required",
                                  pattern: {
                                    value:
                                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                                    message:
                                      "Password must be atleast 8 characters long with One capital letter,One Special Character and One Number",
                                  },
                                  minLength: {
                                    value: 8,
                                    message:
                                      "Password must have at least 8 characters",
                                  },
                                })}
                                autoComplete="off"
                                name="Password"
                                onKeyUp={() => {
                                  trigger("Password");
                                }}
                              />

                              <span
                                class="flex justify-around items-center"
                                onClick={PassToggle}
                                role="button"
                              >
                                <Icon
                                  class="absolute mr-10"
                                  icon={icon}
                                  size={25}
                                />
                              </span>
                            </InputGroup>

                            {errors.Password && (
                              <div style={{ color: "red" }}>
                                {errors.Password.message}.
                              </div>
                            )}
                          </FormGroup>
                        </div>

                        <div className="col-md-6 hckcrFrmC">
                          <FormGroup>
                            <Label for="*">
                              Confirm password<span>*</span>
                            </Label>

                            <InputGroup className="align-items-center">
                              <input
                                className="form-control"
                                type={conPassType}
                                placeholder="Confirm password"
                                ref={register({
                                  required: "Confirm password is required",

                                  validate: (val) => {
                                    if (watch("Password") != val) {
                                      return "Your passwords do no match";
                                    }
                                  },
                                })}
                                autoComplete="off"
                                name="conPassword"
                                onKeyUp={() => {
                                  trigger("conPassword");
                                }}
                              />

                              <span
                                class="flex justify-around items-center"
                                onClick={ConpassToggle}
                                role="button"
                              >
                                <Icon
                                  class="absolute mr-10"
                                  icon={icon1}
                                  size={25}
                                />
                              </span>
                            </InputGroup>

                            {errors.conPassword && (
                              <div style={{ color: "red" }}>
                                {errors.conPassword.message}.
                              </div>
                            )}
                          </FormGroup>
                        </div>

                        {/* <div className="col-md-6 hckcrFrmC">
                          <FormGroup>
                            <Label for="*">
                              Mobile Number<span>*</span>
                            </Label>

                            <PhoneInput
                              country={"us"}
                              // placeholder="Enter phone number"
                              value={value}
                              // onChange={setValue}
                              onChange={validation}
                              enableSearch="false"
                              inputProps={{
                                name: "phone",
                                required: true,
                              }}
                              inputStyle={{
                                borderRadius: 6,
                                backgroundColor: "#F6F7FC",
                                borderColor: "#F6F7FC",
                                // height: calc(1.5 + 0.75 + 2 + 5.5),
                                fontSize: 15,
                              }}
                            />
                          </FormGroup>
                        </div> */}

                        <div className="col-md-6 hckcrFrmC">
                          <FormGroup>
                            <Label for="*">
                              Country Code <span>*</span>
                            </Label>

                            <div className="mobilenumber-field">
                              <Input
                                type="select"
                                onChange={(e) => {
                                  setCountryCode(JSON.parse(e.target.value));
                                }}
                              >
                                <option selected disabled hidden>
                                  India +91
                                </option>
                                {countryData && countryData.length > 0 ? (
                                  countryData &&
                                  countryData.map((item) => {
                                    return (
                                      <option value={JSON.stringify(item)}>
                                        {item?.country_fullname} -{" "}
                                        {item.dial_code}
                                      </option>
                                    );
                                  })
                                ) : (
                                  <></>
                                )}

                                {/* <option value="">Aus (+43)</option> */}
                                {/* <option value="">France (+33)</option> */}
                              </Input>
                            </div>
                          </FormGroup>
                        </div>

                        <div className="col-md-6 hckcrFrmC">
                          <FormGroup>
                            <Label for="*">
                              Phone number<span>*</span>
                            </Label>

                            <input
                              className="form-control"
                              type="number"
                              placeholder="mobile_number"
                              ref={register({
                                required: "Mobile Number is required",

                                pattern: {
                                  value: /^[0-9]+$/,
                                  message: "Must number should allowed",
                                },

                                minLength: {
                                  value: 8,
                                  message:
                                    "You need to enter at least 8 numbers!",
                                },

                                maxLength: {
                                  value: 18,
                                  message:
                                    "You need to enter at max 18 numbers!",
                                },
                              })}
                              autoComplete="off"
                              name="mobileNumber"
                              onKeyUp={() => {
                                trigger("mobileNumber");
                              }}
                              // placeholder="Phone number"
                            />
                            {errors.mobileNumber && (
                              <div style={{ color: "red" }}>
                                {errors.mobileNumber.message}.
                              </div>
                            )}
                          </FormGroup>
                        </div>

                        <div className="col-12 hckcrFrmC  mt-2">
                          <FormGroup>
                            <div class="custom-checkbox custom-control">
                              <input
                                type="checkbox"
                                id="agreeCheckbox"
                                class="custom-control-input"
                                onChange={(e) => {
                                  setUserDetails({
                                    ...userDetails,
                                    isChecked: e.target.checked,
                                  });
                                }}
                                checked={userDetails.isChecked}
                                ref={register({
                                  required: "Please check the terms",
                                })}
                                name="check"
                                trigger={() => {
                                  "check";
                                }}
                              />

                              <label
                                class="custom-control-label"
                                for="agreeCheckbox"
                              >
                                Yes, I agree to{" "}
                                <a href="/privacy">privacy policy</a>{" "}
                                <span>*</span>
                              </label>

                              {errors.check && (
                                <div style={{ color: "red" }}>
                                  {errors.check.message}
                                </div>
                              )}
                            </div>
                          </FormGroup>
                        </div>
                      </div>
                    </div>

                    {/* <Controller
                      control={control}
                      name="phone"
                      rules={{
                        required: { value: true, message: "Required" },
                        minLength: { value: 12, message: "Min Length" },
                        maxLength: { value: 12, message: "Max Length" },
                        // validate: isNumber,
                      }}
                      render={({ field: { ref, ...field } }) => (
                        <PhoneInput
                          {...field}
                          inputExtraProps={{
                            ref,
                            required: true,
                            autoFocus: true,
                          }}
                          country={"in"}
                          onlyCountries={["in"]}
                          countryCodeEditable={false}
                          specialLabel={"Player Mobile Number"}
                        />
                      )}
                    />
                    {errors.phone && <p>{errors.phone.message}</p>} */}

                    <div className="hckcrFrmSbmRd">
                      <button
                        class="btn btn-17442 btn-bluFl v2"
                        // onClick={toggle2}
                      >
                        Create a Account
                      </button>
                      <div className="hckCrRd">
                        <p>
                          Already have an account?
                          <Link to="/hackathon-login">Login</Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-5 hckcrPgC rt">
                  <div className="hckcrPgInrIgTk">
                    <div className="hckCrPgIg">
                      <img src={laptop} alt="laptop" />
                    </div>
                    <div className="hckCrPgTx">
                      <h1>
                        Welcome to <br /> Register
                      </h1>
                      <p> &copy; 2023 Parkchain - All rights reserved.</p>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Modal
        isOpen={modal}
        toggle={toggle2}
        centered={true}
        className="kphckMdl"
        backdropClassName={"kphckMdlBkDrp"}
      >
        <ModalBody>
          <button className="btn btnMdlcls" onClick={toggle2}>
            <img src={closeCircle} alt="closeCircle" />
          </button>
          <div className="kpHckMdlIc">
            <img src={success} alt="success" />
          </div>
          <div className="kpHckMdlTx">
            <h4>Verification link sent</h4>
            <p>
              We have emailed a one-time login link to
              <a href="#">{userDetails.email}</a>
            </p>
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default HackathonRegister;
