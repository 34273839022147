import {
  Button,
  Card,
  CardText,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import React, {
  Component,
  Fragment,
  createRef,
  useEffect,
  useRef,
  useState,
} from "react";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import { CONST } from "../../_config";
import Footer from "../../common/Footer/Footer";
import Header from "../../common/Header/Header";
import ReactDOM from "react-dom";
import axios from "axios";
import classnames from "classnames";
import laptopCoffee from "../KPHackathonUser/assets/laptop.png";
import uploadwhite from "../KPHackathonUser/assets/upload-white.svg";
import { useForm } from "react-hook-form";

/** Images **/

const AboutIdea = (props) => {
  const [userIdea, setUserIdea] = useState({
    title: "",
    dec: "",
    attachment: "",

    // isChecked: false,
  });

  const [hackSatus, setHackSatus] = useState("");

  const [isChecked, setIsChecked] = useState(false);

  const [image, setImage] = useState({
    img1: "",
  });

  const { register, handleSubmit, errors, trigger } = useForm({
    mode: "onBlur",
  });

  const location = useLocation();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  var userID = localStorage.getItem("user_id");

  var reduxRes = useSelector((state) => state);

  reduxRes = reduxRes?.user;

  console.log(reduxRes, "redux0--------");

  useEffect(() => {
    setLoading(true);
    const ideaList = {
      userId: reduxRes?.userId,
      hackathonId: location?.state?.hackathonId,
    };

    axios
      .post(CONST.BACKEND_URL + "users/getIdeaList", ideaList)
      .then((res) => {
        console.log("res", res.data);
        if (res.data.status == false && res.data.message == "No idea found") {
          setLoading(false);
        } else if (res.data.status == true) {
          setLoading(false);

          setUserIdea({
            title: res.data.data[0].title,
            dec: res.data.data[0].description,
            attachment: res.data.data[0].attachment,
            isChecked: false,
          });
          setHackSatus(res.data.data[0].hackathon_status);
          console.log(userIdea, "userIdeauserIdea");
          console.log("ideaRes", res.data.data.attachment);
        }
      })
      .catch((err) => {
        setLoading(false);

        toast.error(err);
      });
  }, [reduxRes?.userId]);

  console.log("userIDea", reduxRes?.userId);

  const Submit = (e) => {
    setLoading(true);

    const idea = {
      title: e.title,
      description: e.dec,
      attachment: image.img1 ? image.img1 : userIdea.attachment,
      hackathonId: location?.state?.hackathonId,
      userId: reduxRes?.userId,
    };

    const config = {
      header: {
        Origin: "http://localhost:3001",
      },
    };
    axios
      .post(CONST.BACKEND_URL + "users/addIdea", idea, config)

      .then((res) => {
        if (res.data.status == true) {
          navigate("/hackathon-main", {
            state: {
              hackathonId: location?.state?.hackathonId,
            },
          });
          setLoading(false);

          // navigate("/hackathon-main");

          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);

          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);

        toast.error(err);
      });
  };

  const onFileChange = (e, type) => {
    console.log("====================================", image, e);

    e.preventDefault();
    var file = e.target.files[0];
    var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.svg)$/i;
    var n = e.target.files[0].type.includes("image");

    console.log(
      "e.target.files[0].name",
      e.target.files[0].name,
      n,
      e.target.files[0].type
    );

    if (!allowedExtensions.exec(e.target.files[0].name) || !n) {
    } else {
      ImageUpload(file, type);
    }
  };

  const ImageUpload = (file, type) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("uploads", file);

    const options = {
      method: "POST",
      body: formData,
    };

    fetch(CONST.BACKEND_URL + "img", options)
      .then((response) => response.json())
      .then((res) => {
        if (res.status == true) {
          setLoading(false);

          setImage({ ...image, [type]: res.data.Location });
          console.log("location", res.data.Location);
          toast.success("File Upload Successfully");
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  return (
    <Fragment>
      <Header />

      {loading == true ? (
        <div className="pageloade">
          <div className="page-loader">
            <div class="loader-12">
              <span class="loader_arrow"></span>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className="hckPrfPg">
        <div className="hckLsBnr">
          <div className="container">
            <div className="hckLsBnrR row">
              <div className="hckLsBnrC col-lg-auto">
                <div className="hckLsBnrTx">
                  <h1>Hackathons</h1>
                </div>
              </div>
              <div className="hckLsBnrC col-lg-auto">
                <div className="hckLsBnrIg">
                  <img src={laptopCoffee} alt="laptopCoffee" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="aboutidea-main">
          <section>
            <div className="container">
              <div className="aboutidea-head">
                <div className="lefthead-txt">
                  <button
                    onClick={() =>
                      navigate("/hackathon-main", {
                        state: {
                          hackathonId: location?.state?.hackathonId,
                        },
                      })
                    }
                  >
                    <svg
                      width="8"
                      height="14"
                      viewBox="0 0 8 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7 1L1 7L7 13"
                        stroke="#2C3E50"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                  <h5>Write about your ideas</h5>
                </div>
              </div>

              <div className="aboutidea-inrcnt">
                <form>
                  <FormGroup>
                    {/* <Input type="text" placeholder="Title" /> */}

                    <input className="w-100"
                      type="text"
                      placeholder="Title"
                      name="title"
                      ref={register({
                        required: "Title is  required",
                      })}
                      onKeyUp={() => {
                        trigger("title");
                      }}
                      defaultValue={userIdea.title}
                    />

                    {errors.title && (
                      <div style={{ color: "red" }}>{errors.title.message}</div>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <textarea
                      type="textarea"
                      name="dec"
                      ref={register({
                        required: "Description is required",
                      })}
                      onKeyUp={() => trigger("dec")}
                      placeholder="Description"
                      defaultValue={userIdea.dec}
                    />
                    {errors.dec && (
                      <div style={{ color: "red" }}>{errors.dec.message}</div>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <div className="fileupload-box">
                      <div className="fileupload-grid">
                        <div>
                          <h3>Attatchment</h3>
                        </div>
                        <div>
                          {/* <input
                            type="file"
                            id="idea-fileupload"
                            onChange={(e) => {
                              onFileChange(e, "img1");
                              // setPreviewImage4(URL.createObjectURL(e.target.files[0]))
                            }}
                          /> */}
                          <input
                            className="mb-3"
                            type="file"
                            id="idea-fileupload"
                            onChange={(e) => {
                              onFileChange(e, "img1");
                              // setPreviewImage4(URL.createObjectURL(e.target.files[0]))
                            }}
                            defaultValue={userIdea.attachment}
                          />

                          <Label for="idea-fileupload">
                            <div>
                              <img
                                src={uploadwhite}
                                alt=""
                                className="img-fluid"
                              />
                              <h5 style={{ cursor: "pointer" }}>Choose File</h5>
                            </div>
                          </Label>
                        </div>
                      </div>
                      <img
                        src={image.img1 ? image.img1 : userIdea.attachment}
                        style={{ width: "200px" }}
                      />
                      {/* <img src={userIdea.attachment ? userIdea.attachment : ""} style={{ width: "200px" }} /> */}
                    </div>
                  </FormGroup>

                  <FormGroup check className="p-0">
                    <h6>Publish Idea</h6>
                    <input
                      type="checkbox"
                      name="check"
                      // id="exampleCheck"
                      onChange={(e) => {
                        setIsChecked(e.target.checked);
                      }}
                      checked={isChecked}
                      ref={register({
                        required: "Please check Terms and Conditions",
                      })}
                      onKeyUp={() => {
                        trigger("check");
                      }}
                    />

                    <Label for="exampleCheck" check>
                      I accept to publish my idea recording terms and condition
                    </Label>

                    {errors.check && (
                      <div style={{ color: "red" }}>{errors.check.message}</div>
                    )}
                  </FormGroup>

                  <button
                    //  className="publishidea-btn"
                    className="btn btn-13738 btn-bluFl v2 mt-3"
                    onClick={handleSubmit(Submit)}
                  >
                    Publish Idea
                  </button>
                </form>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};
export default AboutIdea;
