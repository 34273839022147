import "./scss/contract.scss";

import HtmlParser, { htmlparser2 } from "react-html-parser";
import React, {
  Component,
  Fragment,
  createRef,
  useEffect,
  useRef,
  useState,
} from "react";

import Footer from "../../common/Footer/Footer";
import Header from "../../common/Header/Header";
import ReactDOM from "react-dom";
import bnr from "./images/bnr.png";
import classnames from "classnames";
import pakchainmechanism from "./images/parkchain-img.png";
import savings from "./images/savings.png";
import security from "./images/security.png";
import speed from "./images/speed.png";
import transparency from "./images/transparency.png";
import { useSelector } from "react-redux";

/** Images  **/

const Smartcontract = (props) => {
  const [smartContract, setSmartContract] = useState("");
  var Res = useSelector((state) => state.user);
  useEffect(() => {
    if (Res?.data?.status == 200) {
      Res = Res.data.data;
      setSmartContract(Res);
      console.log("Redss", Res);
    }
  }, [Res, smartContract]);
  // smarts heading
  return (
    <Fragment>
      <Header />
      <div className="smartcontract-pagecnt">
        {smartContract &&
          smartContract.map((item) => {
            if (item.heading == "smarts heading") {
              return (
                <div>
                  <section className="smartcontractbnr-sec bluebgbnr-sec">
                    <div className="container">
                      <div className="row align-items-center">
                        <div className="col-lg-6">
                          <div className="cmnbnr-txt">
                            <h1>{item.title}</h1>
                            <p>{HtmlParser(item.subcontentdescription)}</p>
                          </div>
                        </div>
                        <div className="col-lg-6 text-center mt-4 mt-lg-0">
                          <div>
                            <img
                              src={item.image}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              );
            }
          })}

        {smartContract &&
          smartContract.map((item) => {
            if (item.heading == "smarts contract") {
              console.log("item-----", item);

              return (
                <div>
                  <section className="hoedoeswork-sec pt-5">
                    <div className="container">
                      <div className="row align-items-center">
                        <div className="col-lg-7">
                          <div className="howdoes-leftcnt">
                            <h2>{item.title}</h2>

                            <p>{HtmlParser(item.content_description)}</p>
                          </div>
                        </div>
                        <div className="col-lg-5 text-center mt-4 mt-lg-0">
                          <div>
                            <img
                              src={item.image}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="benifitparkchain-sec mt-5 pb-4">
                    <div className="container">
                      <h2 className="text-center">
                        Benefits of smart contracts
                      </h2>
                      <div className="row mt-4">
                        <div className="container">
                          <div className="col-lg-10 mx-auto">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="benefit-inbox">
                                  <h5>{item.Overview}</h5>
                                  <div className="benefit-para">
                                    <p>{HtmlParser(item.OverviewDes)}</p>
                                  </div>
                                  <div className="benift-img">
                                    <img
                                      src={item.sub_image}
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="benefit-inbox">
                                  <h5>{HtmlParser(item.Transactions)}</h5>
                                  <div className="benefit-para">
                                    <p>{HtmlParser(item.TransactionDesc)}</p>
                                  </div>
                                  <div className="benift-img">
                                    <img
                                      //src={transparency}
                                      src={item.sub_image1}
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="benefit-inbox">
                                  <h5>{item.Blocks}</h5>
                                  <div className="benefit-para">
                                    <p>{HtmlParser(item.BlockDes)}</p>
                                  </div>
                                  <div className="benift-img">
                                    <img
                                      src={item.subimage2}
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="benefit-inbox">
                                  <h5>{item.savings}</h5>
                                  <div className="benefit-para">
                                    <p>{HtmlParser(item.savings_dec)}</p>
                                  </div>
                                  <div className="benift-img">
                                    <img
                                      src={item.subimage3}
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <h5 className="note mt-4">
                              Note : Use the Github source shared. i.e{" "}
                              <a href="https://parkchain.org/developerprogram">
                                Token standards.
                              </a>
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              );
            }
          })}
      </div>
      <Footer />
    </Fragment>
  );
};
export default Smartcontract;
