import React, { Component, Fragment, useState, useRef, useEffect, createRef  } from "react";
import ReactDOM from "react-dom";
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import Overview from '../Overview/Overview';
import NFTDocumentation from '../NFTDocumentation/nftbenifits';
import DeveloperProgram from '../DeveloperProgram/DeveloperProgram';
import {
	BrowserRouter, Navigate, Route, Routes, Link
 } from 'react-router-dom';

import Header from "../../common/Header/Header";
import Footer from "../../common/Footer/Footer";

import downimg from '../../assets/images/pics/downimg.png';
import { useSelector } from "react-redux";
import HtmlParser from "react-html-parser";


const Home = (props) => {  
    const [activeTab, setActiveTab] = useState('1');
    const toggle = tab => {
      if(activeTab !== tab) setActiveTab(tab);
    }
    let data, data1;
    data = useSelector(state => state.user);
    if(data.data.status==200){
        data = data.data.data;

        data1 = data.find(elem => elem.title == 'Terms of Use');

    }

    return (
        <Fragment>
            <Header />
            <div className="pkchinsec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="pkmainsec w-100">
                                <h2 class="kphedone">{data1 ? (data1.title) : <></>}</h2>
                                {data1 ? HtmlParser(data1.content_description) : <></>}
                                {/* <p class="kpparaone mb-2">By accessing, browsing, or utilizing PARKCHAIN.ORG, you acknowledge your reading, understanding, and agreement to abide by these Terms of Use, which include the binding arbitration agreement stated in Section 17.</p>
                                <p class="kpparaone mb-2">Thank you for selecting parkchain.org (the "Website"). The Terms presented herein apply to all visitors of the Website ("you", "your" or "Website User") and establish an agreement between you and Parkchain Innovation Limited ("we", "us" or "Parkchain Innovation").</p>
                                <p class="kpparaone mb-2">Any additional provisions, licenses, restrictions, disclaimers, and responsibilities stated on the Website are included in these Terms by reference, which includes the Privacy Policy.</p>
                                <p class="kpparaone mb-2">Please carefully read these Terms as they govern your use and access to the Website, as well as any services, features, functions, or content provided on the Website periodically ("Services").</p>

                                <p class="kpparaone mb-2">You acknowledge and accept that the Website solely serves as an interface for the Community, consisting of users and developers interested in or contributing to the infrastructure, code, and progress of Parkchain (the "Community"). Parkchain refers to any blockchains utilizing KPK as the native token, encompassing a public, decentralized, and permissionless blockchain called Parkchain. We do not possess the ability to control the activities and data on Parkchain or the projects that Community participants may develop using Parkchain. Decisions are made collectively by the Community rather than a single entity.</p>

                                <p class="kpparaone mb-2">You comprehend and agree that we reserve the right to modify these Terms at any time at our own discretion. The "Last Updated" section at the start of these Terms will indicate the most recent date of amendments, which become effective immediately, unless expressly stated otherwise. It is your responsibility to review these Terms and stay informed about any modifications as long as you continue to access or use the Website or any Services.</p>

                                <p class="kpparaone mb-2">If you disagree with these Terms or any subsequent amendments, changes, or updates, you must refrain from using the Website or any of the Services. Your only course of action in such cases is to cease accessing the Website.</p>

                                <p class="kpparaone">In case of any inconsistency between these Terms and other pages or policies on the Website, these Terms will take precedence.</p>


                                <h2 class="kphedtwo fnbold">1. Interpretation</h2>
                                    <p class="kpparaone"><span class="ulboldtxt">1.1. Definitions:</span> In these Terms, the following terms have the subsequent meanings, unless otherwise specified.
                                    </p>

                                    <ul class="pkmenu2 pkmenu3">
                                        <li><a>"Affiliates" refers to Parkchain Innovation and all its direct or indirect shareholders, subsidiaries, employees, contractors, agents, 
                                        directors, officers, and partners. </a></li>
                                        <li><a>"Parkchain Innovation" denotes Parkchain Innovation.</a></li>
                                        <li><a>"Parkchain" is the general name assigned to any blockchains utilizing kpk as the native token and encompasses a public,      
                                        decentralized, and permissionless blockchain, Parkchain.</a></li>
                                        <li><a>"Community" pertains to the community of users and developers who are interested in or contribute to the infrastructure, code,
                                        and advancement of Parkchain.</a></li>
                                        <li><a>"Person" includes individuals, associations, partnerships, corporations, other legal entities, trusts, and any other form of legal
                                        organization or entity.</a></li>
                                        <li><a>"Prohibited Use" carries the meaning specified in paragraph 4 of these Terms.</a></li>

                                        <li><a>"Restricted Person" denotes an individual or legal entity residing in a country where access to the Website or any Service is prohibited under local laws.</a></li>

                                        <li><a>"Service" encompasses any services, functions, features, or content available on or through the Website.</a></li>

                                        <li><a>"Website" refers to the website located at bnbchain.org, along with all content, services, and products provided by Parkchain
                                            Innovation on or through the Website. This also includes subdomains of bnbchain.org owned by Parkchain Innovation, such as
                                            forum.bnbchain.org or dappbay.bnbchain.org. Occasionally, websites owned by Parkchain Innovation may have additional terms
                                            of service. If there is any conflict between those additional terms and these Terms, the more specific terms shall apply to the
                                            relevant page or service.</a></li>

                                            <li><a>"Terms" refers to these terms and conditions, including any changes, amendments, or updates made from time to time. It also
                                            encompasses any conditions, licenses, limitations, and obligations stated on the Website or any document incorporated herein
                                            by reference.</a></li>

                                            <li><a>"you" or "your" denotes the visitors of the Website.</a></li>


                                    </ul>
                                    <p class="kpparaone mb-2"><span class="ulboldtxt">1.2. Headings: </span>The headings and sub-headings utilized in these Terms are solely for convenience and do not affect the interpretation
                                        or construction of any provision to which they refer.
                                    </p>
                                    <p class="kpparaone"><span class="ulboldtxt">1.3. Extended Meanings:</span> Unless explicitly specified otherwise in these Terms, singular words include the plural form and vice versa, and
                                        words indicating a particular gender encompass all genders.
                                    </p>

                                    <h2 class="kphedtwo fnbold">2. Access to the Website</h2>
                                        <p class="kpparaone mb-1">2.1. You possess the privilege to utilize the Website and any Services provided that you adhere to these Terms. You declare and
                                        guarantee that:</p>

                                
                                <ul class="pkmenu2 pkmenu3">
                                    <li><a>You are either 13 years old or above, or you possess the legal capacity to enter into a contract according to the applicable law. </a></li>
                                    <li><a>You are not a Restricted Person, or you are not otherwise prohibited by the applicable law from utilizing the Website or Services.</a></li>
                                    <li><a>You assert and warrant that you will not employ the Website if the laws of your country restrict you from doing so.</a></li>
                                    <li><a>You access or use the Website or Services for your personal use only.</a></li>
                                    


                                </ul>
                                    <p class="kpparaone">2.2. We reserve the right, at our sole discretion, to modify, suspend, or terminate the Website or Services, or any part thereof, without
                                        prior notice, and to block or prevent your future access to the Website or use of the Services.</p>


                                    <h2 class="kphedtwo fnbold">3. Intellectual Property Rights</h2>

                                        <p class="kpparaone mb-2">3.1. The Website and its content, including logos, designs, information, data, text, images, links, sounds, graphics, videos, and other
                                            materials, as well as the arrangement thereof, are the property of Parkchain Innovation, our Affiliates, or our licensors or other
                                            providers of such materials. They are protected under the intellectual property or proprietary laws.</p>


                                        <p class="kpparaone mb-2">3.2. The trademarks "Parkchain," "Parkchain logo," and all related names, logos, product and service names, designs, and slogans
                                        ("Parkchain Marks") are owned by us. Nothing on the Website should be interpreted as granting, implicitly or otherwise, any license or
                                        right to use, copy, or imitate, either in whole or in part, any trademark displayed on the Website without our written permission.</p>



                                        <p class="kpparaone mb-2">3.3. You are prohibited from creating or registering any businesses, URLs, domain names, software application names or titles, social
                                        media handles, or profiles that incorporate Parkchain Marks or any mark, name, title, or work that is confusingly or substantially
                                        similar. You are also prohibited from applying to register, reference, use, copy, and/or claim ownership of Parkchain Marks in any
                                        manner for any purposes, whether alone or in combination with other letters, punctuation, words, symbols, designs, or creative works.
                                        Additionally, you may not use Parkchain Marks in a manner that disparages or discredits them.</p>




                                <h2 class="kphedtwo fnbold">4. Prohibited Uses</h2>

                                    <p class="kpparaone">You are prohibited from:</p>

                                        <div class="pl-3">
                                                <p class="kpparaone mb-2">4.1. using the Website or any Services to facilitate any violation of applicable  laws or regulations.</p>
                                                <p class="kpparaone mb-2">4.2. using the Website or any Services to interfere with or undermine the rights or obligations of Parkchain Innovation, its Affiliates, other
                                                Website Users, or any third party.</p>
                                                <p class="kpparaone mb-2">4.3. exploiting any technical glitches, malfunctions, failures, delays, defaults, or security breaches on the Website or any Services.</p>
                                                <p class="kpparaone mb-2">4.4. utilizing the Website or any Services to engage in conduct that harms Parkchain Innovation, its Affiliates, other Website users, or
                                                any third party.</p>
                                                <p class="kpparaone mb-2">4.5. Reverse-engineering, decompiling, or disassembling any closed-sourced or proprietary software running on the Website.</p>
                                                <p class="kpparaone mb-2">4.6. attempting to harm Parkchain Innovation, any Website User, or any third party through your access to the Website or any Services,
                                                    except as permitted by applicable law in relation to your free speech rights.</p>
                                                <p class="kpparaone mb-2">4.7. attempting to impersonate another Website User or Person.</p>
                                                <p class="kpparaone mb-2">4.8. transmitting or soliciting the sending of any advertising or promotional material without our prior written consent, including
                                                    junk mail, chain letters, spam, or similar solicitations.  </p>
                                                <p class="kpparaone mb-2">4.9. violating these Terms.</p>
                                                <p class="kpparaone mb-2">4.10. using the Website or any Services if you are a resident or citizen of a country where access to the Website or any Service is
                                                    prohibited under local laws. By accessing the Website, you do so voluntarily and assume responsibility for complying with local laws.</p>

                                            </div>

                                        <p class="kpparaone">Any use described in this section shall be considered a "Prohibited Use." If we determine that you have engaged in any Prohibited Use,
                                            we may address such use at our sole discretion.</p>

                                    <h2 class="kphedtwo fnbold">5. Accuracy of Materials</h2>

                                    <p class="kpparaone">The content provided on the Website may contain technical, typographical, or photographic errors. We do not guarantee the accuracy, completeness, or currency of any materials on the Website or any linked websites. Furthermore, we are not obligated to update the content available on the Website.</p>



                                        <h2 class="kphedtwo fnbold">6. Website User Submissions</h2>


                                        <div class="pl-3">
                                            <p class="kpparaone mb-2">6.1. All materials uploaded, posted, transmitted, or made available on our Website by Website Users ("Website User Submissions"), including submissions on Parkchain Forum, are the sole responsibility of the users. Parkchain Innovation is not liable for any claims arising from Website User Submissions. You warrant that you have the necessary rights to make your Website User Submissions available in accordance with these terms and applicable laws.</p>

                                            <p class="kpparaone mb-2">6.2. Guidelines for Parkchain Forum Submissions: When using Parkchain Forum, you must adhere to the following rules. We reserve the right to modify, redact, or delete content on the Forum for any reason.</p>
                                        </div>
                                            <div class="pl-3">
                                                <ul class="pkmenu2 pkmenu3">
                                                    <li><a>You must not attempt to use another person's account on the Forum. </a></li>
                                                    <li><a>You must not engage in the buying, selling, or trading of user names or unique identifiers on the Forum.</a></li>
                                                    <li><a>You must not send advertisements, chain letters, or other solicitations through the Forum, nor collect personal data for
                                                    commercial purposes.</a></li>
                                                    <li><a>You must not automate access to or monitor the Forum using unauthorized computer programs, except for publicly available
                                                    search engines.</a></li>
                                                    <li><a>You must not post objectionable, offensive, unlawful, deceptive, inaccurate, or harmful content. </a></li>
                                                    <li><a>You must not use the Forum to send emails to distribution lists, newsgroups, or group mail aliases.</a></li>
                                                    <li><a>You must not falsely imply affiliation with or endorsement by the company. </a></li>
                                                    <li><a>You must not hyperlink to Forum content on other web pages.</a></li>
                                                    <li><a>You must not remove proprietary ownership marks from downloaded Forum materials.</a></li>
                                                    <li><a>You must not embed any part of the Forum on other websites using</a></li>
                                                    <li><a>You must not disable, circumvent, or avoid any Forum security or access restrictions. </a></li>
                                                    <li><a>You must not overload the Forum infrastructure with an excessive volume of requests or requests that impose an unreasonable
                                                    load on the underlying information systems. </a></li>
                                                    <li><a>You must not impersonate others on the Forum.</a></li>
                                                    <li><a>You must not encourage or assist others in violating these Terms.</a></li>
                                                    <li><a>You must not submit content containing malicious computer code to the Forum. </a></li>
                                                    <li><a>You must not submit content as a placeholder for specific addresses, usernames, or unique identifiers.</a></li>
                                                    <li><a>You must not plan or engage in illegal, fraudulent, or manipulative activities. </a></li>
                                            </ul>
                                            </div>
                                        <div class="pl-3">
                                            <p class="kpparaone mb-2">6.3. In addition to the conditions listed in Article 6.2, your use of features such as comments, ratings, or reviews on our Services, including dAppbay, must comply with the following:</p>

                                            <ul class="pkmenu2 pkmenu3">
                                                <li><a>You must not post dishonest, abusive, harmful, misleading, or bad-faith ratings or reviews that are irrelevant to the content
                                                    being reviewed. </a></li>
                                                <li><a>You must not post, modify, or remove ratings or reviews in exchange for compensation or incentives.</a></li>
                                                
                                                


                                            </ul>
                                        </div>
                                            <div class="pl-3">
                                            <p class="kpparaone mb-2">6.4. Parkchain Innovation may organize online or offline projects, events, workshops, or activities through the Website. Specific license terms or agreements may apply to such projects and may require users to agree to additional terms regarding submissions made during those activities. In the case of any conflict between these Terms and those specific license terms or agreements, the latter will take precedence. Any projects, events, workshops, or activities organized by Parkchain Innovation are primarily for educational purposes, community communication, or idea exchange unless expressly stated otherwise. Information provided during these activities does not constitute investment advice or any indication of that nature.</p>

                                            <p class="kpparaone mb-2">6.5. Information or material included in Website User Submissions will not be considered confidential.</p>

                                            <p class="kpparaone mb-2">6.6. You are prohibited from posting or transmitting any content to or from this Website, including DAppbay or Parkchain Forum, that violates the law, infringes on intellectual property rights, invades privacy, breaches agreements with others, or contains threatening, libelous, defamatory, obscene, scandalous, inflammatory, pornographic, or profane material. Posting such content may result in civil or criminal liability under applicable laws.</p>
                                        </div>

                                            <h2 class="kphedtwo fnbold">7. Third-Party Content and Links</h2>
                                            <p class="kpparaone">The Website may contain third-party content and provide links to external websites or content not owned or controlled by us, including third-party advertisements or promotions (referred to as "Third Party Content"). We do not endorse, control, or adopt any Third Party Content and make no warranties regarding its accuracy or completeness. The inclusion of Third Party Content does not imply our endorsement. We are not responsible for any Third Party Content and do not undertake to review or update it. Users access and use Third Party Content at their own risk. Your interactions or participation in promotions with third parties, as well as any associated terms, conditions, warranties, or representations, are solely between you and those third parties. We are not liable for any loss or damage resulting from such interactions or the presence of Third Party Content on the Website.</p>


                                        <h2 class="kphedtwo fnbold">8. No Representations & Warranties</h2>
                                        <p class="kpparaone mb-2">We do not make any representations, warranties, or guarantees of any kind to you. The Website and Services are provided "as-is" and "as-available" without any representation of merchantability or fitness for a particular purpose. We do not guarantee continuous, uninterrupted, timely, or error-free access to the Website or Services, or any materials contained therein.</p>

                                        <p class="kpparaone mb-2">The information provided on the Website should not be relied upon. While we strive to provide accurate and timely information, we cannot guarantee that the Website will always be completely accurate, up to date, or free from technical inaccuracies or typographical errors. Information may be changed or updated from time to time without notice, including policies, agreements, products, and services, to the extent permitted by applicable law. Therefore, it is your responsibility to verify all information before making any decisions, and we disclaim any liability for decisions made based on information from the Website.</p>


                                        <h2 class="kphedtwo fnbold">9. No Advice</h2>

                                        <p class="kpparaone">We do not offer any investment advice or advice regarding trading techniques, models, algorithms, or any other schemes.</p>


                                        <h2 class="kphedtwo fnbold">10. Independent Contractor</h2>

                                        <p class="kpparaone">Both parties to these Terms will operate as independent contractors. The access or usage of the Website or Services, as well as the contents herein, shall not establish any association, partnership, or joint venture between the parties, nor indicate an intention to establish such a relationship.</p>

                                        <h2 class="kphedtwo fnbold">11. Limitation of Liability & Release</h2>

                                        <p class="kpparaone">Parkchain Innovation accepts no liability or responsibility for any Losses directly or indirectly arising from:</p>

                                        <div class="pl-3">
                                            <p class="kpparaone mb-1">11.1. these Terms</p>
                                            <p class="kpparaone mb-1">11.2. the Website and your use of it</p>
                                            <p class="kpparaone mb-1">11.3. Parkchain and your use of it</p>
                                            <p class="kpparaone mb-1">11.4. the Services and your use of any of them</p>
                                            <p class="kpparaone mb-1">11.5. any failure, delay, malfunction, interruption, or decision by Parkchain Innovation in operating the Website or providing any Service, including any variation or interference with your rights</p>
                                            <p class="kpparaone mb-1">11.6. any offer, representation, suggestion, statement, or claim made by any Website User or Community participant regarding Parkchain Innovation, the Website, or any Service.</p>
                                        </div>

                                        <p class="kpparaone">You hereby agree to release Parkchain Innovation and its Affiliates from liability for any and all Losses, and you shall indemnify and hold Parkchain Innovation and its Affiliates harmless from such Losses. These limitations of liability apply regardless of the basis of alleged liability or Losses, whether it be contract, negligence, tort, unjust enrichment, strict liability, or any other basis, even if Parkchain Innovation or its Affiliates were aware or should have known of the possibility of such losses and damages. These limitations of liability also apply irrespective of the success or effectiveness of any other remedies.</p>

                                        <h2 class="kphedtwo fnbold">12. No Waiver</h2>

                                        <p class="kpparaone">The failure of Parkchain Innovation to exercise any of its rights, powers, or remedies under these Terms, or under the law, or any delay in doing so, does not constitute a waiver of such rights, powers, or remedies. The exercise of any right, power, or remedy by Parkchain Innovation, whether in part or in whole, does not prevent the exercise of any other rights, powers, or remedies.</p>

                                        <h2 class="kphedtwo fnbold">13. Force Majeure</h2>

                                        <p class="kpparaone">Neither party shall be held responsible for any loss or damages resulting from the delay or failure to perform obligations under these Terms of Service due to unforeseeable events beyond their control, including but not limited to fires, strikes, floods, power outages or failures, acts of God or enemies of the state, actions by public authorities, computer or Internet malfunctions, security breaches or cyberattacks, criminal acts, delays or defaults by common carriers, acts or omissions of third parties, or any other delays, defaults, failures, or interruptions that cannot reasonably be anticipated or prevented.</p>


                                        <h2 class="kphedtwo fnbold">14. Assignment</h2>

                                        <p class="kpparaone">You may not assign these Terms, or any rights, duties, or obligations contained herein, without our prior written consent. We, on the other hand, may freely assign these Terms, as well as any rights, duties, or obligations contained herein, without notice or your consent. Any attempt by you to assign these Terms without written consent will be void.</p>


                                        <h2 class="kphedtwo fnbold">15. No Class Proceedings</h2>

                                        <p class="kpparaone">You agree that any claims relating to these Terms or your relationship with Parkchain Innovation as a Website User shall be brought solely on an individual basis. You may not bring such claims as a plaintiff or class member in any class or representative action or proceeding, whether based on contract, statute, fraud, tort, misrepresentation, or any other legal theory, and whether the claims occur during or after the termination of these Terms. No adjudicator may consolidate or join multiple claims, and they may not head over any form of a consolidated, representative, or class proceeding. Furthermore, you waive any right to bring such claims as a class, collective, representative, or private attorney general action, to the extent permitted by applicable law. The consolidation of individual arbitrations into a single arbitration requires the consent of Parkchain Innovation. Any relief granted to one Website User shall not affect any other Website User.</p>


                                        <h2 class="kphedtwo fnbold">16. Disclaimer</h2>

                                        <p class="kpparaone">We offer the Website and any Services on an "as is" and "as available" basis. The use of the Internet may entail limitations, delays, or other inherent problems, and we do not guarantee constant availability or error-free operation of the Website and Services.</p>


                                        <h2 class="kphedtwo fnbold">17. Applicable Law and Jurisdiction</h2>

                                        <p class="kpparaone">PLEASE READ THIS SECTION CAREFULLY AS IT MAY SIGNIFICANTLY IMPACT YOUR LEGAL RIGHTS, INCLUDING YOUR RIGHT TO INITIATE A LAWSUIT IN COURT. SECTION 17 MANDATES BINDING ARBITRATION FOR ANY DISPUTES ARISING FROM OR RELATED TO THESE TERMS OR THE USE OF THE WEBSITE OR RELATED SERVICES, AND LIMITS THE METHODS THROUGH WHICH YOU CAN SEEK REMEDIES FROM US.</p>

                                        <div class="pl-3">
                                            <p class="kpparaone mb-1">17.1. All matters pertaining to the Website and any dispute, controversy, difference, or claim arising from or relating to these Terms, including their existence, validity, interpretation, or breach, or any dispute regarding non-contractual obligations arising from or relating to these Terms, shall be referred to and ultimately resolved through arbitration administered by the Hong Kong International Arbitration Centre (HKIAC) under the HKIAC Administered Arbitration Rules in effect at the time the Notice of Arbitration is submitted.</p>
                                            <p class="kpparaone mb-1">17.2. The law applicable to this arbitration clause shall be Hong Kong law. The arbitration proceedings shall take place in Hong Kong, and the English language shall be used for conducting the arbitration.</p>
                                            <p class="kpparaone mb-1">17.3. The parties agree to maintain the confidentiality of the arbitration. The existence of the arbitration, any nonpublic information disclosed during the arbitration, and any submissions, orders, or awards made in the arbitration shall not be disclosed to third parties except for the tribunal, the HKIAC, the parties, their legal counsel, experts, witnesses, accountants and auditors, insurers and reinsurers, and any other individuals necessary for conducting the arbitration. However, a party may disclose such confidential information to the extent required to fulfill a legal duty, protect or pursue a legal right, or enforce or challenge an award in genuine legal proceedings. This confidentiality provision shall remain in effect even after the termination of these Terms and the conclusion or stay of any arbitration initiated pursuant to these Terms.</p>
                                            
                                        </div>



                                        <h2 class="kphedtwo fnbold">18. Severability</h2>

                                        <p class="kpparaone">If any provision of these Terms, as amended from time to time, is deemed invalid, void, or unenforceable, in whole or in part, by any competent court, such invalidity, voidness, or unenforceability shall only apply to that specific provision, and all other provisions of these Terms shall continue to be fully valid and enforceable.</p>

                                            <h2 class="kphedtwo fnbold">19. Entire Agreement</h2>

                                        <p class="kpparaone">These Terms, along with any additional terms, conditions, licenses, limitations, and obligations set forth within and on the Website, constitute the complete agreement between you and Parkchain Innovation regarding the use of the Website and Services. Except for the explicit statements, agreements, and rules outlined in these Terms, you acknowledge and agree that you have not relied on any other statement or agreement, whether written or oral, concerning your use and access to the Website and Services.</p>                  */}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
   
}

export default Home;