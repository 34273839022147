import "react-toastify/dist/ReactToastify.css";

import { Bounce, Flip, Slide, Zoom } from "react-toastify";
import React, {
  Component,
  Fragment,
  createRef,
  useEffect,
  useRef,
  useState,
} from "react";
import { ToastContainer, toast } from "react-toastify";

import { CONST } from "../../../_config";
import HtmlParser from "react-html-parser";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

const HackathonDetailsJudging = (props) => {
  const params = useParams();
  console.log(params, "params");

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDd = () => setDropdownOpen((prevState) => !prevState);

  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const [HackathonDetails, setHackathonDetails] = useState([]);

  console.log("props", props?.conclusion);

  useEffect(() => {
    SingleHcakdetails();
  }, []);

  const SingleHcakdetails = async () => {
    let hackdata = {
      _id: params.id,
    };

    let getdata = await axios.post(
      CONST.BACKEND_URL + "listHackthonById",
      hackdata
    );
    console.log(getdata.data.data, "Listhacksddddddd");
    if (getdata.data.status == true) {
      // toast.success(getdata.data.message,
      //     {
      //         transition: Flip,
      //         className: 'foo-bar'
      //     })
      setHackathonDetails(getdata.data.data);
    }
  };
  return (
    <Fragment>
      {HackathonDetails && HackathonDetails.length > 0 ? (
        HackathonDetails.map((data, index) => (
          <div className="hckDtCms">
            {props?.conclusion == true ? <></> : <h6>Judging Criteria</h6>}

            {/* <h5>Judges Will Be Announced At a Later Date</h5>
                <br />
                <h5>Judging Criteria</h5>

                <p>Originality</p>
                <p>How novel and innovative is the idea? Does it set out to do something unique or at least improve existing models?</p>

                <p>Technicality</p>
                <p>What is tech like? Is it user-friendly and does it have comprehensive and efficient features? Is it designed to scale?</p>
                <p>Business Model</p>
                <p>What is the value proposition and how does it set out to differentiate itself? How will it capture the market and/or generate revenue?</p>
                <p>Usability</p>
                <p>What is the user experience like? Is it clean and intuitive such that users not familiar with blockchain can easily interact with the program?</p>
                <p>Practicality</p>
                <p>Is this something the community would actually use and want? Will the idea have an impact on the crypto community?</p>
                <p>Contribution to the Crypto Ecosystem</p>
                <p>How does your idea increase crypto adoption, value for its users, and innovation in the field of blockchain? Does it welcome new users and help existing users?</p> */}

            <p>{data ? HtmlParser(data.judging_criteria) : <></>}</p>
          </div>
        ))
      ) : (
        <></>
      )}
    </Fragment>
  );
};

export default HackathonDetailsJudging;
