import { Input, Table } from "reactstrap";
import React, { Fragment, useEffect, useState } from "react";

import { CONST } from "../../_config";
import Footer from "../../common/Footer/Footer";
import Header from "../../common/Header/Header";
import Pagination from "react-js-pagination";
import { Tab } from "bootstrap";
import axios from "axios";
import indiaflag from "../../assets/images/leaderboard/ind.svg";
import searchicon from "../../assets/images/search-normal.svg";
import { toast } from "react-toastify";
import user from "../../assets/images/default-avatar-dark.jpg";
import { useSelector } from "react-redux";

/** Images **/

const UserHackathonLists = (props) => {

    var reduxRes = useSelector((state) => state);

    reduxRes = reduxRes?.user;


    const [leaderboard, setLeaderBoard] = useState("");
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [pageNotationCount, setPageNotationCount] = useState(0);
    const [page, setPage] = useState(1);
    const handlePagination = (pageNumber) => {
        setPage(pageNumber);
        var skip = pageNumber - 1;
        pageNotationFun(skip);
    };
    useEffect(() => {
        pageNotationFun(0);
        setPage(1);
    }, [search]);
    const pageNotationFun = (value) => {
        setLoading(true);
        let userDatas = {
            hackathon_create_userId: reduxRes?.userId,
        }
        const config = {
            header: "https://parkchain.org",
        };

        axios

            .post(CONST.BACKEND_URL + "users/listHackathonTopicByUser", userDatas, config)
            .then((res) => {
                console.log(res.data, "ressssssssssssssssssssggdfgdfgdfgdfgdfg");
                setLoading(false);
                if (res.data.status == true) {
                    setLoading(false);
                    setLeaderBoard(res.data.data);
                    setPageNotationCount(res.data.leaderBoardCount);
                } else {
                    setLoading(false);
                    // toast.error(res.data.message)
                }
            })
            .catch((err) => {
                setLoading(false);
                toast.error(err);
            });
    };
    return (
        <Fragment>
            <Header />
            {loading == true ? (
                <div className="pageloade">
                    <div className="page-loader">
                        <div class="loader-12">
                            <span class="loader_arrow"></span>
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}
            <div className="pagemain-content">
                <div className="leaderboard-maincontent">
                    <div className="container">
                        <div className="leaderboard-head">
                            <h5>Leaderboard</h5>
                        </div>
                        <div className="leaderboard-search">
                            <Input
                                placeholder="search"
                                onChange={(e) => {
                                    setSearch(e.target.value);
                                }}
                            />
                            <div className="search-icon">
                                <img src={searchicon} alt="" className="img-fluid" />
                            </div>
                        </div>
                        <div className="leadertable-main">
                            <div className="leaderboard-table">
                                <Table responsive borderless>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>currency</th>
                                            <th>User Address</th>
                                            <th>Ratings</th>

                                            <th>Points</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {leaderboard && leaderboard.length > 0 ? (
                                            leaderboard &&
                                            leaderboard.map((itemData) => {
                                                return (
                                                    <tr>
                                                        <td>
                                                            <div className="userdet">
                                                                <div className="user-icon">
                                                                    <img
                                                                        src={
                                                                            itemData.profile_img
                                                                                ? itemData.profile_img
                                                                                : user
                                                                        }
                                                                        alt=""
                                                                        className="img-fluid"
                                                                    />
                                                                </div>
                                                                <div className="user-name">
                                                                    {itemData.userName}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>{itemData.currency}</td>
                                                        {/* <td className='flag-td'><img src={indiaflag} alt="" className='img-fluid' /></td> */}
                                                        <td>{itemData.userAddress}</td>
                                                        <td>{itemData.overallRating}</td>

                                                        <td>{itemData.earning_amount}</td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <span>No data found</span>
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                            <div className="table-show">
                                <p>Showing 1 - 10 of {pageNotationCount}</p>
                            </div>
                            <div className="table-pagination">
                                <div className="tableshow">
                                    {/* <p>Show</p> */}
                                    {/* <div>
               
                              <Input type='select'>
                                 <option value="">1</option>
                                 <option value="">2</option>
                                 <option value="">3</option>
                                 <option value="">4</option>
                              </Input>
                           </div> */}
                                </div>
                                <div
                                    className="tablepagination-ul"
                                    style={{
                                        display: pageNotationCount > 0 ? "flex" : "none",
                                        justifyContent: "flex-end",
                                    }}
                                >
                                    {/* <button>Previous</button> */}
                                    <Pagination
                                        activePage={page}
                                        itemsCountPerPage={10}
                                        totalItemsCount={pageNotationCount}
                                        onChange={handlePagination}
                                        pageRangeDisplayed={5}
                                        itemClass="page-item"
                                    />
                                    {/* <ul>
                              <li><a href="">1</a></li>
                              <li><a href="">2</a></li>
                              <li><a href="">3</a></li>
                              <li><a href="">4</a></li>
                           </ul> */}
                                    {/* <button>Next</button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
};
export default UserHackathonLists;
