import React, { Component, Fragment, useState, useRef, useEffect, createRef  } from "react";
import ReactDOM from "react-dom";
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import Overview from '../Overview/Overview';
import NFTDocumentation from '../NFTDocumentation/nftbenifits';
import DeveloperProgram from '../DeveloperProgram/DeveloperProgram';
import {
	BrowserRouter, Navigate, Route, Routes, Link
 } from 'react-router-dom';
 import { useSelector } from "react-redux";

import Header from "../../common/Header/Header";
import Footer from "../../common/Footer/Footer";

import downimg from '../../assets/images/pics/downimg.png';
import HtmlParser from "react-html-parser";


const Home = (props) => {  
    const [activeTab, setActiveTab] = useState('1');
    const toggle = tab => {
      if(activeTab !== tab) setActiveTab(tab);
    }
    let data1;
    let data = useSelector(state => (state.user));
    console.log(data)
    if(data.data.status==200){
        data = data.data.data;
        data1 = data.find(elem=>elem.title=='Wallet');

    }
    console.log(data1)
    return (
        <Fragment>
            <Header />
            <div className="pkchinsec cmsCnt">
                <section className="cmsBannerSec wltBanner d-flex align-items-center justify-content-start">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h3 className="cmsTitle">Wallet</h3>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="container py-5">
                    {/* <p>Parkchain token (KPK) is the utility token that serves as the foundation of the Parkchain ecosystem, driven by the vision of making the cryptocurrency system accessible, user-friendly, and secure on a global scale. Beyond its token status, KPK acts as a dynamic web3-ecosystem, seamlessly integrating various aspects of the crypto world such as cryptocurrency holdings, community tokens, NFTs, DeFi apps, the Metaverse, and more. The KPK token is built on the ParkChain blockchain and has a total supply of 10,000,000,000 (10 billion). Token holders can expect to receive various rewards through our ongoing projects and trading exchange.With KPK, users can experience a comprehensive and interconnected crypto journey, empowering them to navigate and engage in a wide range of crypto activities with ease and efficiency.</p> */}
                    

                    {/* <h3 className="cmsHeading">How does KPK work & where can I get a Trust wallet?</h3>
                    <p>KPK operates within the Parkchain ecosystem, offering various benefits and functionalities. It can be used to pay trading fees on Parkchain, providing users with discounts. Additionally, KPK can serve as collateral for loans and can be staked to earn rewards. As a holder of KPK, you gain exclusive access to opportunities on Parkchain, including investment options in Parkchain Launchpad events.</p>
                    <p>KPK operates on Parkchain and is compatible with the Parkchain Virtual Machine (EVM), making it easy to migrate decentralized applications (DApps) from Parkchain to Parkchain.</p>
                    <p>Parkchain tools like Remix, Truffle, and Metamask can be utilized with Parkchain, allowing developers familiar with building on Parkchain to transition their DApps seamlessly.</p>
                    <p>For those seeking a wallet, Trust Wallet is recommended for both Android and iOS devices. Trust Wallet not only enables KPK trading on the Parkchain decentralized exchange (DEX), but it also features a built-in DApp browser, allowing users to interact with Smart Chain DApps directly within the wallet interface.</p>

                    <h3 className="cmsHeading">What are the features of KPK and Parkchain?</h3>
                    <h5 className="cmsHeading">1. Low Transaction Fees</h5>
                    <p>Compared to Parkchain, Parkchain boasts gas fees that are approximately 20 times cheaper. The increasing transaction fees on Parkchain have created challenges and costs for DeFi users. With KPK, transaction fees are significantly reduced, enabling a broader range of investors from diverse backgrounds and economies to participate without incurring excessive overhead expenses.</p>

                    <h5 className="cmsHeading">2. Faster Performance</h5>
                    <p>Parkchain employs a consensus mechanism similar to Parkchain 2.0's proof-of-stake plans, eliminating the reliance on mining. This design ensures faster transaction processing compared to other blockchain networks. Users can experience quick and efficient interactions within the Parkchain ecosystem.</p>

                    <h5 className="cmsHeading">3. Cross-chain DeFi</h5>
                    <p>Parkchain is equipped with built-in capabilities for cross-chain transactions. Trust Wallet users can seamlessly switch between different versions of BNB, such as BEP2 (Binance Chain) and BEP20 (Binance Smart Chain), directly within their wallets. Furthermore, ongoing developments are underway to introduce additional cross-chain protocols on Parkchain, expanding access for DeFi investors to assets from other chains, including Parkchain.</p> */}
                    {data1 ? HtmlParser(data1.content_description) : <></>}
                </div>
            </div>
            <Footer />
        </Fragment>
    );
   
}

export default Home;