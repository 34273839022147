import "react-toastify/dist/ReactToastify.css";

import { Bounce, Flip, Slide, Zoom } from "react-toastify";
import React, {
  Component,
  Fragment,
  createRef,
  useEffect,
  useRef,
  useState,
} from "react";
import { ToastContainer, toast } from "react-toastify";

import { CONST } from "../../../_config";
import HtmlParser from "react-html-parser";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

const HackathonDetailsRules = (props) => {
  const params = useParams();
  console.log(params, "oooooooooooooooooooo");

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDd = () => setDropdownOpen((prevState) => !prevState);

  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const [HackathonDetails, setHackathonDetails] = useState([]);

  useEffect(() => {
    SingleHcakdetails();
  }, []);

  const SingleHcakdetails = async () => {
    let hackdata = {
      _id: params.id,
    };

    let getdata = await axios.post(
      CONST.BACKEND_URL + "listHackthonById",
      hackdata
    );
    console.log(getdata.data.data, "Listhacksddddddd");
    if (getdata.data.status == true) {
      // toast.success(getdata.data.message,
      //     {
      //         transition: Flip,
      //         className: 'foo-bar'
      //     })
      setHackathonDetails(getdata.data.data);
    }
  };

  return (
    <Fragment>
      {HackathonDetails && HackathonDetails.length > 0 ? (
        HackathonDetails.map((data, index) => (
          <div className="hckDtCms">
            {props?.useCase == true ? (
              <> </>
            ) : (
              <h6>Features and Functionalities:</h6>
            )}

            {/* <ul>
                            <li>Residents from Brazil, Crimea, Cuba, Iran, North Korea, Syria, Quebec and Nigeria are not eligible to participate in the hackathon.</li>
                            <li>Individuals who are at least the age of majority in the country where they reside as of the time of entry Teams or Organizations of any size are welcome to join.</li>
                            <li>Entrants must create (or significantly update) a working software application/dApp/website that integrates with the TRON or BitTorrent blockchain that fits into one of the hackathon tracks</li>
                            <li>Entrants must register not only on HackerEarth but also register on the TRON DAO Forum and create a post for the TRON Community under the season 5 category for TRON community voting.</li>
                        </ul>

                        <br />

                        <p>Your submission should include the following items by the end of the submission period:</p>
                        <ol>
                            <li>Complete project description.</li>
                            <li>Valid personal contact</li>
                            <li>a TRON DAO Forum Submission link, following TRON DAO Forum Submission Requirements below.</li>
                            <li>A demo video that is publicly visible on the Internet and explains Entrant’s track and includes footage that explains your project’s features and functionality comprehensively. No less than 3 minutes.</li>
                            <li>The Smart Contracts deployment links on TRON/BTTC (Mainnet / Testnet).</li>
                            <li>A detailed description of how your Project integrates with TRON/BTTC.</li>
                            <li>If the Project has been submitted in any form to another hackathon, include which Hackathons.</li>
                            <li>Links to your code repository, website and socials to help judges review your submission. If Entrant’s website is private, Entrant must include login credentials in its testing instructions.</li>
                        </ol> */}
            <p>{data ? HtmlParser(data.theme_description) : <></>}</p>
          </div>
        ))
      ) : (
        <></>
      )}
    </Fragment>
  );
};

export default HackathonDetailsRules;
