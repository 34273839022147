import "react-toastify/dist/ReactToastify.css";

import { Bounce, Flip, Slide, Zoom } from "react-toastify";
import {
  BrowserRouter,
  Link,
  Navigate,
  Route,
  Routes,
  useParams,
} from "react-router-dom";
import React, {
  Component,
  Fragment,
  createRef,
  useEffect,
  useRef,
  useState,
} from "react";
import { ToastContainer, toast } from "react-toastify";

import { CONST } from "../../../_config";
import axios from "axios";
import bronze from "../../../assets/images/hackathonList/bronze.png";
import gold from "../../../assets/images/hackathonList/gold.png";
import silver from "../../../assets/images/hackathonList/silver.png";

const HackathonDetailsPrizes = (props) => {
  const params = useParams();
  console.log(params);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDd = () => setDropdownOpen((prevState) => !prevState);

  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const [HackathonDetails, setHackathonDetails] = useState([]);

  useEffect(() => {
    SingleHcakdetails();
  }, []);
  var PriceAmt;

  const SingleHcakdetails = async () => {
    let hackdata = {
      _id: params.id,
    };

    let getdata = await axios.post(
      CONST.BACKEND_URL + "listHackthonById",
      hackdata
    );
    console.log(getdata.data.data, "Listhacksddddddd");
    if (getdata.data.status == true) {
      // toast.success(getdata.data.message, {
      //   transition: Flip,
      //   className: "foo-bar",
      // });
      setHackathonDetails(getdata.data.data[0]);
    }
  };

  return (
    <Fragment>
      <div className="hckDtCms">
        <h6>Prizes</h6>
        <div className="hckDtPzLs">
          <div className="hckDtPz">
            <div className="hckDtPzInr">
              <div className="hckDtPzInrLt">
                <div className="hckDtPzIc">
                  <img src={gold} alt="gold" />
                </div>
                <div className="hckDtPzCt">1st Place Web3</div>
              </div>
              <div className="hckDtPzInrRt">
                <div className="hckDtPzLb">Prize</div>
                <div className="hckDtPzAmt">
                  {HackathonDetails && HackathonDetails.p1} INR
                </div>
              </div>
            </div>
          </div>

          <div className="hckDtPz">
            <div className="hckDtPzInr">
              <div className="hckDtPzInrLt">
                <div className="hckDtPzIc">
                  <img src={silver} alt="silver" />
                </div>
                <div className="hckDtPzCt">2nd Place Web3</div>
              </div>
              <div className="hckDtPzInrRt">
                <div className="hckDtPzLb">Prize</div>
                <div className="hckDtPzAmt">
                  {HackathonDetails && HackathonDetails.p2} INR
                </div>
              </div>
            </div>
          </div>

          <div className="hckDtPz">
            <div className="hckDtPzInr">
              <div className="hckDtPzInrLt">
                <div className="hckDtPzIc">
                  <img src={bronze} alt="bronze" />
                </div>
                <div className="hckDtPzCt">3rd Place Web3</div>
              </div>
              <div className="hckDtPzInrRt">
                <div className="hckDtPzLb">Prize</div>
                <div className="hckDtPzAmt">
                  {HackathonDetails && HackathonDetails.p3} INR
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default HackathonDetailsPrizes;
