import "./scss/guidehub.scss";

import React, {
  Component,
  Fragment,
  createRef,
  useEffect,
  useRef,
  useState,
} from "react";

import Footer from "../../common/Footer/Footer";
import Header from "../../common/Header/Header";
import HtmlParser from "react-html-parser";
import ReactDOM from "react-dom";
/** Images  **/
import bnr from "./images/bnr.png";
import classnames from "classnames";
import crypto from "./images/crypto.png";
import decentraland from "./images/decentralnd.png";
import digitalidentiy from "./images/digital.png";
import efficiency from "./images/efficiency.png";
import immutability from "./images/immutability.png";
import lowfees from "./images/lowfees.png";
import pakchainmechanism from "./images/parkchain-img.png";
import pakchainwork from "./images/parkchainwork.png";
import smartcontract from "./images/smartcontract.png";
import supplychain from "./images/chain.png";
import transparency from "./images/transparency.png";
import trust from "./images/trust.png";
import { useSelector } from "react-redux";
import voting from "./images/vote.png";

const Guidehub = (props) => {
  const [guideHb, setGuideHb] = useState("");
  var Res = useSelector((state) => state.user);
  useEffect(() => {
    if (Res?.data?.status == 200) {
      Res = Res.data.data;
      setGuideHb(Res);

      console.log("Redss", Res);
    }
  }, [Res, guideHb]);

  return (
    <Fragment>
      <Header />
      <div className="kpkpayment-pagecnt">
        {guideHb &&
          guideHb.map((item) => {
            if (item.heading == "guidesHubHead") {
              console.log("item------", item);
              return (
                <div>
                  <section className="kpkbanner-sec bluebgbnr-sec">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="cmnbnr-txt">
                            <h1>
                              What is ParkChain & <span>How Does It Work?</span>
                            </h1>
                            <p>{HtmlParser(item.content_description)}</p>
                          </div>
                        </div>
                        <div className="col-lg-6 text-center mt-4 mt-lg-0">
                          <div>
                            <img src={bnr} alt="" className="img-fluid" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="whatdecentralization-sec">
                    <div className="container">
                      <div class="row">
                        <div class="col-lg-10 col-xl-8 mx-auto">
                          <div class="whatdec-inrbox">
                            <h5>{HtmlParser(item.Overview)}</h5>
                            <p className="text-center">
                              {HtmlParser(item.OverviewDes)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="hoedoeswork-sec">
                    <div className="container">
                      <div className="row align-items-center">
                        <div className="col-lg-6">
                          <div className="howdoes-leftcnt">
                            <h2>{item.Transactions}</h2>
                            <p>
                              At its core, a Parkchain digital ledger that
                              securely records transactions between two parties
                              in a tamper-proof manner. These transaction data
                              are recorded by a globally distributed network of
                              special computers called nodes.
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-6 text-center mt-4 mt-lg-0">
                          <div>
                            <img
                              src={pakchainwork}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="py-3">
                        <p>{HtmlParser(item.TransactionDesc)}</p>
                      </div>
                    </div>
                  </section>
                  <section className=" py-4">
                    <div className="container">
                      <div className="row align-items-center">
                        <div className="col-lg-7">
                          <div className="howdoes-leftcnt">
                            <h2>{item.Blocks}</h2>
                            <p>{HtmlParser(item.BlockDes)}</p>
                          </div>
                        </div>
                        <div className="col-lg-5 text-center mt-4 mt-lg-0">
                          <div>
                            <img
                              src={pakchainmechanism}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="benifitparkchain-sec">
                    <div className="container">
                      <h2 className="text-center">Benefits of ParkChain</h2>
                      <div className="row mt-3">
                        <div className="col-md-6 col-lg-4 mt-4">
                          <div className="benefit-inbox">
                            <h5>{item.savings}</h5>
                            <div className="benefit-para">
                              <p>{HtmlParser(item.savings_dec)}</p>
                            </div>
                            <div className="benift-img">
                              <img
                                src={decentraland}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-4 mt-4">
                          <div className="benefit-inbox">
                            <h5>{item.security}</h5>
                            <div className="benefit-para">
                              <p>{HtmlParser(item.security_dec)}</p>
                            </div>
                            <div className="benift-img">
                              <img
                                src={transparency}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-4 mt-4">
                          <div className="benefit-inbox">
                            <h5>{item.Immutability}</h5>
                            <div className="benefit-para">
                              <p>{HtmlParser(item.Efficiency_Desc)}</p>
                            </div>
                            <div className="benift-img">
                              <img
                                src={immutability}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-4 mt-4">
                          <div className="benefit-inbox">
                            <h5>{item.Efficiency}</h5>
                            <div className="benefit-para">
                              <p>{HtmlParser(item.Lower_Fees_Desc)}</p>
                            </div>
                            <div className="benift-img">
                              <img
                                src={efficiency}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-4 mt-4">
                          <div className="benefit-inbox">
                            <h5>{item.Lower_Fees}</h5>
                            <div className="benefit-para">
                              <p>{HtmlParser(item.Trustlessness_Desc)}</p>
                            </div>
                            <div className="benift-img">
                              <img src={lowfees} alt="" className="img-fluid" />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-4 mt-4">
                          <div className="benefit-inbox">
                            <h5>{item.Trustlessness}</h5>
                            <div className="benefit-para">
                              <p>{HtmlParser(item.Cryptocurrencies_Desc)}</p>
                            </div>
                            <div className="benift-img">
                              <img src={trust} alt="" className="img-fluid" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="parkchainuse-sec py-5">
                    <div className="container">
                      <div className="row align-items-center">
                        <div className="col-lg-4">
                          <div className="colorbg-box pink-bg">
                            <img src={crypto} alt="" className="img-fluid" />
                            <h5>{item.Cryptocurrencies}</h5>
                            <p>{HtmlParser(item.Digital_Identity_Desc)}</p>
                          </div>
                        </div>
                        <div className="col-lg-8 mt-4">
                          <div className="row">
                            <div className="col-lg-11 col-xl-10 mx-auto">
                              <h2>{item.Digital_Identity}</h2>
                              <p>{HtmlParser(item.Voting_Desc)}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row my-3">
                        <div className="col-lg-7">
                          <div className="colorbg-box vilot-bg">
                            <img
                              src={digitalidentiy}
                              alt=""
                              className="img-fluid"
                            />
                            <h5> {item.Voting}</h5>
                            <p>{HtmlParser(item.Supply_Desc)}</p>
                          </div>
                        </div>
                        <div className="col-lg-5 mt-4 mt-lg-0">
                          <div className="colorbg-box lightgreen-bg">
                            <img src={voting} alt="" className="img-fluid" />
                            <h5>{item.Supply}</h5>
                            <p>{HtmlParser(item.Immutability_Desc)}</p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-7">
                          <div className="colorbg-box lightyellow-bg">
                            <img
                              src={supplychain}
                              alt=""
                              className="img-fluid"
                            />
                            <h5>{item.Smart}</h5>
                            <p>{HtmlParser(item.Smart_Desc)}</p>
                          </div>
                        </div>
                        <div className="col-lg-5 mt-4 mt-lg-0">
                          <div className="colorbg-box purple-bg">
                            <img
                              src={smartcontract}
                              alt=""
                              className="img-fluid"
                            />
                            <h5>{item.Parkchain_Used}</h5>
                            <p>{HtmlParser(item.Parkchain_Used_Desc)}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              );
            }
          })}
      </div>
      <Footer />
    </Fragment>
  );
};
export default Guidehub;
