import "./kphckcr.scss";

import { BrowserRouter, Link, Navigate, Route, Routes } from "react-router-dom";
import {
  Button,
  Collapse,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import React, {
  Component,
  Fragment,
  createRef,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import { CONST } from "../../_config";
import Footer from "../../common/Footer/Footer";
import Header from "../../common/Header/Header";
import Loader from "../../_config/Loader";
import ReactDOM from "react-dom";
import axios from "axios";
import camera from "./assets/camera.svg";
import classnames from "classnames";
import { getUserProfile } from "../../redux/action";
import laptopCoffee from "../../assets/images/hackathonList/laptopCoffee.png";
import profile from "./assets/profile.png";
import { toast } from "react-toastify";
import { type } from "@testing-library/user-event/dist/type";
import { useNavigate } from "react-router-dom";

const HackathonProfileEdit = (props) => {
  const [profileDetails, setProfileDetails] = useState({
    first_name: "",
    last_name: "",
    email: "",
    profile: "",
    mobile: "",
    country_id: "",
    country: "",
    profile_img: "",
    gender: "",
  });

  const [loading, setLoading] = useState(false);

  var reduxRes = useSelector((state) => state);
  reduxRes = reduxRes?.user;

  var dispatch = useDispatch();

  var navigate = useNavigate();

  console.log("reduxRes", reduxRes?.userProfile?.data);

  useEffect(() => {}, [reduxRes]);

  const GetUserProfile = () => {
    dispatch(
      getUserProfile({
        userId: reduxRes?.userId,
      })
    );

    if (reduxRes?.userProfile?.data) {
      setProfileDetails({
        first_name: reduxRes.userProfile.data.first_name,
        last_name: reduxRes.userProfile.data.last_name,
        email: reduxRes.userProfile.data.email,
        profile: reduxRes.userProfile.data.profile,
        mobile: reduxRes.userProfile.data.mobile,
        country_id: reduxRes.userProfile.data.country_id,
        country: reduxRes.userProfile.data.country,
        userName: reduxRes.userProfile.data.userName,
        profile_img: reduxRes.userProfile.data.profile_img,
        gender: reduxRes.userProfile.data.gender,
      });
    }
  };

  useEffect(() => {
    if (reduxRes?.userId) {
      GetUserProfile();
    }
  }, [reduxRes?.userId, reduxRes?.userProfile?.data?.first_name]);

  const onFileChange = (e, type) => {
    //  console.log("====================================", image, e);

    e.preventDefault();
    var file = e.target.files[0];
    var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.svg)$/i;
    var n = e.target.files[0].type.includes("image");

    console.log(
      "e.target.files[0].name",
      e.target.files[0].name,
      n,
      e.target.files[0].type
    );

    if (!allowedExtensions.exec(e.target.files[0].name) || !n) {
    } else {
      ImageUpload(file, type);
    }
  };

  const ImageUpload = (file, type) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("uploads", file);

    const options = {
      method: "POST",
      body: formData,
    };

    fetch(CONST.BACKEND_URL + "img", options)
      .then((response) => response.json())
      .then((res) => {
        if (res.status == true) {
          setLoading(false);

          setProfileDetails({ ...profileDetails, [type]: res.data.Location });
          console.log("location", res.data.Location);
          toast.success("File Upload Successfully");
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const editProfile = () => {
    setLoading(true);

    const config = {
      header: {
        Origin: "http://localhost:3000",
      },
    };

    const data = {
      userId: reduxRes?.userId,
      userName: profileDetails.userName,
      first_name: profileDetails.first_name,
      last_name: profileDetails.last_name,
      country: profileDetails.country,
      profile_img: profileDetails.profile_img,
      mobile: profileDetails.mobile,
      gender: profileDetails.gender,
    };

    axios
      .post(CONST.BACKEND_URL + "users/editProfile", data, config)

      .then((res) => {
        setLoading(false);

        if (res.data.status == true) {
          toast.success(res.data.message);
          navigate("/hackathon-profile");
        } else {
          toast.error(res.data.message);
        }
      });
  };

  return (
    <Fragment>
      <Header />

      {loading == true ? <Loader /> : <></>}

      <div className="hckPrfPg">
        <div className="hckLsBnr">
          <div className="container">
            <div className="hckLsBnrR row">
              <div className="hckLsBnrC col-lg-auto">
                <div className="hckLsBnrTx">
                  <h1>Profile</h1>
                </div>
              </div>
              <div className="hckLsBnrC col-lg-auto">
                <div className="hckLsBnrIg">
                  <img src={laptopCoffee} alt="laptopCoffee" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="hckPrfEd">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-xl-6 mx-auto">
                <div className="hckEdtPrf">
                  <div className="hckPrfDp v2">
                    <img
                      src={
                        profileDetails.profile_img
                          ? profileDetails.profile_img
                          : profile
                      }
                      alt="profile"
                    />
                    <input
                      type="file"
                      id="hckProfile"
                      onChange={(e) => {
                        onFileChange(e, "profile_img");
                      }}
                    />
                    <label htmlFor="hckProfile">
                      <div className="mdrIcG">
                        <div className="mdrIcBg"></div>
                        <div className="mdrIcCn">
                          <img src={camera} alt="camera" />
                        </div>
                      </div>
                    </label>
                  </div>
                </div>

                <div className="hckEdtPrfIp">
                  <div className="hckEdtPrfIpTy">PERSONAL DETAILS</div>

                  <div className="hckcrFrm">
                    <div className="row hckcrFrmR">
                      <div className="col-lg-6 hckcrFrmC">
                        <FormGroup>
                          <Label for="">Name</Label>
                          <Input
                            type="text"
                            name=""
                            id=""
                            placeholder=""
                            defaultValue={profileDetails.first_name}
                            onChange={(e) => {
                              setProfileDetails({
                                ...profileDetails,
                                first_name: e.target.value,
                              });
                            }}
                          />
                        </FormGroup>
                      </div>

                      <div className="col-lg-6 hckcrFrmC">
                        <FormGroup>
                          <Label for="">Gender</Label>
                          <Input
                            type="text"
                            name=""
                            id=""
                            defaultValue={profileDetails.gender}
                            onChange={(e) => {
                              setProfileDetails({
                                ...profileDetails,
                                gender: e.target.value,
                              });
                            }}

                            //    placeholder="Male"
                          />
                        </FormGroup>
                      </div>

                      <div className="col-lg-6 hckcrFrmC">
                        <FormGroup>
                          <Label for="">Phone Number</Label>
                          <Input
                            type="text"
                            name=""
                            id=""
                            placeholder=""
                            defaultValue={profileDetails.mobile}
                            onChange={(e) => {
                              setProfileDetails({
                                ...profileDetails,
                                mobile: e.target.value,
                              });
                            }}
                          />
                        </FormGroup>
                      </div>

                      <div className="col-lg-6 hckcrFrmC">
                        <FormGroup>
                          <Label for="">Email</Label>
                          <Input
                            type="email"
                            name=""
                            id=""
                            // placeholder="Jhon@gmail.com"
                            defaultValue={profileDetails.email}
                            onChange={(e) => {
                              setProfileDetails({
                                ...profileDetails,
                                email: e.target.value,
                              });
                            }}
                          />
                        </FormGroup>
                      </div>

                      <div className="col-12 hckcrFrmC">
                        <FormGroup>
                          <Label for="*">
                            Country<span>*</span>
                          </Label>

                          <Input
                            type="country"
                            name=""
                            id=""
                            // placeholder="Jhon@gmail.com"
                            defaultValue={profileDetails.country}
                            onChange={(e) => {
                              setProfileDetails({
                                ...profileDetails,
                                country: e.target.value,
                              });
                            }}
                          />
                          {/* <Input type="select" name="select">
                            <option>10010, Newyork, USA</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                          </Input> */}
                        </FormGroup>
                      </div>

                      <div className="col-12">
                        <div className="prfEdtSbm">
                          <button
                            class="btn btn-17442 btn-bluFl v2"
                            onClick={editProfile}
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Fragment>
  );
};

export default HackathonProfileEdit;
