import { BrowserRouter, Link, Navigate, Route, Routes } from "react-router-dom";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import React, {
  Component,
  Fragment,
  createRef,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import DeveloperProgram from "../DeveloperProgram/DeveloperProgram";
import Footer from "../../common/Footer/Footer";
import Header from "../../common/Header/Header";
import HtmlParser from "react-html-parser";
import NFTDocumentation from "../NFTDocumentation/NFTDocumentation";
import Overview from "../Overview/Overview";
import ReactDOM from "react-dom";
import classnames from "classnames";
import eventicon1 from "../../assets/images/pics/eventicon1.png";
import eventicon2 from "../../assets/images/pics/eventicon2.png";
import eventicon3 from "../../assets/images/pics/sicon1.png";
import eventicon4 from "../../assets/images/pics/sicon2.png";
import eventicon5 from "../../assets/images/pics/sicon3.png";
import eventicon6 from "../../assets/images/pics/eventicon6.png";
import kpeventimg1 from "../../assets/images/pics/kpeventimg1.png";
import kpevnt from "../../assets/images/pics/kpevnt.png";

const Home = (props) => {
  const [activeTab, setActiveTab] = useState("1");
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  // const [isLoading,setisLoading]= useState(true)
  // var isLoading = '';
  // console.log(isLoading, "isLoading");

  let data, data1, data2, data3, data4, data5, data6, data7, data8;
  let datanew;

  data = useSelector((state) => state.user);

  // setTimeout(() => {
  // setisLoading(data.isLoading)

  // }, 1000);

  //   useEffect(() => {}, [data]);

  useEffect(() => {
    if (data?.data?.status == 200) {
      // isLoading = (data.isLoading)
      data = data.data.data;
      data1 = data.filter((elem) => elem.title == "navbar heading - Events");
      data7 = data.filter((elem) => elem.heading == "Event");
      setEvents(data7);

      setLoading(false);
      // console.log(data7[0].category, "data7----");
      data1.reverse();
    }
  }, [data, events]);

  let site_inform = useSelector((state) => state.user);
  if (site_inform.data1.status == true) {
    site_inform = site_inform.data1.data;
  }
  return (
    <Fragment>
      <Header />
      <div class="pkchinsec">
        <section class="kpeventbanner px-3">
          <div class="container">
            <div class="row kpEvRw align-items-center">
              <div>
                <h2>
                  Parkchain Ecosystem,
                  <br />
                  in real life
                </h2>
                {/* <p>Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor<br /> sit amet consectetur.</p> */}
                {/* /<a href="#" class="submitbtn mt-5">Submit Event</a> */}
              </div>
            </div>
          </div>
        </section>

        <div class="container">
          {data.isLoading == true ? (
            <h6 className="newtext">Loading</h6>
          ) :
           data.isLoading == false && loading == false ? (
            <div class="row mt-5">
              {events && events.length > 0 ? (
                events.map((data, index) => (
                  <div class="col-lg-6 col-sm-12 mb-5">
                    <div class="kpblkChain kpeventchain evLsCon">
                      <div className="evLsIg">
                        <img src={data.image} className="img-fluid w-100" />
                      </div>
                      <div class="kpblogcnt kpeventcnt evLsTxCon">
                        <div className="evLsTx">
                          <h6>{data ? data.title : <></>}</h6>
                          <h3>{new Date(data.created).toDateString()}</h3>
                        </div>

                        <div className="evLsLk">
                          <div class="text-center">
                            <a
                              href={`/eventdetail/${data._id}`}
                              class="submitbtn submitbtn1 d-inline-block"
                            >
                              Details
                            </a>
                          </div>

                          <div class="text-center">
                            <ul className="eventiconmenu pl-0">
                              <li>
                                <a
                                  href={
                                    site_inform ? site_inform.discord : <></>
                                  }
                                  target="_blank"
                                >
                                  <img src={eventicon1} alt="" />
                                </a>
                              </li>
                              <li>
                                <a
                                  href={
                                    site_inform ? site_inform.instagram : <></>
                                  }
                                  target="_blank"
                                >
                                  <img src={eventicon2} alt="" />
                                </a>
                              </li>
                              <li>
                                <a
                                  href={
                                    site_inform ? site_inform.twitter : <></>
                                  }
                                  target="_blank"
                                >
                                  <img src={eventicon3} alt="" />
                                </a>
                              </li>
                              <li>
                                <a
                                  href={
                                    site_inform ? site_inform.facebook : <></>
                                  }
                                  target="_blank"
                                >
                                  <img src={eventicon4} alt="" />
                                </a>
                              </li>
                              <li>
                                <a
                                  href={
                                    site_inform ? site_inform.linkedin : <></>
                                  }
                                  target="_blank"
                                >
                                  <img src={eventicon5} alt="" />
                                </a>
                              </li>
                              <li>
                                <a
                                  href={
                                    site_inform ? site_inform.telegram : <></>
                                  }
                                  target="_blank"
                                >
                                  <img src={eventicon6} alt="" />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <h1 className="text-center">No Events Found</h1>
              )}
            </div>
          ) : (
            <></>
          )}

          <div class="horizonline"></div>

          {/* <div class="row my-5"> 
                        <div class="col-lg-4 col-md-6 mb-5">
                            <div>
                                <div class="kpEvBx">
                                    <div class="text-center">
                                    <img src={kpevnt} alt="" class="w-100" />
                                    </div>
                                    <div class="kpblogcnt">
                                    <h3>World Blockchain Forum 
                                        Investments & ICOs</h3>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <h6>Thu, Sep 14, 10:00 AM</h6>
                                        <h6>Bengaluru</h6>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 mb-5">
                            <div>
                                <div class="kpEvBx">
                                    <div class="text-center">
                                    <img src={kpevnt} alt="" class="w-100" />
                                    </div>
                                    <div class="kpblogcnt">
                                    <h3>World Blockchain Forum 
                                        Investments & ICOs</h3>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <h6>Thu, Sep 14, 10:00 AM</h6>
                                        <h6>Bengaluru</h6>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 mb-5">
                            <div>
                                <div class="kpEvBx">
                                    <div class="text-center">
                                    <img src={kpevnt} alt="" class="w-100" />
                                    </div>
                                    <div class="kpblogcnt">
                                    <h3>World Blockchain Forum 
                                        Investments & ICOs</h3>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <h6>Thu, Sep 14, 10:00 AM</h6>
                                        <h6>Bengaluru</h6>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 mb-5">
                            <div>
                                <div class="kpEvBx">
                                    <div class="text-center">
                                    <img src={kpevnt} alt="" class="w-100" />
                                    </div>
                                    <div class="kpblogcnt">
                                    <h3>World Blockchain Forum 
                                        Investments & ICOs</h3>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <h6>Thu, Sep 14, 10:00 AM</h6>
                                        <h6>Bengaluru</h6>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 mb-5">
                            <div>
                                <div class="kpEvBx">
                                    <div class="text-center">
                                    <img src={kpevnt} alt="" class="w-100" />
                                    </div>
                                    <div class="kpblogcnt">
                                    <h3>World Blockchain Forum 
                                        Investments & ICOs</h3>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <h6>Thu, Sep 14, 10:00 AM</h6>
                                        <h6>Bengaluru</h6>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 mb-5">
                            <div>
                                <div class="kpEvBx">
                                    <div class="text-center">
                                    <img src={kpevnt} alt="" class="w-100" />
                                    </div>
                                    <div class="kpblogcnt">
                                    <h3>World Blockchain Forum 
                                        Investments & ICOs</h3>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <h6>Thu, Sep 14, 10:00 AM</h6>
                                        <h6>Bengaluru</h6>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 mb-5">
                            <div>
                                <div class="kpEvBx">
                                    <div class="text-center">
                                    <img src={kpevnt} alt="" class="w-100" />
                                    </div>
                                    <div class="kpblogcnt">
                                    <h3>World Blockchain Forum 
                                        Investments & ICOs</h3>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <h6>Thu, Sep 14, 10:00 AM</h6>
                                        <h6>Bengaluru</h6>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default Home;