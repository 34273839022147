import React, { Component, Fragment, useState, useRef, useEffect, createRef  } from "react";
import ReactDOM from "react-dom";
import { UncontrolledCollapse } from 'reactstrap';
import classnames from 'classnames';
import Overview from '../Overview/Overview';
import NFTDocumentation from '../NFTDocumentation/NFTDocumentation';
import DeveloperProgram from '../DeveloperProgram/DeveloperProgram';
import {
	BrowserRouter, Navigate, Route, Routes, Link
 } from 'react-router-dom';
 import ScrollSpy from "react-ui-scrollspy";
import Header from "../../common/Header/Header";
import Footer from "../../common/Footer/Footer";

import downimg from '../../assets/images/pics/downimg.png';


const Home = (props) => {  
    const [isActive, setActive] = useState("false");
    const ToggleClass = () => {
        setActive(!isActive); 
    };

    const parentScrollContainerRef = useRef<HTMLDivElement | null>(null);
    const onPress = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        const target = window.document.getElementById(
          e.currentTarget.href.split("#")[1]
        );
        if (target) {
          var headerOffset = 97;
          var elementPosition = target.getBoundingClientRect().top;
          var offsetPosition = elementPosition - headerOffset;
    
          window.scrollBy({
            top: offsetPosition,
            behavior: "smooth",
          });
        }
      };

    return (
        <Fragment>
            <Header />
            <div className="pkchinsec">
                <div className="container">
                    <div className="d-flex sidebarCnt">
                        <div className={isActive ? "pksidebr" : "pksidebr SideMenuOpen"}>
                            <div className="sidenav">
                                <ul className="ml-0 pl-0">
                                    <li><a data-to-scrollspy-id="parkchain1" onClick={(e) => onPress(e)} href={"#parkchain1"}>WHAT IS KPK?</a></li>
                                    <li><a data-to-scrollspy-id="parkchain2" onClick={(e) => onPress(e)} href={"#parkchain2"}>Complete ownership in your hands</a></li>
                                    <li><a data-to-scrollspy-id="parkchain3" onClick={(e) => onPress(e)} href={"#parkchain3"}>Uncompromising security through cryptography</a></li>
                                    <li><a data-to-scrollspy-id="parkchain4" onClick={(e) => onPress(e)} href={"#parkchain4"}>Direct peer-to-peer transactions</a></li>
                                    <li><a data-to-scrollspy-id="parkchain5" onClick={(e) => onPress(e)} href={"#parkchain5"}>Decentralization and global accessibility</a></li>
                                    <li><a data-to-scrollspy-id="parkchain6" onClick={(e) => onPress(e)} href={"#parkchain6"}>Inclusive accessibility for all</a></li>
                                    <li><a data-to-scrollspy-id="parkchain7" onClick={(e) => onPress(e)} href={"#parkchain7"}>What sets KPK apart from others?</a></li>
                                    <li><a data-to-scrollspy-id="parkchain8" onClick={(e) => onPress(e)} href={"#parkchain8"}>KPK fuels and secures Parkcoin</a></li>
                                    <li><a data-to-scrollspy-id="parkchain9" onClick={(e) => onPress(e)} href={"#parkchain9"}>KPK underpins the Parkchain financial system</a></li>
                                    <li><a data-to-scrollspy-id="parkchain10" onClick={(e) => onPress(e)} href={"#parkchain10"}>Where to get KPK</a></li>
                                    <li><a data-to-scrollspy-id="parkchain11" onClick={(e) => onPress(e)} href={"#parkchain11"}>What contributes to the value of KPK?</a></li>
                                </ul>
                            </div>                            
                        </div>
                        <svg id="hamburger" viewBox="0 0 100 100" onClick={ToggleClass}>
                            <rect className={isActive ? "hamburger-line hamburger-line-1" : "hamburger-line hamburger-line-1 active"} x="20" y="50" rx="1" width="60" height="2"></rect>
                            <rect className={isActive ? "hamburger-line hamburger-line-2" : "hamburger-line hamburger-line-2 active"} x="20" y="50" rx="1" width="60" height="2"></rect>
                            <rect className={isActive ? "hamburger-line hamburger-line-3" : "hamburger-line hamburger-line-3 active"} x="20" y="50" rx="1" width="60" height="2"></rect>
                        </svg>

                        <div className="pkmainsec" useRef={parentScrollContainerRef}>                        
                            <ScrollSpy scrollThrottle={100} useBoxMethod={true}>
                                <h2 className="kphedone" id="parkchain1">WHAT IS KPK?</h2>
                                <p className="kpparaone">Currency for the digital future</p>                            
                                <p className="kpparaone">KPK stands as the digital, global currency, serving as the designated medium of exchange within Parkchain applications.</p>                            
                                <p className="kpparaone">KPK represents a cryptocurrency, a limited supply of digital currency that holds value for online transactions. For those unfamiliar with cryptocurrencies, it is essential to understand how KPK distinguishes itself from conventional forms of money.</p>                            

                                <h2 className="kphedtwo" id="parkchain2">Complete ownership in your hands</h2>
                                <p className="kpparaone">With KPK, you become the master of your own financial destiny. You have full control over your funds through your wallet, serving as irrefutable evidence of ownership. No intermediaries are required to manage your transactions.</p>

                                <h2 className="kphedtwo" id="parkchain3">Uncompromising security through cryptography</h2>
                                <p className="kpparaone">While internet-based money may be relatively new, it relies on robust cryptographic techniques for foolproof protection. This ensures the safety of your wallet, ETH, and all associated transactions.</p>

                                <h2 className="kphedtwo" id="parkchain4">Direct peer-to-peer transactions</h2>
                                <p className="kpparaone">KPK enables seamless transactions without the need for intermediaries like banks. Similar to exchanging physical cash in person, you can securely send and receive KPK with anyone, anytime, and anywhere.</p>

                                <h4 className="kphedtwo mb-1" id="parkchain5">Decentralization and global accessibility</h4>
                                <p className="kpparaone">KPK operates in a decentralized manner, free from control by any single company or financial institution. No entity possesses the power to create more KPK or modify its terms of use.</p>

                                <h4 className="kphedtwo mb-1" id="parkchain6">Inclusive accessibility for all</h4>
                                <p className="kpparaone">Accepting KPK requires nothing more than an internet connection and a wallet. You don't need a bank account to receive payments, making it open to everyone.</p>

                                <h4 className="kphedtwo mb-1" id="parkchain7">What sets KPK apart from others?</h4>
                                <p className="kpparaone">While Parkchain hosts various cryptocurrencies and tokens, KPK possesses distinct capabilities.</p>                      

                                <h2 className="kphedtwo" id="parkchain8">KPK fuels and secures Parkcoin</h2>
                                <p className="kpparaone">KPK drives the functionality and security of Parkchain.
                                KPK serves as the lifeblood of Parkchain. When utilizing Parkchain applications or sending KPK, a fee in KPK is required to access the network. This fee incentivizes block producers to validate and process your transactions.</p>
                                <p className="kpparaone">Validators play a crucial role as the guardians of Parkchain, ensuring the integrity of the network and preventing any fraudulent activities. Through a random selection process, validators propose blocks of transactions and receive small amounts of newly-issued KPK as rewards.</p>
                                <p className="kpparaone">The dedication of validators and the capital they invest contribute to the security and decentralized nature of Parkchain. KPK serves as the driving force behind Parkchain's operations.</p>
                                <p className="kpparaone">By staking your KPK, you actively contribute to the security of Parkchain while earning rewards. This system establishes a deterrent against potential attackers, as the risk of losing KPK acts as a strong disincentive.</p>

                                <h2 className="kphedtwo" id="parkchain9">KPK underpins the Parkchain financial system</h2>
                                <p className="kpparaone">KPK forms the foundation of the robust financial ecosystem within Parkchain. Going beyond simple payment solutions, the Parkchain community is actively constructing a decentralized peer-to-peer financial system accessible to all.</p>
                                <p className="kpparaone">Through KPK, users have the ability to leverage their holdings as collateral, creating diverse cryptocurrency tokens unique to Parkchain. Additionally, individuals can participate in borrowing, lending, and earning interest on both KPK and other tokens backed by KPK. This multifaceted approach empowers users to engage in a wide range of financial activities within the Parkchain network.</p>
                                
                                <h2 className="kphedtwo" id="parkchain10">Where to get KPK</h2>
                                <p className="kpparaone">To get KPK, individuals have options such as exchanges and wallets. However, it is crucial to note that different countries have varying policies regarding KPK acquisition. It is advisable to review and understand the available services that facilitate the purchase of KPK based on your specific country's regulations.</p>
                                
                
                                <h2 className="kphedtwo" id="parkchain11">What contributes to the value of KPK?</h2>
                                <p className="kpparaone">KPK holds value in various ways, depending on the perspective of different individuals. For Parkchain users, KPK is valuable as it enables them to pay transaction fees within the network. Additionally, some perceive KPK as a digital store of value due to its decreasing rate of issuance over time. Furthermore, KPK has gained value among users of financial applications on Parkchain. It can be utilized as collateral for crypto loans or as a payment method. Naturally, many also view KPK as an investment opportunity, similar to Bitcoin and other cryptocurrencies.</p>
                                     
                            </ScrollSpy>   
                                      
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
   
}

export default Home;