import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import {
  Link,
  Route,
  BrowserRouter as Router,
  Switch,
  useNavigate,
} from "react-router-dom";
import React, {
  Component,
  Fragment,
  createRef,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import ReactDOM from "react-dom";
import { getUserProfile } from "../../redux/action";
import logoImg from "../../assets/images/logo.svg";
import logoltrImg from "../../assets/images/logo-ltr.svg";
import profileImg from "../../assets/images/user-sm.png";
import { ToastContainer, toast } from 'react-toastify';
import { services } from '../connectwallet/connectwallet';
import { useAccount, useConnect, useDisconnect } from 'wagmi'

import { CONST } from "../../_config";
import Web3 from 'web3';
import axios from "axios";

const Header = (props) => {

  let web3;
  let providerType = '';

  // const [scroll, setScroll] = useState(false);
  // useEffect(() => {
  // window.addEventListener("scroll", () => {
  // setScroll(window.scrollY > 50);
  // });
  // }, []);
  var navigate = useNavigate();
  const [classIsActive, setClassIsActive] = useState(true);
  const smClose = () => {
    setClassIsActive(!classIsActive);
    classIsActive
      ? document.body.classList.add("sbMenu")
      : document.body.classList.remove("sbMenu");
  };

  const dispatch = useDispatch();

  var reduxRes = useSelector((state) => state);

  reduxRes = reduxRes?.user;
  console.log("reduxRes1233", reduxRes?.userProfile?.data?.user_type);

  const LogOut = () => {
    localStorage.removeItem("user_id");
    navigate("/hackathon-login");
  };

  useEffect(() => {
    if (reduxRes?.userId) {
      dispatch(getUserProfile({ userId: reduxRes?.userId }));
    }
  }, [reduxRes?.userId]);

  const metamaskDisconnect = async () => {
    sessionStorage.clear();
    setTimeout(() => {
      toast.success('Wallet Disconnected')
      // window.location.reload();
      window.location.href = '/'
    }, 2000);
  }



  const metamaskconnect = async function () {
    if (!window.ethereum) {
      toast.error('Please Install Metamask');
    }
    else if (window.ethereum.providers) {
      if (window.ethereum.providers.length) {
        let Metamask = window.ethereum.providers.find(p => p.isMetaMask === true);
        console.log(Metamask)
        providerType = Metamask;
        ConnectWallet(providerType)
      }
      else {
        toast.error('Please Install Metamask')
      }
    } else {

      providerType = window.ethereum;
      console.log(window.ethereum)
      ConnectWallet(providerType)
    }

  }

  const ConnectWallet = async (wallet) => {
    console.log(wallet, "wallet");

    try {
      // await wallet.enable();
      if (typeof window !== undefined) {
        web3 = new Web3(window.ethereum);
        if (typeof window.web3 !== undefined) {
          await wallet.enable();

          try {
            // await wallet.enable();

            await services.networkChange(wallet)
            const accounts = await web3.eth.getAccounts();
            const chainId = await web3.eth.getChainId();
            // sessionStorage.setItem("parkAccount", accounts[0]);
            const balance = await web3.eth.getBalance(accounts[0]);
            let address = accounts[0]
            // balanceWallet = balance / Math.pow(10, 18);
            console.log(chainId)

            let first = accounts[0].substring(0, 3);
            let last = accounts[0].substring(38, 42);
            let shortAccount = `${first}...${last}`;
            if (chainId == CONST.chainId) {
              sessionStorage.setItem('parkAccount', (accounts[0]).toLowerCase())
              let data = await axios.post(CONST.BACKEND_URL + 'users/editProfile', { userAddress: (accounts[0]).toLowerCase(), userId: reduxRes?.userId, })
              console.log((accounts[0]), data, "(accounts[0])");
              toast.success('Wallet Connected');
              window.location.href = '/'
            }
            else {
              toast.error('Kindly Connect KPK Network Testnet')
            }
          }

          catch (error) {
            console.log(error)
            // toast.error('Please Install Metamask')

          }

        }
      }
    }
    catch (err) {

      if (window.ethereum == undefined) {
        // setModal(false)
        toast.error('Please Install Metamask');
      }
      else {
        console.log(err)
        // setModal(false)
        // toast.error('Please Install Metamask')Edit Profile

      }

    }




    return (
      <>
        <div><ToastContainer /></div>
      </>
    )
  }

  return (
    <Fragment>
      <header class="indHd">
        <div class="indHdTp">
          <div class="container">
            <nav class="navbar navbar-expand-lg px-0">
              <a class="navbar-brand" href="/">
                <img src={logoImg} alt="logo" class="hdrLogo" />
                <img src={logoltrImg} alt="logo" class="hdrLogo-ltr" />
              </a>
              <div class="hdNavMenu">
              {reduxRes?.userId ? (
                <div class="collapse_rn navbar-collapse Newtab" id="indHdClp">
                  <ul class="navbar-nav mobileNav">
                    <li class="nav-item dropdown">
                      <UncontrolledDropdown>
                        <DropdownToggle>Build & Deploy</DropdownToggle>
                        <DropdownMenu>
                          <Link to="/parkdocumentation">
                            <DropdownItem>Park Documentation</DropdownItem>
                          </Link>
                          <Link to="/nftdocumentations">
                            <DropdownItem>NFT Documentation</DropdownItem>
                          </Link>
                          {/* <a href='https://tutorial.parkchain.org/nft/' target="_blank"><DropdownItem>NFT Documentation</DropdownItem></a> */}
                          <a href="parkscancore">
                            <DropdownItem>GitHub</DropdownItem>
                          </a>
                          <Link to="/developerprogram">
                            <DropdownItem>Token Standards</DropdownItem>
                          </Link>
                          {/* <Link to='/daos'><DropdownItem>Developer Tools</DropdownItem></Link> */}
                          <Link to="/hackathonlist">
                            <DropdownItem>Hackathon</DropdownItem>
                          </Link>
                          {reduxRes?.userProfile?.data?.user_type === "Developer" ? (<Link to="/hackathon-form3">
                            <DropdownItem>Create Hackathon</DropdownItem>
                          </Link>)
                            :
                            <></>
                          }
                          {/* <Link to="/leaderboard">
                            <DropdownItem>LeaderBoard</DropdownItem>
                          </Link> */}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li>
                    <li className="nav-item dropdown">
                      <UncontrolledDropdown>
                        <DropdownToggle>Learn</DropdownToggle>
                        <DropdownMenu className="lgMenu">
                          {/* <li className="subMenuHeading">Start Here</li> */}
                          <Link to="/learnhub">
                            {" "}
                            <DropdownItem tag="a">Learn Hub</DropdownItem>
                          </Link>
                          <Link to="/guidehub">
                            {" "}
                            <DropdownItem tag="a">Guides Hub</DropdownItem>
                          </Link>
                          {/* <li className="subMenuHeading">Parkchain Basics</li> */}
                          <Link to="/kpparkchain">
                            <DropdownItem tag="a">
                              What is Parkchain?
                            </DropdownItem>
                          </Link>
                          <Link to="/kpkpk">
                            <DropdownItem tag="a">what is KPK?</DropdownItem>
                          </Link>
                          <Link to="/smartcontract">
                            <DropdownItem tag="a">Smart Contracts</DropdownItem>
                          </Link>
                          <Link to="/webthree">
                            {" "}
                            <DropdownItem tag="a">What is Web3?</DropdownItem>
                          </Link>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li>

                    <li class="nav-item">
                      {/* <a class="nav-link" href="#secAbt">Ecosystem</a> */}
                      <UncontrolledDropdown>
                        <DropdownToggle>Ecosystem</DropdownToggle>
                        <DropdownMenu>
                          <Link to="/parkscancore">
                            <DropdownItem>Chain</DropdownItem>
                          </Link>
                          <Link to="/koinpark_exchange">
                            <DropdownItem>Exchange</DropdownItem>
                          </Link>
                          <Link to="/exploreguide">
                            <DropdownItem>Explorer</DropdownItem>
                          </Link>
                          <Link to="/staking">
                            <DropdownItem>Staking</DropdownItem>
                          </Link>
                          <Link to="/parkwallet">
                            <DropdownItem>Wallet</DropdownItem>
                          </Link>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li>
                    <li class="nav-item">
                      {/* <a class="nav-link" href="#secFtrs">Use Cases</a> */}
                      <UncontrolledDropdown>
                        <DropdownToggle>Use Cases</DropdownToggle>
                        <DropdownMenu>
                          <Link to="/kpgaming">
                            <DropdownItem>Gaming</DropdownItem>
                          </Link>
                          <Link to="/kpgamingone">
                            <DropdownItem>NFT</DropdownItem>
                          </Link>
                          {/* <Link to='/daos'><DropdownItem>Metaverse</DropdownItem></Link> */}
                          <Link to="/kpdefi">
                            <DropdownItem>DeFi</DropdownItem>
                          </Link>
                          <Link to="/daos">
                            <DropdownItem>DAOs</DropdownItem>
                          </Link>
                          <Link to="/kppayment">
                            <DropdownItem>Payments</DropdownItem>
                          </Link>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li>
                    <li class="nav-item">
                      {/* <a class="nav-link" href="#secPgs">Community</a> */}
                      <UncontrolledDropdown>
                        <DropdownToggle>Community</DropdownToggle>
                        <DropdownMenu>
                          <Link to="/news">
                            <DropdownItem>News</DropdownItem>
                          </Link>
                          <Link to="/event">
                            <DropdownItem>Events</DropdownItem>
                          </Link>
                          <Link to="/community">
                            <DropdownItem>Community Hub</DropdownItem>
                          </Link>
                          <Link to="/kpblog">
                            <DropdownItem>Blog</DropdownItem>
                          </Link>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li>
                  </ul>
                </div>
              ):
              <div class="collapse_rn navbar-collapse" id="indHdClp">
              <ul class="navbar-nav">
                <li class="nav-item dropdown">
                  <UncontrolledDropdown>
                    <DropdownToggle>Build & Deploy</DropdownToggle>
                    <DropdownMenu>
                      <Link to="/parkdocumentation">
                        <DropdownItem>Park Documentation</DropdownItem>
                      </Link>
                      <Link to="/nftdocumentations">
                        <DropdownItem>NFT Documentation</DropdownItem>
                      </Link>
                      {/* <a href='https://tutorial.parkchain.org/nft/' target="_blank"><DropdownItem>NFT Documentation</DropdownItem></a> */}
                      <a href="parkscancore">
                        <DropdownItem>GitHub</DropdownItem>
                      </a>
                      <Link to="/developerprogram">
                        <DropdownItem>Token Standards</DropdownItem>
                      </Link>
                      {/* <Link to='/daos'><DropdownItem>Developer Tools</DropdownItem></Link> */}
                      <Link to="/hackathonlist">
                        <DropdownItem>Hackathon</DropdownItem>
                      </Link>
                      {reduxRes?.userProfile?.data?.user_type === "Developer" ? (<Link to="/hackathon-form3">
                        <DropdownItem>Create Hackathon</DropdownItem>
                      </Link>)
                        :
                        <></>
                      }
                      {/* <Link to="/leaderboard">
                        <DropdownItem>LeaderBoard</DropdownItem>
                      </Link> */}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </li>
                <li className="nav-item dropdown">
                  <UncontrolledDropdown>
                    <DropdownToggle>Learn</DropdownToggle>
                    <DropdownMenu className="lgMenu">
                      {/* <li className="subMenuHeading">Start Here</li> */}
                      <Link to="/learnhub">
                        {" "}
                        <DropdownItem tag="a">Learn Hub</DropdownItem>
                      </Link>
                      <Link to="/guidehub">
                        {" "}
                        <DropdownItem tag="a">Guides Hub</DropdownItem>
                      </Link>
                      {/* <li className="subMenuHeading">Parkchain Basics</li> */}
                      <Link to="/kpparkchain">
                        <DropdownItem tag="a">
                          What is Parkchain?
                        </DropdownItem>
                      </Link>
                      <Link to="/kpkpk">
                        <DropdownItem tag="a">what is KPK?</DropdownItem>
                      </Link>
                      <Link to="/smartcontract">
                        <DropdownItem tag="a">Smart Contracts</DropdownItem>
                      </Link>
                      <Link to="/webthree">
                        {" "}
                        <DropdownItem tag="a">What is Web3?</DropdownItem>
                      </Link>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </li>

                <li class="nav-item">
                  {/* <a class="nav-link" href="#secAbt">Ecosystem</a> */}
                  <UncontrolledDropdown>
                    <DropdownToggle>Ecosystem</DropdownToggle>
                    <DropdownMenu>
                      <Link to="/parkscancore">
                        <DropdownItem>Chain</DropdownItem>
                      </Link>
                      <Link to="/koinpark_exchange">
                        <DropdownItem>Exchange</DropdownItem>
                      </Link>
                      <Link to="/exploreguide">
                        <DropdownItem>Explorer</DropdownItem>
                      </Link>
                      <Link to="/staking">
                        <DropdownItem>Staking</DropdownItem>
                      </Link>
                      <Link to="/parkwallet">
                        <DropdownItem>Wallet</DropdownItem>
                      </Link>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </li>
                <li class="nav-item">
                  {/* <a class="nav-link" href="#secFtrs">Use Cases</a> */}
                  <UncontrolledDropdown>
                    <DropdownToggle>Use Cases</DropdownToggle>
                    <DropdownMenu>
                      <Link to="/kpgaming">
                        <DropdownItem>Gaming</DropdownItem>
                      </Link>
                      <Link to="/kpgamingone">
                        <DropdownItem>NFT</DropdownItem>
                      </Link>
                      {/* <Link to='/daos'><DropdownItem>Metaverse</DropdownItem></Link> */}
                      <Link to="/kpdefi">
                        <DropdownItem>DeFi</DropdownItem>
                      </Link>
                      <Link to="/daos">
                        <DropdownItem>DAOs</DropdownItem>
                      </Link>
                      <Link to="/kppayment">
                        <DropdownItem>Payments</DropdownItem>
                      </Link>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </li>
                <li class="nav-item">
                  {/* <a class="nav-link" href="#secPgs">Community</a> */}
                  <UncontrolledDropdown>
                    <DropdownToggle>Community</DropdownToggle>
                    <DropdownMenu>
                      <Link to="/news">
                        <DropdownItem>News</DropdownItem>
                      </Link>
                      <Link to="/event">
                        <DropdownItem>Events</DropdownItem>
                      </Link>
                      <Link to="/community">
                        <DropdownItem>Community Hub</DropdownItem>
                      </Link>
                      <Link to="/kpblog">
                        <DropdownItem>Blog</DropdownItem>
                      </Link>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </li>
              </ul>
            </div>}
              </div>

              <div class="hdRt">
                {/* <Link to="/developerprogram" class="btn btn-12040 btn-bluFl">
                  Start Building
                </Link> */}

                {reduxRes?.userId ? (
                  <div class="hdNavMenu">
                    <div class="collapse_rn navbar-collapse" id="indHdClp">
                      <ul class="navbar-nav">
                        <li class="nav-item dropdown">
                          <UncontrolledDropdown>
                            <DropdownToggle>
                              {reduxRes?.userProfile?.data?.profile_img ? (
                                <img class="userimg mr-2"
                                  src={reduxRes?.userProfile?.data?.profile_img}
                                ></img>
                              ) : (
                                <img src={profileImg}></img>
                              )}
                            </DropdownToggle>
                            <DropdownMenu>
                              <Link to="/hackathon-profile">
                                <DropdownItem>Edit Profile</DropdownItem>
                              </Link>

                              <DropdownItem onClick={LogOut}>
                                {" "}
                                Logout
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </li>
                      </ul>
                    </div>
                  </div>
                ) : (
                  <div>
                    {/* <button
                      className="btn btn-primary login-menu"
                      onClick={() => {
                        navigate("/hackathon-register");
                      }}
                    >
                      {" "}
                      Sign up{" "}
                    </button> */}

                    <button
                      className="btn btn-primary reg-menu min-100"
                      onClick={() => {
                        navigate("/hackathon-login");
                      }}
                    >
                      {" "}
                      Sign In{" "}
                    </button>
                  </div>
                )}
                {sessionStorage.getItem('parkAccount') == null || !sessionStorage.getItem('parkAccount') || sessionStorage.getItem('parkAccount') == undefined || sessionStorage.getItem('parkAccount') == 'undefined' || sessionStorage.getItem('parkAccount') == '' ?
                  <Link onClick={metamaskconnect} class="btn btn-12040 min-100 btn-bluFl">Connect</Link> :
                  <button onClick={metamaskDisconnect} class="btn btn-12040 min-100 btn-bluFl"><span></span>{sessionStorage.getItem('parkAccount').slice(0, 4) + '...' + sessionStorage.getItem('parkAccount').slice(36, 42)}</button>

                }

                <div class="mobMenu d-lg-none">
                  <div class="smClose" onClick={smClose}>
                    <svg id="closeicon" viewBox="0 0 800 600">
                      <path
                        d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200"
                        id="top"
                      ></path>
                      <path d="M300,320 L460,320" id="middle"></path>
                      <path
                        d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190"
                        id="bottom"
                        transform="translate(480, 320) scale(1, -1) translate(-480, -318) "
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
    </Fragment>
  );
};

export default Header;
