import React, { Component, Fragment, useState, useRef, useEffect, createRef } from "react";
import ReactDOM from "react-dom";
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import Overview from '../Overview/Overview';
import NFTDocumentation from '../NFTDocumentation/NFTDocumentation';
import DeveloperProgram from '../DeveloperProgram/DeveloperProgram';
import {
    BrowserRouter, Navigate, Route, Routes, Link
} from 'react-router-dom';

import Header from "../../common/Header/Header";
import Footer from "../../common/Footer/Footer";

import kpParkChainImg from '../../assets/images/kpParkChainImg.png';
import parkChainSummaryImg from '../../assets/images/parkChainSummaryImg.png';
import whatiscrypto from '../../assets/images/whatiscrypto.png';
import bankingIcon from '../../assets/images/bankingIcon.svg';
import privateInternet from '../../assets/images/privateInternet.svg';
import ptpNetwork from '../../assets/images/ptpNetwork.svg';
import censorIcon from '../../assets/images/censorIcon.svg';
import medalIcon from '../../assets/images/medalIcon.svg';
import composableIcon from '../../assets/images/composableIcon.svg';
import ethereumImg from '../../assets/images/ethereumImg.png';
import parkChainUse from '../../assets/images/parkChainUse.png';
import pkEnergyConsumption from '../../assets/images/pkEnergyConsumption.png';
import kpkpurImg from '../../assets/images/kpkpurImg.png';
import kpkorImg from '../../assets/images/kpkorImg.png';
import kpkbluImg from '../../assets/images/kpkbluImg.png';
import kpkgrnImg from '../../assets/images/kpkgrnImg.png';

import kpbank from '../../assets/images/kpparkchain/banking.png';
import netImage from '../../assets/images/kpparkchain/private-net.png'
import peerimg from '../../assets/images/kpparkchain/peertopeer.png'
import cardImg from '../../assets/images/kpparkchain/resistant.png'
import CommerImg from '../../assets/images/kpparkchain/commerce.png'
import productImg from '../../assets/images/kpparkchain/com-products.png'

import { useSelector, useDispatch } from 'react-redux';
import HtmlParser from "react-html-parser";



const Home = (props) => {
    const [activeTab, setActiveTab] = useState('1');
    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    let data1, data2, data3, data4, data5, data6, data7, data8, data9, data10, data11, data12, data13, data14, data15, data16, data17,
        data18, data19, data20, data21, data22, data23, data24, data25, data26, data27, data28, data29;

    let data = useSelector(state => (state.user));
    console.log(data, "kpkpkdata");
    if (data.data.status == 200) {
        data = data.data.data;

        data1 = data.find(elem => elem.title == 'Introducing Parkchain:');
        data2 = data.find(elem => elem.title == 'header content2');
        data3 = data.find(elem => elem.title == 'header summary');
        data4 = data.find(elem => elem.title == 'Summary');
        data5 = data.find(elem => elem.title == 'Crypto summary1');
        data6 = data.find(elem => elem.title == 'Crypto summary2');
        data7 = data.find(elem => elem.title == 'But what exactly is a middleman?');
        data8 = data.find(elem => elem.title == 'Crypto summary4');
        data9 = data.find(elem => elem.title == 'ParkChain Overview');
        data10 = data.find(elem => elem.title == 'What is Bridging?');
        data11 = data.find(elem => elem.title == 'innerheader1');
        data12 = data.find(elem => elem.title == 'innerheader2');
        data13 = data.find(elem => elem.title == 'innerheader3');
        data14 = data.find(elem => elem.title == 'Banking for Everyone:');
        data15 = data.find(elem => elem.title == 'A More Private Internet:');
        data16 = data.find(elem => elem.title == 'A Peer-to-Peer Network:');
        data17 = data.find(elem => elem.title == 'Censorship-Resistant:');
        data18 = data.find(elem => elem.title == 'Commerce Guarantees:');
        data19 = data.find(elem => elem.title == 'Composable Products:');
        data20 = data.find(elem => elem.title == 'inner content');
        data21 = data.find(elem => elem.title == 'Why would I use Parkchain?');
        data22 = data.find(elem => elem.title == 'Why would I use Parkchain? 2');
        data23 = data.find(elem => elem.title == 'Why would I use Parkchain? 3');
        data24 = data.find(elem => elem.title == "Meet KPK, Parkchain's cryptocurrency:");
        data25 = data.find(elem => elem.title == "What can I do with KPK coins?");
        data26 = data.find(elem => elem.title == "Who runs Parkchain?");
        data27 = data.find(elem => elem.title == "Who runs Parkchain?1");
        data28 = data.find(elem => elem.title == "What are smart contracts?");
        data29 = data.find(elem => elem.title == "Parkchain's energy consumption");

    }
    { console.log(data22, data23, "data3") }


    return (
        <Fragment>
            <Header />
            <div className="pkchinsec">
                <section className="kpparkchain kpparkchain-new">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <h3 className="kpgameheadLg text-center text-lg-left">{data1 ? HtmlParser(data1.title) : <></>}</h3>
                                {/* <h2 className="kpgamehead text-center text-lg-left">{data2 ? HtmlParser(data2.content_description) : <></>}</h2> */}
                                <h3 className="kpGameSubHead text-center text-lg-left">Building the Pillars of Our Digital Future</h3>
                                {/* <h3 className="kpGameSubHead text-center text-lg-left">{data2 ? HtmlParser(data2.content_description) : <></>}</h3> */}
                                {/* <p className="kpgamepara text-center text-lg-left">Discover a comprehensive beginner's guide to understanding PARKCHAIN, its advantages, and its widespread adoption by millions of individuals worldwide. Explore how PARKCHAIN operates, the benefits it offers, and its diverse applications in various industries.</p> */}
                                <p className="kpgamepara text-center text-lg-left text-white">{data3 ? HtmlParser((data3.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                            </div>
                            <div className="col-lg-6">
                                <div className="text-center"><img src={data3 ? HtmlParser(data3.image) : <></>} alt="" className="img-fluid" /></div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="container">
                    <div className="row my-5 align-items-center justify-content-center">
                        <div className="col-lg-10">
                            <div className="shadowCnt kpmintbx">
                                <div className="row">
                                    <div className="col-lg-9">
                                        <h4>{data4 ? HtmlParser(data4.title) : <></>}</h4>
                                        <p>{data4 ? HtmlParser(data4.content_description) : <></>}</p>
                                        {/* <p>Feeling perplexed? Don't worry; we'll break it down for you in a systematic manner.</p> */}
                                    </div>
                                    <div className="col-lg-3 d-flex justify-content-center align-items-center">
                                        <img src={data4 ? HtmlParser(data4.image) : <></>} className="img-fluid" />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="row pdbtm">
                        <div className="col-lg-7">
                            <div>
                                <h5 className="xxframe">What is a </h5>
                                <h3 className="ignHead mb-3">Parkchain Protocol?</h3>
                                <p className="ignPara">{data5 ? HtmlParser(data5.content_description) : <></>}</p>
                                <p className="ignPara">{data6 ? HtmlParser(data6.content_description) : <></>}</p>
                                {/* <p className="ignPara my-3">{data7 ? HtmlParser(data7.title) : <></>}</p> */}
                                <p className="ignPara">{data7 ? HtmlParser(data7.content_description) : <></>}</p>
                                <p className="ignPara">{data8 ? HtmlParser(data8.content_description) : <></>}</p>
                            </div>
                        </div>
                        <div className="col-lg-5 d-flex justify-content-center align-items-center">
                            <img src={data8 ? HtmlParser(data8.image) : <></>} className="img-fluid" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 kpkTabCnt mb-5">
                            <div className="shadowCnt">
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '1' })}
                                            onClick={() => { toggle('1'); }}
                                        >
                                            01
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '2' })}
                                            onClick={() => { toggle('2'); }}
                                        >
                                            02
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId="1">
                                        <h3 className="heading">{data9 ? HtmlParser(data9.title) : <></>}</h3>
                                        <p>{data9 ? HtmlParser(data9.content_description) : <></>}</p>
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <h3 className="heading">{data10 ? HtmlParser(data10.title) : <></>}</h3>
                                        <p>{data10 ? HtmlParser(data10.content_description) : <></>}</p>
                                    </TabPane>
                                </TabContent>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="whyeth mt-2">
                    <div className="container">
                        <h2 className="kpbuldtxt text-center mb-2">{data11 ? HtmlParser((data11.content_description).replace(/(<([^>]+)>)/gi, "" )): <></>}</h2>
                        <h4 className="text-center">{data12 ? HtmlParser(data12.content_description) : <></>}</h4>
                        <div className="row justify-content-center">
                            <div className="col-lg-6">
                                <p className="ignPara text-center mt-2">{data13 ? HtmlParser(data13.content_description) : <></>}</p>
                            </div>
                        </div>
                        <div className="wtParkchainCnt">
                            <div className="row align-items-center justify-content-center">
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-lg-6 col-xl-4 mb-5">
                                            <div className="rpwitheth rrski_left">

                                                <h4 className="text-left">{data14 ? HtmlParser(data14.title) : <></>}</h4>
                                                <p className="text-left">{data14 ? HtmlParser(data14.content_description) : <></>}</p>
                                                <div className="text-center"><img src={kpbank} alt="" className="img-fluid" /></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-4 mb-5">
                                            <div className="rpwitheth rrski_left">
                                                {/* <h4 className="text-left">A More Private Internet:</h4>
                                                <p className="text-left">In an era of increasing surveillance, Parkchain leverages Ethereum to create an economy based on value rather than invasive monitoring. When utilizing Ethereum apps, users are not required to disclose all their personal details. This commitment to privacy fosters a more secure and confidential online environment.</p> */}
                                                <h4 className="text-left">{data15 ? HtmlParser(data15.title) : <></>}</h4>
                                                <p className="text-left">{data15 ? HtmlParser(data15.content_description) : <></>}</p>
                                                <div className="text-left mb-2"><img src={netImage} alt="" className="img-fluid" /></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-4 mb-5">
                                            <div className="rpwitheth rrski_left">
                                                <h4 className="text-left">{data16 ? HtmlParser(data16.title) : <></>}</h4>
                                                <p className="text-left">{data16 ? HtmlParser(data16.content_description) : <></>}</p>
                                                <div className="text-left mb-2"><img src={peerimg} alt="" className="img-fluid" /></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-4 mb-5">
                                            <div className="rpwitheth rrski_left">
                                                <h4 className="text-left">{data17 ? HtmlParser(data17.title) : <></>}</h4>
                                                <p className="text-left">{data17 ? HtmlParser(data17.content_description) : <></>}</p>
                                                <div className="text-left mb-2"><img src={cardImg} alt="" className="img-fluid" /></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-4 mb-5">
                                            <div className="rpwitheth rrski_left" >
                                                <h4 className="text-left">{data18 ? HtmlParser(data18.title) : <></>}</h4>
                                                <p className="text-left">{data18 ? HtmlParser(data18.content_description) : <></>}</p>
                                                <div className="text-left mb-2"><img src={CommerImg} alt="" className="img-fluid" /></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-4 mb-5">
                                            <div className="rpwitheth rrski_left">
                                                <h4 className="text-left">{data19 ? HtmlParser(data19.title) : <></>}</h4>
                                                <p className="text-left">{data19 ? HtmlParser(data19.content_description) : <></>}</p>
                                                <div className="text-left mb-2"><img src={productImg} alt="" className="img-fluid" /></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <p className="ignPara text-center mt-2">{data20 ? HtmlParser(data20.content_description) : <></>}</p>
                            </div>
                        </div>
                        <section className="kpkEthSec">
                            <div className="row justify-content-center">
                                <div className="col-lg-11">
                                    <div className="ethgamekit">
                                        <div className="ethgamecnt communityTxtCnt">
                                            <div className="row">
                                                <div className="col-lg-8 col-xl-8 text-center text-lg-left">
                                                    <h3 className="mb-5">Park Coin in numbers</h3>
                                                    <div className="row">
                                                        <div className="col-md-6 col-xl-4 text-center mb-3">
                                                            <h5>4k+</h5>
                                                            <p>Projects built on Park Coin</p>
                                                        </div>
                                                        <div className="col-md-6 col-xl-4 text-center mb-3">
                                                            <h5>96M+</h5>
                                                            <p>Accounts (wallets) with an Park Coin balance</p>
                                                        </div>
                                                        <div className="col-md-6 col-xl-4 text-center mb-3">
                                                            <h5>53.3M+</h5>
                                                            <p>Smart contracts on Park Coin</p>
                                                        </div>
                                                        <div className="col-md-6 col-xl-4 text-center mb-3">
                                                            <h5>$410B</h5>
                                                            <p>Value secured on Park Coin</p>
                                                        </div>
                                                        <div className="col-md-6 col-xl-4 text-center mb-3">
                                                            <h5>$3.5B</h5>
                                                            <p>Creator earnings on Park Coin in 2021</p>
                                                        </div>
                                                        <div className="col-md-6 col-xl-4 text-center mb-3">
                                                            <h5>1.092M</h5>
                                                            <p>Number of transactions today</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 d-flex justify-content-center align-items-center">
                                                    <img src={kpParkChainImg} className="img-fluid" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="row pdbtm">
                            <div className="col-lg-12">
                                <h3 className="ignHead mb-3">{data21 ? HtmlParser(data21.title) : <></>}</h3>
                            </div>
                            <div className="col-lg-6">
                                <div>
                                    <p className="ignPara">{data21 ? HtmlParser(data21.content_description) : <></>}</p>

                                    <p className="ignPara">{data22 ? HtmlParser(data22.content_description) : <></>}</p>

                                    <p className="ignPara">{data23 ? HtmlParser(data23.content_description) : <></>}</p>

                                </div>
                            </div>
                            <div className="col-lg-6 d-flex justify-content-center align-items-center">
                                <img src={data23 ? HtmlParser(data23.image) : <></>} className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="kpkpurSec">
                    <div className="container">
                        <div className="w-100 kpkpurCnt">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="kpkTextCnt">
                                        <h3 className="heading">{data24 ? HtmlParser(data24.title) : <></>}</h3>
                                        <p className="mb-0">{data24 ? HtmlParser(data24.content_description) : <></>}</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 d-flex justify-content-center align-items-center">
                                    <img src={data24 ? HtmlParser(data24.image) : <></>} className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="kpkorSec">
                    <div className="container">
                        <div className="w-100 kpkorCnt">
                            <div className="row">
                                <div className="col-lg-4 d-flex justify-content-center align-items-center">
                                    <div className="p-3">
                                        <img src={data25 ? HtmlParser(data25.image) : <></>} className="img-fluid" />
                                    </div>
                                </div>
                                <div className="col-lg-8 d-flex align-items-center">
                                    <div className="kpkTextCnt">
                                        <h3 className="heading">{data25 ? HtmlParser(data25.title) : <></>}</h3>
                                        <p className="mb-0">{data25 ? HtmlParser(data25.content_description) : <></>}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="kpkbluSec">
                    <div className="container">
                        <div className="w-100 kpkbluCnt">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="kpkTextCnt">
                                        <h3 className="heading">{data26 ? HtmlParser(data26.title) : <></>}</h3>
                                        <p>{data26 ? HtmlParser(data26.content_description) : <></>}</p>
                                        <p className="mb-0">{data27 ? HtmlParser(data27.content_description) : <></>}</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 d-flex justify-content-center align-items-center">
                                    <div className="p-3">
                                        <img src={data27 ? HtmlParser(data27.image) : <></>} className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="kpkgrnSec pb-5">
                    <div className="container">
                        <div className="w-100 kpkgrnCnt">
                            <div className="row">
                                <div className="col-lg-4 d-flex justify-content-center align-items-end">
                                    <div className="px-3">
                                        <img src={data28 ? HtmlParser(data28.image) : <></>} className="img-fluid" />
                                    </div>
                                </div>
                                <div className="col-lg-8 ">
                                    <div className="kpkTextCnt">
                                        <h3 className="heading">{data28 ? HtmlParser(data28.title) : <></>}</h3>
                                        <p className="mb-0">{data28 ? HtmlParser(data28.content_description) : <></>}</p>

                                        

                                    </div>
                                    

                                </div>
                                
                            </div>

                        </div>
                    </div>
                </section>
                <section className="pt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7">
                                <div>
                                    <h3 className="ignHead mb-3">{data29 ? HtmlParser(data29.title) : <></>}</h3>
                                    <p className="ignPara">{data29 ? HtmlParser(data29.content_description) : <></>}</p>
                                </div>
                            </div>
                            <div className="col-lg-5 d-flex justify-content-center align-items-center">
                                <img src={data29 ? HtmlParser(data29.image) : <></>} className="img-fluid ecs-img" />
                            </div>
                        </div>
                    </div>
                </section>


                <div class="text-center my-4">
                                            <a href="https://parkchain.org/developerprogram"  class="btn btn-primary"> Explore more </a>
                                        </div>

            </div>
            <Footer />
        </Fragment>
    );

}

export default Home;