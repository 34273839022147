import {
  Button,
  Card,
  CardText,
  CardTitle,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import React, {
  Component,
  Fragment,
  createRef,
  useEffect,
  useRef,
  useState,
} from "react";
import { getUserProfile, localUserId } from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { CONST } from "../../_config";
import Footer from "../../common/Footer/Footer";
import Header from "../../common/Header/Header";
import ReactDOM from "react-dom";
import axios from "axios";
import classnames from "classnames";
import editIcon from "../KPHackathonUser/assets/edit.svg";
import editIconlg from "../KPHackathonUser/assets/editlg.svg";
import laptopCoffee from "../../assets/images/hackathonList/laptopCoffee.png";
import profileImg from "../KPHackathonUser/assets/profile.png";
import { toast } from "react-toastify";

//import editIcon from "../../assets/images/hackathon/edit.svg";
//import editIconlg from "../../assets/images/hackathon/editlg.svg";
/** Images **/

const TeamIdea = (props) => {
  const [activeTab, setActiveTab] = useState("1");
  const [activeTab2, setActiveTab2] = useState("1");
  const [profileDetails, setProfileDetails] = useState({});

  const [loading, setLoading] = useState(false);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const toggle1 = (tab) => {
    if (activeTab2 !== tab) setActiveTab2(tab);
  };

  const location = useLocation();

  var hackathonId = location?.state?.hackathonId;
  console.log(hackathonId, "hackathonIdhackathonId");

  const navigate = useNavigate();

  const [addIdea, setAddIdea] = useState();
  const [projectData, setProjectData] = useState();
  const [ideaRes, setIdeaRes] = useState();
  const [submission, setSubmission] = useState();
  const [data, setData] = useState("");

  var dispatch = useDispatch();
  var ReduxRes = useSelector((state) => state);
  ReduxRes = ReduxRes?.user;
  console.log(addIdea, "addIdeaaddIdeaaddIdea");
  useEffect(() => {}, [addIdea]);
  useEffect(() => {
    dispatch(localUserId());
    dispatch(
      getUserProfile({
        userId: ReduxRes?.userId,
      })
    );
  }, [ReduxRes?.userId]);

  useEffect(() => {
    getIdeaList();
    Projectsubmission();
  }, [ReduxRes?.userId]);

  const getIdeaList = () => {
    setLoading(true);
    const ideaList = {
      userId: ReduxRes?.userId,
      hackathonId: hackathonId,
    };

    const config = {
      header: "https://parkchain.org",
    };

    axios
      .post(CONST.BACKEND_URL + "users/getIdeaList", ideaList, config)
      .then((res) => {
        setLoading(false);

        console.log("res", res.data);
        if (res.data.status == false && res.data.message == "No idea found") {
          setAddIdea(false);
        } else if (res.data.status == true) {
          setLoading(false);

          setAddIdea(true);
          setIdeaRes(res.data.data);
          setData(res.data.data[0].hackathon_status);
          console.log("ideaRes", res.data.data.attachment);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  };

  console.log("data----", data);

  useEffect(() => {
    if (ReduxRes?.user?.userProfile?.data) {
      var data = ReduxRes.user.userProfile.data;
      setProfileDetails({
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        profile: data.profile_img,
      });
    }
  }, [ReduxRes?.user?.userProfile?.data?.first_name]);

  console.log("profile", profileDetails);

  const Projectsubmission = () => {
    setLoading(true);
    const submissionList = {
      userId: ReduxRes?.userId,
      hackathon_id: hackathonId,
    };

    const config = {
      header: "https://parkchain.org",
    };

    axios

      .post(
        CONST.BACKEND_URL + "users/projectSubmissionByHackathon",
        submissionList,
        config
      )
      .then((res) => {
        setLoading(false);

        console.log("ressaaaaaaaaaaaaaaaaaaaaa", res.data);
        if (
          res.data.status == false &&
          res.data.message == "No project submission found"
        ) {
          setProjectData(false);
        } else if (res.data.status == true) {
          setLoading(false);

          setProjectData(true);
          setSubmission(res.data.data);
          // console.log("ideaRes", res.data.data.attachment);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  };

  var lastUpdateDate;

  const TimeConverter = (time) => {
    lastUpdateDate = new Date(time).toLocaleString("en-US", {
      weekday: "short",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      //timeZoneName: "short",
    });
  };

  const red = { color: "red" };
  const green = { color: "green" };
  const gray = { color: "gray" };

  const routingMag = () => {
    if (ReduxRes?.userId) {
      navigate("/hackathon-aboutidea", {
        state: {
          hackathonId: hackathonId,
        },
      });
    } else {
      navigate("/hackathon-login");
    }
  };

  const btnMag = () => {
    return (
      <button onClick={routingMag} disabled={addIdea}>
        <div className="newidea-grid">
          <div>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_1301_8166)">
                <path
                  d="M12 2L12.324 2.001L12.642 2.005L13.258 2.022L13.557 2.035L14.136 2.069L14.689 2.115C19.474 2.579 21.421 4.526 21.885 9.311L21.931 9.864L21.965 10.443C21.97 10.541 21.975 10.641 21.978 10.742L21.995 11.358L22 12L21.995 12.642L21.978 13.258L21.965 13.557L21.931 14.136L21.885 14.689C21.421 19.474 19.474 21.421 14.689 21.885L14.136 21.931L13.557 21.965C13.459 21.97 13.359 21.975 13.258 21.978L12.642 21.995L12 22L11.358 21.995L10.742 21.978L10.443 21.965L9.864 21.931L9.311 21.885C4.526 21.421 2.579 19.474 2.115 14.689L2.069 14.136L2.035 13.557C2.03014 13.4574 2.0258 13.3577 2.022 13.258L2.005 12.642C2.002 12.432 2 12.218 2 12L2.001 11.676L2.005 11.358L2.022 10.742L2.035 10.443L2.069 9.864L2.115 9.311C2.579 4.526 4.526 2.579 9.311 2.115L9.864 2.069L10.443 2.035C10.541 2.03 10.641 2.025 10.742 2.022L11.358 2.005C11.568 2.002 11.782 2 12 2ZM12 8C11.7348 8 11.4804 8.10536 11.2929 8.29289C11.1054 8.48043 11 8.73478 11 9V11H9L8.883 11.007C8.6299 11.0371 8.39785 11.1627 8.23426 11.3582C8.07067 11.5536 7.98789 11.8042 8.00283 12.0586C8.01776 12.313 8.1293 12.5522 8.31463 12.7272C8.49997 12.9021 8.74512 12.9997 9 13H11V15L11.007 15.117C11.0371 15.3701 11.1627 15.6021 11.3582 15.7657C11.5536 15.9293 11.8042 16.0121 12.0586 15.9972C12.313 15.9822 12.5522 15.8707 12.7272 15.6854C12.9021 15.5 12.9997 15.2549 13 15V13H15L15.117 12.993C15.3701 12.9629 15.6021 12.8373 15.7657 12.6418C15.9293 12.4464 16.0121 12.1958 15.9972 11.9414C15.9822 11.687 15.8707 11.4478 15.6854 11.2728C15.5 11.0979 15.2549 11.0003 15 11H13V9L12.993 8.883C12.9643 8.63975 12.8474 8.41547 12.6644 8.25272C12.4813 8.08996 12.2449 8.00003 12 8Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_1301_8166">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div>{ReduxRes?.userId ? <h5>Add New Idea</h5> : <h5>Login</h5>}</div>
        </div>
      </button>
    );
  };

  return (
    <Fragment>
      <Header />

      {loading == true ? (
        <div className="pageloade">
          <div className="page-loader">
            <div class="loader-12">
              <span class="loader_arrow"></span>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className="hckPrfPg">
        <div className="hckLsBnr">
          <div className="container">
            <div className="hckLsBnrR row">
              <div className="hckLsBnrC col-lg-auto">
                <div className="hckLsBnrTx">
                  <h1>Hackathons</h1>
                </div>
              </div>
              <div className="hckLsBnrC col-lg-auto">
                <div className="hckLsBnrIg">
                  <img src={laptopCoffee} alt="laptopCoffee" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hackathon-ideateammain">
          <section>
            <div className="container">
              <div className="ideateam-gridmain">
                <div className="hackathon-leftcnt">
                  <div className="teamidea-userprofile">
                    <div className="hackathon-profileImg">
                      <img
                        src={
                          profileDetails?.profile
                            ? profileDetails?.profile
                            : profileImg
                        }
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="text-center">
                      <div className="hackathon-usernamebox">
                        <div className="username-grid">
                          <div className="pr-3">
                            <h5>{profileDetails?.first_name}</h5>
                          </div>
                          <div>
                            <button
                              onClick={() => {
                                navigate("/hackathon-profileEdit");
                              }}>
                              <img
                                src={editIcon}
                                alt=""
                                className="img-fluid"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="hackathon-email">
                      <p className="m-0">{profileDetails?.email}</p>
                    </div>
                  </div>
                  <div className="teamidea-tabul">
                    <Nav tabs>
                      {addIdea == false || data == "Pending" ? (
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "1",
                            })}
                            onClick={() => {
                              toggle("1");
                            }}>
                            Idea(s)
                          </NavLink>
                        </NavItem>
                      ) : data == "Shortlisted" ? (
                        <NavItem>
                          <NavLink style={{fontSize: 20,fontWeight:40000,color:"green",marginLeft:"50px"}} 
                            className={classnames({
                              active: activeTab === "2",
                            })}
                            onClick={() => {
                              toggle("2");
                            }}
                            // onClick={()=>{
                            //   navigate("/submisssionList")
                            // }}
                          >
                            Submission
                          </NavLink>
                        </NavItem>
                      ) : (
                        <></>
                      )}
                    </Nav>
                  </div>
                </div>
                <div className="hackathon-rightcnt">
                  <div className="teamidea-tabcnt">
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                        <div className="idea-tabcnt">
                          <div className="teamidea-head">
                            <div className="lefthead-txt">
                              <button>
                                <svg
                                  width="8"
                                  height="14"
                                  viewBox="0 0 8 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  onClick={() => {
                                    navigate("/hackathonlist");
                                  }}>
                                  <path
                                    d="M7 1L1 7L7 13"
                                    stroke="#2C3E50"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </button>
                              <h5>Ideas</h5>
                            </div>
                            <div className="righthead-txt">
                              {addIdea == false ? btnMag() : <></>}
                            </div>
                          </div>
                          <div className="teamidea-count">
                            {/* <h5>Drafts (3)</h5> */}
                          </div>

                          <div className="idea-testingbox">
                            {ideaRes && ideaRes.length > 0 ? (
                              ideaRes &&
                              ideaRes.map((item) => {
                                TimeConverter(item.created);

                                console.log("lastUpdateDate", lastUpdateDate);

                                return (
                                  <div className="ideatest-flex">
                                    <>
                                      <div className="testbox-cnt">
                                        <h5>{item?.title}</h5>
                                        <p>
                                          Author : {item?.userName} | Last
                                          updated :{lastUpdateDate}
                                          {/* Jul 25, 2023 08:30 AM IST */}
                                        </p>

                                        <p
                                          style={
                                            item.hackathon_status == "Pending"
                                              ? red
                                              : item.hackathon_status ==
                                                "Rejected"
                                              ? red
                                              : item.hackathon_status ==
                                                "Shortlisted"
                                              ? green
                                              : ""
                                          }>
                                          {item.hackathon_status}
                                        </p>
                                        <h5>{item.hackathon_status ==
                                                "Shortlisted"?<span >Click Submission to Submit Your Project</span>:<></>}</h5>
                                        <br></br>
                                        {item.hackathon_status ==
                                        "Shortlisted" ? (
                                          <></>
                                        ) : (
                                          <p
                                            style={{
                                              backgroundColor: "#bcb5b5",
                                              color: "black",
                                            }}>
                                            <span style={{ color: "black" }}>
                                              <b>Note: </b>
                                            </span>
                                            Once admin approved your idea you
                                            can submit your smart contract code
                                            in github.
                                          </p>
                                        )}
                                      </div>

                                      {item.hackathon_status == "Rejected" ||
                                      item.hackathon_status == "Shortlisted" ? (
                                        <></>
                                      ) : item.hackathon_status == "Pending" ? (
                                        <div>
                                          <button
                                            onClick={() => {
                                              navigate("/hackathon-aboutidea", {
                                                state: {
                                                  hackathonId: hackathonId,
                                                },
                                              });
                                            }}
                                            style={{ cursor: "pointer" }}>
                                            <img
                                              src={editIconlg}
                                              alt=""
                                              className="img-fluid"
                                            />
                                          </button>
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  </div>
                                );
                              })
                            ) : (
                              <h6>NO DATA FOUND</h6>
                            )}
                          </div>

                          {/* <div className="idea-testingbox">
                            <div className="ideatest-flex">
                              <div className="testbox-cnt">
                                <h5>Testing-1</h5>
                                <p>
                                  Author : Steve | Last updated : Jul 25, 2023
                                  08:30 AM IST
                                </p>
                              </div>
                              <div>
                                <button>
                                  <img
                                    src={editIconlg}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="idea-testingbox">
                            <div className="ideatest-flex">
                              <div className="testbox-cnt">
                                <h5>Testing-1</h5>
                                <p>
                                  Author : Steve | Last updated : Jul 25, 2023
                                  08:30 AM IST
                                </p>
                              </div>
                              <div>
                                <button>
                                  <img
                                    src={editIconlg}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </button>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </TabPane>
                    </TabContent>

                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="2">
                        <div className="idea-tabcnt">
                          <div className="teamidea-head">
                            <div className="lefthead-txt">
                              <button>
                                <svg
                                  width="8"
                                  height="14"
                                  viewBox="0 0 8 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M7 1L1 7L7 13"
                                    stroke="#2C3E50"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </button>
                              <h5>Submission</h5>
                            </div>
                            <div className="righthead-txt">
                              <button
                                onClick={() =>
                                  navigate("/formSubmission", {
                                    state: {
                                      hackathonId: hackathonId,
                                      _id: location?.state?._id,
                                      type: "add",
                                    },
                                  })
                                }
                                disabled={projectData}>
                                <div className="newidea-grid">
                                  <div>
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg">
                                      <g clip-path="url(#clip0_1301_8166)">
                                        <path
                                          d="M12 2L12.324 2.001L12.642 2.005L13.258 2.022L13.557 2.035L14.136 2.069L14.689 2.115C19.474 2.579 21.421 4.526 21.885 9.311L21.931 9.864L21.965 10.443C21.97 10.541 21.975 10.641 21.978 10.742L21.995 11.358L22 12L21.995 12.642L21.978 13.258L21.965 13.557L21.931 14.136L21.885 14.689C21.421 19.474 19.474 21.421 14.689 21.885L14.136 21.931L13.557 21.965C13.459 21.97 13.359 21.975 13.258 21.978L12.642 21.995L12 22L11.358 21.995L10.742 21.978L10.443 21.965L9.864 21.931L9.311 21.885C4.526 21.421 2.579 19.474 2.115 14.689L2.069 14.136L2.035 13.557C2.03014 13.4574 2.0258 13.3577 2.022 13.258L2.005 12.642C2.002 12.432 2 12.218 2 12L2.001 11.676L2.005 11.358L2.022 10.742L2.035 10.443L2.069 9.864L2.115 9.311C2.579 4.526 4.526 2.579 9.311 2.115L9.864 2.069L10.443 2.035C10.541 2.03 10.641 2.025 10.742 2.022L11.358 2.005C11.568 2.002 11.782 2 12 2ZM12 8C11.7348 8 11.4804 8.10536 11.2929 8.29289C11.1054 8.48043 11 8.73478 11 9V11H9L8.883 11.007C8.6299 11.0371 8.39785 11.1627 8.23426 11.3582C8.07067 11.5536 7.98789 11.8042 8.00283 12.0586C8.01776 12.313 8.1293 12.5522 8.31463 12.7272C8.49997 12.9021 8.74512 12.9997 9 13H11V15L11.007 15.117C11.0371 15.3701 11.1627 15.6021 11.3582 15.7657C11.5536 15.9293 11.8042 16.0121 12.0586 15.9972C12.313 15.9822 12.5522 15.8707 12.7272 15.6854C12.9021 15.5 12.9997 15.2549 13 15V13H15L15.117 12.993C15.3701 12.9629 15.6021 12.8373 15.7657 12.6418C15.9293 12.4464 16.0121 12.1958 15.9972 11.9414C15.9822 11.687 15.8707 11.4478 15.6854 11.2728C15.5 11.0979 15.2549 11.0003 15 11H13V9L12.993 8.883C12.9643 8.63975 12.8474 8.41547 12.6644 8.25272C12.4813 8.08996 12.2449 8.00003 12 8Z"
                                          fill="white"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_1301_8166">
                                          <rect
                                            width="24"
                                            height="24"
                                            fill="white"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  </div>
                                  <div>
                                    <h5>Project Submission</h5>
                                  </div>
                                </div>
                              </button>
                            </div>
                          </div>
                          <div className="teamidea-count">
                            {/* <h5>Drafts (3)</h5> */}
                          </div>

                          <div className="idea-testingbox">
                            {submission && submission.length > 0 ? (
                              submission &&
                              submission.map((item) => {
                                TimeConverter(item.created_at);

                                console.log("lastUpdateDate", lastUpdateDate);

                                return (
                                  <div className="ideatest-flex">
                                    <>
                                      <div className="testbox-cnt">
                                        <h5>{item?.project_title}</h5>
                                        <p
                                          style={
                                            item.submission_status == "Pending"
                                              ? gray
                                              : item.submission_status ==
                                                "Rejected"
                                              ? red
                                              : item.submission_status ==
                                                "Rewarded"
                                              ? green
                                              : ""
                                          }>
                                          {item.submission_status}
                                        </p>
                                        <p>
                                          Description :{" "}
                                          {item?.project_description}
                                          {/* | Last */}
                                          {/* updated :{TimeConverter(item.created_at)} */}
                                          {/* Jul 25, 2023 08:30 AM IST */}
                                        </p>

                                        {/* <p style={item.hackathon_status == 'Pending' ? gray : item.hackathon_status == 'Rejected' ? red : item.hackathon_status == 'Shortlisted' ? green : ''}>



                                          {item.hackathon_status}
                                        </p> */}
                                      </div>

                                      {item.submission_status == "Rejected" ||
                                      item.submission_status ==
                                        "Shortlisted" ? (
                                        <></>
                                      ) : item.submission_status ===
                                        "Pending" ? (
                                        <div>
                                          <button
                                            onClick={() =>
                                              navigate("/formSubmission", {
                                                state: {
                                                  hackathonId: hackathonId,
                                                  _id: location?.state?._id,
                                                  type: "edit",
                                                },
                                              })
                                            }
                                            style={{ cursor: "pointer" }}>
                                            <img
                                              src={editIconlg}
                                              alt=""
                                              className="img-fluid"
                                            />
                                          </button>
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  </div>
                                );
                              })
                            ) : (
                              <h6>NO DATA FOUND</h6>
                            )}
                          </div>

                          {/* <div className="idea-testingbox">
                            <div className="ideatest-flex">
                              <div className="testbox-cnt">
                                <h5>Testing-1</h5>
                                <p>
                                  Author : Steve | Last updated : Jul 25, 2023
                                  08:30 AM IST
                                </p>
                              </div>
                              <div>
                                <button>
                                  <img
                                    src={editIconlg}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="idea-testingbox">
                            <div className="ideatest-flex">
                              <div className="testbox-cnt">
                                <h5>Testing-1</h5>
                                <p>
                                  Author : Steve | Last updated : Jul 25, 2023
                                  08:30 AM IST
                                </p>
                              </div>
                              <div>
                                <button>
                                  <img
                                    src={editIconlg}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </button>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </TabPane>
                    </TabContent>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};
export default TeamIdea;
