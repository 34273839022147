import React, { Component, Fragment, useState, useRef, useEffect, createRef } from "react";
import ReactDOM from "react-dom";
import classnames from 'classnames';
import Header from "../../common/Header/Header";
import Footer from "../../common/Footer/Footer";
/** Images  **/
import exchangert from '../../assets/images/kpkdigital/exchange-rt.png';
import tradingtree from '../../assets/images/kpkdigital/tradingtree.png';
import secure from '../../assets/images/kpkdigital/secure.png';
import men from '../../assets/images/kpkdigital/men.png';
import notes from '../../assets/images/kpkdigital/notes.png';
import rocket from '../../assets/images/kpkdigital/rocket.png';
import tradecont from '../../assets/images/kpkdigital/tradecont.png';
import taskcentr from '../../assets/images/kpkdigital/taskcentr.png';
import cussupport from '../../assets/images/kpkdigital/cussupport.png';

import { useSelector, useDispatch } from 'react-redux';
import HtmlParser from "react-html-parser";


const KPKExchange = (props) => {



   let data, data1, data2, data3, data4, data5, data6, data7, data8, data9;

   data = useSelector(state => state.user);
   console.log(data, "gagagadadada");

   if (data.data.status == 200) {
      data = data.data.data;
      data1 = data.filter(elem => elem.title == 'The Ultimate Destination for');
      data2 = data.filter(elem => elem.title == 'Trading made simple');
      data3 = data.filter(elem => elem.title == 'Secure and Reliable');
      data4 = data.filter(elem => elem.title == 'Community-driven experience');
      data5 = data.filter(elem => elem.title == 'Koinpark Fees');
      data6 = data.filter(elem => elem.title == 'Koinpark Launchpad');
      data7 = data.filter(elem => elem.title == 'Koinpark Trade Contest');
      data8 = data.filter(elem => elem.title == 'Koinpark Task Center');
      data9 = data.filter(elem => elem.title == 'Customer Support');


   }

   return (
      <Fragment>
         <Header />
         <div className="kpkexchange-pagecont">
            <section className="kpkexchange-bnrsec">
               {data1 && data1.length > 0 ?
                  data1.map((data, index) => (
                     <div className="container">
                        <div className="row">
                           <div className="col-lg-7">
                              <div className="kpkexchange-leftcnt">
                                 <h2>{data ? HtmlParser(data.title) : <></>}<span>{data.subtitle}</span></h2>
                                 <p>{data ? HtmlParser((data.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                 <p>{data ? HtmlParser((data.subcontentdescription).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                              </div>
                           </div>
                           <div className="col-lg-5 text-center">
                              <div className="kpkexchange-rightcnt">
                                 <img src={data ? HtmlParser(data.image) : <></>} alt="" className="img-fluid" />
                              </div>
                           </div>
                        </div>
                     </div>
                  )) : <></>
               }

            </section>
            <section className="exchangeleftright-sec">
               <div className="container">
                  {data2 && data2.length > 0 ?
                     data2.map((data, index) => (
                        <div className="exchange-inrtopcnt">
                           <div className="row align-items-center">
                              <div className="col-md-8">
                                 <h2>{data ? HtmlParser(data.title) : <></>}</h2>
                                 <p>{data ? HtmlParser((data.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                 <p>{data ? HtmlParser((data.subcontentdescription).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                              </div>
                              <div className="col-md-4 mt-3 text-center">
                                 <div>
                                    <img src={data ? HtmlParser(data.image) : <></>} alt="" className="img-fluid" />
                                 </div>
                              </div>
                           </div>
                        </div>
                     )) : <></>}
                  {data3 && data3.length > 0 ?
                     data3.map((data, index) => (
                        <div className="exchange-inrbgcnt">
                           <div className="row align-items-center">
                              <div className="col-md-4 order-img text-center">
                                 <div>
                                    <img src={data ? HtmlParser(data.image) : <></>} alt="" className="img-fluid" />
                                 </div>
                              </div>
                              <div className="col-md-8">
                                 <h2>{data ? HtmlParser(data.title) : <></>}</h2>
                                 <p>{data ? HtmlParser((data.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                 <p>{data ? HtmlParser((data.subcontentdescription).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                              </div>
                           </div>
                        </div>
                     )) : <></>}
               </div>
            </section>
            <section className="exchangeleftright-sec">
               <div className="container">
                  {data4 && data4.length > 0 ?
                     data4.map((data, index) => (
                        <div className="exchange-inrtopcnt">
                           <div className="row align-items-center">
                              <div className="col-md-8">
                                 <h2>{data ? HtmlParser(data.title) : <></>}</h2>
                                 <p>{data ? HtmlParser((data.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                 <p>{data ? HtmlParser((data.subcontentdescription).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                              </div>
                              <div className="col-md-4 mt-3 text-center">
                                 <div>
                                    <img src={data ? HtmlParser(data.image) : <></>} alt="" className="img-fluid" />
                                 </div>
                              </div>
                           </div>
                        </div>
                     )) : <></>}

                  {data5 && data5.length > 0 ?
                     data5.map((data, index) => (
                        <div className="exchange-inrbgcnt">
                           <div className="row align-items-center">
                              <div className="col-md-4 order-img text-center">
                                 <div>
                                    <img src={data ? HtmlParser(data.image) : <></>} alt="" className="img-fluid" />
                                 </div>
                              </div>
                              <div className="col-md-8">
                                 <h2>{data ? HtmlParser(data.title) : <></>}</h2>
                                 <p>{data ? HtmlParser((data.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                 <p>{data ? HtmlParser((data.subcontentdescription).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                              </div>
                           </div>
                        </div>
                     )) : <></>}
               </div>
            </section>
            <section className="exchangeleftright-sec">
               <div className="container">
                  {data6 && data6.length > 0 ?
                     data6.map((data, index) => (
                        <div className="exchange-inrtopcnt">
                           <div className="row align-items-center">
                              <div className="col-md-8">
                                 <h2>{data ? HtmlParser(data.title) : <></>}</h2>
                                 <p>{data ? HtmlParser((data.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                 <p>{data ? HtmlParser((data.subcontentdescription).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                              </div>
                              <div className="col-md-4 mt-3 text-center">
                                 <div>
                                    <img src={data ? HtmlParser(data.image) : <></>} alt="" className="img-fluid" />
                                 </div>
                              </div>
                           </div>
                        </div>
                     )) : <></>}
                  {data7 && data7.length > 0 ?
                     data7.map((data, index) => (
                        <div className="exchange-inrbgcnt">
                           <div className="row align-items-center">
                              <div className="col-md-4 order-img text-center">
                                 <div>
                                    <img src={data ? HtmlParser(data.image) : <></>} alt="" className="img-fluid" />
                                 </div>
                              </div>
                              <div className="col-md-8">
                                 <h2>{data ? HtmlParser(data.title) : <></>}</h2>
                                 <p>{data ? HtmlParser((data.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                 <p>{data ? HtmlParser((data.subcontentdescription).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                              </div>
                           </div>
                        </div>
                     )) : <></>}
               </div>
            </section>
            <section className="exchangeleftright-sec">
               <div className="container">
                  {data8 && data8.length > 0 ?
                     data8.map((data, index) => (
                        <div className="exchange-inrtopcnt">
                           <div className="row align-items-center">
                              <div className="col-md-8">
                                 <h2>{data ? HtmlParser(data.title) : <></>}</h2>
                                 <p>{data ? HtmlParser((data.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                 <p>{data ? HtmlParser((data.subcontentdescription).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                              </div>
                              <div className="col-md-4 mt-3 text-center">
                                 <div>
                                    <img src={data ? HtmlParser(data.image) : <></>} alt="" className="img-fluid" />
                                 </div>
                              </div>
                           </div>
                        </div>
                     )) : <></>}
                  {data9 && data9.length > 0 ?
                     data9.map((data, index) => (
                        <div className="exchange-inrbgcnt">
                           <div className="row align-items-center">
                              <div className="col-md-4 order-img text-center">
                                 <div>
                                    <img src={data ? HtmlParser(data.image) : <></>} alt="" className="img-fluid" />
                                 </div>
                              </div>
                              <div className="col-md-8">
                                 <h2>{data ? HtmlParser(data.title) : <></>}</h2>
                                 <p>{data ? HtmlParser((data.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                 <p>{data ? HtmlParser((data.subcontentdescription).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                              </div>
                           </div>
                        </div>
                     )) : <></>}
               </div>
            </section>
            <div class="text-center my-2 mt-4">
               <a href="https://koinpark.com/" target="blank" className="btn btn-primary px-5 py-2 px-5" >
                  Explore
               </a>
            </div>
         </div>
         <Footer />
      </Fragment>
   );
}
export default KPKExchange;