import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/scss/style.scss';
import '../src/scss/dev.scss';
import 'react-toastify/dist/ReactToastify.css';

import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import React, {Component, Suspense} from 'react';
import {ToastContainer, toast} from 'react-toastify';
import {getUserList, localUserId, siteInform} from './redux/action';
import {useDispatch, useSelector} from 'react-redux';

// import LoadingOverlay from 'react-loading-overlay-ts';
import AboutIdea from './views/KPHackathonUser/Aboutidea';
import ComingSoon from './views/Coming_soon/comingsoon';
import Cryptonft from './views/NFT/Cryptonft';
import DevDocument from './views/DeveloperProgram/DeveloperProgram';
import DeveloperProgram from './views/DeveloperProgram/DeveloperProgram';
import Document from './views/Document/Document';
import Ecosystem from './views/ecosystem/Ecosystem';
import Exploreguide from './views/Exploreguide/Exploreguide';
import Guidehub from './views/Guidehub/Guidehub';
import HackathonForgot from './views/KPHackathonUser/KPHackathonForgot';
import HackathonForm1 from './views/KPHackathonUser/KPHackathonForm1';
import HackathonForm2 from './views/KPHackathonUser/KPHackathonForm2';
import HackathonLogin from './views/KPHackathonUser/KPHackathonLogin';
import HackathonProfile from './views/KPHackathonUser/KPHackathonProfile';
import HackathonProfileEdit
  from './views/KPHackathonUser/KPHackathonProfileEdit';
import HackathonRegister from './views/KPHackathonUser/KPHackathonRegister';
import Home from './views/Home/Home';
import IdeaTeamSub from './views/KPHackathonUser/IdeaTeam';
import Innerpages from './views/Innerpages/Innerpages';
import KPBlog from './views/KPBlog/KPBlog';
import KPBlogDetails from './views/KPBlogDetails/KPBlogDetails';
import KPCommunity from './views/KPCommunity/KPCommunity';
import KPDao from './views/KPDao/KPDao';
import KPDefi from './views/KPDefi/KPDefi';
import KPEvent from './views/KPEvent/KPEvent';
import KPEventDetail from './views/KPEventDetail/KPEventDetail';
import KPGaming from './views/KPGaming/KPGaming';
import KPGamingOne from './views/KPGamingOne/KPGamingOne';
import KPHackathon from './views/KPHackathon/KPHackathon';
import KPHackathonDetails from './views/KPHackathonList/KPHackathonDetails';
import KPHackathonList from './views/KPHackathonList/KPHackathonList';
import KPJobs from './views/KPJobs/KPJobs';
import KPK from './views/KPK/KPK';
import KPKExchange from './views/KPExchange/KPKExchange';
import KPKPayment from './views/KPPayment/KPPayment';
import KPKStake from './views/KPKStake/KPKStake';
import KPKpk from './views/KPKpk/KPKpk';
import KPNews from './views/KPNews/KPNews';
import KPNewsDetails from './views/KPNewsDetails/KPNewsDetails';
import KPParkchain from './views/KPParkchain/KPParkchain';
import KPPayment from './views/KPPayment/KPPayment';
import KPVerification from './views/KPVerification/KPVerification';
// import EditFormSubmission from "./views/KPHackathonUser/Edit_Form"
import LeaderBoard from './views/KPHackathonUser/Leaderboard';
import Learnhub from './views/Learnhub/Learnhub';
import Manageteam from './views/KPHackathonUser/Manageteam';
import Metaversenft from './views/NFT/Metaversenft';
import NFT from './views/NFT/Nft';
import NFTCase from './views/NFT/Nftcase';
import NFTDocumentation from './views/NFTDocumentation/NFTDocumentation';
import NFTdocument from './views/NFTDocument/Nftdocument';
import OTP from './views/KPHackathonUser/OTP';
import ParkDocumentation from './views/parkdocumentation/index';
import Parkscancore from './views/Parkscancore/Parkscancore';
import Parkwallet from './views/Parkwallet/Parkwallet';
import Privacy from './views/Privacy/Privacy';
import SmartContract from './views/SmartContract/Smartcontract';
import SmartContracts from './views/SmartContracts/SmartContracts';
import Staking from './views/Stake/Stake';
import SubmissionForm from './views/KPHackathonUser/Submission';
import TeamIdea from './views/KPHackathonUser/Hackathonmain';
import Terms from './views/Terms/Terms';
import Typesofnft from './views/NFT/Typesofnft';
import Wallet from './views/Wallet/Wallet';
import Webthree from './views/Webthree/Webthree';
import WhyNft from './views/NFT/Whynft';
import {useEffect} from 'react';
// import Metamask from "./views/metamask/metamask";
import HackathonForm3 from './views/KPHackathonUser/KPHackathonForm3';
import UserHackathonLists from './views/KPHackathonUser/HackathonUserList';

const App = props => {
  const dispatch = useDispatch ();
  console.log ('app is checking.........');
  useEffect (() => {
    dispatch (getUserList ());
    dispatch (siteInform ());
    dispatch (localUserId ());
  }, []);
  const todos = useSelector (state => state.user);
  console.log (todos);

  // var enableLoaderRedux = useSelector(state => state.userSlice.loader);

  return (
    <React.Fragment>
      {/* <LoadingOverlay
          active={enableLoaderRedux}
          spinner
          text={"Please Wait"} */}
      {/* > */}
      <BrowserRouter>
        <Routes history={props.history}>
          <Route path="/" element={<Home />} />
          {/* <Route path='*' element={<Innerpages />} /> */}
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/staking" element={<Staking />} />
          <Route path="/wallet" element={<Wallet />} />
          <Route path="/daos" element={<KPDao />} />
          <Route path="/kpgaming" element={<KPGaming />} />
          <Route path="/kpdefi" element={<KPDefi />} />
          <Route path="/kppayment" element={<KPPayment />} />
          <Route path="/kpgamingone" element={<KPGamingOne />} />
          <Route path="/kpblog" element={<KPBlog />} />
          <Route path="/kpblogdetails/:id" element={<KPBlogDetails />} />
          <Route path="/verification" element={<KPVerification />} />
          <Route path="/community" element={<KPCommunity />} />
          <Route path="/event" element={<KPEvent />} />
          <Route path="/eventdetail/:id" element={<KPEventDetail />} />
          <Route path="/news" element={<KPNews />} />
          <Route path="/newsdetails/:id" element={<KPNewsDetails />} />
          <Route path="/jobs" element={<KPJobs />} />
          <Route path="/parkdocumentation" element={<ParkDocumentation />} />
          <Route path="/nftdocumentation" element={<NFTDocumentation />} />
          <Route path="/developerprogram" element={<DeveloperProgram />} />
          <Route path="/smartcontracts" element={<SmartContracts />} />
          <Route path="/smartcontract" element={<SmartContract />} />
          {/* <Route path="/meta" element={<Metamask/>} /> */}

          <Route path="/webthree" element={<Webthree />} />
          <Route path="/kpk" element={<KPK />} />
          <Route path="/kpparkchain" element={<KPParkchain />} />
          <Route path="/kpkpk" element={<KPKpk />} />
          <Route path="/developer" element={<DevDocument />} />

          <Route path="/hackathon" element={<KPHackathon />} />
          <Route path="/hackathonlist" element={<KPHackathonList />} />
          <Route
            path="/hackathon-details/:id"
            element={<KPHackathonDetails />}
          />

          <Route path="/ecosystem" element={<Ecosystem />} />
          <Route path="/hackathon-register" element={<HackathonRegister />} />
          <Route path="/hackathon-login" element={<HackathonLogin />} />
          <Route path="/hackathon-forgot" element={<HackathonForgot />} />
          <Route path="/hackathon-profile" element={<HackathonProfile />} />
          <Route path="/parkscancore" element={<Parkscancore />} />
          <Route
            path="/hackathon-profileEdit"
            element={<HackathonProfileEdit />}
          />
          <Route
            path="/hackathon-form1/:hackathonId"
            element={<HackathonForm1 />}
          />
          <Route
            path="/hackathon-form1/:hackathonId/:investorId/:refererCode"
            element={<HackathonForm1 />}
          />
          <Route
            path="/hackathon-form3"
            element={<HackathonForm3 />}
          />
          <Route path="/hackathon-form2" element={<HackathonForm2 />} />
          <Route path="/kpkpayment" element={<KPKPayment />} />
          <Route path="/koinpark_exchange" element={<KPKExchange />} />
          <Route path="/parkwallet" element={<Parkwallet />} />
          <Route path="/exploreguide" element={<Exploreguide />} />
          <Route path="/koinparkstake" element={<KPKStake />} />
          <Route path="/guidehub" element={<Guidehub />} />
          <Route path="/learnhub" element={<Learnhub />} />
          <Route path="/nftdocumentations" element={<NFTdocument />} />

          <Route path="/nft" element={<NFT />} />
          <Route path="/typesofnft" element={<Typesofnft />} />
          <Route path="/metaversenft" element={<Metaversenft />} />
          <Route path="/nftcase" element={<NFTCase />} />
          <Route path="/cryptonft" element={<Cryptonft />} />
          <Route path="/whynft" element={<WhyNft />} />

          <Route path="/document" element={<Document />} />
          <Route path="/hackathon-main" element={<TeamIdea />} />
          <Route path="/hackathon-aboutidea" element={<AboutIdea />} />
          <Route path="/IdeaTeam" element={<IdeaTeamSub />} />
          <Route path="/comingsoon" element={<ComingSoon />} />
          <Route path="/formSubmission" element={<SubmissionForm />} />
          <Route path="/userHackathonlist" element={<UserHackathonLists />} />
          <Route path="/OTP" element={<OTP />} />
          {/* <Route path="/hackathon-edit-form" element={<EditFormSubmission />} /> */}
          <Route path="/leaderBoard" element={<LeaderBoard />} />
          <Route path="/hackathon-invite" element={<Manageteam />} />
          {/* <Route path="/metamask-connect" element={<MetamaskConnect />} /> */}

        </Routes>
      </BrowserRouter>
      {/* </LoadingOverlay> */}
      <ToastContainer />
    </React.Fragment>
  );
};

export default App;
