import React from "react";

function Loader() {
  return (
    <div className="pageloade">
      <div className="page-loader">
        <div class="loader-12">
          <span class="loader_arrow"></span>
        </div>
      </div>
    </div>
  );
}

export default Loader;