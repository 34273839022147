import React, { Component, Fragment, useState, useRef, useEffect, createRef  } from "react";
import ReactDOM from "react-dom";
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import Overview from '../Overview/Overview';
import NFTDocumentation from '../NFTDocumentation/NFTDocumentation';
import DeveloperProgram from '../DeveloperProgram/DeveloperProgram';
import {
	BrowserRouter, Navigate, Route, Routes, Link
 } from 'react-router-dom';
import Header from "../../common/Header/Header";
import Footer from "../../common/Footer/Footer";
import kpsearch from '../../assets/images/pics/kpsearch.png';
import { useSelector, useDispatch } from 'react-redux';
import HtmlParser from "react-html-parser";

const Home = (props) => {  
    const [activeTab, setActiveTab] = useState('1');
    const toggle = tab => {
      if(activeTab !== tab) setActiveTab(tab);
    }
    let data, data1;

    data = useSelector(state => state.user);
    if (data.data.status == 200) {
        data = data.data.data;

        data1 = data.find(elem => elem.title == 'body content - verification');
    
    }

    return (
        <Fragment>
            <Header />
            <div className="pkchinsec">
                <section className="kpverifysec">
                    <div className="container">
                        <div className="row align-items-center justify-content-center kpVfyRw">
                                <div className="col-lg-6">    
                                <div className="KpVryIn">
                                    <h2 className="kpbloghead">VERIFICATION TOOL</h2>
                                    <div className="input-group KpVryIngp">
                                    <div className="input-group-append">
                                        <span className="input-group-text " id="basic-addon2">
                                        <img src={kpsearch} alt="" />
                                        </span>
                                    </div>
                                    <input type="search" className="form-control" placeholder="Enter Your ID
                                        "aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                    <div className="input-group-append">
                                        <button className="input-group-text searchspan" id="basic-addon2">Search</button>
                                    </div>
                                    </div>
                                    <p>{data1 ? HtmlParser((data1.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                </div>
                            </div>
                        </div>                    
                    </div>
                </section>                
            </div>
            <Footer />
        </Fragment>
    );
   
}

export default Home;