import "./kphckcr.scss";

import {
  BrowserRouter,
  Link,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  Button,
  Collapse,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import React, {
  Component,
  Fragment,
  createRef,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import { CONST } from "../../_config";
import Footer from "../../common/Footer/Footer";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import Header from "../../common/Header/Header";
import ReactCountryFlag from "react-country-flag";
import ReactDOM from "react-dom";
import Select from "react-select";
import axios from "axios";
import camera from "./assets/camera.svg";
import classnames from "classnames";
import countryList from "react-select-country-list";
import { getUserProfile } from "../../redux/action";
import laptopCoffee from "../../assets/images/hackathonList/laptopCoffee.png";
import profile from "./assets/profile.png";
import tron from "./assets/tron.png";
import { useForm } from "react-hook-form";

const HackathonForm1 = () => {
  const [userDetails, setUserDetails] = useState({
    firstName: "",
    gender: "",
    email: "",
    terms: false,
    selContry: null,
  });
  const [value, setValue] = useState(null);
  const [profileDetails, setProfileDetails] = useState();

  const [genderList, setGenderList] = useState([
    {
      value: "male",
      label: "Male",
    },
    {
      value: "female",
      label: "Female",
    },
    {
      value: "other",
      label: "Other",
    },
  ]);

  const [hackathonDetails, setHackathonDetails] = useState({});

  const { register, handleSubmit, errors, watch, trigger } = useForm({
    mode: "onBlur",
  });

  const navigate = useNavigate();
  const location = useLocation();

  const parms = useParams();

  const dispatch = useDispatch();
  var reduxRes = useSelector((state) => state);

  reduxRes = reduxRes?.user;

  var userID = localStorage.getItem("user_id");

  console.log("parms", parms);

  console.log("locatoin1", location);

  const Submit = (e) => {
    console.log(e, "userDetailssadasddddddddd");

    navigate("/hackathon-form2", {
      state: {
        userDetails: e,
        location: value.label,
        hackathonId: parms?.hackathonId,
        endTime: hackathonDetails?.endTime,
        _id: hackathonDetails?._id,
        title: hackathonDetails?.title,
        image: hackathonDetails?.image,
        investorId: parms?.investorId,
        refererCode: parms?.refererCode,
      },
    });
  };

  console.log(" parms?.investorId", parms?.investorId);
  const options = useMemo(() => countryList().getData(), []);
  const changeHandler = (value) => {
    console.log(value.label, "valueeeeeeeeeekkkk");
    setValue(value);
  };

  useEffect(() => {
    const data = {
      hackthonId: parms.hackathonId,
    };

    axios
      .post(CONST.BACKEND_URL + "users/getHackathonListByHackId", data)
      .then((res) => {
        if (res.data.status == true) {
          setHackathonDetails(res.data.data);
        }
      })
      .catch((err) => {});
  }, []);

  console.log("hackathonDetails?.heading", hackathonDetails);

  const GetUserProfile = () => {
    dispatch(
      getUserProfile({
        userId: reduxRes?.userId,
      })
    );
    if (reduxRes?.userProfile?.data) {
      setProfileDetails({
        email: reduxRes.userProfile.data.email,
      });
    }
  };

  useEffect(() => {
    if (reduxRes?.userId) {
      GetUserProfile();
    }
  }, [reduxRes?.userId, reduxRes?.userProfile?.data?.email]);

  console.log("profileDetails?.email", profileDetails?.email);

  useEffect(() => {
    setTimeout(() => {
      checkRegisteredUser();
    }, 1000);
  }, [userID]);

  const checkRegisteredUser = () => {
    if (userID !== null && userID !== "" && userID !== undefined) {
      console.log("userrrr-----");
    } else {
      localStorage.setItem("pathName", location.pathname);
      navigate("/hackathon-login");
    }
  };

  return (
    <Fragment>
      <Header />

      <div className="hckPrfPg">
        <div className="hckLsBnr">
          <div className="container">
            <div className="hckLsBnrR row">
              <div className="hckLsBnrC col-lg-auto">
                <div className="hckLsBnrIcTx">
                  <div className="hckLsBnrIc">
                    <img src={hackathonDetails?.image} alt="tron" />
                  </div>
                  <div className="hckLsBnrTx">
                    <h1>{hackathonDetails?.title}</h1>
                    <p>
                      ENDS ON:{" "}
                      {new Date(
                        hackathonDetails?.end_date_time
                      ).toLocaleString()}
                    </p>
                  </div>
                </div>
              </div>
              <div className="hckLsBnrC col-lg">
                <div className="hckLsBnrIg v2">
                  <img src={laptopCoffee} alt="laptopCoffee" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="hckPrfEd">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-xl-6 mx-auto">
                <form onSubmit={handleSubmit(Submit)}>
                  <div className="hckEdtPrfIp">
                    <h4>Enter the following details</h4>

                    <div className="hckEdtPrfIpTy">PERSONAL DETAILS</div>
                    <div className="hckcrFrm pb-3">
                      <div className="row hckcrFrmR">
                        <div className="col-md-6 hckcrFrmC">
                          <FormGroup>
                            <Label for="">
                              Name<span>*</span>
                            </Label>

                            <input
                              className="form-control"
                              type="text"
                              placeholder="Name"
                              ref={register({
                                required: "Name is required",
                                // pattern: {
                                //   value:
                                //     /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                //   message: "Should match Email pattern",
                                // },
                              })}
                              // autoComplete="off"
                              name="firstName"
                              onKeyUp={() => {
                                trigger("firstName");
                              }}
                            />

                            {errors.firstName && (
                              <div style={{ color: "red" }}>
                                {errors.firstName.message}.
                              </div>
                            )}
                          </FormGroup>
                        </div>

                        <div className="col-md-6 hckcrFrmC">
                          <FormGroup>
                            <Label for="">
                              Gender<span>*</span>
                            </Label>

                            {genderList.map((item, i) => {
                              return (
                                <div>
                                  <input
                                    // className="form-control"
                                    type="radio"
                                    // autoComplete="off"
                                    name="gender"
                                    id={item.value}
                                    key={i}
                                    value={item.value}
                                    onChange={(e) => {
                                      setUserDetails({
                                        ...userDetails,
                                        [e.target.name]: e.target.value,
                                      });
                                      console.log(e.target.value)
                                    }}
                                    ref={register({
                                      required: "Gender is required",
                                    })}
                                    onKeyUp={() => {
                                      trigger("gender");
                                    }}
                                  />
                                  <label for={item.value} className="ml-2"> {item.label}</label>
                                </div>
                              );
                            })}

                            {errors.gender && (
                              <div style={{ color: "red" }}>
                                {errors.gender.message}.
                              </div>
                            )}
                          </FormGroup>
                        </div>

                        <div className="col-12 hckcrFrmC">
                          {/* <FormGroup>
                            <Label for="*">
                              Current Location<span>*</span>
                            </Label>
                            <Input type="select" name="select">
                              <option>10010, Newyork, USA</option>
                              <option>2</option>
                              <option>3</option>
                              <option>4</option>
                              <option>5</option>
                            </Input>
                          </FormGroup> */}
                          {/* 
                          <GooglePlacesAutocomplete
                            apiKey="AIzaSyBzDvp4-fh7MIYMeHJYbNDrymo3oF3GSvs"
                            // onSelect={console.log}
                            onSelect={({ description }) =>
                              setValue({ address: description })
                            }
                            selectProps={{
                              styles: {
                                input: (provided) => ({
                                  ...provided,
                                  color: "blue",
                                }),
                                option: (provided) => ({
                                  ...provided,
                                  color: "blue",
                                }),
                                singleValue: (provided) => ({
                                  ...provided,
                                  color: "blue",
                                }),
                              },
                            }}
                          /> */}
                        </div>
                      </div>
                    </div>

                    <div className="hckEdtPrfIpTy">OTHER DETAILS</div>
                    <div className="hckcrFrm">
                      <div className="row hckcrFrmR">
                        <div className="col-md-6 hckcrFrmC">
                          <FormGroup>
                            <Label for="">
                              Email<span>*</span>
                            </Label>

                            <input
                              className="form-control"
                              type="text"
                              // placeholder="firstName"
                              ref={register({
                                required: "Email is required",
                                pattern: {
                                  value:
                                    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                  message: "Should match Email pattern",
                                },
                              })}
                              value={profileDetails?.email}
                              // autoComplete="off"
                              name="email"
                              onKeyUp={() => {
                                trigger("email");
                              }}
                            />

                            {errors.email && (
                              <div style={{ color: "red" }}>
                                {errors.email.message}.
                              </div>
                            )}
                          </FormGroup>
                        </div>

                        <div className="col-lg-6 hckcrFrmC">
                          <FormGroup>
                            <Label for="">Country</Label>
                            {/* <Input type="select" name="select">
                              <option>USA</option>
                              <option>2</option>
                              <option>3</option>
                              <option>4</option>
                              <option>5</option>
                            </Input> */}

                            <Select
                              options={options}
                              value={value}
                              onChange={changeHandler}
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused
                                    ? "grey"
                                    : "white",
                                }),
                              }}
                            />
                          </FormGroup>
                        </div>

                        {/* <div className="col-12 hckcrFrmC">
                          <FormGroup>
                            <Label for="*">
                              Current Location<span>*</span>
                            </Label>
                            <Input type="select" name="select">
                              <option>10010, Newyork, USA</option>
                              <option>2</option>
                              <option>3</option>
                              <option>4</option>
                              <option>5</option>
                            </Input>
                          </FormGroup>
                        </div> */}

                        <div className="col-12 hckcrFrmC">
                          <FormGroup>
                            <div class="custom-checkbox custom-control">
                              <input
                                type="checkbox"
                                id="agreeCheckbox"
                                class="custom-control-input"
                                onChange={(e) => {
                                  setUserDetails({ terms: e.target.checked });
                                  console.log(e.target.checked);
                                }}
                                checked={userDetails.terms}
                                ref={register({
                                  required: "Terms is required",
                                })}
                                name="terms"
                                onKeyUp={() => {
                                  trigger("terms");
                                }}
                              />

                              <label
                                class="custom-control-label f13"
                                for="agreeCheckbox"
                              >
                                By participating in this campaign, I agree to
                                applicable <a href="#">Terms and Services.</a>
                              </label>

                              {errors.terms && (
                                <div style={{ color: "red" }}>
                                  {errors.terms.message}.
                                </div>
                              )}
                            </div>
                          </FormGroup>
                        </div>
                      </div>
                    </div>

                    <div className="prfEdtSbm">
                      <button class="btn btn-17442 btn-bluFl v2">Submit</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Fragment>
  );
};

export default HackathonForm1;
