import React, { Component, Fragment, useState, useRef, useEffect, createRef, } from "react";
import ReactDOM from "react-dom";
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import Overview from '../Overview/Overview';
import NFTDocumentation from '../NFTDocumentation/NFTDocumentation';
import DeveloperProgram from '../DeveloperProgram/DeveloperProgram';
import {
    BrowserRouter, Navigate, Route, Routes, Link, useParams
} from 'react-router-dom';

import Header from "../../common/Header/Header";
import Footer from "../../common/Footer/Footer";

import eventicon1 from '../../assets/images/pics/eventicon1.png';
import eventicon2 from '../../assets/images/pics/eventicon2.png';
import eventicon3 from '../../assets/images/pics/sicon1.png';
import eventicon4 from '../../assets/images/pics/sicon2.png';
import eventicon5 from '../../assets/images/pics/sicon3.png';
import eventicon6 from '../../assets/images/pics/eventicon6.png';
import kpevnt from '../../assets/images/pics/kpevnt.png';
import kpeventimg1 from '../../assets/images/pics/kpeventimg1.png';
import { CONST } from "../../_config";
import axios from "axios";
import HtmlParser from "react-html-parser";
import { useSelector } from "react-redux";


const Home = (props) => {
    const [activeTab, setActiveTab] = useState('1');
    const [eventdetails, setEvent] = useState('');
    const params = useParams();
    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    let data, data1;
    data = useSelector(state => state.user);
    if (data.data.status == 200) {
        data = data.data.data;

        data1 = data.find(elem => elem.title == 'footer content - home');
        // console.log(data1)
    }
    let site_inform = useSelector(state => (state.user));
    if (site_inform.data1.status == true) {
        site_inform = site_inform.data1.data
    }


    useEffect(() => {
        getEventDetails();
    }, [])

    const getEventDetails = async () => {
        let data = {
            "_id": params.id
        }
        let getevent = await axios.post(CONST.BACKEND_URL + 'users/blogInfo', data);
        console.log(getevent)
        if (getevent.data.status == 200) {
            setEvent(getevent.data.data[0])
            //    setDate (new Date (getevent.data.data.created_at).toUTCString()) 
        }
    }

    return (
        <Fragment>
            <Header />
            <div className="pkchinsec evnPg">
                <section className="">
                    <div className="container">
                        <div className="">
                            <div className="row justify-content-center my-5 ">
                                <div className="col-lg-10 kpEvDet">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-7 col-xl-8 pl-lg-0 mb-lg-4 mb-lg-0 kporder1">
                                            <div><img className="w-100" src={eventdetails != '' ? eventdetails.image : <></>} alt="" /></div>
                                        </div>

                                        <div className="col-lg-5 col-xl-4 pr-lg-0 mb-lg-0 mb-lg-0 kporder2">

                                            <div className="kpeventcnt  kpbksec p-0 d-block">
                                                <div>
                                                    <h3 className="text-left mt-4">{eventdetails != '' ? new Date(eventdetails.start_datetime).toDateString() : <></>}</h3>
                                                    <h6 className="text-left">{eventdetails != '' ? eventdetails.title : <></>}</h6>
                                                </div>
{/* 
                                                <div class="mt-3 mb-1" style={{ display: 'inline-grid', alignItems: 'center' }} >
                                                    <div className=""><a href="https://www.eventbrite.com/e/world-blockchain-summit-dubai-2023-tickets-514833148807" target="_blank" className="submitbtn submitbtn2">Book</a></div>
                                                </div> */}

                                                <div className="">
                                                    <ul className="eventiconmenu pl-0 mb-lg-4 mt-lg-0 mb-lg-0">
                                                        <li>
                                                            <a href={site_inform ? site_inform.discord : <></>} target="_blank">
                                                                <img src={eventicon1} alt="" />
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <a href={site_inform ? site_inform.twitter : <></>} target="_blank">
                                                                <img src={eventicon3} alt="" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href={site_inform ? site_inform.facebook : <></>} target="_blank">
                                                                <img src={eventicon4} alt="" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href={site_inform ? site_inform.linkedin : <></>} target="_blank">
                                                                <img src={eventicon5} alt="" />
                                                            </a>
                                                        </li>

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-8 kpEvDEtail mb-lg-0 kporder3">
                                            {/* <h2>{eventdetails!='' ? eventdetails.title : <></>}</h2> */}
                                            <h2 className="mb-3">{eventdetails != '' ? eventdetails.sub_content : <></>}</h2>
                                            {eventdetails != '' ? HtmlParser(eventdetails.content_description) : <></>}
                                            {/* <h4>About the Event</h4>

                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p> */}
                                        </div>

                                        <div className="col-lg-4 kpEvDEtail mb-lg-4 mt-lg-0 mb-lg-0 kporder4">
                                            <div>
                                                <h4>Date and time</h4>
                                                <p className="pb-0 mb-2">{eventdetails != '' ? new Date(eventdetails.start_datetime).toUTCString() : <></>}</p>
                                                <p className="pb-0 mb-2">-</p>
                                                <p>{eventdetails != '' ? new Date(eventdetails.end_date_time).toUTCString() : <></>}</p>
                                            </div>

                                            <div>
                                                <h4>Location</h4>
                                                <p>{eventdetails != '' ? eventdetails.location : <></>}</p>
                                            </div>

                                        </div>

                                        {/* <div className="col-lg-12 kpEvDEtail pt-0 mb-4 mb-lg-0 kporder5">
                                                <h4>HIGH LEVEL AGENDA</h4>
                                                    <ul className="pkmenu2">
                                                        <li><a>Day -1: 10AM - 9PM - Welcome and Talks on All things Solana </a></li>
                                                        <li><a>Day -2: 10AM - 9PM - Workshops, Talks & Panels </a></li>
                                                        <li><a>Day -3: 10AM - 9PM - Workshops, Talks & Panels</a></li>
                                                        <li><a>Day -4: 10AM - 8:00PM - DEMO DAY!</a></li>
                                                        
                                                </ul>
                                            </div> */}

                                        <div className="col-lg-12 kpEvDEtail pt-0 kporder6">
                                            <div className="horizonline "></div>

                                            <div className=" pl-0 pt-3">
                                                <h6>SHARE ARTICLE</h6>
                                            </div>

                                            <ul className="eventiconmenu pl-0">
                                                <li>
                                                    <a href={site_inform ? site_inform.discord : <></>} target="_blank">
                                                        <img src={eventicon1} alt="" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href={site_inform ? site_inform.instagram : <></>} target="_blank">
                                                        <img src={eventicon2} alt="" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href={site_inform ? site_inform.twitter : <></>} target="_blank">
                                                        <img src={eventicon3} alt="" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href={site_inform ? site_inform.facebook : <></>} target="_blank">
                                                        <img src={eventicon4} alt="" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href={site_inform ? site_inform.linkedin : <></>} target="_blank">
                                                        <img src={eventicon5} alt="" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href={site_inform ? site_inform.telegram : <></>} target="_blank">
                                                        <img src={eventicon6} alt="" />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>

                                    </div>


                                </div>



                            </div>

                            <div className="row">
                                <div className="col-lg-7">

                                </div>
                            </div>
                        </div>
                    </div>
                </section>




            </div>
            <Footer />
        </Fragment>
    );

}

export default Home;