import "react-toastify/dist/ReactToastify.css";

import { Bounce, Flip, Slide, Zoom } from "react-toastify";
import {
  BrowserRouter,
  Link,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import React, {
  Component,
  Fragment,
  createRef,
  useEffect,
  useRef,
  useState,
} from "react";
import { ToastContainer, toast } from "react-toastify";

import { CONST } from "../../_config";
//import { CONST } from "../../../_config";
import axios from "axios";
import search from "../../assets/images/search.svg";
import user1 from "../../assets/images/user1.png";
import user2 from "../../assets/images/user2.png";
import user3 from "../../assets/images/user3.png";

// import search from "../../assets/images/hackathon/search.svg";
// import user1 from "../../assets/images/hackathon/user1.png";
// import user2 from "../../assets/images/hackathon/user2.png";
// import user3 from "../../assets/images/hackathon/user3.png";

const HackathonSubmission = (props) => {
  const params = useParams();
  const navigate = useNavigate();

  const location = useLocation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDd = () => setDropdownOpen((prevState) => !prevState);

  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const [HackathonDetails, setHackathonDetails] = useState([]);
  const [submissionDetails, setSubmissionDeils] = useState([]);
  const [submissionCount, setSubmissionCount] = useState();
  const [searchData, setSearchData] = useState("");
  const [loading, setLoading] = useState(false);
  const [Giturl, setGiturl] = useState("");

  useEffect(() => {
    AllSubmissionDetails();
  }, [searchData, HackathonDetails[0]?.hackthonId]);
  useEffect(() => {
    SingleHcakdetails();
  }, []);
  console.log(
    HackathonDetails[0]?.hackthonId,
    "HackathonDetailsHackathonDetails"
  );
  const AllSubmissionDetails = async () => {
    setLoading(true);
    //"64ad54852d178d26d3a5d044"
    var hackathonDetails = {
      hackathon_id: HackathonDetails[0]?.hackthonId,
      search: searchData,
    };

    let submissionData = await axios.post(
      CONST.BACKEND_URL + "users/UserSubmissionList",
      hackathonDetails
    );
    console.log(submissionData.data.data, "submissionDatasubmissionData");
    setSubmissionCount(submissionData.data.submissionCount);
    if (submissionData.data.status == true) {
      setLoading(false);
      setSubmissionDeils(submissionData?.data?.data[0]?.userDetails);
    }
  };
  const SingleHcakdetails = async () => {
    setLoading(true);
    let hackdata = {
      _id: params.id,
    };

    let getdata = await axios.post(
      CONST.BACKEND_URL + "listHackthonById",
      hackdata
    );
    console.log(getdata.data.data, "Listhacksddddddd");
    if (getdata.data.status == true) {
      setLoading(false);
      setHackathonDetails(getdata.data.data);

      setGiturl(getdata.data.data.gitUrl);
    }
  };

  console.log("Giturl", Giturl);

  return (
    <Fragment>
      {loading == true ? (
        <div className="pageloade">
          <div className="page-loader">
            <div class="loader-12">
              <span class="loader_arrow"></span>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className="submission-main">
        <div className="submission-head">
          <div>
            <h5>
              Submissions
              {/* <span>({submissionCount})</span> */}
            </h5>
          </div>
          <div className="sub-input">
            <div>
              <input
                placeholder="Search by project name "
                onChange={(e) => {
                  setSearchData(e.target.value);
                }}
              />
              <div className="searchicon">
                {/* <img src={search} alt="" className="img-fluid" /> */}
              </div>
            </div>
          </div>
        </div>
        {submissionDetails && submissionDetails.length > 0 ? (
          loading == false ? (
            submissionDetails &&
            submissionDetails.map((submissionList) => {
              return (
                <div className="submission-inrcnt">
                  <div className="submissioninr-head">
                    <div>
                      <h5>{submissionList?.project_title}</h5>
                    </div>
                    <div>
                      <p>
                        <span>by</span> {submissionList?.first_name}
                      </p>
                    </div>
                  </div>
                  <div className="themebox mt-2">
                    <div className="theme active">
                      <h5>Theme</h5>
                    </div>
                    {/* <div className="theme ml-2">
                  <h5>Theme 1</h5>
                </div> */}
                  </div>
                  <div className="sub-para">
                    <p>{submissionList?.project_description}</p>
                  </div>
                  <div className="member-user">
                    <div className="member-box">
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          navigate("/formSubmission", {
                            state: {
                              hackathonId: props.hackathonId,
                              _id: props._id,
                            },
                          })
                        }
                      >
                        View
                      </span>
                    </div>
                    <div className="userdet-main mt-sm-3 mt-md-0 ml-md-4">
                      <div className="userdet">
                        <div>
                          <img
                            src={
                              submissionList?.profile_img
                                ? submissionList?.profile_img
                                : user1
                            }
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <div>
                          <h5>{submissionList?.userName}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <></>
          )
        ) : (
          <>
            <b>
              <span>No Data Found</span>
            </b>
          </>
        )}
      </div>
    </Fragment>
  );
};

export default HackathonSubmission;
