import React, { Component, Fragment, useState, useRef, useEffect, createRef } from "react";


import axios from "axios";
import { CONST } from "../../../_config";
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
    BrowserRouter, Navigate, Route, Routes, Link, useParams
} from 'react-router-dom';
import HtmlParser from "react-html-parser";

const HackathonOverview = (props) => {

    const params = useParams();
    console.log(params)

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleDd = () => setDropdownOpen(prevState => !prevState);

    const [activeTab, setActiveTab] = useState('1');
    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    const [HackathonDetails, setHackathonDetails] = useState([])



    useEffect(() => {
        SingleHcakdetails();
        scrollWin()
    }, [])

    function scrollWin() {
        window.scrollTo(0, 0);
    }

    const SingleHcakdetails = async () => {
        let hackdata = {
            _id: params.id
        }

        let getdata = await axios.post(CONST.BACKEND_URL + 'listHackthonById', hackdata);
        console.log(getdata.data.data, "Listhacksddddddd");
        if (getdata.data.status == true) {
            // toast.success(getdata.data.message,
            //     {
            //         transition: Flip,
            //         className: 'foo-bar'
            //     })
            setHackathonDetails(getdata.data.data)

        }

    }

    return (
        <Fragment>
            {HackathonDetails && HackathonDetails.length > 0 ?
                HackathonDetails.map((data, index) => (
                    <div className="hckDtCms">

                        <h6>Overview</h6>
                        {/* <h6>Overview</h6>
                <p>TRON DAO is thrilled to launch the 2023 HackaTRON s5. This HackaTRON is designed not only to empower developers to enhance their BUIDLing skills but also to facilitate connections among the global blockchain ecosystems.</p>
                <br />
                <p>Those who are non-coders can also gain by actively participating in our TRON DAO forum. The future is not far from widespread decentralized storage, decentralized applications, digital assets, and cryptocurrency wallets. With the increasing use of decentralized, peer-to-peer, and secure networks, blockchain is becoming the backbone of Web 3.0 — the decentralized web.</p>
                <br />
                <p>
                    The HackaTRON season 5 creates opportunities for unheard talents to make their marks and accelerate the development of Web 3.0 while promoting the growth of the blockchain ecosystem at the same time.
                    Furthermore, developing on the BitTorrent Chain would allow one to easily convert the developed application to work on different blockchain platforms such as TRON, Ethereum, BNB Chain, and other blockchain platforms in the near future.
                </p>
                <br />
                <p>With a 500,000 USDD prize pool on TRON it is time to build!</p> */}
                        <p>{data ? HtmlParser(data.overview_description) : <></>}</p>

                    </div>
                )) : <></>}
        </Fragment>
    );

}

export default HackathonOverview;