import React, { Component, Fragment, useState, useRef, useEffect, createRef } from "react";
import ReactDOM from "react-dom";
import { UncontrolledCollapse } from 'reactstrap';
import classnames from 'classnames';
import Overview from '../Overview/Overview';
import NFTDocumentation from '../NFTDocumentation/nftbenifits';
import DeveloperProgram from '../DeveloperProgram/DeveloperProgram';
import {
    BrowserRouter, Navigate, Route, Routes, Link
} from 'react-router-dom';
import ScrollSpy from "react-ui-scrollspy";
import Header from "../../common/Header/Header";
import Footer from "../../common/Footer/Footer";

import downimg from '../../assets/images/pics/downimg.png';


const SidebarNew = (props) => {
    const [isActive, setActive] = useState("false");
    const ToggleClass = () => {
        setActive(!isActive);
    };

    const parentScrollContainerRef = useRef(null);
    const onPress = (e) => {
        e.preventDefault();
        const target = window.document.getElementById(
            e.currentTarget.href.split("#")[1]
        );
        if (target) {
            var headerOffset = 97;
            var elementPosition = target.getBoundingClientRect().top;
            var offsetPosition = elementPosition - headerOffset;

            window.scrollBy({
                top: offsetPosition,
                behavior: "smooth",
            });
        }
    };

    return (
        <Fragment>
            {/* <Header /> */}
            {/* <div className="pkchinsec">
                <div className="container"> */}
            {/* <div className="d-flex sidebarCnt"> */}
            <div className={isActive ? "pksidebr" : "pksidebr SideMenuOpen"}>
                <div className="sidenav">
                    <ul className="ml-0 pl-0">
                        <li><Link to="/parkdocumentation/parkintro" className="">Introduction</Link></li>
                        <li><Link to="https://tutorial.parkchain.org/parkchain-protocol/protocol-overview">Protocol Overview</Link></li>
                        <li><Link to="/parkdocumentation/parktokenomics">Tokenomics</Link></li>
                        <li><Link to="/parkdocumentation/parkproduct">Product</Link></li>
                        <li><Link to="/parkdocumentation/parkrisksecure">Risk & Security</Link></li>
                        <li><Link to="/parkdocumentation/parkdevelopers" >Developers</Link></li>
                        <li><Link to="/parkdocumentation/parksmartcontract" className="ml-3">Smart Contract</Link></li>
                        <li><Link to="/parkdocumentation/parkbridge">Bridging</Link></li>
                        <li><Link to="/parkdocumentation/parkmetamask">How to add Parkchain Network in Metamask?</Link></li>
                        <li><Link to="/parkdocumentation/parkfaq">FAQ</Link></li>
                    </ul>
                </div>
            </div>
            <svg id="hamburger" viewBox="0 0 100 100" onClick={ToggleClass}>
                <rect className={isActive ? "hamburger-line hamburger-line-1" : "hamburger-line hamburger-line-1 active"} x="20" y="50" rx="1" width="60" height="2"></rect>
                <rect className={isActive ? "hamburger-line hamburger-line-2" : "hamburger-line hamburger-line-2 active"} x="20" y="50" rx="1" width="60" height="2"></rect>
                <rect className={isActive ? "hamburger-line hamburger-line-3" : "hamburger-line hamburger-line-3 active"} x="20" y="50" rx="1" width="60" height="2"></rect>
            </svg>
            {/* 
                        <div className="pkmainsec" useRef={parentScrollContainerRef}>                        
                            <ScrollSpy scrollThrottle={100} useBoxMethod={true}>
                                <h2 className="kphedone" id="parkchain1">Parkchain:<br />
                                An Ecosystem of Blockchains</h2>
                                <p className="kpparaone">Parkchain introduces a groundbreaking solution to address the lack of a reliable and trusted source of time in current publicly available blockchains. Unlike existing systems that rely on individual nodes' local clocks without synchronization, Parkchain leverages a Proof of Authority (PoA) consensus mechanism to establish a ledger with a verifiable passage of time. By ensuring accurate duration between events and message ordering, Parkchain enables every node in the network to rely on the recorded time within the ledger without the need for blind trust. This innovative approach revolutionizes blockchain technology by providing a robust and consistent timeline for events, enhancing the overall reliability and trustworthiness of blockchain-based systems. In this paper, we will delve into the technical details of Parkchain and explore its potential applications across various industries where precise timekeeping is essential. Through Parkchain's verifiable passage of time, we aim to redefine the capabilities and possibilities of blockchain, opening up new avenues for secure and efficient decentralized systems.</p>                            

                                <h2 className="kphedtwo" id="parkchain2">What is ParkChain?</h2>
                                <p className="kpparaone">Parkchain is a revolutionary blockchain platform designed to revolutionize finance by prioritizing innovation,
                                security, and user experience. The platform allows individuals and businesses to participate in secure,
                                transparent, and efficient financial transactions. Leveraging the Parkchain protocol, Parkchain integrates the
                                strengths of the mainnet community with cutting-edge advancements in privacy, permissioning, and
                                consensus protocols. We aim to make finance more inclusive, ensuring accessibility for all, and promoting
                                trust, privacy, and innovation within the decentralized ecosystem.</p>

                                <h2 className="kphedtwo" id="parkchain3">KPK Token</h2>
                                <p className="kpparaone">The KoinPark ecosystem relies on the KoinPark token, a utility token designed to create a user-friendly, secure, and globally accessible cryptosystem. More than just a token, KPK serves as a dynamic and seamless web3 ecosystem for managing your cryptocurrency holdings, community tokens, NFTs, DeFi apps, Metaverse, and other purposes. The KPK token is built on the Binance blockchain and has a total supply of <span className="blutext">10,000,000,000 (10 billion)</span>. Token holders can expect to receive various rewards through our ongoing projects and trading exchange.</p>

                                <h2 className="kphedtwo" id="parkchain4">Key Features</h2>
                                <h4 className="kphedthree mb-1 ml-4" id="parkchain5">Public Chain</h4>
                                <p className="kpparaone ml-4">Parkchain is built on the Parkchain protocol, serving as a full implementation that runs multiple nodes on the Parkchain mainnet. By leveraging the Parkchain network effects, Parkchain benefits from the established community, robust security guarantees, and continuous innovation that Parkchain offers.</p>

                                <h4 className="kphedthree mb-1 ml-4" id="parkchain6">Privacy</h4>
                                <p className="kpparaone ml-4">Parkchain incorporates PegaSys' private transaction manager, Orion, which adheres to the Enterprise Parkchain Alliance (EPA) privacy standard. Orion employs off-chain communication and embeds a hash of private transactions on-chain. With the addition of Privacy Groups in Orion, Parkchain enables multiple companies to access a shared private state while ensuring the privacy of transaction content and participants.</p>

                                <h4 className="kphedthree mb-1 ml-4" id="parkchain7">Permissioning </h4>
                                <p className="kpparaone">Parkchain simplifies permissioning through smart contracts, providing consistency across the network. With an easy-to-use decentralized application (dApp), permissioning can be managed at both node and account levels. This feature enhances network security by enabling precise control over access and defining permissions for specific nodes and accounts.</p>

                                <h4 className="kphedthree mb-1 ml-4" id="parkchain8">Finality  </h4>
                                <p className="kpparaone ml-4">Parkchain implements various consensus protocols, allowing it to operate on the Parkchain public network, private and consortium networks, as well as multi-client test networks like Rinkeby, Ropsten, and Göerli. This flexibility ensures that transactions on Parkchain achieve finality based on the chosen network, providing users with certainty and reliability.</p>                      

                                <h2 className="kphedtwo" id="parkchain9">Future Goal</h2>
                                <p className="kpparaone">The crux of innovation never rests, and it is evident that the future of Parkchain lies in a realm with over 1 billion users. Parkchain is dedicated to constructing the framework that propels the global parallel virtual ecosystem, and its unwavering dedication to the community is reflected in the following principles:</p>
                                <ul className="pkmenu2">
                                    <li><a>Open </a></li>
                                    <li><a>Decentralized </a></li>
                                    <li><a>Permission less</a></li>
                                    <li><a>Deep Liquidity</a></li>
                                    <li><a>Multi-chain</a></li>
                                    <li><a>For inventors and creators.</a></li>
                                </ul>     
                            </ScrollSpy>               
                        </div> */}
            {/* </div> */}
            {/* </div>
            </div> */}
            {/* <Footer /> */}
        </Fragment>
    );

}

export default SidebarNew;