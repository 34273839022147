import React, { Component, Fragment, useState, useRef, useEffect, createRef } from "react";


import axios from "axios";
import { CONST } from "../../../_config";
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
    BrowserRouter, Navigate, Route, Routes, Link, useParams
} from 'react-router-dom';



const HackathonWebHosting = (props) => {


    const params = useParams();
    console.log(params)


    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleDd = () => setDropdownOpen(prevState => !prevState);

    const [activeTab, setActiveTab] = useState('1');
    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }
 const [HackathonDetails, setHackathonDetails] = useState([])



    useEffect(() => {
        SingleHcakdetails();
    },[])

    const SingleHcakdetails = async () => {
        let hackdata = {
            _id: params.id
        }

        let getdata = await axios.post(CONST.BACKEND_URL + 'listHackthonById', hackdata);
        console.log(getdata.data.data, "Listhacksddddddd");
        if (getdata.data.status == true) {
            // toast.success(getdata.data.message,
            //     {
            //         transition: Flip,
            //         className: 'foo-bar'
            //     })
            setHackathonDetails(getdata.data.data)

        }

    }


    return (
        <Fragment>
            <div className="hckDtCms">
                <h6>Free Web Hosting</h6>
                <p>The BTFS team also provides tools and solutions to assist HackaTRON S5 developers in obtaining free hosting for showcasing their projects live on the decentralized web. Please refer to the guides below and feel free to ask any questions. If you have any inquiries regarding the free decentralized web hosting opportunity provided by BTFS, please tag @BTFS on the <a href="#">TRON DAO Forum</a></p>
                <p>I. Storage3 Overview</p>
                <p>Welcome to Storage3, a specialized decentralized web hosting platform in the BTFS ecosystem that has served more than 200 websites.</p>
                <p>We are committed to providing industry-leading services and tools for developers to build, deploy, and manage websites without hassle.</p>
                <p>II. Advantages</p>
                <p>Why Storage3?</p>
                <p>Decentralization-based security: We use BTFS-based distributed storage and encryption mechanisms to protect your website data and guarantee its reliability. Your data will be stored on multiple nodes, ensuring that your site remains accessible even in the event of node failures or attacks.</p>
            </div>
        </Fragment>
    );

}

export default HackathonWebHosting;