import React, { Component, Fragment, useState, useRef, useEffect, createRef  } from "react";
import ReactDOM from "react-dom";
import classnames from 'classnames';
import './scss/pscore.scss';
import Header from "../../common/Header/Header";
import Footer from "../../common/Footer/Footer";
/** Images  **/
import corebnr from './images/corebanner.png';
import keyfeature from './images/keyfeature.png';
import networkarchitech from './images/networkarchitech.png';
import flowarchitech from './images/flowarchitechure.png';
import tokenomics from './images/tokonimics.png';


import { useSelector, useDispatch } from 'react-redux';
import HtmlParser from "react-html-parser";

const Parkscancore = (props) => {  


   let data = useSelector(state => (state.user));
   console.log(data, "kpkpkdata");

   let data1, data2, data3, data4, data5, data6, data7, data8, data9, data10, data11, data12, data13, data14, data15, data16, data17,
   data18, data19, data20, data21, data22, data23, data24, data25, data26, data27, data28, data29;

   if (data.data.status == 200) {
       data = data.data.data;

       data1 = data.find(elem => elem.title == 'Parkscan core');
       data2 = data.find(elem => elem.title == 'ParkScan core1');
       data3 = data.find(elem => elem.title == 'ParkScan core2');
       data4 = data.find(elem => elem.title == 'ParkScan core3');
       data5= data.find(elem => elem.title == 'Key Features');
       data6= data.find(elem => elem.title == 'Key Features1');
       data7= data.find(elem => elem.title == 'Network Architecture');
       data8= data.find(elem => elem.title == 'Dapp/Userflow Architecture');
       data9= data.find(elem => elem.title == 'Network');
       data10= data.find(elem => elem.title == 'Total Supply');


       
   }
console.log(data3,"data3");

   useEffect(()=>{
      scrollWin()
  },[1000])

  function scrollWin() {
      window.scrollTo(0, 0);
    }
    
return (
<Fragment>
   <Header />
   <div className="parkscan-pagecont">
      <section className="psbanner-sec">
         <div className="container">
            <div className="row align-items-center">
               <div className="col-lg-7">
                  <div className="parkscan-leftcnt">
                     <h1>{data1 ? HtmlParser((data1.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</h1>
                     <p>{data1 ? HtmlParser((data1.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                     <p>{data1 ? HtmlParser((data1.subcontentdescription).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                  </div>
               </div>
               <div className="col-lg-5 text-center mt-4">
                  <div className="parkscan-rightcnt">
                     <img src={data1 ? HtmlParser(data1.image) : <></>} alt="" className="img-fluid" />
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section className="pscore-sec py-4">
         <div className="container">
            <h2>{data1 ? HtmlParser((data1.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</h2>
            <div className="psul-cnt mt-4">
               <div className="row">
                  <div className="col-lg-6">
                     <div className="psul-inrcnt">
                        <div className="ulinr">
                           <div className="psul-icon">
                              <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                 <circle cx="15" cy="15" r="15" fill="url(#paint0_linear_802_5378)"/>
                                 <path d="M13.3183 19.6424C12.9532 19.6424 12.5881 19.5079 12.2999 19.2197L9.4179 16.3377C8.8607 15.7805 8.8607 14.8582 9.4179 14.3011C9.97509 13.7439 10.8973 13.7439 11.4545 14.3011L13.3567 16.2032L18.5828 11.3806C19.1592 10.8426 20.0814 10.881 20.6194 11.4574C21.1574 12.0338 21.119 12.9561 20.5426 13.4941L14.2981 19.2582C14.0292 19.5079 13.6833 19.6424 13.3183 19.6424Z" fill="#0066FF"/>
                                 <defs>
                                    <linearGradient id="paint0_linear_802_5378" x1="31.2431" y1="24.5116" x2="-8.54756" y2="22.0479" gradientUnits="userSpaceOnUse">
                                       <stop stop-color="#0066FF" stop-opacity="0.08"/>
                                       <stop offset="1" stop-color="#0066FF" stop-opacity="0.28"/>
                                    </linearGradient>
                                 </defs>
                              </svg>
                           </div>
                           <div className="psul-txt">
                              {data2 ? HtmlParser((data2.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}
                           </div>
                        </div>
                        <div className="ulinr">
                           <div className="psul-icon">
                              <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                 <circle cx="15" cy="15" r="15" fill="url(#paint0_linear_802_5378)"/>
                                 <path d="M13.3183 19.6424C12.9532 19.6424 12.5881 19.5079 12.2999 19.2197L9.4179 16.3377C8.8607 15.7805 8.8607 14.8582 9.4179 14.3011C9.97509 13.7439 10.8973 13.7439 11.4545 14.3011L13.3567 16.2032L18.5828 11.3806C19.1592 10.8426 20.0814 10.881 20.6194 11.4574C21.1574 12.0338 21.119 12.9561 20.5426 13.4941L14.2981 19.2582C14.0292 19.5079 13.6833 19.6424 13.3183 19.6424Z" fill="#0066FF"/>
                                 <defs>
                                    <linearGradient id="paint0_linear_802_5378" x1="31.2431" y1="24.5116" x2="-8.54756" y2="22.0479" gradientUnits="userSpaceOnUse">
                                       <stop stop-color="#0066FF" stop-opacity="0.08"/>
                                       <stop offset="1" stop-color="#0066FF" stop-opacity="0.28"/>
                                    </linearGradient>
                                 </defs>
                              </svg>
                           </div>
                           <div className="psul-txt">
                           {data2 ? HtmlParser((data2.subcontentdescription).replace(/(<([^>]+)>)/gi, "")) : <></>}
                           </div>
                        </div>
                        <div className="ulinr">
                           <div className="psul-icon">
                              <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                 <circle cx="15" cy="15" r="15" fill="url(#paint0_linear_802_5378)"/>
                                 <path d="M13.3183 19.6424C12.9532 19.6424 12.5881 19.5079 12.2999 19.2197L9.4179 16.3377C8.8607 15.7805 8.8607 14.8582 9.4179 14.3011C9.97509 13.7439 10.8973 13.7439 11.4545 14.3011L13.3567 16.2032L18.5828 11.3806C19.1592 10.8426 20.0814 10.881 20.6194 11.4574C21.1574 12.0338 21.119 12.9561 20.5426 13.4941L14.2981 19.2582C14.0292 19.5079 13.6833 19.6424 13.3183 19.6424Z" fill="#0066FF"/>
                                 <defs>
                                    <linearGradient id="paint0_linear_802_5378" x1="31.2431" y1="24.5116" x2="-8.54756" y2="22.0479" gradientUnits="userSpaceOnUse">
                                       <stop stop-color="#0066FF" stop-opacity="0.08"/>
                                       <stop offset="1" stop-color="#0066FF" stop-opacity="0.28"/>
                                    </linearGradient>
                                 </defs>
                              </svg>
                           </div>
                           <div className="psul-txt">
                           {data2 ? HtmlParser((data2.meta_description).replace(/(<([^>]+)>)/gi, "")) : <></>}
                           </div>
                        </div>
                        <div className="ulinr">
                           <div className="psul-icon">
                              <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                 <circle cx="15" cy="15" r="15" fill="url(#paint0_linear_802_5378)"/>
                                 <path d="M13.3183 19.6424C12.9532 19.6424 12.5881 19.5079 12.2999 19.2197L9.4179 16.3377C8.8607 15.7805 8.8607 14.8582 9.4179 14.3011C9.97509 13.7439 10.8973 13.7439 11.4545 14.3011L13.3567 16.2032L18.5828 11.3806C19.1592 10.8426 20.0814 10.881 20.6194 11.4574C21.1574 12.0338 21.119 12.9561 20.5426 13.4941L14.2981 19.2582C14.0292 19.5079 13.6833 19.6424 13.3183 19.6424Z" fill="#0066FF"/>
                                 <defs>
                                    <linearGradient id="paint0_linear_802_5378" x1="31.2431" y1="24.5116" x2="-8.54756" y2="22.0479" gradientUnits="userSpaceOnUse">
                                       <stop stop-color="#0066FF" stop-opacity="0.08"/>
                                       <stop offset="1" stop-color="#0066FF" stop-opacity="0.28"/>
                                    </linearGradient>
                                 </defs>
                              </svg>
                           </div>
                           <div className="psul-txt">
                           {data3 ? HtmlParser((data3.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-6">
                     <div className="psul-inrcnt">
                        <div className="ulinr">
                           <div className="psul-icon">
                              <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                 <circle cx="15" cy="15" r="15" fill="url(#paint0_linear_802_5378)"/>
                                 <path d="M13.3183 19.6424C12.9532 19.6424 12.5881 19.5079 12.2999 19.2197L9.4179 16.3377C8.8607 15.7805 8.8607 14.8582 9.4179 14.3011C9.97509 13.7439 10.8973 13.7439 11.4545 14.3011L13.3567 16.2032L18.5828 11.3806C19.1592 10.8426 20.0814 10.881 20.6194 11.4574C21.1574 12.0338 21.119 12.9561 20.5426 13.4941L14.2981 19.2582C14.0292 19.5079 13.6833 19.6424 13.3183 19.6424Z" fill="#0066FF"/>
                                 <defs>
                                    <linearGradient id="paint0_linear_802_5378" x1="31.2431" y1="24.5116" x2="-8.54756" y2="22.0479" gradientUnits="userSpaceOnUse">
                                       <stop stop-color="#0066FF" stop-opacity="0.08"/>
                                       <stop offset="1" stop-color="#0066FF" stop-opacity="0.28"/>
                                    </linearGradient>
                                 </defs>
                              </svg>
                           </div>
                           <div className="psul-txt">
                           {data3 ? HtmlParser((data3.subcontentdescription).replace(/(<([^>]+)>)/gi, "")) : <></>}
                           </div>
                        </div>
                        <div className="ulinr">
                           <div className="psul-icon">
                              <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                 <circle cx="15" cy="15" r="15" fill="url(#paint0_linear_802_5378)"/>
                                 <path d="M13.3183 19.6424C12.9532 19.6424 12.5881 19.5079 12.2999 19.2197L9.4179 16.3377C8.8607 15.7805 8.8607 14.8582 9.4179 14.3011C9.97509 13.7439 10.8973 13.7439 11.4545 14.3011L13.3567 16.2032L18.5828 11.3806C19.1592 10.8426 20.0814 10.881 20.6194 11.4574C21.1574 12.0338 21.119 12.9561 20.5426 13.4941L14.2981 19.2582C14.0292 19.5079 13.6833 19.6424 13.3183 19.6424Z" fill="#0066FF"/>
                                 <defs>
                                    <linearGradient id="paint0_linear_802_5378" x1="31.2431" y1="24.5116" x2="-8.54756" y2="22.0479" gradientUnits="userSpaceOnUse">
                                       <stop stop-color="#0066FF" stop-opacity="0.08"/>
                                       <stop offset="1" stop-color="#0066FF" stop-opacity="0.28"/>
                                    </linearGradient>
                                 </defs>
                              </svg>
                           </div>
                           <div className="psul-txt">
                           {data3 ? HtmlParser((data3.meta_description).replace(/(<([^>]+)>)/gi, "")) : <></>}
                           </div>
                        </div>
                        <div className="ulinr">
                           <div className="psul-icon">
                              <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                 <circle cx="15" cy="15" r="15" fill="url(#paint0_linear_802_5378)"/>
                                 <path d="M13.3183 19.6424C12.9532 19.6424 12.5881 19.5079 12.2999 19.2197L9.4179 16.3377C8.8607 15.7805 8.8607 14.8582 9.4179 14.3011C9.97509 13.7439 10.8973 13.7439 11.4545 14.3011L13.3567 16.2032L18.5828 11.3806C19.1592 10.8426 20.0814 10.881 20.6194 11.4574C21.1574 12.0338 21.119 12.9561 20.5426 13.4941L14.2981 19.2582C14.0292 19.5079 13.6833 19.6424 13.3183 19.6424Z" fill="#0066FF"/>
                                 <defs>
                                    <linearGradient id="paint0_linear_802_5378" x1="31.2431" y1="24.5116" x2="-8.54756" y2="22.0479" gradientUnits="userSpaceOnUse">
                                       <stop stop-color="#0066FF" stop-opacity="0.08"/>
                                       <stop offset="1" stop-color="#0066FF" stop-opacity="0.28"/>
                                    </linearGradient>
                                 </defs>
                              </svg>
                           </div>
                           <div className="psul-txt">
                           {data4 ? HtmlParser((data4.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}
                           </div>
                        </div>
                        <div className="ulinr">
                           <div className="psul-icon">
                              <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                 <circle cx="15" cy="15" r="15" fill="url(#paint0_linear_802_5378)"/>
                                 <path d="M13.3183 19.6424C12.9532 19.6424 12.5881 19.5079 12.2999 19.2197L9.4179 16.3377C8.8607 15.7805 8.8607 14.8582 9.4179 14.3011C9.97509 13.7439 10.8973 13.7439 11.4545 14.3011L13.3567 16.2032L18.5828 11.3806C19.1592 10.8426 20.0814 10.881 20.6194 11.4574C21.1574 12.0338 21.119 12.9561 20.5426 13.4941L14.2981 19.2582C14.0292 19.5079 13.6833 19.6424 13.3183 19.6424Z" fill="#0066FF"/>
                                 <defs>
                                    <linearGradient id="paint0_linear_802_5378" x1="31.2431" y1="24.5116" x2="-8.54756" y2="22.0479" gradientUnits="userSpaceOnUse">
                                       <stop stop-color="#0066FF" stop-opacity="0.08"/>
                                       <stop offset="1" stop-color="#0066FF" stop-opacity="0.28"/>
                                    </linearGradient>
                                 </defs>
                              </svg>
                           </div>
                           <div className="psul-txt">
                           {data4 ? HtmlParser((data4.subcontentdescription).replace(/(<([^>]+)>)/gi, "")) : <></>}
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section className="keyfeature-sec">
         <div className="container">
            <h2>{data5 ? HtmlParser((data5.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</h2>
            <h5>{data5 ? HtmlParser((data5.subtitle).replace(/(<([^>]+)>)/gi, "")) : <></>}</h5>
            <div className="keyfeature-inrcnt">
               <p>{data5 ? HtmlParser((data5.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
               <p>{data5 ? HtmlParser((data5.subcontentdescription).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
               <p>{data5 ? HtmlParser((data5.meta_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
            </div>
            <div className="keyfeature-img">
               <img src={data5 ? HtmlParser(data5.image):<></>} alt="" className="img-fluid" />
            </div>
            <div className="row">
               <div className="col-lg-4">
                  <div className="ulinr">
                     <div className="psul-icon">
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <circle cx="15" cy="15" r="15" fill="url(#paint0_linear_802_5378)"/>
                           <path d="M13.3183 19.6424C12.9532 19.6424 12.5881 19.5079 12.2999 19.2197L9.4179 16.3377C8.8607 15.7805 8.8607 14.8582 9.4179 14.3011C9.97509 13.7439 10.8973 13.7439 11.4545 14.3011L13.3567 16.2032L18.5828 11.3806C19.1592 10.8426 20.0814 10.881 20.6194 11.4574C21.1574 12.0338 21.119 12.9561 20.5426 13.4941L14.2981 19.2582C14.0292 19.5079 13.6833 19.6424 13.3183 19.6424Z" fill="#0066FF"/>
                           <defs>
                              <linearGradient id="paint0_linear_802_5378" x1="31.2431" y1="24.5116" x2="-8.54756" y2="22.0479" gradientUnits="userSpaceOnUse">
                                 <stop stop-color="#0066FF" stop-opacity="0.08"/>
                                 <stop offset="1" stop-color="#0066FF" stop-opacity="0.28"/>
                              </linearGradient>
                           </defs>
                        </svg>
                     </div>
                     <div className="psul-txt">
                        <h5>{data6 ? HtmlParser((data6.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</h5>
                     </div>
                  </div>
               </div>
               <div className="col-lg-4">
                  <div className="ulinr">
                     <div className="psul-icon">
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <circle cx="15" cy="15" r="15" fill="url(#paint0_linear_802_5378)"/>
                           <path d="M13.3183 19.6424C12.9532 19.6424 12.5881 19.5079 12.2999 19.2197L9.4179 16.3377C8.8607 15.7805 8.8607 14.8582 9.4179 14.3011C9.97509 13.7439 10.8973 13.7439 11.4545 14.3011L13.3567 16.2032L18.5828 11.3806C19.1592 10.8426 20.0814 10.881 20.6194 11.4574C21.1574 12.0338 21.119 12.9561 20.5426 13.4941L14.2981 19.2582C14.0292 19.5079 13.6833 19.6424 13.3183 19.6424Z" fill="#0066FF"/>
                           <defs>
                              <linearGradient id="paint0_linear_802_5378" x1="31.2431" y1="24.5116" x2="-8.54756" y2="22.0479" gradientUnits="userSpaceOnUse">
                                 <stop stop-color="#0066FF" stop-opacity="0.08"/>
                                 <stop offset="1" stop-color="#0066FF" stop-opacity="0.28"/>
                              </linearGradient>
                           </defs>
                        </svg>
                     </div>
                     <div className="psul-txt">
                        <h5>{data6 ? HtmlParser((data6.subcontentdescription).replace(/(<([^>]+)>)/gi, "")) : <></>}</h5>
                     </div>
                  </div>
               </div>
               <div className="col-lg-4">
                  <div className="ulinr">
                     <div className="psul-icon">
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <circle cx="15" cy="15" r="15" fill="url(#paint0_linear_802_5378)"/>
                           <path d="M13.3183 19.6424C12.9532 19.6424 12.5881 19.5079 12.2999 19.2197L9.4179 16.3377C8.8607 15.7805 8.8607 14.8582 9.4179 14.3011C9.97509 13.7439 10.8973 13.7439 11.4545 14.3011L13.3567 16.2032L18.5828 11.3806C19.1592 10.8426 20.0814 10.881 20.6194 11.4574C21.1574 12.0338 21.119 12.9561 20.5426 13.4941L14.2981 19.2582C14.0292 19.5079 13.6833 19.6424 13.3183 19.6424Z" fill="#0066FF"/>
                           <defs>
                              <linearGradient id="paint0_linear_802_5378" x1="31.2431" y1="24.5116" x2="-8.54756" y2="22.0479" gradientUnits="userSpaceOnUse">
                                 <stop stop-color="#0066FF" stop-opacity="0.08"/>
                                 <stop offset="1" stop-color="#0066FF" stop-opacity="0.28"/>
                              </linearGradient>
                           </defs>
                        </svg>
                     </div>
                     <div className="psul-txt">
                        <h5>{data6 ? HtmlParser((data6.meta_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</h5>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section className="architechture-sec">
         <div className="container">
            <h2>{data7 ? HtmlParser(data7.title):<></>}</h2>
            <div className="architech-img mt-2 mb-5">
               <img src={data7 ? HtmlParser(data7.image):<></>} alt="" className="img-fluid"/>    
            </div>
         </div>
      </section>
      <section className="architechture-sec">
         <div className="container">
            <h2>{data8 ? HtmlParser(data8.title):<></>}</h2>
            <div className="architech-img mt-3 mb-5 text-center">
               <img src={data8 ? HtmlParser(data8.image):<></>} alt="" className="img-fluid"/>    
            </div>
         </div>
      </section>
      <section className="tokenomics-sec">
         <div className="container">
            <h2>{data8 ? HtmlParser(data8.subtitle):<></>}</h2>
            <div className="row my-4 pt-4">
               <div className="col-lg-3">
                  <div className="tokenomics-cnt tokenomics-right">
                     <div className="tokenomice-grid">
                        <div className="token-cnt">
                           <h4>{data9 ? HtmlParser(data9.title):<></>}</h4>
                           <p>{data9 ? HtmlParser(data9.content_description):<></>}</p>
                        </div>
                        <div className="token-icon">
                           <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect opacity="0.2" width="30" height="30" rx="15" fill="#456AFF"/>
                              <rect x="8" y="8" width="14" height="14" rx="7" fill="#456AFF"/>
                           </svg>
                        </div>
                     </div>
                     <div className="tokenomice-grid">
                        <div className="token-cnt">
                           <h4>{data9 ? HtmlParser(data9.subtitle):<></>}</h4>
                           <p>{data9 ? HtmlParser(data9.subcontentdescription):<></>}</p>
                        </div>
                        <div className="token-icon">
                           <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect opacity="0.2" width="30" height="30" rx="15" fill="#456AFF"/>
                              <rect x="8" y="8" width="14" height="14" rx="7" fill="#456AFF"/>
                           </svg>
                        </div>
                     </div>
                     <div className="tokenomice-grid">
                        <div className="token-cnt">
                           <h4>{data9 ? HtmlParser(data9.subtitle1):<></>}</h4>
                           <p>{data9 ? HtmlParser(data9.meta_description):<></>}</p>
                        </div>
                        <div className="token-icon">
                           <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect opacity="0.2" width="30" height="30" rx="15" fill="#456AFF"/>
                              <rect x="8" y="8" width="14" height="14" rx="7" fill="#456AFF"/>
                           </svg>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="col-lg-6">
                  <div className="text-center">
                     <img src={data8 ? HtmlParser(data8.sub_image):<></>} alt="" className="img-fluid"/>
                  </div>
               </div>
               <div className="col-lg-3">
                  <div className="tokenomics-cnt tokenomics-left">
                     <div className="tokenomice-grid">
                        <div className="token-icon">
                           <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect opacity="0.2" width="30" height="30" rx="15" fill="#456AFF"/>
                              <rect x="8" y="8" width="14" height="14" rx="7" fill="#456AFF"/>
                           </svg>
                        </div>
                        <div className="token-cnt">
                           <h4>{data10 ? HtmlParser(data10.title):<></>}</h4>
                           <p>{data10 ? HtmlParser(data10.content_description):<></>}</p>
                        </div>
                     </div>
                     <div className="tokenomice-grid">
                        <div className="token-icon">
                           <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect opacity="0.2" width="30" height="30" rx="15" fill="#456AFF"/>
                              <rect x="8" y="8" width="14" height="14" rx="7" fill="#456AFF"/>
                           </svg>
                        </div>
                        <div className="token-cnt">
                           <h4>{data10 ? HtmlParser(data10.subtitle):<></>}</h4>
                           <p>{data10 ? HtmlParser(data10.subcontentdescription):<></>}</p>
                        </div>
                     </div>
                     <div className="tokenomice-grid">
                        <div className="token-icon">
                           <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect opacity="0.2" width="30" height="30" rx="15" fill="#456AFF"/>
                              <rect x="8" y="8" width="14" height="14" rx="7" fill="#456AFF"/>
                           </svg>
                        </div>
                        <div className="token-cnt">
                           <h4>{data10 ? HtmlParser(data10.subtitle1):<></>}</h4>
                           <p>{data10 ? HtmlParser(data10.meta_description):<></>}</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
   </div>
   <Footer />
</Fragment>
);
}
export default Parkscancore;