import React, { Component, Fragment, useState, useRef, useEffect, createRef  } from "react";
import ReactDOM from "react-dom";
import { UncontrolledCollapse } from 'reactstrap';
import classnames from 'classnames';
import Overview from '../Overview/Overview';
import NFTDocumentation from '../NFTDocumentation/nftbenifits';
import DeveloperProgram from '../DeveloperProgram/DeveloperProgram';
import {
	BrowserRouter, Navigate, Route, Routes, Link
 } from 'react-router-dom';
 import ScrollSpy from "react-ui-scrollspy";
import Header from "../../common/Header/Header";
import Footer from "../../common/Footer/Footer";

import downimg from '../../assets/images/pics/downimg.png';


const Home = (props) => {  
    const [isActive, setActive] = useState("false");
    const ToggleClass = () => {
        setActive(!isActive); 
    };

    const parentScrollContainerRef = useRef<HTMLDivElement | null>(null);
    const onPress = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        const target = window.document.getElementById(
          e.currentTarget.href.split("#")[1]
        );
        if (target) {
          var headerOffset = 97;
          var elementPosition = target.getBoundingClientRect().top;
          var offsetPosition = elementPosition - headerOffset;
    
          window.scrollBy({
            top: offsetPosition,
            behavior: "smooth",
          });
        }
      };

    return (
        <Fragment>
            <Header />
            <div className="pkchinsec">
                <div className="container">
                    <div className="d-flex sidebarCnt">
                        <div className={isActive ? "pksidebr" : "pksidebr SideMenuOpen"}>
                            <div className="sidenav">
                                <ul className="ml-0 pl-0">
                                    <li><a data-to-scrollspy-id="parkchain1" onClick={(e) => onPress(e)} href={"#parkchain1"}>What are contracts?</a></li>
                                    <li><a data-to-scrollspy-id="parkchain2" onClick={(e) => onPress(e)} href={"#parkchain2"}>Trust and Contracts</a></li>
                                    <li><a data-to-scrollspy-id="parkchain3" onClick={(e) => onPress(e)} href={"#parkchain3"}>Smart contracts</a></li>
                                    <li><a data-to-scrollspy-id="parkchain4" onClick={(e) => onPress(e)} href={"#parkchain4"}>A digital vending machine</a></li>
                                    <li><a data-to-scrollspy-id="parkchain5" onClick={(e) => onPress(e)} href={"#parkchain5"}>Automatic execution</a></li>
                                    <li><a data-to-scrollspy-id="parkchain6" onClick={(e) => onPress(e)} href={"#parkchain6"}>Predictable outcomes</a></li>
                                    <li><a data-to-scrollspy-id="parkchain7" onClick={(e) => onPress(e)} href={"#parkchain7"}>Public record</a></li>
                                    <li><a data-to-scrollspy-id="parkchain8" onClick={(e) => onPress(e)} href={"#parkchain8"}>Privacy protection</a></li>
                                    <li><a data-to-scrollspy-id="parkchain9" onClick={(e) => onPress(e)} href={"#parkchain9"}>Visible terms</a></li>
                                    <li><a data-to-scrollspy-id="parkchain10" onClick={(e) => onPress(e)} href={"#parkchain10"}>Smart contract use cases</a></li>
                                </ul>
                            </div>                            
                        </div>
                        <svg id="hamburger" viewBox="0 0 100 100" onClick={ToggleClass}>
                            <rect className={isActive ? "hamburger-line hamburger-line-1" : "hamburger-line hamburger-line-1 active"} x="20" y="50" rx="1" width="60" height="2"></rect>
                            <rect className={isActive ? "hamburger-line hamburger-line-2" : "hamburger-line hamburger-line-2 active"} x="20" y="50" rx="1" width="60" height="2"></rect>
                            <rect className={isActive ? "hamburger-line hamburger-line-3" : "hamburger-line hamburger-line-3 active"} x="20" y="50" rx="1" width="60" height="2"></rect>
                        </svg>

                        <div className="pkmainsec" useRef={parentScrollContainerRef}>                        
                            <ScrollSpy scrollThrottle={100} useBoxMethod={true}>
                                <h2 className="kphedone">Introduction to smart contracts</h2>
                                <p className="kpparaone">Smart contracts serve as the foundational elements of Parkchain applications, functioning as computer programs stored on the blockchain. They enable the transformation of conventional contracts into their digital equivalents. These contracts operate on a highly logical basis, adhering to an "if this, then that" structure. Consequently, they execute precisely as programmed and remain immutable, incapable of alteration.</p>
                                <p className="kpparaone">The term "Smart contract" was coined by Nick Szabo, who introduced the concept in 1994 and explored its potential in 1996. Szabo envisioned a digital marketplace constructed upon automated processes that possess cryptographic security. Such a marketplace would facilitate trustless transactions and business operations, eliminating the need for intermediaries. Parkchain's implementation of smart contracts realizes this visionary concept.</p>

                                <h4 className="kphedtwo" id="parkchain1">What are contracts?</h4>
                                <p className="kpparaone">You might be wondering, "I'm not a legal expert, so why should I be interested in contracts?". When most people think of contracts, they often associate them with excessively lengthy terms and conditions or tedious legal paperwork.</p>
                                <p className="kpparaone">Contracts are simply agreements. In fact, any type of agreement can be formalized within the framework of a contract. While verbal agreements or handwritten contracts may suffice in certain situations, they do have their limitations.</p>

                                <h4 className="kphedtwo" id="parkchain2">Trust and Contracts</h4>
                                <p className="kpparaone">One of the primary challenges associated with traditional contracts is the reliance on trustworthy individuals to uphold the outcomes specified in the contract.</p>
                                <p className="kpparaone">Consider this scenario: Alice and Bob are engaged in a bicycle race. Let's suppose Alice wagers $10 with Bob, betting that she will emerge as the race's winner. Bob, confident in his abilities, agrees to the bet. Ultimately, Alice finishes the race well ahead of Bob, establishing herself as the undeniable winner. However, Bob refuses to honor the bet, alleging that Alice must have cheated.</p>
                                <p className="kpparaone">This example highlights the inherent issue with non-smart agreements. Even if the conditions outlined in the agreement are fulfilled (e.g., winning the race), one still needs to place trust in the other person to fulfill their end of the agreement (e.g., paying out on the bet)</p>

                                <h4 className="kphedtwo" id="parkchain3">Smart contracts</h4>
                                <p className="kpparaone">Smart contracts transform agreements into computer code, enabling automatic execution when the specified terms of the contract are fulfilled.</p>

                                <h4 className="kphedtwo" id="parkchain4">A digital vending machine</h4>
                                <p className="kpparaone">A digital vending machine serves as a convenient analogy for understanding a smart contract. Like a smart contract, a vending machine operates on the principle that specific inputs lead to predetermined outputs. Here's how the comparison holds:</p>
                                <ol className="pkmenu2">
                                    <li>Choosing a product: Just as you make a selection in a vending machine, you decide on a desired product within a smart contract.</li>
                                    <li>Obtaining the required amount: Similar to how the vending machine calculates and displays the necessary payment, a smart contract determines the amount needed to fulfill the transaction.</li>
                                    <li>Inserting the correct amount: In the case of a vending machine, you must insert the exact payment. Likewise, a smart contract verifies if you have provided the correct funds.</li>
                                    <li>Verification of payment: The vending machine ensures that you have inserted the correct amount. Similarly, a smart contract validates the adequacy of your payment.</li>
                                    <li>Dispensing the chosen product: When all the requirements are met, the vending machine dispenses the product you selected. Similarly, a smart contract releases your desired outcome or result.</li>
                                    <li>For inventors and creators.</li>
                                </ol>
                                <p className="kpparaone">The vending machine, just like a smart contract, strictly follows the predefined rules. If you fail to select a product or provide enough money, the vending machine will not provide the desired item.</p>

                                <h2 className="kphedtwo" id="parkchain5">Automatic execution</h2>
                                <p className="kpparaone">One of the key advantages of smart contracts over traditional contracts is their ability to automatically execute the specified outcome once the contract conditions are met. There is no requirement to rely on human intervention for executing the result. Smart contracts annihilate the need for trust.</p>
                                <p className="kpparaone">For instance, a smart contract can be created to hold funds in escrow for a child, enabling them to withdraw the funds only after a specific date. If an attempt is made to withdraw the funds prior to the designated date, the smart contract will not carry out the execution. Similarly, a contract can be written to automatically provide you with a digital version of a car's title once you have made the payment to the dealer.</p>
                                
                                <h2 className="kphedtwo" id="parkchain6">Predictable outcomes</h2>
                                <p className="kpparaone">Traditional contracts often fail due to the human element, which can lead to different interpretations by different individuals, such as judges. As a result, varying decisions and outcomes may arise. In contrast, smart contracts eliminate the potential for divergent interpretations. They strictly adhere to the conditions outlined in their code, ensuring precise execution. This precision guarantees consistent results under identical circumstances.</p>

                                <h2 className="kphedtwo" id="parkchain7">Public record</h2>
                                <p className="kpparaone">Smart contracts serve a valuable purpose in terms of audits and monitoring. Parkchain smart contracts operate on a public blockchain, individuals have the ability to track asset transfers promptly and associated details. For example, it is possible to verify if someone has sent funds to your address.</p>
                                
                                <h2 className="kphedtwo" id="parkchain8">Privacy protection</h2>
                                <p className="kpparaone">Smart contracts can safeguard your privacy as well. Parkchain operates on a pseudonymous network, whereby your transactions are linked publicly to a distinct cryptographic address rather than your personal identity, you have the means to shield your privacy from prying eyes.</p>
                                
                                <h2 className="kphedtwo" id="parkchain9">Visible terms</h2>
                                <p className="kpparaone">Smart contracts allow you to review their contents before signing or engaging with them. Furthermore, the contract's terms are openly available for public examination, enabling anyone to examine them thoroughly.</p>
                                
                                <h2 className="kphedtwo" id="parkchain10">Smart contract use cases</h2>
                                <p className="kpparaone">Smart contracts are computer programs residing on the blockchain that possess the ability to execute automatically. These contracts enable the tracking of transactions, offer predictability in their behavior, and allow for pseudonymous utilization, which is quite fascinating. But what exactly are their practical applications? Well, smart contracts have the capability to perform a wide range of tasks similar to other computer programs. They can carry out computations, establish digital currencies, store data, generate non-fungible tokens (NFTs), facilitate communication, and even produce graphics. Below are some popular and real-world examples of smart contract usage:</p>
                                <ul className="pkmenu2">
                                    <li>Stablecoins</li>
                                    <li>Creation and distribution of unique digital assets</li>
                                    <li>Implementation of an automatic and open currency exchange</li>
                                    <li>Development of decentralized gaming platforms</li>
                                    <li>Automatic payout insurance policies</li>
                                    <li>A standardized framework enabling individuals to create personalized and interoperable currencies.</li>
                                </ul>


                        <div class="bnrLks text-center">
               <Link to="/developerprogram" class="btn btn-12040 btn-bluFl text-center">
                  Explore
               </Link>
            </div>

                            </ScrollSpy>               
                        </div>


                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
   
}

export default Home;