import React, { Component, Fragment, useState, useRef, useEffect, createRef } from "react";
import ReactDOM from "react-dom";
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import Overview from '../Overview/Overview';
import NFTDocumentation from '../NFTDocumentation/NFTDocumentation';
import DeveloperProgram from '../DeveloperProgram/DeveloperProgram';
import {
    BrowserRouter, Navigate, Route, Routes, Link
} from 'react-router-dom';

import Header from "../../common/Header/Header";
import Footer from "../../common/Footer/Footer";

import kpuser from '../../assets/images/pics/kpuser.png';
import readarrow from '../../assets/images/pics/readarrow.png';
import uparrow from '../../assets/images/pics/uparrow.png';
import downarrow from '../../assets/images/pics/downarrow.png';
import kpblogimg1 from '../../assets/images/pics/kpblogimg1.png';
import kpblogimg2 from '../../assets/images/pics/kpblogimg2.png';
import kpblogimg3 from '../../assets/images/pics/kpblogimg3.png';
import { useDispatch } from "react-redux";
import { CONST } from "../../_config";
import axios from "axios";
import HtmlParser from "react-html-parser";

const Home = (props) => {
    const [activeTab, setActiveTab] = useState('1');
    const [blogdetails, setBlog] = useState([]);
    const [moreOption, setMoreOption] = useState('');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);

    }


    useEffect(() => {
        getBlog();
    }, [])

    const getBlog = async () => {

        sessionStorage.removeItem('count')
        let data = {
            "pageNo": 0,
            "pageSize": 10,
            "type": "Blog",
            "sub_type": "ALL"
        }

        let getnews = await axios.post('https://usrbaklifhbeje.koinpark.com/coin_detail/get_all_blog_detail_check', data);
        console.log(getnews);
        if (getnews.data.status == 1) {
            setBlog(getnews.data.data);
            setMoreOption(getnews.data.moreOption)

        }
    }
  
    const getBlogDetails = async (pageNo) => {

        if (pageNo == '1') {
            sessionStorage.setItem('count', Number(sessionStorage.getItem('count')) - 1)
        }
        else if (pageNo == '2') {
            sessionStorage.setItem('count', Number(sessionStorage.getItem('count')) + 1)
        }
       
        let data = {
            "pageNo": Number(sessionStorage.getItem('count')),
            "pageSize": 10,
            "type": "Blog",
            "sub_type": "ALL"
        }

        let getnews = await axios.post('https://usrbaklifhbeje.koinpark.com/coin_detail/get_all_blog_detail_check', data);
        console.log(getnews);
        if (getnews.data.status == 1) {
            setBlog(getnews.data.data);
            setMoreOption(getnews.data.moreOption)
        }
    }
    return (
        <Fragment>
            <Header />
            <div className="pkchinsec">
                <section className="blogbanner">
                    <div className="container">
                        <h2 className="kpbloghead">Blog</h2>
                    </div>
                </section>
                <div className="container">
                    <div className="row mt-5">
                        {blogdetails && blogdetails.length > 0 ?
                            blogdetails.map((data, index) => (
                                <div className="col-lg-6 col-md-6 col-sm-12 mb-5">
                                    <div className="kpblkChain">
                                        <div className="kpblogbx">
                                            <img src={data ? data.image : <></>} className="blogImg" />
                                            {/* <div className="kpbloguser">
                                     <div className="d-flex align-items-start">
                                         <img src={kpuser} alt="" />
                                         <div className="px-3">
                                             <h5 className="mb-0">CHRISTOPHER DUGGAN</h5>
                                             <h5 className="mb-0">9 JUN 2023 • 3 MIN READ</h5>
                                         </div>
                                     </div>
                                 </div> */}
                                        </div>
                                        <div className="kpblogcnt">
                                            {/* <h6>ECOSYSTEM</h6> */}
                                            <h3>{data ? data.title : <></>}</h3>
                                            <p>{data ? (((data.content).replace(/(<([^>]+)>)/gi, "")).slice(0,310))+'...' : <></>}</p>
                                            <Link to={`/kpblogdetails/${data.route_url}`}><span className="readmoretxt">Read More <span><img src={readarrow} alt="" /></span></span></Link>
                                        </div>
                                    </div>
                                </div>)) : <></>
                        }
                        {/* <div className="col-lg-6 col-md-6 col-sm-12 mb-5">
                            <div className="kpblkChain">
                                <div className="kpblogbx">
                                    <img src={kpblogimg1} className="blogImg" />
                                    <div className="kpbloguser">
                                        <div className="d-flex align-items-start">
                                            <img src={kpuser} alt="" />
                                            <div className="px-3">
                                                <h5 className="mb-0">CHRISTOPHER DUGGAN</h5>
                                                <h5 className="mb-0">9 JUN 2023 • 3 MIN READ</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="kpblogcnt">
                                    <h6>ECOSYSTEM</h6>
                                    <h3>Why Blockchain is totally worth the hype?</h3>
                                    <p>Blockchain, the industry that evolved with the introduction of Bitcoin, has developed into today’s one of the most ground-breaking technologies that hold the potential to change the entire dynamics of every industry in the world.</p>
                                    <a href="#"><span className="readmoretxt">Read More <span><img src={readarrow} alt="" /></span></span></a>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="col-lg-6 col-md-6 col-sm-12 mb-5">
                            <div className="kpblkChain">
                                <div className="kpblogbx kpblogbx1">
                                    <img src={kpblogimg2} className="blogImg" />
                                    <div className="kpbloguser">
                                        <div className="d-flex align-items-start">
                                            <img src={kpuser} alt="" />
                                            <div className="px-3">
                                                <h5 className="mb-0">CHRISTOPHER DUGGAN</h5>
                                                <h5 className="mb-0">9 JUN 2023 • 3 MIN READ</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="kpblogcnt">
                                    <h6>COMMUNITY</h6>
                                    <h3>imple method to assess your next
                                        crypto investment
                                    </h3>
                                    <p>cryptocurrency is fascinating to look at from the outside, but it's a bit like the wild west in some ways. Central bank digital currency plans prompt banks to wade in; So, if you're a gamer and you already...</p>
                                    <a href="#"><span className="readmoretxt">Read More <span><img src={readarrow} alt="" /></span></span></a>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    {/* <div className="horizonline"></div>
                    <div className="row mt-5">
                        <div className="col-lg-6 col-md-6 col-sm-12 mb-5">
                            <div className="kpblkChain">
                                <div className="kpblogbx kpblogbx2">
                                    <img src={kpblogimg3} className="blogImg" />
                                    <div className="kpbloguser">
                                        <div className="d-flex align-items-start">
                                            <img src={kpuser} alt="" />
                                            <div className="px-3">
                                                <h5 className="mb-0">CHRISTOPHER DUGGAN</h5>
                                                <h5 className="mb-0">9 JUN 2023 • 3 MIN READ</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="kpblogcnt">
                                    <h6>ECOSYSTEM</h6>
                                    <h3>Crypto Gambling Future: A Decentralized
                                        And Borderless Era
                                    </h3>
                                    <p>Since the inception of cryptocurrency back in 2009, the world has seen shifting in how we transact money and how we view the monetary systems as a whole. As the cryptocurrency industry brought us many great attributes, it was a matter of time...</p>
                                    <a href="#"><span className="readmoretxt">Read More <span><img src={readarrow} alt="" /></span></span></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 mb-5">
                            <div className="kpblkChain">
                                <div className="kpblogbx kpblogbx1">
                                    <img src={kpblogimg2} className="blogImg" />
                                    <div className="kpbloguser">
                                        <div className="d-flex align-items-start">
                                            <img src={kpuser} alt="" />
                                            <div className="px-3">
                                                <h5 className="mb-0">CHRISTOPHER DUGGAN</h5>
                                                <h5 className="mb-0">9 JUN 2023 • 3 MIN READ</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="kpblogcnt">
                                    <h6>COMMUNITY</h6>
                                    <h3>imple method to assess your next
                                        crypto investment
                                    </h3>
                                    <p>cryptocurrency is fascinating to look at from the outside, but it's a bit like the wild west in some ways. Central bank digital currency plans prompt banks to wade in; So, if you're a gamer and you already...</p>
                                    <a href="#"><span className="readmoretxt">Read More <span><img src={readarrow} alt="" /></span></span></a>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="horizonline"></div>
                    <div className="row mt-5">
                        <div className="col-lg-6 col-md-6 col-sm-12 mb-5">
                            <div className="kpblkChain">
                                <div className="kpblogbx">
                                    <img src={kpblogimg3} className="blogImg" />
                                    <div className="kpbloguser">
                                        <div className="d-flex align-items-start">
                                            <img src={kpuser} alt="" />
                                            <div className="px-3">
                                                <h5 className="mb-0">CHRISTOPHER DUGGAN</h5>
                                                <h5 className="mb-0">9 JUN 2023 • 3 MIN READ</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="kpblogcnt">
                                    <h6>ECOSYSTEM</h6>
                                    <h3>Why Blockchain is totally worth the hype?</h3>
                                    <p>Blockchain, the industry that evolved with the introduction of Bitcoin, has developed into today’s one of the most ground-breaking technologies that hold the potential to change the entire dynamics of every industry in the world.</p>
                                    <a href="#"><span className="readmoretxt">Read More <span><img src={readarrow} alt="" /></span></span></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 mb-5">
                            <div className="kpblkChain">
                                <div className="kpblogbx kpblogbx1">
                                    <img src={kpblogimg1} className="blogImg" />
                                    <div className="kpbloguser">
                                        <div className="d-flex align-items-start">
                                            <img src={kpuser} alt="" />
                                            <div className="px-3">
                                                <h5 className="mb-0">CHRISTOPHER DUGGAN</h5>
                                                <h5 className="mb-0">9 JUN 2023 • 3 MIN READ</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="kpblogcnt">
                                    <h6>COMMUNITY</h6>
                                    <h3>imple method to assess your next
                                        crypto investment
                                    </h3>
                                    <p>cryptocurrency is fascinating to look at from the outside, but it's a bit like the wild west in some ways. Central bank digital currency plans prompt banks to wade in; So, if you're a gamer and you already...</p>
                                    <a href="#"><span className="readmoretxt">Read More <span><img src={readarrow} alt="" /></span></span></a>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="horizonline"></div>
                    <div className="blogpagnt d-flex align-items-center justify-content-between ">
                        <div>
                            <h3>You're reading 1 - 3 of 165</h3>
                        </div>
                        <div className="d-flex align-items-center">
                        <button className="btn prevbtn ml-0" onClick={() => getBlogDetails('1')} disabled={Number(sessionStorage.getItem('count'))<=0 || !sessionStorage.getItem('count')}><span className="px-2"><img src={uparrow} alt="" /></span>Previous Page</button>
                            <button className="btn prevbtn" onClick={() => getBlogDetails('2')} disabled={moreOption==false}><span className="px-2"><img src={downarrow} alt="" /></span>Next Page</button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );

}

export default Home;