import React, { Component, Fragment, useState, useRef, useEffect, createRef } from "react";
import {
   TabContent, TabPane, Nav, NavItem, NavLink, Card, Button,
   CardTitle, CardText, Row, Col,
   Form, FormGroup, Label, Input
} from 'reactstrap';
import ReactDOM from "react-dom";
import {
   Navigate,
   useNavigate, useLocation
} from "react-router-dom";
import classnames from 'classnames';
import Header from "../../common/Header/Header";
import Footer from "../../common/Footer/Footer";
/** Images **/
import laptopCoffee from "../../assets/images/hackathonList/laptopCoffee.png";
import addcnt from "./assets/addcontent.png";
import calender from "./assets/calendar.svg";
import { useForm } from "react-hook-form";
import axios from "axios";
import { CONST } from "../../_config";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import uploadwhite from "../KPHackathonUser/assets/upload-white.svg";


// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";




const Addcontent = (props) => {
   const navigate = useNavigate();
   const location = useLocation();


   var reduxRes = useSelector((state) => state);

   reduxRes = reduxRes?.user;

   const { register, handleSubmit, errors, trigger } = useForm({
      mode: "onBlur",
   });

   const [startDate, setStartDate] = useState("");
   const [endDate, setEndDate] = useState("");
   const [loading, setLoading] = useState(false);

   const [title, setTitle] = useState("")
   const [description, setDescription] = useState("")
   const [subheading, setSubHeading] = useState("")
   const [projectOverview, setProjectOverview] = useState("")
   const [features, setFeatures] = useState("")
   const [gitUrl, setGitUrl] = useState("")
   const [conclusion, setConslusion] = useState("")
   const [image, setImage] = useState("");
   const [priceDes, setPriceDes] = useState("")

   useEffect(() => {

   }, [reduxRes?.userId])


   const onSubmit = (e) => {
      console.log(e, "eeeeeeeeee");
      setLoading(true);

      const userDatas = {
         title: title,
         content_description: description,
         start_date_time_timestamp: startDate,
         end_date_time_timestamp: endDate,
         start_datetime: startDate,
         end_date_time: endDate,
         heading: "userHackathon",
         image: image,
         overview_description: projectOverview,
         price_description: priceDes,
         theme_description: features,
         sub_content: projectOverview,
         category: "Hackathon",
         hackathon_create_userId: reduxRes?.userId,
      };

      axios
         .post(CONST.BACKEND_URL + "users/userCreateHackathonTopics", userDatas)

         .then((res) => {
            if (res.data.status == true) {
               setLoading(false);

               // navigate("/hackathon-main");

               toast.success(res.data.message);
               navigate("/hackathonlist");
            } else {
               toast.error(res.data.message);

               setLoading(false);
            }
         })
         .catch((err) => {
            setLoading(false);

            toast.error(err);
         });
   };
   console.log(priceDes, "priceDespriceDes")

   const onFileChange = (e) => {
      // setLoading(true)
      e.preventDefault();

      let reader = new FileReader();

      var file = e.target.files[0];
      var nameElement = e.target.name;

      var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.svg)$/i;
      var n = e.target.files[0].type.includes("image");
      if (!allowedExtensions.exec(e.target.files[0].name) || !n) {
      } else {
         const formData = new FormData();
         formData.append("uploads", file);

         const options = {
            method: "POST",
            body: formData,
         };

         fetch(CONST.BACKEND_URL + "img", options)
            .then((response) => response.json())
            .then((res) => {
               if (res.status == true) {
                  setImage(res.data.Location);
                  toast("File Upload Successfully");
                  setLoading(false);
               }
            })
            .catch((error) => { });
      }
   };

   console.log(startDate, endDate, "Dattttteeeeeee");

   return (
      <Fragment>
         <Header />
         {loading == true ? (
            <div className="pageloade">
               <div className="page-loader">
                  <div class="loader-12">
                     <span class="loader_arrow"></span>
                  </div>
               </div>
            </div>
         ) : (
            <></>
         )}
         <div className="hckPrfPg">
            <div className="hckLsBnr">
               <div className="container">
                  <div className="hckLsBnrR row">
                     <div className="hckLsBnrC col-lg-auto">
                        <div className="hckLsBnrTx">
                           <h1>Add Content</h1>
                        </div>
                     </div>
                     <div className="hckLsBnrC col-lg-auto">
                        <div className="hckLsBnrIg">
                           <img src={laptopCoffee} alt="laptopCoffee" />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <section className="addcontent-main">
               <div className="container">
                  <div className="row">
                     <div className="col-lg-8 mx-auto">
                        <div className="lefthead-txt mb-3">
                           <button
                              onClick={() =>
                                 navigate("/hackathonlist")
                              }
                           >
                              <svg
                                 width="8"
                                 height="14"
                                 viewBox="0 0 8 14"
                                 fill="none"
                                 xmlns="http://www.w3.org/2000/svg"
                              >
                                 <path
                                    d="M7 1L1 7L7 13"
                                    stroke="#2C3E50"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                 />
                              </svg>
                           </button>
                           <h5>Back</h5>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)} >
                           <FormGroup>
                              <Label>Title</Label>
                              <input
                                 className="form-control"
                                 type="text"
                                 placeholder="Title"
                                 name="title"
                                 onChange={(e) => setTitle(e.target.value)}
                                 value={title}
                                 ref={register({ required: "Title is required" })} />
                              {errors.title && (
                                 <div style={{ color: "red" }}>{errors.title.message}</div>
                              )}
                           </FormGroup>
                           <FormGroup>
                              <Label>Description</Label>
                              {/* <div><img src={addcnt} className="img-fluid" alt="" /></div> */}
                              <textarea
                                 className="form-control"
                                 type="textarea"
                                 placeholder="Description"
                                 name="description"
                                 onChange={(e) => setDescription(e.target.value)}
                                 value={description}
                                 ref={register({ required: "Description is required" })}
                              />
                              {errors.description && (
                                 <div style={{ color: "red" }}>{errors.description.message}</div>
                              )}
                           </FormGroup>

                           <FormGroup>
                              <Label>Project Overview</Label>
                              {/* <div><img src={addcnt} className="img-fluid" alt="" /></div> */}
                              <textarea
                                 className="form-control"
                                 type="textarea"
                                 placeholder="Project Overview"
                                 name="projectoverview"
                                 onChange={(e) => setProjectOverview(e.target.value)}
                                 value={projectOverview}
                                 ref={register({ required: "Project Overview is required" })}
                              />
                              {errors.projectoverview && (
                                 <div style={{ color: "red" }}>{errors.projectoverview.message}</div>
                              )}
                           </FormGroup>
                           <FormGroup>
                              <Label>Features and Functionalities</Label>
                              {/* <div><img src={addcnt} className="img-fluid" alt="" /></div> */}
                              <textarea
                                 className="form-control"
                                 type="textarea"
                                 placeholder="Features"
                                 name="features"
                                 onChange={(e) => setFeatures(e.target.value)}
                                 value={features}
                                 ref={register({ required: "Features is required" })}
                              />
                              {errors.features && (
                                 <div style={{ color: "red" }}>{errors.features.message}</div>
                              )}
                           </FormGroup>

                           <FormGroup>
                              <Label>Start Date and Time</Label>
                              <div className="date-input">
                                 <div className="date-icon"><img src={calender} className="img-fluid" alt="" /></div>
                                 <DatePicker
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    minDate={new Date()}
                                    showTimeSelect
                                    timeIntervals={1}
                                    className="form-control"
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                    value={startDate}
                                    placeholderText="Start Date"
                                 />
                              </div>
                           </FormGroup>
                           <FormGroup>
                              <Label>End Date and Time</Label>
                              <div className="date-input">
                                 <div className="date-icon"><img src={calender} className="img-fluid" alt="" /></div>
                                 <DatePicker
                                    selected={endDate}
                                    onChange={(date) => setEndDate(date)}
                                    minDate={new Date()}
                                    showTimeSelect
                                    timeIntervals={1}
                                    className="form-control"
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                    value={endDate}
                                    placeholderText="End Date"
                                 />
                              </div>
                           </FormGroup>


                           <FormGroup className="fileupload-box-tl">


                              <div className="fileupload-box">
                                 <div className="fileupload-grid">
                                    <div>
                                       <h3>Attatchment</h3>
                                    </div>
                                    <div>
                                       {/* <input
                                          type="file"
                                          id="idea-fileupload"
                                          onChange={(e) => {
                                             onFileChange(e, "img1");
                                             // setPreviewImage4(URL.createObjectURL(e.target.files[0]))
                                          }}
                                       /> */}
                                       <input
                                          className="mb-3"
                                          type="file"
                                          id="idea-fileupload"
                                          onChange={onFileChange}
                                          name="image"
                                          ref={register({ required: "Image is required" })}

                                       // defaultValue={userIdea.attachment}
                                       />
                                       {errors.image && (
                                          <div style={{ color: "red" }} className="form-error">{errors.image.message}.</div>
                                       )}
                                       <Label for="idea-fileupload">
                                          <div className="btnns">
                                             <img
                                                src={uploadwhite}
                                                alt=""
                                                className="img-fluid"
                                             />

                                             <h5 style={{ cursor: "pointer" }}>Choose File</h5>
                                          </div>
                                       </Label>
                                    </div>
                                 </div>
                                 {/* <img
                        src={image.img1 ? image.img1 : userIdea.attachment}
                        style={{ width: "200px" }}
                        
                      /> */}
                                 <div className="text-center">
                                    <img className="img-fluid mt-2 "
                                       src={image ? image : ""}
                                       style={{ width: "200px" }}
                                    />

                                 </div>

                                 {/* <img src={userIdea.attachment ? userIdea.attachment : ""} style={{ width: "200px" }} /> */}
                              </div>


                           </FormGroup>

                           <FormGroup>
                              <Label>Conclusion</Label>
                              {/* <div><img src={addcnt} className="img-fluid" alt="" /></div> */}
                              <textarea
                                 className="form-control"
                                 type="textarea"
                                 placeholder="Conclusion"
                                 name="pricedes"
                                 onChange={(e) => setPriceDes(e.target.value)}
                                 value={priceDes}
                                 ref={register({ required: "Conclusion is required" })}
                              />
                              {errors.pricedes && (
                                 <div style={{ color: "red" }}>{errors.pricedes.message}</div>
                              )}
                           </FormGroup>

                           <button className="btn btn-primary">Submit</button>
                        </form>
                     </div>
                  </div>
               </div>
            </section>
         </div>
         <Footer />
      </Fragment>
   );
}
export default Addcontent;