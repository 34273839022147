import React, {
  Component,
  Fragment,
  createRef,
  useEffect,
  useRef,
  useState,
} from "react";

import Footer from "../../common/Footer/Footer";
import Header from "../../common/Header/Header";
import HtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import ReactDOM from "react-dom";
import classnames from "classnames";
import exploreblocks from "../../assets/images/kpkdigital/explore-books.png";
/** Images  **/
import explorebnr from "../../assets/images/kpkdigital/explore.png";
import explorechart from "../../assets/images/kpkdigital/explorechart.png";
import exploreoverview from "../../assets/images/kpkdigital/exploreoverview.png";
import exploretrans from "../../assets/images/kpkdigital/explore-transaction.png";
import { useSelector } from "react-redux";

const Exploreguide = (props) => {
  const [smartContract, setSmartContract] = useState("");
  var Res = useSelector((state) => state.user);
  useEffect(() => {
    if (Res?.data?.status == 200) {
      Res = Res.data.data;
      setSmartContract(Res);

      console.log("Redss", Res);
    }
  }, [Res, smartContract]);

  return (
    <Fragment>
      <Header />
      <div className="kpkexchange-pagecont">
        {smartContract &&
          smartContract.map((item) => {
            if (item.heading == "explorer heading") {
              console.log("item------", item);

              return (
                <section className="kpkexchange-bnrsec">
                  <div className="container">
                    <div className="row align-items-center mt-5">
                      <div className="col-lg-7">
                        <div className="kpkexchange-leftcnt">
                          <h2>
                            PARK Chain <span>Explorer Guide</span>
                          </h2>

                          <p>{HtmlParser(item.subcontentdescription)}</p>
                        </div>
                      </div>
                      <div className="col-lg-5 text-center">
                        <div className="kpkexchange-rightcnt">
                          <img src={item.image} alt="" className="img-fluid" />
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              );
            }
          })}

        {smartContract &&
          smartContract.map((item) => {
            if (item.heading == "Explorer content") {
              return (
                <div>
                  <section className="explore-sec">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="explore-inrcnt">
                            <h2>{item.title}</h2>

                            <p>{HtmlParser(item.content_description)}</p>
                          </div>
                        </div>
                        <div className="col-lg-6 mt-4 mt-lg-0">
                          <div>
                            <img
                              src={item.image}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="explore-sec">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-6 orderimg">
                          <div>
                            <img
                              src={item.sub_image}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="explore-inrcnt">
                            <h2>{item.Overview}</h2>
                            <p>{HtmlParser(item.OverviewDes)}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="explore-sec">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="explore-inrcnt">
                            <h2>{item.Transactions}</h2>
                            <p>{HtmlParser(item.TransactionDesc)}</p>
                          </div>
                        </div>
                        <div className="col-lg-6 mt-4 mt-lg-0">
                          <div>
                            <img
                              src={item.sub_image1}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="explore-sec">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-6 orderimg">
                          <div>
                            <img
                              src={item.subimage2}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="explore-inrcnt">
                            <h2>{item.Blocks}</h2>
                            <p>{HtmlParser(item.BlockDes)}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  {/* <div class="bnrLks text-center">
                    <a
                      href={item.link}
                      target="blank"
                      class="btn btn-12040 btn-bluFl text-center"
                    >
                      Explore
                    </a>
                  </div> */}
                  <div class="text-center"> 
                    <a href={item.link} class="btn btn-primary text-center px-4">
                      Explore
                    </a>
                  </div>
                </div>
              );
            }
          })}
      </div>
      <Footer />
    </Fragment>
  );
};
export default Exploreguide;
