import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
  Button,
  Card,
  CardText,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import React, {
  Component,
  Fragment,
  createRef,
  useEffect,
  useRef,
  useState,
} from "react";

import Footer from "../../common/Footer/Footer";
import Header from "../../common/Header/Header";
import ReactDOM from "react-dom";
import Slider from "react-slick";
import classnames from "classnames";
import laptopCoffee from "../../assets/images/hackathonList/laptopCoffee.png";
import member1 from "../../assets/images/member1.png";
import member2 from "../../assets/images/member2.png";
import member3 from "../../assets/images/member3.png";
import showImg from "../../assets/images/showImg.png";
import slide1 from "../../assets/images/slide1.png";
import slide2 from "../../assets/images/slide2.png";
import slide3 from "../../assets/images/slide3.png";
import usersm from "../../assets/images/user-sm.png";

const IdeaTeamSub = (props) => {
  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const slider1 = useRef(null);
  const slider2 = useRef(null);
  useEffect(() => {
    setNav1(slider1.current);
    setNav2(slider2.current);
  }, []);
  return (
    <Fragment>
      <Header />
      <div className="hckPrfPg">
        <div className="hckLsBnr">
          <div className="container">
            <div className="hckLsBnrR row">
              <div className="hckLsBnrC col-lg-auto">
                <div className="hckLsBnrTx">
                  <h1>Hackathons</h1>
                </div>
              </div>
              <div className="hckLsBnrC col-lg-auto">
                <div className="hckLsBnrIg">
                  <img src={laptopCoffee} alt="laptopCoffee" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ideateamsub-main">
          <section className="user-sec">
            <div className="container">
              <div className="ideatem-userdet">
                <div>
                  <img src={usersm} alt="" className="img-fluid" />
                </div>
                <div className="ml-2">
                  <h5>Steven Mendoza</h5>
                </div>
              </div>
              <div className="ideatem-tabul">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "1" })}
                      onClick={() => {
                        toggle("1");
                      }}
                    >
                      Idea
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "2" })}
                      onClick={() => {
                        toggle("2");
                      }}
                    >
                      Team
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "3" })}
                      onClick={() => {
                        toggle("3");
                      }}
                    >
                      Submission(1)
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
            </div>
          </section>
          <section className="ideatabcnt-sec">
            <div className="container">
              <div className="ideatem-tabcnt">
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <div className="hackathon-ideatabcnt">
                      <div className="salaryhead">
                        <h5 className="ideateam-head m-0">
                          Salary SPI calculation machine learing
                        </h5>
                        <div className="shortlist ml-3">
                          <h6>Shortlisted</h6>
                        </div>
                      </div>
                      <p className="mt-2">
                        Lorem ipsum dolor sit amet consectetur. Quam id sit
                        nulla in sodales lorem. Lorem leo platea et natoque
                        tortor nunc vitae id. Vitae sagittis turpis eget urna
                        vitae ullamcorper adipiscing. Neque lacus viverra augue
                        volutpat turpis eu. Aliquet condimentum v
                      </p>
                      <div className="shoeimg-box mt-3">
                        <img src={showImg} alt="" className="img-fluid" />
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tabId="2">
                    <div className="hackathon-teamcnt">
                      <h5 className="ideateam-head">Team Description</h5>
                      <p>
                        Our team is group of people who work together to achive
                        a common purpose . Win Win!
                      </p>
                      <div className="teammember-box">
                        <h6>Team Members</h6>
                        <div className="teamdet-box">
                          <div>
                            <img src={member1} alt="" className="img-fluid" />
                          </div>
                          <div className="ml-2">
                            <h5>Harsha</h5>
                            <p>
                              Front end Development, Machine Learning,
                              Marketing, Designer
                            </p>
                          </div>
                        </div>
                        <div className="teamdet-box">
                          <div>
                            <img src={member2} alt="" className="img-fluid" />
                          </div>
                          <div className="ml-2">
                            <h5>Nivesh Rolden</h5>
                            <p>
                              Front end Development, Machine Learning,
                              Marketing, Designer
                            </p>
                          </div>
                        </div>
                        <div className="teamdet-box">
                          <div>
                            <img src={member3} alt="" className="img-fluid" />
                          </div>
                          <div className="ml-2">
                            <h5>Sofia Turner</h5>
                            <p>
                              Front end Development, Machine Learning,
                              Marketing, Designer
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tabId="3">
                    <div className="hackathon-submissiontabcnt">
                      <h5 className="ideateam-head">
                        Salary SPI calculation machine learing
                      </h5>
                      <p>
                        Theme : Theme 1 | Author : harsha@12 | Last Updated :
                        Jul 25, 2023 08:30 PM{" "}
                      </p>
                      <div className="sub-description">
                        <h5 className="fs-16-500">Description</h5>
                        <p>
                          Lorem ipsum dolor sit amet consectetur. Proin ac
                          commodo tempus quis fames aliquam. Egestas lacus risus
                          mattis eget. Cursus nisl ornare dolor volutpat netus
                          volutpat blandit ipsum. Est nullam risus faucibus mi.
                          Urna at cras scelerisque id volutpat in purus euismod
                          aliquam.
                        </p>
                      </div>
                      <div className="tech-box">
                        <div className="techname">
                          <h5>Python</h5>
                        </div>
                        <div className="techname">
                          <h5>Jupyter Notebook</h5>
                        </div>
                        <div className="techname">
                          <h5>Conda</h5>
                        </div>
                        <div className="techname">
                          <h5>Cmd</h5>
                        </div>
                        <div className="techname">
                          <h5>Csv</h5>
                        </div>
                        <div className="techname">
                          <h5>Tkinter</h5>
                        </div>
                      </div>
                      <div className="slidermain-cnt">
                        <Slider asNavFor={nav2} ref={slider1}>
                          <div>
                            <img src={slide1} alt="" className="img-fluid" />
                          </div>
                          <div>
                            <img src={slide2} alt="" className="img-fluid" />
                          </div>
                          <div>
                            <img src={slide3} alt="" className="img-fluid" />
                          </div>
                        </Slider>
                      </div>
                      <div className="slidersub-cnt">
                        <Slider
                          asNavFor={nav1}
                          ref={slider2}
                          slidesToShow={3}
                          swipeToSlide={true}
                          focusOnSelect={true}
                        >
                          <div className="slider-item">
                            <img src={slide1} alt="" className="img-fluid" />
                          </div>
                          <div className="slider-item">
                            <img src={slide2} alt="" className="img-fluid" />
                          </div>
                          <div className="slider-item">
                            <img src={slide3} alt="" className="img-fluid" />
                          </div>
                        </Slider>
                      </div>
                      <Form className="mt-5">
                        <h5 className="fs-16-500">Video and Presentation</h5>

                        <FormGroup className="mt-5">
                          <Label>Video</Label>
                          <div className="graybox"></div>
                        </FormGroup>
                        <FormGroup className="my-4">
                          <Label>Presentation</Label>
                          <div className="graybox"></div>
                        </FormGroup>
                        <FormGroup>
                          <Label>Demo</Label>
                          <div className="graybox"></div>
                        </FormGroup>
                      </Form>
                    </div>
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};
export default IdeaTeamSub;
