import React, { Component, Fragment, useState, useRef, useEffect, createRef  } from "react";
import ReactDOM from "react-dom";
import classnames from 'classnames';
import './scss/nft.scss';
import Header from "../../common/Header/Header";
import Footer from "../../common/Footer/Footer";
/** Images  **/
import bannerImg from './images/nft-bnr.png';

import { useSelector, useDispatch } from 'react-redux';
import HtmlParser from "react-html-parser";


const NFT = (props) => {  

   let data = useSelector(state => (state.user));
   console.log(data, "kpkpkdata");

   let data1, data2, data3, data4, data5, data6, data7, data8, data9, data10;

   if (data.data.status == 200) {
       data = data.data.data;

       data1 = data.find(elem => elem.title == "What's NFT? Page");
       data2 = data.find(elem => elem.title == "Uniqueness");
       data3 = data.find(elem => elem.title == "Uniqueness1");



       
   }
return (
<Fragment>
   <Header />
   <div className="nftpage-content">
      <section className="nftbanner-sec">
         <div className="container">
            <div className="row align-items-center">
               <div className="col-md-6">
                  <div>
                     <h1>{data1 ? HtmlParser((data1.subtitle).replace(/(<([^>]+)>)/gi, "")) : <></>}</h1>
                  </div>
               </div>
               <div className="col-md-6 text-center mt-4 mt-lg-0">
                  <div>
                     <img src={data1 ? HtmlParser(data1.image) : <></>} alt="" className="img-fluid" />
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section className="pt-5">
         <div className="container">
            <p>{data1 ? HtmlParser((data1.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
         </div>
      </section>
      <section className="my-5">
         <div className="container">
            <h3>{data1 ? HtmlParser((data1.subtitle1).replace(/(<([^>]+)>)/gi, "")) : <></>}</h3>
            <div className="row mt-5 mb-3">
               <div className="col-md-6 col-lg-4">
                  <div className="nft-inrbox">
                     <h5>{data2 ? HtmlParser((data2.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</h5>
                     <p>{data2 ? HtmlParser((data2.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                  </div>
               </div>
               <div className="col-md-6 col-lg-4">
                  <div className="nft-inrbox">
                     <h5>{data2 ? HtmlParser((data2.subtitle).replace(/(<([^>]+)>)/gi, "")) : <></>}</h5>
                     <p>{data2 ? HtmlParser((data2.subcontentdescription).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                  </div>
               </div>
               <div className="col-md-6 col-lg-4">
                  <div className="nft-inrbox">
                     <h5>{data2 ? HtmlParser((data2.subtitle1).replace(/(<([^>]+)>)/gi, "")) : <></>}</h5>
                     <p>{data2 ? HtmlParser((data2.meta_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                  </div>
               </div>
               <div className="col-md-6 col-lg-4">
                  <div className="nft-inrbox">
                  <h5>{data3 ? HtmlParser((data3.subtitle).replace(/(<([^>]+)>)/gi, "")) : <></>}</h5>
                     <p>{data3 ? HtmlParser((data3.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                  </div>
               </div>
               <div className="col-md-6 col-lg-4">
                  <div className="nft-inrbox">
                  <h5>{data3 ? HtmlParser((data3.subtitle1).replace(/(<([^>]+)>)/gi, "")) : <></>}</h5>
                     <p>{data3 ? HtmlParser((data3.subcontentdescription).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                  </div>
               </div>
            </div>
            <p>{data1 ? HtmlParser((data1.subcontentdescription).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
         </div>
      </section>
   </div>
   <Footer />
</Fragment>
);
}
export default NFT;