import "react-toastify/dist/ReactToastify.css";

import { Bounce, Flip, Slide, Zoom } from "react-toastify";
import {
  BrowserRouter,
  Link,
  Navigate,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import React, {
  Component,
  Fragment,
  createRef,
  useEffect,
  useRef,
  useState,
} from "react";
import { TimeConverter, localUserId } from "../../redux/action";
// import TimeConverter, { Time } from "../../redux/TimeMag";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import { CONST } from "../../_config";
import Countdown from "react-countdown";
import Footer from "../../common/Footer/Footer";
import Header from "../../common/Header/Header";
import ReactDOM from "react-dom";
import axios from "axios";
import classnames from "classnames";
import laptopCoffee from "../../assets/images/hackathonList/laptopCoffee.png";
import season1 from "../../assets/images/hackathonList/season-1.jpg";
import season2 from "../../assets/images/hackathonList/season-2.jpg";
import season3 from "../../assets/images/hackathonList/season-3.jpg";
import season4 from "../../assets/images/hackathonList/season-4.jpg";

const HackathonList = (props) => {

  var reduxRes = useSelector((state) => state);

  reduxRes = reduxRes?.user;

  const [activeTab, setActiveTab] = useState(
    sessionStorage.getItem("1") ? sessionStorage.getItem("1") : "1"
  );
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(sessionStorage.setItem("1", tab));
    window.location.reload();
  };

  const [HackathonLists, setListHackathon] = useState([]);
  const [countList, setCountList] = useState({});
  const [loading, setLoading] = useState(false);
  const [HackathonUserData, setUserData] = useState([])

  const dispatch = useDispatch();
  const ReduxRes = useSelector((state) => state);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(localUserId());
    ListHackathon();
    TimeingType();
    pageNotationFun();
  }, [reduxRes?.userId]);

  console.log(reduxRes?.userId, "ReduxRes");


  const pageNotationFun = (value, hackathonId) => {
    setLoading(true);
    let userDatas = {
      hackathon_create_userId: reduxRes?.userId,
    }
    const config = {
      header: "https://parkchain.org",
    };

    axios

      .post(CONST.BACKEND_URL + "users/listHackathonTopicByUser", userDatas, config)
      .then((res) => {
        console.log(res.data, res.status, "ressssssssssssssssssssggdfgdfgdfgdfgdfg");
        setLoading(false);
        if (res.data.status == true && res.data.data != null && res.data.data != undefined && res.data.data != "") {
          setLoading(false);
          setUserData(res.data.data)
        } else {
          setLoading(false);
          // toast.error(res.data.message)
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  };



  const ListHackathon = async (e, list) => {
    setLoading(true);
    var tab = sessionStorage.getItem("1");

    let data = {
      type:
        tab == "1" || tab == null || tab == undefined || tab == ""
          ? "live"
          : tab == "2"
            ? "upcoming"
            : tab == "3"
              ? "previous"
              : tab == "4"
                ? "AI"
                : "",
    };
    await axios
      .post(CONST.BACKEND_URL + "get_hackthon_list", data)
      .then((res) => {  
        if (res.data.status == true) {
          toast.success(res.data.message, {
            transition: Flip,
            className: "foo-bar",
          });
          setListHackathon(res.data.data);
          setLoading(false);
        }
      })

      .catch((err) => {
        setLoading(false);
      });
  };

  const TimeingType = () => {
    const config = {
      header: {
        Origin: "http://localhost:3000",
      },
    };

    axios
      .get(CONST.BACKEND_URL + "getHackathonCountList", config)
      .then((res) => {
        setCountList(res.data);
      });
  };

  const routingFun = (id, hackathonId) => {
    if (ReduxRes?.user?.userId) {
      navigate(`/hackathon-details/${id}`, {
        state: {
          hackthonId: hackathonId,
        },
      });
    } else {
      navigate(`/hackathon-login`);
    }
  };

  const btnMag = (id, hackathonId) => {
    return (
      <button
        class="btn btn-11738 btn-bluFl v2"
        onClick={() => {
          routingFun(id, hackathonId);
        }}
      >
        {ReduxRes?.user?.userId ? "Start Now" : "Login"}
      </button>
    );
  };

  var convertedtime;
  const Time = (time) => {
    // dispatch(TimeConverter(time));
    // convertedtime = ReduxRes?.user?.timeValue;

    convertedtime = new Date(time).toLocaleString("en-US", {
      weekday: "short",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      timeZoneName: "short",
    });
  };

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      // ListHackathon();
    }
  };

  setInterval(() => {
    if (HackathonLists.length != 0) {
      for (var i = 0; i < HackathonLists.length; i++) {
        const currentDate = new Date().getTime() / 1000;

        // var minus =
        //   new Date().getTime() / 1000 -
        //   new Date(HackathonLists[i].startDate) / 1000;

        var end =
          currentDate - new Date(HackathonLists[i].end_date_time) / 1000;

        // if (parseInt(minus) == 0) {
        //   PoolData();
        // }

        if (parseInt(end) == 0) {
          ListHackathon();
        }
      }
    }
  }, [1000]);
  console.log(HackathonUserData, "HackathonUserDataHackathonUserData")
  // console.log(HackathonUserData[0]?.hackathon_create_userId,"HackathonUserData[0]?.hackathon_create_userId")
  //console.log(new Date(HackathonUserData?.end_date_time_timestamp*1000).toISOString(),"timestamppppppp")

  const Timer = (end_date_time) => {
    return (
      <div className="hckEveEdDtR">
        <div className="hckEveEdDtC">
          <Countdown
            date={end_date_time}
            renderer={(props) => (
              <div>
                <div className="hckEveDtN"> {props.days} </div>
                <div className="hckEveDtT">Days</div>
                <> {renderer(props)}</>
              </div>
            )}
          />
        </div>

        <div className="hckEveEdDtC">
          <Countdown
            date={end_date_time}
            renderer={(props) => (
              <div>
                <div className="hckEveDtN"> {props.hours} </div>
                <div className="hckEveDtT">Hours</div>
              </div>
            )}
          />
        </div>

        <div className="hckEveEdDtC">
          <Countdown
            date={end_date_time}
            renderer={(props) => (
              <div>
                <div className="hckEveDtN"> {props.minutes} </div>
                <div className="hckEveDtT">Minutes</div>
              </div>
            )}
          />
        </div>

        <div className="hckEveEdDtC">
          <Countdown
            date={end_date_time}
            renderer={(props) => (
              <div>
                <div className="hckEveDtN"> {props.seconds} </div>
                <div className="hckEveDtT">Seconds</div>
              </div>
            )}
          />
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <Header />

      {loading == true ? (
        <div className="pageloade">
          <div className="page-loader">
            <div class="loader-12">
              <span class="loader_arrow"></span>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className="hckLsPg">
        <div className="hckLsBnr">
          <div className="container">
            <div className="hckLsBnrR row">
              <div className="hckLsBnrC col-lg-auto">
                <div className="hckLsBnrTx">
                  <h1>Hackathons</h1>
                </div>
              </div>
              <div className="hckLsBnrC col-lg-auto">
                <div className="hckLsBnrIg">
                  <img src={laptopCoffee} alt="laptopCoffee" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="hckLsEves">
          <div className="container">
            {/* {HackathonLists && HackathonLists.length > 0 ?
                            HackathonLists.map((data, index) => ( */}
            <div className="hckLsEvesTab">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Live <span> ({countList.liveCount})</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    Upcoming <span> ({countList.upcomingCount})</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "3" })}
                    onClick={() => {
                      toggle("3");
                    }}
                  >
                    Previous <span> ({countList.previousCount})</span>
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "4" })}
                    onClick={() => {
                      toggle("4");
                    }}
                  >
                    AI <span> (6)</span>
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "5" })}
                    onClick={() => {
                      navigate("/leaderboard");
                    }}
                  >
                    LeaderBoard
                  </NavLink>
                </NavItem>

                {reduxRes?.userId === HackathonUserData[0]?.hackathon_create_userId ? (<NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "6" })}
                    onClick={() => {
                      toggle("6");
                    }}
                  >
                    My Hackathon
                  </NavLink>
                </NavItem>)
                  :
                  <></>
                }
              </Nav>
            </div>
            {/* )) : <></>} */}
            <div className="hckLsEvesTabCon">
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <div className="row hckEveR">
                    {HackathonLists && HackathonLists.length > 0 ? (
                      loading == false ? (
                        HackathonLists.map((data, index) => (
                          <div className="col-md-6 col-xl-4 hckEveC">
                            <div
                              className="hckEveCon"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                navigate(`/hackathon-details/${data._id}`, {
                                  state: {
                                    hackthonId: data.hackathonId,
                                  },
                                });
                              }}
                            >
                              <div className="hckEveConIg">
                                <img src={data?.image} alt="season1" />
                              </div>
                              <div className="hckEveConTx">
                                <h2>{data?.title}</h2>
                                <div className="hckEveEdDt">
                                  <h6>{data?.newend_date_time}</h6>
                                  {Timer(data?.end_date_time)}
                                </div>

                                <div className="hckEveConCnSt">
                                  <div className="hckEveCnt">
                                    <span className="hckEveCntTx">
                                      Participations
                                    </span>
                                    <span className="hckEveCntNb">
                                      <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M10.0007 9.99999C12.3018 9.99999 14.1673 8.13451 14.1673 5.83332C14.1673 3.53214 12.3018 1.66666 10.0007 1.66666C7.69946 1.66666 5.83398 3.53214 5.83398 5.83332C5.83398 8.13451 7.69946 9.99999 10.0007 9.99999Z"
                                          fill="white"
                                        />
                                        <path
                                          d="M10.0008 12.0833C5.82578 12.0833 2.42578 14.8833 2.42578 18.3333C2.42578 18.5667 2.60911 18.75 2.84245 18.75H17.1591C17.3924 18.75 17.5758 18.5667 17.5758 18.3333C17.5758 14.8833 14.1758 12.0833 10.0008 12.0833Z"
                                          fill="white"
                                        />
                                      </svg>
                                      <span>{data?.enroll_count}</span>
                                    </span>
                                  </div>
                                  <div className="hckEveStr">
                                    {btnMag(data?._id, data?.hackthonId)}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <h1>loading</h1>
                      )
                    ) : (
                      <span>No Data Found</span>
                    )}
                  </div>
                </TabPane>
                <TabPane tabId="2">
                <div className="row hckEveR">
                    {HackathonLists && HackathonLists.length > 0 ? (
                      loading == false ? (
                        HackathonLists.map((data, index) => (
                          <div className="col-md-6 col-xl-4 hckEveC">
                            <div
                              className="hckEveCon"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                navigate(`/hackathon-details/${data._id}`, {
                                  state: {
                                    hackthonId: data.hackathonId,
                                  },
                                });
                              }}
                            >
                              <div className="hckEveConIg">
                                <img src={data?.image} alt="season1" />
                              </div>
                              <div className="hckEveConTx">
                                <h2>{data?.title}</h2>
                                <div className="hckEveEdDt">
                                  <h6>{data?.newend_date_time}</h6>
                                  {Timer(data?.end_date_time)}
                                </div>

                                <div className="hckEveConCnSt">
                                  <div className="hckEveCnt">
                                    <span className="hckEveCntTx">
                                      Participations
                                    </span>
                                    <span className="hckEveCntNb">
                                      <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M10.0007 9.99999C12.3018 9.99999 14.1673 8.13451 14.1673 5.83332C14.1673 3.53214 12.3018 1.66666 10.0007 1.66666C7.69946 1.66666 5.83398 3.53214 5.83398 5.83332C5.83398 8.13451 7.69946 9.99999 10.0007 9.99999Z"
                                          fill="white"
                                        />
                                        <path
                                          d="M10.0008 12.0833C5.82578 12.0833 2.42578 14.8833 2.42578 18.3333C2.42578 18.5667 2.60911 18.75 2.84245 18.75H17.1591C17.3924 18.75 17.5758 18.5667 17.5758 18.3333C17.5758 14.8833 14.1758 12.0833 10.0008 12.0833Z"
                                          fill="white"
                                        />
                                      </svg>
                                      <span>{data?.enroll_count}</span>
                                    </span>
                                  </div>
                                  <div className="hckEveStr">
                                    {btnMag(data?._id, data?.hackthonId)}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <h1>loading</h1>
                      )
                    ) : (
                      <span>No Data Found</span>
                    )}
                  </div>
                </TabPane>
                <TabPane tabId="3">
                  <div className="row hckEveR">
                    {HackathonLists && HackathonLists.length > 0 ? (
                      loading == false ? (
                        HackathonLists.map((data, index) => (
                          <div className="col-md-6 col-xl-4 hckEveC">
                            <div
                              className="hckEveCon"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                navigate(`/hackathon-details/${data._id}`, {
                                  state: {
                                    hackthonId: data.hackathonId,
                                  },
                                });
                              }}
                            >
                              <div className="hckEveConIg">
                                <img src={data?.image} alt="season1" />
                              </div>
                              <div className="hckEveConTx">
                                <h2>{data?.title}</h2>
                                <div className="hckEveEdDt">
                                  <h6>{data?.newend_date_time}</h6>
                                  {Timer(data?.end_date_time)}
                                </div>

                                <div className="hckEveConCnSt">
                                  <div className="hckEveCnt">
                                    <span className="hckEveCntTx">
                                      Participations
                                    </span>
                                    <span className="hckEveCntNb">
                                      <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M10.0007 9.99999C12.3018 9.99999 14.1673 8.13451 14.1673 5.83332C14.1673 3.53214 12.3018 1.66666 10.0007 1.66666C7.69946 1.66666 5.83398 3.53214 5.83398 5.83332C5.83398 8.13451 7.69946 9.99999 10.0007 9.99999Z"
                                          fill="white"
                                        />
                                        <path
                                          d="M10.0008 12.0833C5.82578 12.0833 2.42578 14.8833 2.42578 18.3333C2.42578 18.5667 2.60911 18.75 2.84245 18.75H17.1591C17.3924 18.75 17.5758 18.5667 17.5758 18.3333C17.5758 14.8833 14.1758 12.0833 10.0008 12.0833Z"
                                          fill="white"
                                        />
                                      </svg>
                                      <span>{data?.enroll_count}</span>
                                    </span>
                                  </div>
                                  <div className="hckEveStr">
                                    {btnMag(data?._id, data?.hackthonId)}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <h1>loading</h1>
                      )
                    ) : (
                      <span>No Data Found</span>
                    )}
                  </div>
                </TabPane>

                <TabPane tabId="4">
                  <div className="row hckEveR">
                    {HackathonLists && HackathonLists.length > 0 ? (
                      loading == false ? (
                        HackathonLists.map((data, index) => (
                          <div className="col-md-6 col-xl-4 hckEveC">
                            <div
                              className="hckEveCon"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                navigate(`/hackathon-details/${data._id}`, {
                                  state: {
                                    hackthonId: data.hackathonId,
                                  },
                                });
                              }}
                            >
                              <div className="hckEveConIg">
                                <img src={data?.image} alt="season1" />
                              </div>
                              <div className="hckEveConTx">
                                <h2>{data?.title}</h2>
                                <div className="hckEveEdDt">
                                  <h6>{data?.newend_date_time}</h6>
                                  {Timer(data?.end_date_time)}
                                </div>

                                <div className="hckEveConCnSt">
                                  <div className="hckEveCnt">
                                    <span className="hckEveCntTx">
                                      Participations
                                    </span>
                                    <span className="hckEveCntNb">
                                      <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M10.0007 9.99999C12.3018 9.99999 14.1673 8.13451 14.1673 5.83332C14.1673 3.53214 12.3018 1.66666 10.0007 1.66666C7.69946 1.66666 5.83398 3.53214 5.83398 5.83332C5.83398 8.13451 7.69946 9.99999 10.0007 9.99999Z"
                                          fill="white"
                                        />
                                        <path
                                          d="M10.0008 12.0833C5.82578 12.0833 2.42578 14.8833 2.42578 18.3333C2.42578 18.5667 2.60911 18.75 2.84245 18.75H17.1591C17.3924 18.75 17.5758 18.5667 17.5758 18.3333C17.5758 14.8833 14.1758 12.0833 10.0008 12.0833Z"
                                          fill="white"
                                        />
                                      </svg>
                                      <span>{data?.enroll_count}</span>
                                    </span>
                                  </div>
                                  <div className="hckEveStr">
                                    {btnMag(data?._id, data?.hackthonId)}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <h1>loading</h1>
                      )
                    ) : (
                      <span>No Data Found</span>
                    )}
                  </div>
                </TabPane>
                {reduxRes?.userId === HackathonUserData[0]?.hackathon_create_userId ? (<TabPane tabId="6">
                  <div className="row hckEveR">
                    {HackathonUserData && HackathonUserData.length > 0 ? (
                      loading == false ? (
                        HackathonUserData.map((data, index) => (
                          <div className="col-md-6 col-xl-4 hckEveC">
                            <div
                              className="hckEveCon"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                navigate(`/hackathon-details/${data._id}`, {
                                  state: {
                                    hackthonId: data.hackathonId,
                                  },
                                });
                              }}
                            >
                              <div className="hckEveConIg">
                                <img src={data?.image} alt="season1" />
                              </div>
                              <div className="hckEveConTx">
                                <h2>{data?.title}</h2>
                                <div className="hckEveEdDt">
                                  <h6>{data?.newend_date_time}</h6>
                                  {Timer(data?.end_date_time)}
                                </div>

                                <div className="hckEveConCnSt">
                                  {/* <div className="hckEveCnt">
                                    <span className="hckEveCntTx">
                                      Participations
                                    </span>
                                    <span className="hckEveCntNb">
                                      <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M10.0007 9.99999C12.3018 9.99999 14.1673 8.13451 14.1673 5.83332C14.1673 3.53214 12.3018 1.66666 10.0007 1.66666C7.69946 1.66666 5.83398 3.53214 5.83398 5.83332C5.83398 8.13451 7.69946 9.99999 10.0007 9.99999Z"
                                          fill="white"
                                        />
                                        <path
                                          d="M10.0008 12.0833C5.82578 12.0833 2.42578 14.8833 2.42578 18.3333C2.42578 18.5667 2.60911 18.75 2.84245 18.75H17.1591C17.3924 18.75 17.5758 18.5667 17.5758 18.3333C17.5758 14.8833 14.1758 12.0833 10.0008 12.0833Z"
                                          fill="white"
                                        />
                                      </svg>
                                      <span>{data?.enroll_count}</span>
                                    </span>
                                  </div> */}
                                  <div className="hckEveStr">
                                    {btnMag(data?._id, data?.hackthonId)}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <h1>loading</h1>
                      )
                    ) : (
                      <span>No Data Found</span>
                    )}
                  </div>
                </TabPane>)
                  :
                  <></>}
              </TabContent>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Fragment>
  );
};

export default HackathonList;
