import "./kphckcr.scss";

import {
  BrowserRouter,
  Link,
  Navigate,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import {
  Button,
  Collapse,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
} from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import React, {
  Component,
  Fragment,
  createRef,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import { CONST } from "../../_config";
import Footer from "../../common/Footer/Footer";
import Header from "../../common/Header/Header";
import Loader from "../../_config/Loader";
import ReactDOM from "react-dom";
import award from "./assets/award.svg";
import axios from "axios";
import camera from "./assets/camera.svg";
import classnames from "classnames";
import { getUserProfile } from "../../redux/action";
import laptopCoffee from "../../assets/images/hackathonList/laptopCoffee.png";
import profile from "./assets/profile.png";
import ranking from "./assets/ranking.svg";
import reward from "./assets/reward.svg";
import send from "./assets/send-2.svg";
import { toast } from "react-toastify";

const HackathonProfile = (props) => {
  const [profileDetails, setProfileDetails] = useState();
  const [loading, setLoading] = useState(false);
  const [keys, setKeys] = useState(0);
  const [submissionDetails, setSubmissionDetails] = useState([]);
  const [statusCount, setStatusCount] = useState("");

  var reduxRes = useSelector((state) => state);

  console.log("reduxRes", reduxRes.user);
  reduxRes = reduxRes?.user;

  const { register, handleSubmit, errors, watch, trigger, control } = useForm({
    mode: "onBlur",
  });

  var dispatch = useDispatch();
  var navigate = useNavigate();

  console.log("reduxRes?.userId?.user_id", reduxRes?.userId);

  const GetUserProfile = () => {
    dispatch(
      getUserProfile({
        userId: reduxRes?.userId,
      })
    );

    if (reduxRes?.userProfile?.data) {
      setProfileDetails({
        first_name: reduxRes.userProfile.data.first_name,
        last_name: reduxRes.userProfile.data.last_name,
        email: reduxRes.userProfile.data.email,
        profile: reduxRes.userProfile.data.profile,
        mobile: reduxRes.userProfile.data.mobile,
        country_id: reduxRes.userProfile.data.country_id,
        country: reduxRes.userProfile.data.country,
        userName: reduxRes.userProfile.data.userName,
        profile_img: reduxRes.userProfile.data.profile_img,
        userAddress: reduxRes.userProfile.data.userAddress,
        user_type: reduxRes.userProfile.data.user_type,
        balance: reduxRes.userProfile.data.actual_wallet_balance_user,
      });

      if (!reduxRes?.userProfile?.data?.userAddress) {
        toast.error("Kindly add wallet address");
      }
    }
  };

  useEffect(() => {
    if (reduxRes?.userId) {
      GetUserProfile();
      SubmissionDetails();
      userStatus();
    }
  }, [reduxRes?.userId, reduxRes?.userProfile?.data?.first_name]);

  const AddAddress = (e) => {
    console.log("eee", e);
    setLoading(true);

    const config = {
      header: {
        Origin: "http://localhost:3000",
      },
    };

    const data = {
      userId: reduxRes?.userId,
      userName: profileDetails.userName,
      first_name: profileDetails.first_name,
      last_name: profileDetails.last_name,
      country: profileDetails.country,
      profile_img: profileDetails.profile_img,
      mobile: profileDetails.mobile,
      userAddress: e.userAddress,
      user_type: e.userType,
    };

    axios
      .post(CONST.BACKEND_URL + "users/editProfile", data, config)

      .then((res) => {
        setLoading(false);
        if (res.data.status == true) {
          toast.success(res.data.message);
          var pathName = localStorage.getItem("pathName");

          if (pathName == null || pathName == "" || pathName == undefined) {
            navigate("/hackathonlist");
          } else {
            navigate(pathName);
          }
        } else {
          toast.error(res.data.message);
        }
      });
  };

  const SubmissionDetails = () => {
    const data = {
      userId: reduxRes?.userId,
    };

    const config = {
      header: {
        orgin: "http://localhost:3000",
      },
    };

    axios
      .post(
        CONST.BACKEND_URL + "users/IndividualSubmissionListProfile",
        data,
        config
      )
      .then((res) => {
        if (res.data.status == true) {
          setSubmissionDetails(res.data.data);
        } else {
          toast.error(res.data.message);
        }
      })

      .catch((err) => {
        toast.error(err);
      });
  };

  const userStatus = () => {
    setLoading(true);
    const data = {
      userId: reduxRes?.userId,
    };

    axios
      .post(CONST.BACKEND_URL + "users/SubmissionCountInProfile", data)
      .then((res) => {
        setLoading(false);
        console.log(res.data, "dataaa");
        setStatusCount(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

console.log(profileDetails,"profileDetails");

  return (
    <Fragment>
      <Header />

      {reduxRes?.user?.isLoading == true ? <Loader /> : <></>}

      <div className="hckPrfPg">
        <div className="hckLsBnr">
          <div className="container">
            <div className="hckLsBnrR row">
              <div className="hckLsBnrC col-lg-auto">
                <div className="hckLsBnrTx">
                  <h1>Profile</h1>
                </div>
              </div>
              <div className="hckLsBnrC col-lg-auto">
                <div className="hckLsBnrIg">
                  <img src={laptopCoffee} alt="laptopCoffee" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="hckPrfDt">
          <div className="container">
            <div className="row hckPrfDtR">
              <div className="col-lg-auto hckPrfDtC">
                <div className="hckPrfPsn hckPrfCd">
                  <div className="hckPrfDp">
                    <img src={profileDetails?.profile_img} alt="profile" />
                  </div>

                  <div className="hckPrfTxG">
                    <div className="hckPrfTxLb">Email </div>
                    <div className="hckPrfTxVl">{profileDetails?.email}</div>
                  </div>

                  <div className="hckPrfTxG">
                    <div className="hckPrfTxLb">User_Id </div>
                    <div className="hckPrfTxVl">{reduxRes?.userId}</div>
                  </div>

                  <div className="hckPrfTxG">
                    <div className="hckPrfTxLb">Name </div>
                    <div className="hckPrfTxVl">
                      {profileDetails?.first_name}
                    </div>
                  </div>

                  <div className="hckPrfTxG">
                    <div className="hckPrfTxLb">Phone Number </div>
                    <div className="hckPrfTxVl"> {profileDetails?.mobile}</div>
                  </div>

                  <div className="hckPrfEd">
                    {reduxRes?.userId ? (
                      <button
                        onClick={() => {
                          navigate("/hackathon-profileEdit");
                        }}
                        className="btn btn-14535 btn-bluFl v2"
                      >
                        Edit Profile
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          navigate("/hackathon-login");
                        }}
                        className="btn btn-14535 btn-bluFl v2"
                      >
                        Login
                      </button>
                    )}
                  </div>
                </div>
              </div>
              
              <div className="col-lg hckPrfDtC">
              <>
                  <svg
                   
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={()=>{navigate("/hackathonlist")}}
                    style={{ cursor: "pointer" }}
                  >
                    <g clip-path="url(#clip0_363_2690)">
                      <path
                        d="M20 8L12 16L20 24"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_363_2690">
                        <rect width="32" height="32" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <span>Back to challenge Page</span>
                </>
                <div className="hckPfCnDt hckPrfCd">
                  <div className="hckPfCnDtMsg">
                    You have earned KPK coin <br /> based on your activities.
                  </div>
                  <div className="row hckPfCnDtR">
                    <div className="col-sm-6 col-md-4 col-lg-4 hckPfCnDtC">
                      <div className="hckPfCnDtCon">
                        <div className="hckPfCnDtIc">
                          <div className="mdrIcG v2">
                            <div className="mdrIcBg"></div>
                            <div className="mdrIcCn">
                              <img src={ranking} alt="ranking" />
                            </div>
                          </div>
                        </div>
                        <div className="hckPfCnDtLb">Contest ratings</div>
                        <div className="hckPfCnDtVl">
                          {" "}
                          {statusCount?.problemSolved}/
                          {statusCount?.SubmissionCount}
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-6 col-md-4 col-lg-4 hckPfCnDtC">
                      <div className="hckPfCnDtCon">
                        <div className="hckPfCnDtIc">
                          <div className="mdrIcG v2">
                            <div className="mdrIcBg"></div>
                            <div className="mdrIcCn">
                              <img src={reward} alt="reward" />
                            </div>
                          </div>
                        </div>
                        <div className="hckPfCnDtLb">Problem solved</div>
                        <div className="hckPfCnDtVl">
                          {statusCount?.problemSolved}
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-6 col-md-4 col-lg-4 hckPfCnDtC">
                      <div className="hckPfCnDtCon">
                        <div className="hckPfCnDtIc">
                          <div className="mdrIcG v2">
                            <div className="mdrIcBg"></div>
                            <div className="mdrIcCn">
                              <img src={send} alt="send" />
                            </div>
                          </div>
                        </div>
                        <div className="hckPfCnDtLb">Solutions submitted</div>
                        <div className="hckPfCnDtVl">
                          {statusCount?.SubmissionCount}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="hckPfErSv hckPrfCd">
                  <div className="hckPfErSvLt">
                    <div className="mdrIcG v3">
                      <div className="mdrIcBg"></div>
                      <div className="mdrIcCn">
                        <img src={award} alt="award" />
                      </div>
                    </div>
                    <div className="hckPfErSvTx">
                      <div className="hckPfErLb">Your Earning Amount</div>
                      <div className="hckPfErVl">
                        {profileDetails?.balance}
                        KPK
                      </div>
                    </div>
                  </div>
                  <div className="hckPfErSvRt">
                    <div className="row hckPrSvR hckcrFrm">
                      <div className="col hckPrSvC">
                        <FormGroup className="pb-0">
                          <Label for="" className="d-block">
                            Save Your Wallet Address<span>*</span>
                          </Label>
                          <input
                            className="input-control"
                            type="text"
                            id=""
                            placeholder=""
                            ref={register({
                              required: "Wallet address is required",
                            })}
                            autoComplete="off"
                            name="userAddress"
                            onKeyUp={() => {
                              trigger("userAddress");
                            }}
                            defaultValue={profileDetails?.userAddress}
                          />

                          {errors.userAddress && (
                            <div style={{ color: "red" }}>
                              {errors.userAddress.message}.
                            </div>
                          )}
                        </FormGroup>
                      </div>
                    </div>
                    <div className="hckPrSvNt">
                      <span>*</span>Save your wallet address auto transfer
                      Earning KPK Coins
                    </div>

                    <div className="col-12 hckcrFrmC">
                      <FormGroup>
                        <Label className="d-block">
                          User Type<span>*</span>
                        </Label>
                        <select
                          name="userType"
                          className="input-control"
                          ref={register({
                            required: "User type is required",
                          })}
                          onKeyUp={() => {
                            trigger("userType");
                          }}
                          value={profileDetails?.user_type}
                        >
                          <option selected disabled hidden value="">
                            Select
                          </option>
                          <option value="Investor">Investor</option>
                          <option value="Developer">Developer</option>
                        </select>

                        {errors.userType && (
                          <div style={{ color: "red" }}>
                            {errors.userType.message}.
                          </div>
                        )}
                      </FormGroup>
                    </div>

                    <div className="col-auto hckPrSvC">
                      <button
                        className="btn btn-15337 btn-bluFl v2 mb-3"
                        onClick={handleSubmit(AddAddress)}
                      >
                        {profileDetails?.userAddress ? "Edit" : "Save"}
                      </button>
                    </div>
                  </div>
                </div>

                {/* <div className="col-12 hckcrFrmC">
                  <FormGroup>
                    <Label for="*">
                      User Type<span>*</span>
                    </Label>
                    <select>
                      <option selected disabled hidden>
                        Select
                      </option>
                      <option value="Investor">Inverstor</option>
                      <option value="Developer">Developer</option>
                    </select>
                  </FormGroup>
                </div> */}

                <div className="hackathon-submisions">
                  <h5>Hackathon Submissions</h5>

                  <div className="sub-tbl">
                    <Table borderless responsive>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Title</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {submissionDetails.length > 0 ? (
                          submissionDetails &&
                          submissionDetails.map((item, i) => {
                            return (
                              <tr>
                                <td>{i + 1}</td>
                                <td>{item.project_title}</td>
                                <td>{item.submission_status}</td>
                              </tr>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>

                {/* <div className="hackathon-education">
                  <h5>Education</h5>
                  <h6 className="mt-4">Sri Ramakrishna Engineering College</h6>
                  <p className="m-0">
                    Bachelor of Engineering College (B.E) , Electriclan and
                    Communications Engineering
                  </p>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Fragment>
  );
};

export default HackathonProfile;
