import "react-toastify/dist/ReactToastify.css";

import { Bounce, Flip, Slide, Zoom } from "react-toastify";
import React, {
  Component,
  Fragment,
  createRef,
  useEffect,
  useRef,
  useState,
} from "react";
import { ToastContainer, toast } from "react-toastify";

import { CONST } from "../../../_config";
import HtmlParser from "react-html-parser";
import SidebarNew from "../../Sidebarnew/sidebarnew";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

const HackathonDetailsFaqs = (props) => {
  const params = useParams();
  console.log(params, "params");

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDd = () => setDropdownOpen((prevState) => !prevState);

  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const [HackathonDetails, setHackathonDetails] = useState([]);

  useEffect(() => {
    SingleHcakdetails();
  }, []);

  const SingleHcakdetails = async () => {
    let hackdata = {
      _id: params.id,
    };

    let getdata = await axios.post(
      CONST.BACKEND_URL + "listHackthonById",
      hackdata
    );
    console.log(getdata.data.data, "Listhacksddddddd");
    if (getdata.data.status == true) {
      // toast.success(getdata.data.message,
      //     {
      //         transition: Flip,
      //         className: 'foo-bar'
      //     })
      setHackathonDetails(getdata.data.data);
    }
  };

  return (
    <Fragment>
      {HackathonDetails && HackathonDetails.length > 0 ? (
        HackathonDetails.map((data, index) => (
          <div className="hckDtCms">
            <h6>Conclusion</h6>
            {/* <ol>
                    <li>Do I need to pay any money to register for the Hackathon? No. You do not have to pay anything to anyone to register yourself for any Hackathon on HackerEarth.</li>
                    <li>Do I need to have any specific qualifications to be a participant for the Hackathon? If you love to code, you are more than welcome to participate in the Hackathon.</li>
                    <li>How do I submit what I have made for the Hackathon? You have to develop the application on your local system and submit it on HackerEarth along with instructions to run the application and other various submission requirements.</li>
                    <li>Do we need to have the entire idea fully working? The submission should be functional on TRON or BitTorrent testnet so that it can be reviewed by the judges.</li>
                    <li>Does one have to be online and available for the entire duration of the Hackathon? No, one does not need to be logged in on HackerEarth or be online for the entire duration. You can develop the application on your local system based on the given themes and then submit it on HackerEarth, on the specific challenge page.</li>
                    <li>Do I need to give a demo for the product that I have built? You will need to submit a 3 -5 minute video showcasing your Dapp on testnet.</li>
                    <li>If it is a team submission, does that mean all team members will have access to work at the same time? Yes, all team members can login from their account and do application submission on HackerEarth.</li>
                    <li>Is it required to join TRON DAO Forum for this hackathon? Yes, it is required to join the TRON DAO forum and create a project post to participate in the current hackathon. Projects that do not join will be disqualified.</li>
                </ol> */}
            <p>{data ? HtmlParser(data.price_description) : <></>}</p>
          </div>
        ))
      ) : (
        <></>
      )}
    </Fragment>
  );
};

export default HackathonDetailsFaqs;
