import React, { Component, Fragment, useState, useRef, useEffect, createRef } from "react";
import ReactDOM from "react-dom";
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import Overview from '../Overview/Overview';
import NFTDocumentation from '../NFTDocumentation/NFTDocumentation';
import DeveloperProgram from '../DeveloperProgram/DeveloperProgram';
import {
    BrowserRouter, Navigate, Route, Routes, Link, useParams
} from 'react-router-dom';

import Header from "../../common/Header/Header";
import Footer from "../../common/Footer/Footer";

import kpuser from '../../assets/images/pics/kpuser.png';
import sicon1 from '../../assets/images/pics/sicon1.png';
import sicon2 from '../../assets/images/pics/sicon2.png';
import sicon3 from '../../assets/images/pics/sicon3.png';
import readarrow from '../../assets/images/pics/blgdetail.png';
import { CONST } from "../../_config";
import axios from "axios";
import HtmlParser from "react-html-parser";
import { useSelector, useDispatch } from 'react-redux';

const Home = (props) => {
    const [activeTab, setActiveTab] = useState('1');
    const [blogDetails, setBlog] = useState('');
    const [dates, setDate] = useState('');
    const params = useParams();
    console.log(params)
    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }
    useEffect(() => {
        getBlogDetails();
    }, [])

    const getBlogDetails = async () => {
        let data = {
            "route_url": params.id
        }
        let getblog = await axios.post('https://usrbaklifhbeje.koinpark.com/coin_detail/get_single_blog_detail', data);
        console.log(getblog)
        if (getblog.data.status == 1) {
            setBlog(getblog.data.data)
           setDate (new Date (getblog.data.data.created_at).toUTCString()) 
        }   
    }
    let site_inform = useSelector(state => (state.user));
    if(site_inform.data1.status==true){
        site_inform = site_inform.data1.data
    }

    return (
        <Fragment>
            <Header />
            <div className="pkchinsec">
                <div className="container">
                    <div className="row align-items-center justify-content-center KpBgDet">
                        <div className="col-lg-8">
                            <h3 className="KpBgDethd">{blogDetails != '' ? blogDetails.title : <></>}</h3>
                            <div className="horizonline"></div>

                            <div className=" kpbloguser1 d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-start">
                                    {/* <img src={kpuser} alt="" /> */}
                                    <div className="px-3">
                                        <h5 className="mb-2">Crypto Guide</h5>
                                        <h5 className="mb-0">{dates!='' ? (dates).slice(4,17) : <></>}</h5>
                                    </div>
                                </div>

                                {/* <div>
                                    <a href=""><img className="px-2" src={sicon1} alt="" /></a>
                                    <a href=""><img className="px-2" src={sicon2} alt="" /></a>
                                    <a href=""><img className="px-2" src={sicon3} alt="" /></a>
                                </div> */}
                                </div>

                            <div><img className="img-fluid" src={blogDetails != '' ? blogDetails.image : <></>} alt="" /></div>
                            <div className="KpBgDet">
                                {blogDetails != '' ? HtmlParser(blogDetails.content) : <></>}
                            </div>
                            {/* <p className="KpBgDetpara my-5">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>

                                <h4 className="kplorem">Lorem ipsum dolor</h4>

                                <p className="KpBgDetpara my-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p> */}

                            <div className="horizonline"></div>

                            <h4 className="kplorem my-3">Follow us to stay updated on everything Parkchain!</h4>
                            <ul className="blgnav">
                                <li><a href='https://parkchain.org/' target="_blank">Website</a></li>
                                <li><a href={site_inform ? site_inform.twitter:''} target="_blank">Twitter </a></li>
                                <li><a href={site_inform ? site_inform.facebook:''} target="_blank">Facebook </a></li>
                                <li><a href={site_inform ? site_inform.telegram:''} target="_blank">Telegram </a></li>
                                <li><a href="#" target="_blank">Discord </a></li>
                                <li><a href={site_inform ? site_inform.linkedin:''} target="_blank">LinkedIn </a></li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );

}

export default Home;