import React, { Component, Fragment, useState, useRef, useEffect, createRef } from "react";
import ReactDOM from "react-dom";
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import Overview from '../Overview/Overview';
import NFTDocumentation from '../NFTDocumentation/NFTDocumentation';
import DeveloperProgram from '../DeveloperProgram/DeveloperProgram';
import {
    BrowserRouter, Navigate, Route, Routes, Link
} from 'react-router-dom';

import Header from "../../common/Header/Header";
import Footer from "../../common/Footer/Footer";

import kppayimg1 from '../../assets/images/pics/kppayimg1.png';
import kppayimg2 from '../../assets/images/pics/kppayimg2.png';
import kpay1 from '../../assets/images/pics/kpay1.png';
import kpay2 from '../../assets/images/pics/kpay2.png';
import kpay3 from '../../assets/images/pics/kpay3.png';
import kpay4 from '../../assets/images/pics/kpay4.png';
import newbaner from '../../assets/images/home-img/swpc.png'
import PaymentImg from '../../assets/images/gaming/decpayment.png'

import { useSelector } from "react-redux";
import HtmlParser from "react-html-parser";


const Home = (props) => {
    const [activeTab, setActiveTab] = useState('1');
    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }
    let data, data1, data2, data3, data4, data5, data6, data7;
    data = useSelector(state => state.user);
    if (data.data.status == 200) {
        data = data.data.data;
        data1 = data.find(elem => elem.title == 'Scalable Decentralized Payments');
        data2 = data.find(elem => elem.title == 'payments');
        data3 = data.find(elem => elem.title == 'Why Parkchain? - Payments');
        data4 = data.find(elem => elem.title == 'Revolutionizing Payments with Parkchain');
        data5 = data.filter(elem => elem.title == 'Revolutionizing Payments');
        data6 = data.find(elem => elem.title == 'XXXX framework - payments')
    }
    const [classIsActive, setClassIsActive] = useState(true);
    const smClose = () => {
        setClassIsActive(!classIsActive);
        classIsActive ? document.body.classList.add('sbMenu') : document.body.classList.remove('sbMenu');
    }
    return (
        <Fragment>
            <Header />
            <div className="pkchinsec">
                <section className="kppaybanner">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                {/* <h2 className="kpgamehead">Scalable Decentralized Payments</h2> */}
                                <h2 className="kpgamehead">{data1 ? HtmlParser((data1.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</h2>
                                <p className="kpgamepara">{data2 ? HtmlParser((data2.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                            </div>
                            <div className="col-lg-6">
                                <div className="text-center"><img src={PaymentImg} alt="" className="img-fluid" /></div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="container">
                    <div className="row my-5 align-items-center justify-content-center">
                        <div className="col-lg-9">
                            <div className="ethgamekit">
                                <div className="ethgamecnt">
                                    {/* {data3 ? HtmlParser((data3.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>} */}
                                    <h2 className="kpkithead"> Why Parkchain?</h2>
                                    <p className="kpkitpara">Develop the future iteration of merchant payment and commerce infrastructure utilizing Ethereum technology, which stands as the exclusive option for economical and immediate transaction settlement in both online and physical retail transactions. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="">
                    <div className="container">
                        {/* <h2 className="kpbuldtxt text-center mb-5">{data4 ? HtmlParser((data4.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</h2> */}
                        <h2 className="kpbuldtxt text-center mb-4">
                            Revolutionizing Payment With Parkcoin
                            </h2>
                        <div className="">
                            
                            <div className="row align-items-center justify-content-center">
                                <div className="col-lg-10">
                                    <div className="row">
                                        {data5 && data5.length > 0 ?
                                            data5.map((data, index) => (
                                                <div className="col-lg-6 mb-5">
                                                    <div className="rpwitheth">
                                                        {/* 
                                                        <p>No more burdensome interchange and additional fees that eat into your revenue. Parkchain's transaction fees are only a fraction of a cent, allowing you to maximize your profits.</p> */}
                                                        {/* {data ? HtmlParser((data.title).replace(/(<([^>]+)>)/gi, "")) : <></>} */}
                                                        {data ? HtmlParser((data.content_description)) : <></>}
                                                        <div className="text-center mb-2">
                                                            <img src={data ? data.image : <></>} alt="" className="img-fluid" />
                                                        </div>
                                                    </div>
                                                </div>
                                            )) : <></>
                                        }

                                        {/* <div className="col-lg-6 mb-5">
                                            <div className="rpwitheth">
                                                <div className="text-center mb-2"><img src={kpay2} alt="" className="img-fluid" /></div>
                                                <h4>Capture New Revenue</h4>
                                                <p>Experience lightning-fast settlement times in milliseconds, enabling you to earn high yields on your USD stablecoins without any delay. Seize the opportunity to generate additional revenue streams.</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 mb-5">
                                            <div className="rpwitheth">
                                                <div className="text-center mb-2"><img src={kpay3} alt="" className="img-fluid" /></div>
                                                <h4>Incentivize Your Customers</h4>
                                                <p>Drive customer loyalty and engagement by offering on-chain rewards and loyalty programs. Share the incremental value with your customers, fostering a mutually beneficial relationship.</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 mb-5">
                                            <div className="rpwitheth">
                                                <div className="text-center mb-2"><img src={kpay4} alt="" className="img-fluid" /></div>
                                                <h4>Own Your Relationships</h4>
                                                <p>Take control of your business by removing intermediaries. Embrace a direct relationship with your customers, becoming a merchant that directly serves their needs.</p>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row lastCon align-items-center">
                            <div className="col-lg-7">
                                <div className="">
                                    {/* <h5 className="xxframe">XXXX framework</h5>
                                    <h3 className="ignHead">Collaborate for success</h3>
                                    <p className="ignPara">Collaborate with Parkchain. Join us to shape the future of commerce. Parkchain's blockchain offers the foundation for innovative solutions in payments, loyalty, and offers.</p>
                                    <h3 className="kpbuldtxt">Let's build together. </h3> */}
                                    {data6 ? HtmlParser((data6.content_description)) : <></>}
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="text-center">
                                    <img src={newbaner} alt="" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="text-center py-2">
                    <a href="https://parkwallet.net/" target="_blank" class="btn btn-primary px-4">
                        Explore
                    </a>
{/* 
                    <div class="mobMenu d-lg-none" >
                        <div class="smClose" onClick={smClose} >
                            <svg id="closeicon" viewBox="0 0 800 600">
                                <path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" id="top"></path>
                                <path d="M300,320 L460,320" id="middle"></path>
                                <path d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190" id="bottom" transform="translate(480, 320) scale(1, -1) translate(-480, -318) "></path>
                            </svg>
                        </div>
                    </div> */}

                </div>
            </div>
            <Footer />
        </Fragment>
    );

}

export default Home;