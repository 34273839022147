import "react-toastify/dist/ReactToastify.css";

import { Bounce, Flip, Slide, Zoom } from "react-toastify";
import {
  BrowserRouter,
  Link,
  Navigate,
  Route,
  Routes,
  useParams,
} from "react-router-dom";
import {
  Collapse,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import {
  Button, Form, FormGroup, Label, Input, FormText,
  Row, Col, CustomInput, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import React, {
  Component,
  Fragment,
  createRef,
  useEffect,
  useRef,
  useState,
} from "react";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { CONST } from "../../_config";
import Footer from "../../common/Footer/Footer";
import HackathonDetailsDateTime from "./HackathonDetails/HackathonDetailsDateTime";
import HackathonDetailsFaqs from "./HackathonDetails/HackathonDetailsFaqs";
import HackathonDetailsJudging from "./HackathonDetails/HackathonDetailsJudging";
import HackathonDetailsOverview from "./HackathonDetails/HackathonDetailsOverview";
import HackathonDetailsPrizeEligibility from "./HackathonDetails/HackathonDetailsPrizeEligibility";
import HackathonDetailsPrizes from "./HackathonDetails/HackathonDetailsPrizes";
import HackathonDetailsRules from "./HackathonDetails/HackathonDetailsRules";
import HackathonDetailsThemes from "./HackathonDetails/HackathonDetailsThemes";
import HackathonDetailsWebHosting from "./HackathonDetails/HackathonDetailsWebHosting";
import HackathonSubmission from "./HackathonSubmission";
import Header from "../../common/Header/Header";
import axios from "axios";
import calendar from "../../assets/images/hackathonList/calendar.svg";
import classnames from "classnames";
import hackerearth_banner from "../../assets/images/hackathonList/hackerearth_banner.jpg";
import laptopCoffee from "../../assets/images/hackathonList/laptopCoffee.png";
import { localUserId } from "../../redux/action";
import monitor from "../../assets/images/hackathonList/monitor.svg";
import tron from "../../assets/images/hackathonList/tron.png";

import closeCircle from '../KPHackathonUser/assets/close-circle.svg';
import success from '../KPHackathonUser/assets/success.gif';

const HackathonDetails = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  var ReduxRes = useSelector((state) => state);
  ReduxRes = ReduxRes?.user;
  const params = useParams();
  const [dropdownOpen, setDropdownOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const toggleDd = () => setDropdownOpen((prevState) => !prevState);

  const [activeTab, setActiveTab] = useState("1");
  const [enroll_status, setEnroll_status] = useState("");
  const [hackathonID, setHackathonID] = useState("");


  const [modal, setModal] = useState();
  const toggle2 = () => setModal(!modal);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const [isActive, setActive] = useState("false");
  const ToggleClass = () => {
    setActive(!isActive);
  };

  const [isOpen, setIsOpen] = useState(false);
  const toggleSubMenu = () => setIsOpen(!isOpen);

  const [HackathonDetails, setHackathonDetails] = useState([]);
  const [userProfile, setUserProfile] = useState();

  const location = useLocation();

  console.log("location----", location?.state?.AI);

  var type = location?.state?.AI;



  useEffect(() => {
    SingleHcakdetails();
    dispatch(localUserId());
    getUserProfile();

  }, [ReduxRes?.userId, HackathonDetails[0]?.hackthonId]);
  console.log(ReduxRes?.userId, "ReduxRes?.user?.userId?.user_id");

  const SingleHcakdetails = async () => {

    setLoading(true);
    let hackdata = {
      _id: params.id,
    };

    await axios
      .post(CONST.BACKEND_URL + "listHackthonById", hackdata)

      .then((res) => {
        if (res.data.status == true) {
          console.log(res.data.data, "Listhacksddddddd");
          setLoading(false);
          // toast.success(res.data.message, {
          //   transition: Flip,
          //   className: "foo-bar",s
          // });
          setHackathonDetails(res.data.data);
          setHackathonID(res.data.data[0].hackthonId);
          EnrollStatus(res.data.data[0].hackthonId);
        }
      })
      .catch((err) => {
        setLoading(false);

        console.log(err);
      });
  };
  console.log(
    HackathonDetails[0]?.hackthonId,
    "HackathonDetailsHackathonDetails"
  );


  const getUserProfile = async () => {
    console.log("getiiiio")
    let hackdata = {
      userId: ReduxRes?.userId,
    };
    await axios
      .post(CONST.BACKEND_URL + "users/getUserProfile", hackdata)
      .then((res) => {
        console.log(res.data, "getresponsedtatattat");
        if (res.data.status == true) {
          console.log(res.data.data, "getuserprofileeeeeeeeeeeeeeeeeeee");
          // toast.success(res.data.message, {
          //   transition: Flip,
          //   className: "foo-bar",s
          // });
          setUserProfile(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const EnrollStatus = (hackthonId) => {
    console.log("enrooooo")
    const data = {
      hackathon_id: hackthonId,
      userId: ReduxRes?.userId,
    };

    axios
      .post(CONST.BACKEND_URL + "users/userWiseHackathonStatusList", data)

      .then((res) => {
        console.log("res------", res.data.data.enroll_status);
        setEnroll_status(res.data.data.enroll_status);
        if (res.data.data.enroll_status == false) {
          toggle2()
        }
        else {
          // alert("None")
        }
      })

      .catch((err) => {
        console.log("err");
      });
  };

  const EnrollMag = (data) => {
    console.log("data1111111111", data);

    return (
      <button
        className="btn btn-13738 btn-bluFl v2"
        onClick={() => {
          if (ReduxRes?.userId) {
            {
              (data.status == "live" &&
                enroll_status == false &&
                userProfile?.user_type == "Developer") ||
                (data.status == "upcoming" &&
                  enroll_status == false &&
                  userProfile?.user_type == "Developer")
                ? navigate(`/hackathon-form1/${data.hackthonId}`, {
                  state: {
                    hackathonId: data.hackthonId,
                    endTime: new Date(data.end_date_time).toUTCString(),
                    _id: params.id,
                  },
                })
                : (data.status == "live" &&
                  enroll_status == true &&
                  userProfile?.user_type == "Developer") ||
                  (data.status == "upcoming" &&
                    enroll_status == true &&
                    userProfile?.user_type == "Developer")
                  ? navigate("/hackathon-main", {
                    state: {
                      hackathonId: data.hackthonId,
                      _id: params.id,
                    },
                  })
                  : data.status == "previous"
                    ? navigate("/hackathonlist")
                    : navigate("/hackathon-invite", {
                      state: {
                        hackathonId: data.hackthonId,
                        _id: params.id,
                      },
                    });
            }
          } else {
            navigate("/hackathon-login");
          }
        }}
      >
        {ReduxRes?.userId
          ? (data.status == "live" &&
            enroll_status == false &&
            userProfile?.user_type == "Developer") ||
            (data.status == "upcoming" &&
              enroll_status == false &&
              userProfile?.user_type == "Developer")
            ? "ENROLL"
            : (data.status == "live" &&
              enroll_status == true &&
              userProfile?.user_type == "Developer") ||
              (data.status == "upcoming" &&
                enroll_status == true &&
                userProfile?.user_type == "Developer")
              ? "ADD IDEA"
              : data.status == "previous"
                ? "CHECK OTHER HACKATHON"
                : "Create a Team"
          : "Login"}
      </button>
    );
  };

  return (
    <Fragment>
      <Header />

      {loading == true ? (
        <div className="pageloade">
          <div className="page-loader">
            <div class="loader-12">
              <span class="loader_arrow"></span>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {HackathonDetails && HackathonDetails.length > 0 ? (
        HackathonDetails.map((data, i) => {
          var startDate = new Date(data.start_datetime).toLocaleString();
          var endDate = new Date(data.end_date_time).toLocaleString();

          return (
            <div className="hckDtPg">
              <div className="hckLsBnr">
                <div className="container">
                  <div className="hckLsBnrR row">
                    <div className="hckLsBnrC col-lg-auto">
                      <div className="hckLsBnrTx">
                        <h1>Hackathons</h1>
                      </div>
                    </div>
                    <div className="hckLsBnrC col-lg-auto">
                      <div className="hckLsBnrIg">
                        <img src={laptopCoffee} alt="laptopCoffee" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="hckDtBnr">
                <div className="container">
                  <div className="hckDtBnrIg">
                    <img src={data.image} alt="hackerearth_banner" />
                  </div>
                  {/* <div className="hckDtBnrCr">
                                    <img src={tron} alt="tron" />
                                </div> */}
                </div>
              </div>

              <div className="hckDtFl">
                <div className="container">
                  <div className="hckDtFlHd">
                    <h1>
                      {" "}
                      <span>{data.status}</span> {data.title}
                    </h1>
                  </div>
                  <div className="hckDtFlBd">
                    <div className="row hckDtFlBdR mb-3">
                      <div className="col-md-6 col-lg-4 col-xl-auto mb-2 mb-lg-0 ">
                        <div className="hckDtFlEvCon">
                          <div className="hckDtFlEvCnLt">
                            <img src={calendar} alt="calendar" />
                          </div>
                          <div className="hckDtFlEvCnRt">
                            <div className="hckDtFlLb">Starts on</div>
                            <div className="hckDtFlVl">{startDate}</div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-4 col-xl-auto mb-2 mb-lg-0">
                        <div className="hckDtFlEvCon">
                          <div className="hckDtFlEvCnLt">
                            <img src={calendar} alt="calendar" />
                          </div>
                          <div className="hckDtFlEvCnRt">
                            <div className="hckDtFlLb">Ends on</div>
                            <div className="hckDtFlVl">{endDate}</div>
                          </div>
                        </div>
                      </div>
                      <div className="col-auto mb-2 mb-lg-0">
                        <div className="hckDtFlEvCon">
                          <div className="hckDtFlEvCnLt">
                            <img src={monitor} alt="monitor" />
                          </div>
                          <div className="hckDtFlEvCnRt">
                            <div className="hckDtFlLb">Hackathon</div>

                            <div className="hckDtFlVl">
                              {data.hackathon_type}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-auto mb-2 mb-lg-0">
                        {data.heading !== "userHackathon" ?
                          <div className="hckEveCnt v2 d-inline-flex w-auto">
                            <span className="hckEveCntTx">Participations</span>
                            <span className="hckEveCntNb">
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10.0007 9.99999C12.3018 9.99999 14.1673 8.13451 14.1673 5.83332C14.1673 3.53214 12.3018 1.66666 10.0007 1.66666C7.69946 1.66666 5.83398 3.53214 5.83398 5.83332C5.83398 8.13451 7.69946 9.99999 10.0007 9.99999Z"
                                  fill="white"
                                />
                                <path
                                  d="M10.0008 12.0833C5.82578 12.0833 2.42578 14.8833 2.42578 18.3333C2.42578 18.5667 2.60911 18.75 2.84245 18.75H17.1591C17.3924 18.75 17.5758 18.5667 17.5758 18.3333C17.5758 14.8833 14.1758 12.0833 10.0008 12.0833Z"
                                  fill="white"
                                />
                              </svg>
                              <span>{data.enroll_count}</span>
                            </span>
                          </div> : <></>}
                      </div>
                      {/* <div className="col-auto hckDtFlBdC">
                      {console.log("data.status", data.status)}
                      {data.status == "live" && data.status_enroll == false ? (
                        <button
                          className="btn btn-13738 btn-bluFl v2"
                          key={i}
                          onClick={() => {
                            navigate("/hackathon-form1", {
                              state: {
                                hackathonId: params.id,
                                endTime: new Date(
                                  data.end_date_time
                                ).toUTCString(),
                              },
                            });
                          }}
                        >
                          ENROLL NOW
                        </button>
                      ) : data.status == "previous" ? (
                        <button
                          className="btn btn-13738 btn-bluFl v2"
                          key={i}
                          onClick={() => {
                            navigate("/hackathonlist");
                          }}
                        >
                          CHECK OUT OTHER HACKATHONS
                        </button>
                      ) : (
                        <></>
                      )}
                    </div> */}
                      <div className="col-auto hckDtFlBdCs">
                        <h1 key={i}>{EnrollMag(data)}</h1>
                        {console.log(data, "data")}
                      </div>

                      {userProfile?.user_type == "Developer" ? (
                        <button
                          className="btn btn-13738 btn-bluFl v2 mx-3"
                          onClick={() => {
                            navigate("/hackathon-invite", {
                              state: {
                                hackathonId: data.hackthonId,
                                _id: params.id,
                              },
                            });
                          }}
                        >
                          View Team
                        </button>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="hckDtTabCnt">
                <div className="container">
                  <div className="hckDtTabCntTg">
                    <span className="hckDtTx"></span>
                    <svg
                      className="hckDtTg"
                      viewBox="0 0 100 100"
                      onClick={ToggleClass}
                    >
                      <rect
                        className={
                          isActive
                            ? "hamburger-line hamburger-line-1"
                            : "hamburger-line hamburger-line-1 active"
                        }
                        x="20"
                        y="50"
                        rx="1"
                        width="60"
                        height="2"
                      ></rect>
                      <rect
                        className={
                          isActive
                            ? "hamburger-line hamburger-line-2"
                            : "hamburger-line hamburger-line-2 active"
                        }
                        x="20"
                        y="50"
                        rx="1"
                        width="60"
                        height="2"
                      ></rect>
                      <rect
                        className={
                          isActive
                            ? "hamburger-line hamburger-line-3"
                            : "hamburger-line hamburger-line-3 active"
                        }
                        x="20"
                        y="50"
                        rx="1"
                        width="60"
                        height="2"
                      ></rect>
                    </svg>
                  </div>

                  <div className="row hckDtTabR">
                    <div className="col-lg-auto hckDtTabC lt">
                      <div className={isActive ? "hckDtTab" : "hckDtTab show"}>
                        <div
                          className="hckDtTabCls d-lg-none"
                          onClick={ToggleClass}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="icon icon-tabler icon-tabler-x"
                            width="44"
                            height="44"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="#2c3e50"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M18 6l-12 12" />
                            <path d="M6 6l12 12" />
                          </svg>
                        </div>

                        {HackathonDetails[0]?.heading !== "AI" ? (
                          <Nav tabs>
                            <NavItem>
                              <NavLink
                                className={
                                  "dropdown-toggle " +
                                  classnames({
                                    active:
                                      activeTab === "1" ||
                                      activeTab === "2" ||
                                      activeTab === "3",
                                  })
                                }
                                onClick={toggleSubMenu}
                              >
                                About
                              </NavLink>
                              <Collapse isOpen={isOpen}>
                                <ul className="">
                                  <NavLink
                                    className={classnames({
                                      active: activeTab === "1",
                                    })}
                                    onClick={() => {
                                      toggle("1");
                                    }}
                                  >
                                    Overview
                                  </NavLink>

                                  {/* <NavLink
                                    className={classnames({
                                      active: activeTab === "3",
                                    })}
                                    onClick={() => {
                                      toggle("3");
                                    }}
                                  >
                                    Prizes
                                  </NavLink> */}
                                </ul>
                              </Collapse>
                            </NavItem>
                            <NavLink
                              className={classnames({
                                active: activeTab === "2",
                              })}
                              onClick={() => {
                                toggle("2");
                              }}
                            >
                              Project goals
                            </NavLink>
                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: activeTab === "4",
                                })}
                                onClick={() => {
                                  toggle("4");
                                }}
                              >
                                {/* <a href={`/hackathon-details/${data._id}/rules`}></a> */}
                                Features and Functionalities:
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: activeTab === "5",
                                })}
                                onClick={() => {
                                  toggle("5");
                                }}
                              >
                                Conclusion:
                              </NavLink>
                            </NavItem>

                            {/* <NavItem>
                              <NavLink
                                className={classnames({
                                  active: activeTab === "6",
                                })}
                                onClick={() => {
                                  toggle("6");
                                }}
                              >
                                Prize Eligibility
                              </NavLink>
                            </NavItem> */}
                            {/* <NavItem>
                              <NavLink
                                className={classnames({
                                  active: activeTab === "7",
                                })}
                                onClick={() => {
                                  toggle("7");
                                }}
                              >
                                Judging Criteria
                              </NavLink>
                            </NavItem> */}
                            {/* <NavItem>
                            <NavLink
                              className={classnames({
                                active: activeTab === "8",
                              })}
                              onClick={() => {
                                toggle("8");
                              }}
                            >
                              Dates and Timing
                            </NavLink>
                          </NavItem> */}

                            {/* <NavItem>
                              <NavLink
                                className={classnames({
                                  active: activeTab === "9",
                                })}
                                onClick={() => {
                                  toggle("9");
                                }}
                              >
                                Free Web Hosting
                              </NavLink>
                            </NavItem> */}

                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: activeTab === "10",
                                })}
                                onClick={() => {
                                  toggle("10");
                                }}
                              >
                                Submissions
                              </NavLink>
                            </NavItem>
                          </Nav>
                        ) : (
                          <Nav tabs>
                            <NavItem>
                              <NavLink
                                className={
                                  "dropdown-toggle " +
                                  classnames({
                                    active:
                                      activeTab === "1" ||
                                      activeTab === "2" ||
                                      activeTab === "3",
                                  })
                                }
                                onClick={toggleSubMenu}
                              >
                                About
                              </NavLink>
                              <Collapse isOpen={isOpen}>
                                <ul className="">
                                  <NavLink
                                    className={classnames({
                                      active: activeTab === "1",
                                    })}
                                    onClick={() => {
                                      toggle("1");
                                    }}
                                  >
                                    Overview
                                  </NavLink>
                                  <NavLink
                                    className={classnames({
                                      active: activeTab === "2",
                                    })}
                                    onClick={() => {
                                      toggle("2");
                                    }}
                                  >
                                    Key Features:
                                  </NavLink>
                                </ul>
                              </Collapse>
                            </NavItem>

                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: activeTab === "4",
                                })}
                                onClick={() => {
                                  toggle("4");
                                }}
                              >
                                {/* <a href={`/hackathon-details/${data._id}/rules`}></a> */}
                                Use Case Scenarios
                              </NavLink>
                            </NavItem>

                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: activeTab === "6",
                                })}
                                onClick={() => {
                                  toggle("6");
                                }}
                              >
                                Benefits
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: activeTab === "7",
                                })}
                                onClick={() => {
                                  toggle("7");
                                }}
                              >
                                Conclusion
                              </NavLink>
                            </NavItem>
                            {/* <NavItem>
                            <NavLink
                              className={classnames({
                                active: activeTab === "8",
                              })}
                              onClick={() => {
                                toggle("8");
                              }}
                            >
                              Dates and Timing
                            </NavLink>
                          </NavItem> */}
                          </Nav>
                        )}
                      </div>
                    </div>
                    <div className="col-lg hckDtTabC rt">
                      <div className="hckDtTabCon">
                        <TabContent activeTab={activeTab}>
                          <TabPane tabId="1">
                            <HackathonDetailsOverview />
                          </TabPane>
                          <TabPane tabId="2">
                            <HackathonDetailsThemes
                              feature={location?.state?.AI == true ? true : ""}
                            />
                          </TabPane>
                          <TabPane tabId="3">
                            <HackathonDetailsPrizes />
                          </TabPane>
                          <TabPane tabId="4">
                            <HackathonDetailsRules
                              useCase={location?.state?.AI == true ? true : ""}
                            />
                          </TabPane>
                          <TabPane tabId="5">
                            <HackathonDetailsFaqs />
                          </TabPane>
                          <TabPane tabId="6">
                            <HackathonDetailsPrizeEligibility
                              Benefits={location?.state?.AI == true ? true : ""}
                            />
                          </TabPane>
                          <TabPane tabId="7">
                            <HackathonDetailsJudging
                              conclusion={
                                location?.state?.AI == true ? true : ""
                              }
                            />
                          </TabPane>
                          {/* <TabPane tabId="8">
                            <HackathonDetailsDateTime />
                          </TabPane> */}
                          <TabPane tabId="9">
                            <HackathonDetailsWebHosting />
                          </TabPane>

                          <TabPane tabId="10">
                            <HackathonSubmission
                              _id={params.id}
                              hackathonId={hackathonID}
                            />
                          </TabPane>
                        </TabContent>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <></>
      )}
      {userProfile?.user_type === "Developer" ? (<Modal isOpen={modal} toggle={toggle2} centered={true} className="kphckMdl" backdropClassName={'kphckMdlBkDrp'}>
        <ModalBody>
          <button className="btn btnMdlcls" onClick={toggle2}>
            <img src={closeCircle} alt="closeCircle" />
          </button>
          <div className="kpHckMdlIc">
            <img src={success} alt="success" />
          </div>
          <div className="kpHckMdlTx">
            <h4>{`Kindly Enroll ${HackathonDetails[0]?.title} Hackathon`}</h4>
            {/* <p>
              A reset link has been sent to
              <a href="#"></a>
            </p> */}
          </div>
        </ModalBody>
      </Modal>
      )
        :
        <></>
      }

      <Footer />
    </Fragment>
  );
};

export default HackathonDetails;
