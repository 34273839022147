import React, { Component, Fragment, useState, useRef, useEffect, createRef  } from "react";
import ReactDOM from "react-dom";
import classnames from 'classnames';
import './scss/learn.scss';
import Header from "../../common/Header/Header";
import Footer from "../../common/Footer/Footer";
/** Images  **/
import learnBnr from './images/learnbnr.png';
import video from './images/Video.png';
const Learnhub = (props) => {  
return (
<Fragment>
   <Header />
   <div className="learnhub-pagecnt">
      <section className="learnhubbnr-sec bluebgbnr-sec">
         <div className="container">
            <div className="row align-items-center">
               <div className="col-lg-6">
                  <div className="cmnbnr-txt">
                     <h1>ParkChain <br /> <span>Learn Hub</span></h1>
                     <p>Getting started on ParkChain Blockchain</p>
                  </div>
               </div>
               <div className="col-lg-6 text-center mt-4 mt-lg-0">
                  <div>
                     <img src={learnBnr} alt="" className="img-fluid"/>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section className="py-5">
         <div className="container">
            {/* <div className="learn-inrcnt">
               <div className="row">
                  <div className="col-lg-1">
                     <div className="no-box">
                        <h4>01</h4>
                     </div>
                  </div>
                  <div className="col-lg-11 mt-4">
                     <div className="rpc-inrcnt">
                        <h2>New to crypto? Start here</h2>
                        <img src={video} alt="" className="img-fluid"/>
                     </div>
                  </div>
               </div>
            </div> */}
            <div className="learn-inrcnt my-4">
               <div className="row">
                  <div className="col-lg-1">
                     <div className="no-box">
                        <h4>01</h4>
                     </div>
                  </div>
                  <div className="col-lg-11 mt-4 mb-2">
                     <div className="rpc-inrcnt">
                        <h2>Parkchain custom RPC settings</h2>
                        <div className="rpcsettings-flex">
                           <div>
                              <h5>Network Name</h5>
                              <p>ParkChain (PosA) KPK Mainnet</p>
                           </div>
                           <div>
                              <h5>New RPC URL</h5>
                              <p>https://rpc.parkscan.net</p>
                           </div>
                           <div>
                              <h5>Chain ID</h5>
                              <p>1225</p>
                           </div>
                           <div>
                              <h5>Currency Symbol</h5>
                              <p>KPK</p>
                           </div>
                           <div>
                              <h5>Block Explorer URL</h5>
                              <p>https://parkscan.net</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="learn-inrcnt">
               <div className="row">
                  <div className="col-lg-1">
                     <div className="no-box">
                        <h4>02</h4>
                     </div>
                  </div>
                  <div className="col-lg-11 mt-4">
                     <div className="rpc-inrcnt">
                        <h2>Ready to learn more?</h2>
                        <p>Sign up to the Parkchain website for learn more.</p>
                        {/* <div window.open('')  > */}
                        <button className="btn btn-primary"  onClick={() => window.open("https://koinpark.com/")} >Signup Now</button>
                        {/* </div> */}
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
   </div>
   <Footer />
</Fragment>
);
}
export default Learnhub;