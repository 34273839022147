import "./kphckcr.scss";
import "./kphckcr-dev.scss";
import "react-toastify/dist/ReactToastify.css";

import {
  BrowserRouter,
  Link,
  Navigate,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import {
  Button,
  Col,
  CustomInput,
  Form,
  FormGroup,
  FormText,
  Input,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { CONST, PARKCHAIN_API } from "../../_config";
import React, {
  Component,
  Fragment,
  createRef,
  useEffect,
  useRef,
  useState,
} from "react";
import { ToastContainer, toast } from "react-toastify";
import { browserName, browserVersion, deviceType } from "react-device-detect";
import { dashboard, getUserProfile } from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";

import { Icon } from "react-icons-kit";
import Logo from "./assets/logo.svg";
import ReactDOM from "react-dom";
import axios from "axios";
import classnames from "classnames";
import closeCircle from "./assets/close-circle.svg";
import { eye } from "react-icons-kit/feather/eye";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import laptop from "./assets/laptop.png";
import { localUserId } from "../../redux/action";
import success from "./assets/success.gif";
import { useForm } from "react-hook-form";

const HackathonLogin = (props) => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);

  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  const toggle2 = () => setModal(!modal);

  const [userLoginDetails, setUserLoginDetails] = useState({
    email: "",
    password: "",
  });

  const [isChecked, setIsChecked] = useState(false);

  const { register, handleSubmit, errors, watch, trigger } = useForm({
    mode: "onBlur",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  var reduxRes = useSelector((state) => state);
  reduxRes = reduxRes?.user;

  console.log("reduxRes", reduxRes?.user?.dashboardInfo);

  useEffect(() => {}, [reduxRes?.userProfile?.data?.user_type]);

  const Profile = (data) => {
    dispatch(getUserProfile({ userId: data }));
    var pathName = localStorage.getItem("pathName");
    console.log(pathName,"pathnameeee")

    if (reduxRes?.userProfile?.data) {
      if (reduxRes?.userProfile?.data?.user_type) {
        if (pathName === null || pathName === "" || pathName === undefined) {
          navigate("/hackathonlist");
        } else {
          navigate(pathName);
        }
      } else {
        navigate("/hackathon-profile");
      }
    }
  };

  useEffect(() => {
    var userID = localStorage.getItem("user_id");

    if (userID) {
      Profile(userID);
    }
  }, [reduxRes?.userProfile?.data?.user_type]);

  const Submit = (e) => {
    console.log(e, isChecked, "userDetails");

    setLoading(true);

    const loginDetails = {
      email: e.email,
      password: e.password,
    };

    //konprkusrbakkweb.koinpark.com/auth/metapark_user_login

    axios
      .post(PARKCHAIN_API.parkChain + "auth/metapark_user_login", loginDetails)
      .then((res) => {
        console.log("resLogin", res.data);
        setLoading(false);
        if (res.data.responseCode == "Success") {
          dispatch(dashboard(res.data.data));

          console.log("res.data.user_id", res.data);
          const data = {
            // device_browser: browserName,
            // device_model: browserVersion,
            // device_IMEI: browserVersion,
            // device_type: deviceType,
            user_id: res.data.data.user_id,
            login_verify_token: res.data.data.device_token,
            // tfa_enabled: res.data.tfa_enabled,
            // tfa_or_mob_status: res.data.tfa_or_mob_status,
          };

          localStorage.setItem("user_id", data.user_id);

          dispatch(localUserId());
          Profile(data.user_id);

          // localStorage.setItem("auth", authToken);

          toast.success(`${res.data.message}`);
          localStorage.setItem("time", Date.now() + 60000);
        } else {
          toast.error(`${res.data.message}`);
          // setLoading(false);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });
  };

  return (
    <Fragment>
      {loading == true ? (
        <div className="pageloade">
          <div className="page-loader">
            <div class="loader-12">
              <span class="loader_arrow"></span>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className="hckcrPg">
        <div className="container">
          <div className="hckcrPgCon">
            <form class="mx-1 mx-md-4" onSubmit={handleSubmit(Submit)}>
              <div className="row hckcrPgR">
                <div className="col-sm-12 col-md-12 col-lg-7 col-xl-6 hckcrPgC lt">
                  <div className="hckcrPgInrC">
                    <div className="hckcrPgLgo">
                      <img src={Logo} alt="Logo" />
                    </div>

                    <div className="hckcrFrm">
                      <div className="row hckcrFrmR">
                        <div className="col-12 hckcrFrmC">
                          <FormGroup>
                            <Label for="*">
                              Email<span>*</span>
                            </Label>

                            <input
                              className="form-control"
                              type="text"
                              placeholder="Email"
                              ref={register({
                                required: "Email is required",
                                pattern: {
                                  value:
                                    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                  message: "Invalid email address",
                                },
                              })}
                              autoComplete="off"
                              name="email"
                              onKeyUp={() => {
                                trigger("email");
                              }}
                            />

                            {errors.email && (
                              <div style={{ color: "red" }}>
                                {errors.email.message}.
                              </div>
                            )}
                          </FormGroup>
                        </div>

                        <div className="col-12 hckcrFrmC">
                          <FormGroup>
                            <Label for="*">
                              Password<span>*</span>
                            </Label>
                            <InputGroup className="align-items-center">
                              <input
                                className="form-control"
                                type={type}
                                placeholder="Password"
                                ref={register({
                                  required: "Password is required",
                                })}
                                name="password"
                                onKeyUp={() => {
                                  trigger("password");
                                }}
                              />

                              <span
                                class="flex justify-around items-center"
                                onClick={handleToggle}
                                role="button"
                              >
                                <Icon
                                  class="absolute mr-10"
                                  icon={icon}
                                  size={25}
                                />
                              </span>
                            </InputGroup>

                            {errors.password && (
                              <div style={{ color: "red" }}>
                                {errors.password.message}
                              </div>
                            )}
                          </FormGroup>
                        </div>

                        <div className="col-12 hckcrFrmC">
                          <FormGroup>
                            <div className="RemfgtLk">
                              {/* <div class="custom-checkbox custom-control">
                                <input
                                  type="checkbox"
                                  id="rebMe"
                                  class="custom-control-input"
                                  onChange={(e) => {
                                    setIsChecked(e.target.checked);
                                  }}
                                  checked={isChecked}
                                />
                                <label class="custom-control-label" for="rebMe">
                                  Remember Me
                                </label>
                              </div> */}

                              <div className="fgtLk">
                                <Link to="/hackathon-forgot">
                                  Forgot Password?
                                </Link>
                              </div>
                            </div>
                          </FormGroup>
                        </div>
                      </div>
                    </div>

                    <div className="hckcrFrmSbmRd">
                      <button class="btn btn-17442 btn-bluFl v2">Login</button>
                      <div className="hckCrRd">
                        <p>
                          Don't have an account?
                          <Link to="/hackathon-register">Sign up here</Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-12 col-md-12 col-lg-5 hckcrPgC rt">
                  <div className="hckcrPgInrIgTk">
                    <div className="hckCrPgIg">
                      <img src={laptop} alt="laptop" />
                    </div>
                    <div className="hckCrPgTx">
                      <h1>
                        Welcome to <br /> Login
                      </h1>
                      <p> &copy; 2023 Parkchain - All rights reserved.</p>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default HackathonLogin;
