import React, { Component, Fragment, useState, useRef, useEffect, createRef } from "react";
import { BrowserRouter, Link, Navigate, Route, Routes } from "react-router-dom";
import ReactDOM from "react-dom";
import classnames from 'classnames';
import Header from "../../common/Header/Header";
import Footer from "../../common/Footer/Footer";
/** Images  **/
import docbnr from './images/doc-bnr.png'
const Document = (props) => {
   return (
      <Fragment>
         <Header />
         <div className="documentpage-content">
            <section className="bluebg-bnrsec">
               <div className="container">
                  <div className="blubg-bannercnt">
                     <div className="row align-items-center">
                        <div className="col-lg-7">
                           <div className="bluebg-bnrcnt">
                              <h1>Read the Documents</h1>
                              <p>ParkChain Protocol, Parkchain Core, NFT, Developer Programs, Networking and Earning Opportunities, KPK Business Opportunities, Political Metaverse, Earning Opportunities (Metaverse - Realestate - Politics - NFT), Technical updates.</p>
                           </div>
                        </div>
                        <div className="col-lg-5 text-center mt-4">
                           <div className="bluebg-bnrimg">
                              <img src={docbnr} alt="" className="img-fluid" />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <section className="mt-5 pb-4">
               <div className="container">
                  <div className="row">
                     <div className="col-md-6 col-lg-4">
                        <div className="document-inrbox">
                           <div className="document-cnt">
                              <h5>Document</h5>
                              <h3>ParkChain <br /> Protocol</h3>
                           </div>
                           <a href="https://tutorial.parkchain.org/parkchain-protocol/" target="_blank"><button className="btn btn-primary">Read</button></a>
                        </div>
                     </div>
                     <div className="col-md-6 col-lg-4">
                        <div className="document-inrbox">
                           <div className="document-cnt">
                              <h5>Document</h5>
                              <h3>Parkchain <br /> Core</h3>
                           </div>
                           <a href="https://tutorial.parkchain.org/chain/" target="_blank"><button className="btn btn-primary">Read</button></a>
                        </div>
                     </div>
                     <div className="col-md-6 col-lg-4">
                        <div className="document-inrbox">
                           <div className="document-cnt">
                              <h5>Document</h5>
                              <h3>NFT</h3>
                           </div>
                           <a href="https://tutorial.parkchain.org/nft/" target="_blank"><button className="btn btn-primary">Read</button></a>
                        </div>
                     </div>
                     <div className="col-md-6 col-lg-4">
                        <div className="document-inrbox">
                           <div className="document-cnt">
                              <h5>Document</h5>
                              <h3>Developer <br /> Programs</h3>
                           </div>
                           <a href="https://parkchain.org/developerprogram"><button className="btn btn-primary">Read</button></a>
                           {/* <Link to="/developerprogram" class="btn btn-primary">
                     Read
                  </Link> */}
                        </div>
                     </div>
                     <div className="col-md-6 col-lg-4">
                        <div className="document-inrbox">
                           <div className="document-cnt">
                              <h5>Document</h5>
                              <h3>Networking and <br /> Earning Opportunities</h3>
                           </div>
                           <a href="https://tutorial.parkchain.org/earning-opportunities-in-metaverse-realestate/" target="_blank"><button className="btn btn-primary">Read</button></a>
                        </div>
                     </div>
                     <div className="col-md-6 col-lg-4">
                        <div className="document-inrbox">
                           <div className="document-cnt">
                              <h5>Document</h5>
                              <h3>KPK Business <br /> Opportunities</h3>
                           </div>
                           <a href="https://tutorial.parkchain.org/kpk-business-opportunities/" target="_blank"><button className="btn btn-primary">Read</button></a>
                        </div>
                     </div>
                     <div className="col-md-6 col-lg-4">
                        <div className="document-inrbox">
                           <div className="document-cnt">
                              <h5>Document</h5>
                              <h3>Political <br /> Metaverse</h3>
                           </div>
                           <a href="https://tutorial.parkchain.org/political-metaverse/" target="_blank"><button className="btn btn-primary">Read</button></a>
                        </div>
                     </div>
                     <div className="col-md-6 col-lg-4">
                        <div className="document-inrbox">
                           <div className="document-cnt">
                              <h5>Document</h5>
                              <h3>Earning Opportunities <br /> (Metaverse - Realestate - Politics - NFT)</h3>
                           </div>
                           <a href="https://tutorial.parkchain.org/earning-opportunities-in-metaverse-realestate/" target="_blank"><button className="btn btn-primary">Read</button></a>
                        </div>
                     </div>
                     <div className="col-md-6 col-lg-4">
                        <div className="document-inrbox">
                           <div className="document-cnt">
                              <h5>Document</h5>
                              <h3>Technical <br /> Updates</h3>
                           </div>
                           {/* <a href="https://parkchain.org/comingsoon"><button className="btn btn-primary">Read</button></a> */}
                           <Link to="/comingsoon"><button className="btn btn-primary">Read</button></Link>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
         </div>
         <Footer />
      </Fragment>
   );
}
export default Document;