import React, { Component, Fragment, useState, useRef, useEffect, createRef } from "react";
import ReactDOM from "react-dom";
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import Overview from '../Overview/Overview';
import NFTDocumentation from '../NFTDocumentation/NFTDocumentation';
import DeveloperProgram from '../DeveloperProgram/DeveloperProgram';
import {
    BrowserRouter, Navigate, Route, Routes, Link
} from 'react-router-dom';

import Header from "../../common/Header/Header";
import Footer from "../../common/Footer/Footer";

import kpkBannerImg from '../../assets/images/kpkBannerImg.png';
import parkChainSummaryImg from '../../assets/images/parkChainSummaryImg.png';
import whatiscrypto from '../../assets/images/whatiscrypto.png';
import bankingIcon from '../../assets/images/bankingIcon.svg';
import privateInternet from '../../assets/images/privateInternet.svg';
import ptpNetwork from '../../assets/images/ptpNetwork.svg';
import censorIcon from '../../assets/images/censorIcon.svg';
import medalIcon from '../../assets/images/medalIcon.svg';
import composableIcon from '../../assets/images/composableIcon.svg';
import ethereumImg from '../../assets/images/ethereumImg.png';
import parkChainUse from '../../assets/images/parkChainUse.png';
import pkEnergyConsumption from '../../assets/images/pkEnergyConsumption.png';
import kpkpurImg from '../../assets/images/kpkpurImg.png';
import kpkorImg from '../../assets/images/kpkorImg.png';
import kpkbluImg from '../../assets/images/kpkbluImg.png';
import kpkgrnImg from '../../assets/images/kpkgrnImg.png';
import ownership from '../../assets/images/ownership.svg';
import cryptography from '../../assets/images/cryptography.svg';
import ptpIcon from '../../assets/images/ptpIcon.svg';
import accessibility from '../../assets/images/accessibility.svg';
import inclusiveAccesibility from '../../assets/images/inclusiveAccesibility.svg';
import kpkValue from '../../assets/images/kpkValue.png';
import getKPKImg from '../../assets/images/getKPKImg.png';
import parkchaingif from '../../assets/images/parkchaingif.gif';

import { useSelector, useDispatch } from 'react-redux';
import HtmlParser from "react-html-parser";



const Home = (props) => {
    const [activeTab, setActiveTab] = useState('1');
    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    let data1, data2, data3, data4, data5, data6, data7, data8, data9, data10, data11, data12, data13, data14, data15;
    let data = useSelector(state => (state.user));
    console.log(data, "kpkpkdata");
    if (data.data.status == 200) {
        data = data.data.data;


        data1 = data.find(elem => elem.title == 'heading content');
        data2 = data.find(elem => elem.title == 'heading content2');
        data3 = data.find(elem => elem.title == 'heading content3');
        data4 = data.find(elem => elem.title == 'body content');
        data5 = data.find(elem => elem.title == 'Complete ownership in your hands');
        data6 = data.find(elem => elem.title == 'Uncompromising security through cryptography');
        data7 = data.find(elem => elem.title == 'Direct peer-to-peer transactions');
        data8 = data.find(elem => elem.title == 'Decentralization and global accessibility');
        data9 = data.find(elem => elem.title == 'Inclusive accessibility for all');
        data10 = data.find(elem => elem.title == 'body centerheading');
        data11 = data.find(elem => elem.title == 'body centerdes');
        data12 = data.find(elem => elem.title == 'KPK fuels and secures Parkchain');
        data13 = data.find(elem => elem.title == 'KPK underpins the Parkchain financial system');
        data14 = data.find(elem => elem.title == 'Where to get KPK');
        data15 = data.find(elem => elem.title == 'What contributes to the value of KPK?');


    }

    console.log(data4,"data4");

    return (
        <Fragment>
            <Header />
            <div className="pkchinsec">
                <section className="kpparkchain">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                {/* <h3 className="kpgameheadLg text-center text-lg-left">WHAT IS KPK</h3> */}
                                <h2 className="kpgameheadLg text-center text-lg-left">{data1 ? HtmlParser((data1.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</h2>
                                {/* <h2 className="kpgamehead text-center text-lg-left">Currency for the digital future</h2> */}
                                <h2 className="kpgamehead text-center text-lg-left">{data2 ? HtmlParser((data2.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</h2>
                                {/* <p className="kpgamepara text-center text-lg-left">KPK stands as the digital, global currency, serving as the designated medium of exchange within Parkchain applications.</p> */}
                                <p className="kpgamepara text-center text-lg-left">{data3 ? HtmlParser((data3.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                            </div>
                            <div className="col-lg-6">
                                <div className="text-center"><img src={data3 ? HtmlParser(data3.image) : <></>} alt="" className="img-fluid kpkpkBannerImg" /></div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="container">
                    <div className="row my-5 align-items-center justify-content-center">
                        <div className="col-lg-10">
                            <div className="shadowCnt kpmintbx py-3">
                                <div className="row">
                                    {/* <div className="col-lg-8 d-flex align-items-center justify-content-start">
                                        <p>KPK represents a cryptocurrency, a limited supply of digital currency that holds value for online transactions. For those unfamiliar with cryptocurrencies, it is essential to understand how KPK distinguishes itself from conventional forms of money.</p>
                                    </div> */}
                                    <div className="col-lg-8 d-flex align-items-center justify-content-start">
                                        <p>{data4 ? HtmlParser((data4.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                    </div>
                                    <div className="col-lg-4 d-flex justify-content-center align-items-center">
                                        <img src={data4 ? HtmlParser(data4.image) : <></>} className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section>
                    <div className="container">
                        <div className="wtParkchainCnt">
                            <div className="row align-items-center justify-content-center">
                                <div className="col-lg-12 kpkBx">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-6 col-xl-4 mb-5">
                                            <div className="rpwitheth rrski_left">
                                                <h4 className="text-left">{data5 ? HtmlParser((data5.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</h4>
                                                {/* <p className="text-left">With KPK, you become the master of your own financial destiny. You have full control over your funds through your wallet, serving as irrefutable evidence of ownership. No intermediaries are required to manage your transactions.</p> */}
                                                <p className="text-left">{data5 ? HtmlParser(data5.content_description) : <></>}</p>
                                                <div className="text-left mb-2"><img src={data5?HtmlParser(data5.image):<></>} alt="" className="img-fluid" /></div>
                                            </div>
                                            
                                        </div>
                                        <div className="col-lg-6 col-xl-4 mb-5">
                                            <div className="rpwitheth rrski_left">
                                                <h4 className="text-left">{data6 ? HtmlParser((data6.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</h4>
                                                {/* <p className="text-left">While internet-based money may be relatively new, it relies on robust cryptographic techniques for foolproof protection. This ensures the safety of your wallet, ETH, and all associated transactions.</p> */}
                                                <p className="text-left">{data6 ? HtmlParser(data6.content_description) : <></>}</p>
                                                <div className="text-left mb-2"><img src={data6?HtmlParser(data6.image):<></>} alt="" className="img-fluid" /></div>

                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-4 mb-5">
                                            <div className="rpwitheth rrski_left">
                                                <h4 className="text-left">{data7 ? HtmlParser((data7.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</h4>
                                                {/* <p className="text-left">KPK enables seamless transactions without the need for intermediaries like banks. Similar to exchanging physical cash in person, you can securely send and receive KPK with anyone, anytime, and anywhere.</p> */}
                                                <p className="text-left">{data7 ? HtmlParser(data7.content_description) : <></>}</p>
                                                <div className="text-left mb-2"><img src={data7?HtmlParser(data7.image):<></>} alt="" className="img-fluid" /></div>

                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-4 mb-5">
                                            <div className="rpwitheth rrski_left">
                                                <h4 className="text-left">{data8 ? HtmlParser((data8.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</h4>
                                                {/* <p className="text-left">KPK operates in a decentralized manner, free from control by any single company or financial institution. No entity possesses the power to create more KPK or modify its terms of use.</p> */}
                                                <p className="text-left">{data8 ? HtmlParser(data8.content_description) : <></>}</p>
                                                <div className="text-left mb-2"><img src={data8?HtmlParser(data8.image):<></>} alt="" className="img-fluid" /></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-4 mb-5">
                                            <div className="rpwitheth rrski_left">
                                                <h4 className="text-left">{data9 ? HtmlParser((data9.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</h4>
                                                {/* <p className="text-left">Accepting KPK requires nothing more than an internet connection and a wallet. You don't need a bank account to receive payments, making it open to everyone.</p> */}
                                                <p className="text-left">{data9 ? HtmlParser(data9.content_description) : <></>}</p>
                                                <div className="text-left mb-2"><img src={data9?HtmlParser(data9.image):<></>} alt="" className="img-fluid" /></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="whyeth mt-5">
                    <div className="container">
                        {/* <h3 className="ignHead mb-3 text-center">What sets KPK apart from others?</h3> */}
                        <h3 className="ignHead mb-3 text-center">{data10 ? HtmlParser((data10.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</h3>
                        {/* <p className="ignPara text-center">While Parkchain hosts various cryptocurrencies and tokens, KPK possesses distinct capabilities.</p> */}
                        <p className="ignPara text-center">{data11 ? HtmlParser(data11.content_description) : <></>}</p>
                        <div className="row justify-content-center">
                            <div className="col-lg-4 col-md-6">
                                <img src={parkchaingif} className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div>
                                    <h3 className="ignHead mb-3">{data12 ? HtmlParser((data12.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</h3>
                                    {/* <p className="ignPara">KPK drives the functionality and security of Parkchain.
                                        KPK serves as the lifeblood of Parkchain. When utilizing Parkchain applications or sending KPK, a fee in KPK is required to access the network. This fee incentivizes block producers to validate and process your transactions.
                                        Validators play a crucial role as the guardians of Parkchain, ensuring the integrity of the network and preventing any fraudulent activities. Through a random selection process, validators propose blocks of transactions and receive small amounts of newly-issued KPK as rewards.
                                        The dedication of validators and the capital they invest contribute to the security and decentralized nature of Parkchain. KPK serves as the driving force behind Parkchain's operations.
                                        By staking your KPK, you actively contribute to the security of Parkchain while earning rewards. This system establishes a deterrent against potential attackers, as the risk of losing KPK acts as a strong disincentive.</p> */}
                                    <p className="ignPara">{data12 ? HtmlParser(data12.content_description) : <></>}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="pt-3">
                    <div className="container">
                        <div className="row pdbtm">
                            <div className="col-lg-12">
                                <div>
                                    <h3 className="ignHead mb-3">{data13 ? HtmlParser((data13.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</h3>
                                    {/* <p className="ignPara">KPK forms the foundation of the robust financial ecosystem within Parkchain. Going beyond simple payment solutions, the Parkchain community is actively constructing a decentralized peer-to-peer financial system accessible to all.
                                        Through KPK, users have the ability to leverage their holdings as collateral, creating diverse cryptocurrency tokens unique to Parkchain. Additionally, individuals can participate in borrowing, lending, and earning interest on both KPK and other tokens backed by KPK. This multifaceted approach empowers users to engage in a wide range of financial activities within the Parkchain network.</p> */}
                                    <p className="ignPara">{data13 ? HtmlParser(data13.content_description) : <></>}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="whyeth mt-5">
                    <div className="container">
                        <section className="kpkEthSec getKPK">
                            <div className="row justify-content-center">
                                <div className="col-lg-11">
                                    <div className="ethgamekit">
                                        <div className="communityTxtCnt p-0">
                                            <div className="row">
                                                <div className="col-lg-8 col-xl-8 text-center text-lg-left ethgamecnt">
                                                    {/* <h3 className="mb-3">Where to get KPK</h3> */}
                                                    <h3 className="mb-3">{data14 ? HtmlParser((data14.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</h3>

                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <p className="ignPara">{data14 ? HtmlParser(data14.content_description) : <></>}</p>
                                                            {/* <p className="ignPara">To get KPK, individuals have options such as exchanges and wallets. However, it is crucial to note that different countries have varying policies regarding KPK acquisition. It is advisable to review and understand the available services that facilitate the purchase of KPK based on your specific country's regulations.</p> */}
                                                        </div>
                                                    </div>
                                                    <div class="bnrLks ">
                                                        <a href="https://koinpark.com/" target="blank" class="btn btn-primary text-center">
                                                            Explore more
                                                        </a>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 d-flex justify-content-center align-items-center getKPKImgCnt">
                                                    <img src={data14 ? HtmlParser(data14.image) : <></>} className="img-fluid communityImg" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </section>
                <section className="pt-5">
                    <div className="container">
                        <div className="row pdbtm">
                            <div className="col-lg-6">
                                <div>
                                    {/* <h3 className="ignHead mb-3">What contributes to the value of KPK?</h3> */}
                                    <h3 className="ignHead mb-3">{data15 ? HtmlParser((data15.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</h3>
                                    {/* <p className="ignPara">KPK holds value in various ways, depending on the perspective of different individuals. For Parkchain users, KPK is valuable as it enables them to pay transaction fees within the network. Additionally, some perceive KPK as a digital store of value due to its decreasing rate of issuance over time. Furthermore, KPK has gained value among users of financial applications on Parkchain. It can be utilized as collateral for crypto loans or as a payment method. Naturally, many also view KPK as an investment opportunity, similar to Bitcoin and other cryptocurrencies.</p> */}
                                    <p className="ignPara">{data15 ? HtmlParser(data15.content_description) : <></>}</p>
                                </div>
                            </div>
                            <div className="col-lg-6 d-flex justify-content-center align-items-center">
                                <img src={data15 ? HtmlParser(data15.image) : <></>} className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </Fragment>
    );

}

export default Home;