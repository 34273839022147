import React, { Component, Fragment, useState, useRef, useEffect, createRef  } from "react";
import ReactDOM from "react-dom";
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import Overview from '../Overview/Overview';
import NFTDocumentation from '../NFTDocumentation/nftbenifits';
import DeveloperProgram from '../DeveloperProgram/DeveloperProgram';
import {
	BrowserRouter, Navigate, Route, Routes, Link
 } from 'react-router-dom';

import Header from "../../common/Header/Header";
import Footer from "../../common/Footer/Footer";

import downimg from '../../assets/images/pics/downimg.png';
import { useSelector } from "react-redux";
import HtmlParser from "react-html-parser";


const Home = (props) => {  
    const [activeTab, setActiveTab] = useState('1');
    const toggle = tab => {
      if(activeTab !== tab) setActiveTab(tab);
    }

    let data, data1;
    data = useSelector(state => state.user);
    if(data.data.status==200){
        data = data.data.data;
        data1 = data.find(elem => elem.title == 'Privacy Policy');
    }

    return (
        <Fragment>
            <Header />
            <div className="pkchinsec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="pkmainsec w-100">
                                <h2 class="kphedone">{data1 ? HtmlParser((data1.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</h2>
                                {data1 ? HtmlParser(data1.content_description) : <></>}

                                {/* <p class="kpparaone mb-2">This Privacy Policy explains how Parkchain Innovation Limited ("Parkchain
                                    Innovation") collects and handles your personal information when you use the Parkchain Innovation
                                    websites and applications that refer to this Privacy Notice. Parkchain Innovation encompasses a range
                                    of websites (such as bnbchain.org), mobile apps, clients, applets, and other applications that provide
                                    the services. These collectively form the "Websites." By using or interacting with the Parkchain
                                    Innovation Websites, you acknowledge that your personal information will be collected, stored,
                                    processed, and transferred as outlined in this Privacy Policy.</p>
                                <p class="kpparaone mb-2">Please be aware that the Websites function as an interface for the community of
                                    users and developers. Parkchain refers to any blockchain that utilizes KPK as its native token and
                                    consists of public, decentralized, and permissionless blockchains. We do not have control over the
                                    activities and data on the Parkchain or the projects developed by Community participants. Decisions
                                    are made collectively by the Community, rather than a single entity.</p>
                                <p class="kpparaone">Our Services (referring to any services, features, functions, or content available
                                    on or through the Websites) are provided by various subsidiaries and affiliates of Parkchain
                                    Innovation Limited. You understand that your personal information may be processed by one or more of
                                    these entities, each acting as a Data Controller for the personal information collected and processed
                                    in connection with the provision of Parkchain Innovation Services.</p>

                                <h2 class="kphedtwo fnbold">1. What Personal Information is Collected by Parkchain Innovation?</h2>
                                <p class="kpparaone mb-1">When you visit our Websites or use any of our Services, Parkchain Innovation
                                    may collect the following personal information:</p>
                                <p class="kpparaone mb-1">Information provided by you:</p>
                                <p class="kpparaone">We acquire and hold any information you provide in connection with Parkchain
                                    Innovation. You have the option to withhold certain
                                    information, but this may limit your access to certain Parkchain Innovation Services. Examples of
                                    information provided by you include:</p>
                                <ul class="pkmenu2 pkmenu3">
                                    <li><a>Name </a></li>
                                    <li><a>Email address</a></li>
                                    <li><a>Country of residence</a></li>
                                    <li><a><span class="ulboldtxt">Social Media accounts:</span> We may utilize social network plug-ins,
                                        such as LinkedIn, Twitter, Telegram/Discord Username, and GitHub.
                                        Please refer to the privacy policies and data protection practices of these individual social
                                        media platforms. Parkchain Innovation
                                        is not responsible for the data processing activities conducted by these platforms. We employ
                                        them solely for communication
                                        purposes with our Community.</a></li>
                                    <li><a><span class="ulboldtxt">Wallet Address:</span> To use our Services, you may need to connect a
                                        wallet. The wallet connected to our Services can be used within the
                                        Parkchain ecosystem. To access our forums, you must create an account and provide us with your
                                        username, email address,
                                        and password.</a></li>
                                </ul>

                                <h2 class="kphedfive">Information Gathered Automatically</h2>
                                <p class="kpparaone">We gather and retain specific types of data about your utilization of Parkchain
                                    Innovation Services, including how you engage with the content and services offered through Parkchain
                                    Innovation Services. Similar to many other websites, we utilize cookies and other unique identifiers
                                    to collect certain kinds of information whenever your web browser or device accesses Parkchain
                                    Innovation Websites. It is important to note that this information is not associated with specific
                                    individuals or linked to data obtained from other sources. Examples of the information we gather and
                                    examine include:</p>
                                <ul class="pkmenu2 pkmenu3">
                                    <li><a>The Internet protocol (IP) address is utilized to establish a connection between your computer
                                        and the Internet. </a></li>
                                    <li><a>Version and time zone settings.</a></li>
                                    <li><a>The location of your device or computer.</a></li>
                                    <li><a>Metrics related to Parkchain Innovation Services (such as technical errors, your interactions
                                        with service features and content, and your preferred settings).</a></li>
                                </ul>

                                <h2 class="kphedfive">Registration Information for Events</h2>
                                <p class="kpparaone">Parkchain Innovation offers various events such as Hackathons, classes, and
                                    lectures. To participate in these events through our websites or the websites of our third-party
                                    providers, we may require the following registration details:</p>

                                <ul class="pkmenu2 pkmenu3">
                                    <li><a>Country of residence </a></li>
                                    <li><a>Company/Association</a></li>
                                    <li><a>Image</a></li>
                                    <li><a>Resume/CV</a></li>
                                    <li><a>University/College affiliation</a></li>
                                    <li><a>Project details, pitch decks, or whitepapers (which may contain personal information) We may
                                        utilize this information, along with
                                        any additional information you provide voluntarily (such as comments, preferences, and dietary
                                        preferences), for the purpose of
                                        facilitating your registration, assessing your eligibility, enabling your participation, and
                                        enhancing your overall event experience.
                                        In certain cases, we may seek your consent to process your personal information for a specific
                                        purpose, which we will
                                        communicate to you.</a></li>
                                </ul>

                                <h2 class="kphedtwo fnbold">2. How Does Parkchain Innovation Utilize My Personal Information?</h2>
                                <ul class="pkmenu2 pkmenu3">
                                    <li><a>Processing Personal Information for Parkchain Innovation Services. We utilize your personal
                                        information to provide and enhance the
                                        functionality of Parkchain Innovation Services. This includes troubleshooting, analyzing
                                        performance, resolving errors, and
                                        improving the usability and effectiveness of the services. </a></li>
                                    <li><a>Recommendations and Personalization. Your personal information is used to suggest relevant
                                        features and services, tailor your
                                        experience, and understand your preferences while using Parkchain Innovation Services.</a></li>
                                    <li><a>Compliance with Legal Requirements. In certain instances, we collect and utilize your personal
                                        information to adhere to laws and
                                        regulations. </a></li>
                                    <li><a>Fraud Prevention and Mitigation of Credit Risks. We process personal information to prevent and
                                        detect fraud and misuse, ensuring
                                        the security of our users, Parkchain Innovation Services, and other individuals.</a></li>
                                    <li><a>Event Registration and Reservation. We process personal data to facilitate your registration
                                        for any event organized by us and
                                        maintain a record of your reservation.</a></li>
                                    <li><a>Consent-Based Processing: We may seek your consent to process your personal information for
                                        specific purposes, which will be
                                        communicated to you. You have the right to withdraw your consent at any time, resulting in the
                                        cessation of processing your data
                                        for that particular purpose.</a></li>
                                </ul>

                                <h2 class="kphedtwo fnbold">3. What About Other Identifiers and Cookies?</h2>
                                <p class="kpparaone">Regarding cookies and other identifiers, we utilize these tools to enhance your user
                                    experience, offer our services, and gain insights into how our customers utilize our services in order
                                    to make necessary enhancements. In order to facilitate the provision of Parkchain Innovation Services
                                    to you, we employ cookies to enable our systems to recognize your browser or device. These cookies,
                                    referred to as operational cookies, are utilized to provide various services, including:</p>
                                <ul class="pkmenu2 pkmenu3">
                                    <li><a>Identifying you when you sign in to use our services. </a></li>
                                    <li><a>Providing personalized features and services.</a></li>
                                    <li><a>Preventing fraudulent activities. </a></li>
                                    <li><a>Enhancing security.</a></li>
                                    <li><a>Keeping track of your preferences, such as language and currency.</a></li>
                                    <li><a>We use cookies to analyze how users interact with our services, allowing us to make
                                        improvements accordingly.</a></li>
                                </ul>
                                <p class="kpparaone">Users can adjust their browser settings to manage the acceptance of new cookies,
                                    receive notifications when a new cookie is received, disable and remove cookies, and determine when
                                    cookies will expire. Please note that blocking or rejecting operational cookies through your browser
                                    settings may result in certain features and services of Parkchain Innovation not functioning properly.
                                </p>

                                <h2 class="kphedtwo fnbold">4. Does Parkchain Innovation Share My Personal Information?</h2>
                                <p class="kpparaone mb-2">At Parkchain Innovation, we consider our users' information to be highly important
                                    and we do not engage in the practice of selling personal information to third parties. We only share
                                    users' personal information in the following circumstances, either with Parkchain Innovation's
                                    subsidiaries or affiliates who adhere to the same level of privacy protection as outlined in this
                                    Privacy Notice.</p>
                                <p class="kpparaone mb-2"><span class="ulboldtxt">Third-party service providers:</span> We enlist the services
                                    of external companies and individuals to carry out specific functions on our behalf. For example,
                                    these functions may include data analysis, marketing assistance, payment processing, and content
                                    transmission. While these third-party service providers have access to the personal information
                                    required for their designated tasks, they are strictly prohibited from using it for any other
                                    purposes. Moreover, they are obligated to process this information in compliance with our Privacy
                                    Notice and applicable data protection laws.</p>
                                <p class="kpparaone mb-2"><span class="ulboldtxt">Business transfers: </span>As our business continues to
                                    grow, we may acquire or sell other businesses or services. In such cases, user information may be
                                    transferred as part of these transactions, but it will remain subject to the privacy commitments made
                                    in any existing Privacy Notice (unless the user provides consent otherwise). Additionally, if
                                    Parkchain Innovation or a substantial portion of its assets were to be acquired, user information
                                    would be included among the transferred assets.</p>
                                <p class="kpparaone"><span class="ulboldtxt">Protection of Parkchain Innovation and others:</span> We may
                                    disclose account and other personal information when it is necessary to comply with the law or fulfill
                                    our regulatory obligations. This may involve enforcing our Terms of Use and other agreements or
                                    safeguarding the rights, property, or safety of Parkchain Innovation, our users, or others. If
                                    applicable, this could include sharing information with other companies and organizations to prevent
                                    fraud and reduce credit risk.</p>

                                <h2 class="kphedtwo fnbold">5. Personal Data Transfers Across Borders</h2>
                                <p class="kpparaone mb-2">Parkchain Innovation may transfer your personal data to locations outside the
                                    European Economic Area ("EEA"). Parkchain Innovation takes measures to ensure that such transfers
                                    comply with applicable data protection regulations. However, if the destination country has been
                                    deemed by the European Commission to provide an adequate level of data protection, suitable safeguards
                                    may not be necessary.</p>
                                <p class="kpparaone">When Parkchain Innovation transfers personal data to its affiliates or service
                                    providers, it relies on the standard contractual clauses approved by the European Commission, which
                                    adhere to the requirements of the GDPR or relevant data protection regulations.</p>

                                <h2 class="kphedtwo fnbold">6. How is My Information Secured?</h2>
                                <p class="kpparaone mb-2">Security Measures for Protecting Your Information At Parkchain Innovation, we
                                    prioritize the security and privacy of your information. We employ encryption protocols and software
                                    to ensure the security of your personal data during transmission. Additionally, we maintain physical,
                                    electronic, and procedural safeguards to protect your personal information during its collection,
                                    storage, and disclosure. As part of our security procedures, we may request identity verification
                                    before disclosing personal information to you.</p>

                                <p class="kpparaone">It is important for you to take precautions against unauthorized access to your
                                    account password. If applicable, we suggest using a unique password for your Parkchain Innovation
                                    account that is not used for other online accounts. Remember to sign off when you finish using a
                                    shared computer.</p>

                                <h2 class="kphedtwo fnbold">7. What are my entitlements?</h2>
                                <p class="kpparaone mb-2">If you have any queries or concerns regarding the collection and processing of
                                    your personal information, please contact privacy@parkchain.org.</p>
                                <p class="kpparaone mb-2">When you provide consent for us to process your personal information for a
                                    specific purpose, you have the option to revoke that consent at any time, and we will cease further
                                    processing of your data for that purpose.</p>
                                <p class="kpparaone mb-2">Furthermore, in accordance with applicable laws, you possess the right to
                                    request access to, rectify, and delete your personal data, as well as the right to data portability.
                                    You also have the ability to object to the processing of your personal data or request restrictions on
                                    its processing under certain circumstances. If you wish to exercise any of these rights, please
                                    contact privacy@parkchain.org.</p>
                                <ul class="pkmenu2 pkmenu3 ml-0 pl-4">
                                    <li><a><span class="ulboldtxt">Right to access:</span> You have the right to obtain confirmation of
                                        your Data being processed and receive a copy of it, along with relevant
                                        information about its processing.</a></li>
                                    <li><a><span class="ulboldtxt">Right to rectify:</span> You can request the correction of inaccurate
                                        Data and supplement it as well.</a></li>
                                    <li><a><span class="ulboldtxt">Right to delete:</span> In specific cases, you can have your Data
                                        erased. </a></li>
                                    <li><a><span class="ulboldtxt">Right to object:</span> You can object to the processing of your Data
                                        for reasons related to your particular situation. For example, you have
                                        the right to object to accepting commercial communications.</a></li>
                                    <li><a><span class="ulboldtxt">Right to restrict processing: </span>Under certain circumstances, you
                                        have the right to restrict the processing of your data.</a></li>
                                    <li><a><span class="ulboldtxt">Right to data portability:</span> In some cases, you can request to
                                        receive your Data, which you have provided to us, in a structured,
                                        machine-readable format. Alternatively, if feasible, we can transmit your Data directly to
                                        another data controller on your behalf.</a></li>
                                    <li><a><span class="ulboldtxt">Right to withdraw consent: </span>For processing that requires your
                                        consent, you have the right to withdraw it at any time. This action does
                                        not affect the lawfulness of processing based on the consent given before the withdrawal.</a>
                                    </li>
                                    <li><a><span class="ulboldtxt">Right to specify post-mortem instructions:</span> You have the right to
                                        define instructions regarding the deletion, retention, and
                                        communication of your Data after your death.</a></li>
                                    <li><a><span class="ulboldtxt">Right to lodge a complaint:</span> Can lodge a complaint with the
                                        relevant data protection authority.You can request the correction of inaccurate Data and
                                        supplement it as well.</a></li>
                                </ul>

                                <h2 class="kphedtwo fnbold">8. How long does Parkchain Innovation retain my personal information?</h2>
                                <p class="kpparaone">Parkchain Innovation retains your personal information for the duration necessary to
                                    support your continued use of Parkchain Innovation Services and to fulfill the relevant purposes
                                    outlined in this Privacy Notice. This retention period may be extended as required by law or as
                                    communicated to you.</p>

                                <h2 class="kphedtwo fnbold">9. What are the legal grounds for our utilization of personal information?
                                </h2>
                                <p class="kpparaone">Our usage of personal information is governed by the EU GDPR and UK data protection
                                    laws, which necessitate a legal basis. The specific purpose for which we employ personal information
                                    determines our basis, which includes the following:</p>
                                <p class="kpparaone"><span class="ulboldtxt">Performance of a contract:</span> When we provide you with
                                    products or services or communicate with you about them, our usage is based on fulfilling our
                                    contractual obligations.</p>
                                <p class="kpparaone"><span class="ulboldtxt">Legitimate interests:</span> In order to protect the
                                    security of our users, ourselves, or others, and to detect and prevent fraud and abuse, we rely on our
                                    legitimate interests and the interests of our users.</p>
                                <p class="kpparaone"><span class="ulboldtxt">Consent:</span> If we request your consent to process your
                                    personal information for a particular purpose, we will rely on your consent. You have the right to
                                    withdraw your consent at any time, and we will cease processing your data for that purpose.</p>
                                <p class="kpparaone"><span class="ulboldtxt">Compliance with a legal obligation:</span> To comply with
                                    legal requirements, we may use your personal information. Additional legal bases may apply depending
                                    on the purpose for which we utilize personal information.</p>

                                <h2 class="kphedtwo fnbold">10. Contact Details</h2>
                                <p class="kpparaone">For any inquiries or concerns regarding the collection and processing of your
                                    personal information, you can reach out to our data protection officer at privacy@parkchain.org. They
                                    will be available to assist you and address any questions or issues you may have.</p>

                                <h2 class="kphedtwo fnbold">11. Can Children / Minors Use Parkchain Innovation Services?</h2>
                                <p class="kpparaone">Parkchain Innovation does not permit individuals under the age of majority to
                                    utilize Parkchain Innovation Services, and we do not knowingly gather personal information from
                                    minors.</p>

                                <h2 class="kphedtwo fnbold">12. Terms of Use, Notices, and Policy Revisions</h2>
                                <p class="kpparaone mb-2">By opting to use Parkchain Innovation Services, you agree that your privacy and
                                    any related disputes are governed by this Policy and our Terms of Use. If you have any concerns about
                                    privacy matters at Parkchain Innovation, please contact us with a detailed description, and we will
                                    strive to resolve them. Additionally, you have the right to reach out to your local Data Protection
                                    Authority.</p>
                                <p class="kpparaone">Since our business is subject to constant change, our Privacy Policy will also
                                    undergo revisions. We recommend checking our websites frequently to stay informed about recent
                                    modifications. If you disagree with the updated content, you must immediately cease accessing
                                    Parkchain Innovation. Continuing to use Parkchain Innovation after a new version of the Privacy Policy
                                    is released indicates your acceptance of the updated content and your commitment to comply with the
                                    revised Privacy Policy. Unless otherwise specified, our current Privacy Policy applies to all the
                                    information we possess about you and your account.</p>                      */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
   
}

export default Home;