import React, { Component, Fragment, useState, useRef, useEffect, createRef } from "react";
import ReactDOM from "react-dom";
import {
    TabContent, TabPane, Nav, NavItem, NavLink,
    Button, Form, FormGroup, Label, Input, FormText,
    Row, Col, CustomInput, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import classnames from 'classnames';

import './kphckcr.scss';

import Logo from './assets/logo.svg';
import laptop from './assets/laptop.png';
import closeCircle from './assets/close-circle.svg';
import success from './assets/success.gif';
import { Controller, useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import {
    BrowserRouter, Navigate, Route, Routes, Link
} from 'react-router-dom';
import { PARKCHAIN_API } from "../../_config";
const HackathonForgot = (props) => {

    const [modal, setModal] = useState(false);
    const toggle2 = () => setModal(!modal);
    const { register, handleSubmit, errors, watch, trigger, control } = useForm({
        mode: "onBlur",
    });

    const [forgotDetails, setForgotDetails] = useState({
        email: "",
    });
    const [loading, setLoading] = useState(false)

    const submitData = (e) => {
        console.log(e, "eeeeeeeeeeeeeeeeedattatatatat")
        setLoading(true);
        setForgotDetails(e)
        var data = {
            email: e.email
        }
        axios
            .post(PARKCHAIN_API.parkChain + "auth/forgot_password", data)

            .then((res) => {
                if (res.data.responseCode == 200) {
                    setLoading(false);
                    // toast.success(res.data.message);
                    toggle2();

                    console.log("res", res);
                } else {
                    setLoading(false);
                    toast.error(res.data.message);
                }
            })

            .catch((err) => {
                console.log("err", err);
            });

    }
    console.log(forgotDetails.email, "forgotDetails.emailforgotDetails.email")
    return (
        <Fragment>

            {loading == true ? (
                <div className="pageloade">
                    <div className="page-loader">
                        <div class="loader-12">
                            <span class="loader_arrow"></span>
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}
            <div className="hckcrPg">
                <div className="container">
                    <div className="hckcrPgCon">
                        <form class="mx-1 mx-md-4" onSubmit={handleSubmit(submitData)}>
                            <div className="row hckcrPgR">
                                <div className="col-sm-12 col-md-12 col-lg-7 col-xl-6 hckcrPgC lt">
                                    <div className="hckcrPgInrC">
                                        <div className="hckcrPgLgo">
                                            <img src={Logo} alt="Logo" />
                                        </div>

                                        <div className="hckcrFrm">
                                            <div className="row hckcrFrmR">
                                                <div className="col-12 hckcrFrmC">
                                                    <FormGroup>
                                                        <Label for="">Email ID<span>*</span></Label>
                                                        {/* <Input type="email" name="email_id" id="" placeholder="" /> */}
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder="Enter your email"
                                                            ref={register({
                                                                required: "Email is required",
                                                                pattern: {
                                                                    value:
                                                                        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                                                    message: "Invalid email address",
                                                                },
                                                            })}
                                                            autoComplete="off"
                                                            name="email"
                                                            onKeyUp={() => {
                                                                trigger("email");
                                                            }}
                                                        />

                                                        {errors.email && (
                                                            <div style={{ color: "red" }}>
                                                                {errors.email.message}.
                                                            </div>
                                                        )}
                                                    </FormGroup>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="hckcrFrmSbmRd pt-sm-3">
                                            <button class="btn btn-17442 btn-bluFl v2"
                                            // onClick={toggle2}
                                            >
                                                Forgot Password</button>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-5 hckcrPgC rt">

                                    <div className="hckcrPgInrIgTk">
                                        <div className="hckCrPgIg">
                                            <img src={laptop} alt="laptop" />
                                        </div>
                                        <div className="hckCrPgTx">
                                            <h1> Forgot <br /> Password </h1>
                                            <p> &copy; 2023 Parkchain - All rights reserved.</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>


            <Modal isOpen={modal} toggle={toggle2} centered={true} className="kphckMdl" backdropClassName={'kphckMdlBkDrp'}>
                <ModalBody>
                    <button className="btn btnMdlcls" onClick={toggle2}>
                        <img src={closeCircle} alt="closeCircle" />
                    </button>
                    <div className="kpHckMdlIc">
                        <img src={success} alt="success" />
                    </div>
                    <div className="kpHckMdlTx">
                        <h4>Forgot Password</h4>
                        <p>
                            A reset link has been sent to
                            <a href="#">{forgotDetails.email}</a>
                        </p>
                    </div>
                </ModalBody>
            </Modal>


        </Fragment>
    );

}

export default HackathonForgot;