import React, { Component, Fragment, useState, useRef, useEffect, createRef } from "react";
import ReactDOM from "react-dom";
import classnames from 'classnames';
import './scss/nft.scss';
import Header from "../../common/Header/Header";
import Footer from "../../common/Footer/Footer";
/** Images  **/
import bannerImg from './images/nft-bnr.png';

import { useSelector, useDispatch } from 'react-redux';
import HtmlParser from "react-html-parser";


const WhyNft = (props) => {

   let data = useSelector(state => (state.user));
   console.log(data, "nftdata");

   let data1, data2, data3, data4, data5, data6, data7, data8, data9, data10;

   if (data.data.status == 200) {
      data = data.data.data;

      data1 = data.filter(elem => elem.sub_heading == "Why Parkchain for NFT's?");
      data2 = data.filter(elem => elem.sub_heading == "Creating and Minting NFT's");
      data3 = data.filter(elem => elem.sub_heading == "NFT Earning Opportunities");
      data4 = data.filter(elem => elem.sub_heading == "Earning Opportunities in Parkchain");
      data5 = data.filter(elem => elem.sub_heading == "KPK Business Opportunities");
      data6 = data.filter(elem => elem.sub_heading == "Conclusion");




   }
   console.log(data1, "data1");

   return (
      <Fragment>
         <Header />
         <div className="nftpage-content">
            <section className="nftbanner-sec">
               <div className="container">
                  <div className="row align-items-center">
                     <div className="col-md-7">
                        <div>
                           <h1>Why Parkchain for NFT's?</h1>
                        </div>
                     </div>
                     <div className="col-md-5 text-center mt-4 mt-lg-0">
                        <div>
                           <img src={bannerImg} alt="" className="img-fluid" />
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <section className="pt-4">
               <div className="container">
                  <h6>Parkchain is a blockchain platform developed by the cryptocurrency exchange Koinpark. While Parkchain was primarily designed for the trading of cryptocurrencies, it has also gained popularity as a platform for NFTs. Here are some reasons why Parkchain is chosen for NFTs.</h6>
               </div>
            </section>
            <section className="py-4">

               <div className="container">
                  <div className="row mt-4">
                     {data1 && data1.length > 0 ?
                        data1 && data1.map((item, index) => (
                           <div className="col-md-6 col-lg-4">
                              <div className="nft-inrbox">
                                 <h5>{HtmlParser(item.title)}</h5>
                                 <p>{HtmlParser(item.content_description)}</p>
                              </div>
                           </div>
                        ))
                        : <></>
                     }
                  </div>
                  <p>It's important to note that while Parkchain has advantages for NFTs, other blockchain networks like Ethereum and Flow are also widely used for NFTs and have their own unique features and ecosystems. The choice of blockchain for NFTs depends on various factors, including the specific needs and goals of the project or platform.</p>
               </div>
            </section>
            <section>
               <div className="container">
                  <h2>Creating and Minting NFT's</h2>
                  <h3>Creating and minting NFTs (Non-Fungible Tokens) involves the process of tokenizing unique digital assets on a blockchain platform. Here are the general steps to create and mint NFTs:</h3>
                  <div className="row mt-5">
                     {data2 && data2.length > 0 ?
                        data2 && data2.map((item, index) => (
                           <div className="col-md-6 col-lg-4">
                              <div className="nft-inrbox">
                                 <h5>{HtmlParser(item.title)}</h5>
                                 <p>{HtmlParser(item.content_description)}</p>
                              </div>
                           </div>
                        ))
                        : <></>
                     }

                  </div>
                  <p>Remember to carefully follow the instructions and guidelines provided by the chosen blockchain platform or NFT marketplace for minting NFTs. It's also essential to consider legal and copyright aspects, ensuring that you have the proper rights to tokenize and sell the digital asset.</p>
               </div>
            </section>
            <section className="pt-4">
               <div className="container">
                  <h2>NFT Earning Opportunities</h2>
                  <h3>Non-Fungible Tokens (NFTs) offer several earning opportunities for creators, artists, collectors, and investors. Here are some ways in which individuals can generate income through NFTs:</h3>
                  <div className="row mt-5">
                     {data3 && data3.length > 0 ?
                        data3 && data3.map((item, index) => (
                           <div className="col-md-6 col-lg-4">
                              <div className="nft-inrbox">
                                 <h5>{HtmlParser(item.title)}</h5>
                                 <p>{HtmlParser(item.content_description)}</p>
                              </div>
                           </div>
                        ))
                        : <></>
                     }
                  </div>
                  <p>Remember to carefully follow the instructions and guidelines provided by the chosen blockchain platform or NFT marketplace for minting NFTs. It's also essential to consider legal and copyright aspects, ensuring that you have the proper rights to tokenize and sell the digital asset.</p>
               </div>
            </section>
            <section className="pt-4">
               <div className="container">
                  <h2>Earning Opportunities in Parkchain</h2>
                  <h3>Parkchain Blockchain technology has opened up various earning opportunities for individuals. Here are some ways in which individuals can generate income through blockchain:</h3>
                  <div className="row mt-5">
                     {data4 && data4.length > 0 ?
                        data4 && data4.map((item, index) => (
                           <div className="col-md-6 col-lg-4">
                              <div className="nft-inrbox">
                                 <h5>{HtmlParser(item.title)}</h5>
                                 <p>{HtmlParser(item.content_description)}</p>
                              </div>
                           </div>
                        ))
                        : <></>
                     }
                  </div>
                  <p>It's important to note that the blockchain industry is constantly evolving, and the earning opportunities may vary based on market conditions, regulatory developments, and technological advancements. Engaging in blockchain-related activities requires thorough research, staying updated with industry trends, and understanding the associated risks.</p>
               </div>
            </section>
            <section>
               <div className="container">
                  <h2>KPK Business Opportunities</h2>
                  <h3>Cryptocurrencies have opened up numerous earning opportunities for individuals. Here are some ways in which individuals can generate income through cryptocurrencies:</h3>
                  <div className="row mt-5">
                  {data5 && data5.length > 0 ?
                        data5 && data5.map((item, index) => (
                           <div className="col-md-6 col-lg-4">
                              <div className="nft-inrbox">
                                 <h5>{HtmlParser(item.title)}</h5>
                                 <p>{HtmlParser(item.content_description)}</p>
                              </div>
                           </div>
                        ))
                        : <></>
                     }
                  </div>
                  <p>It's important to note that engaging in cryptocurrency-related activities carries risks, including market volatility, regulatory changes, and cybersecurity threats. Conduct thorough research, exercise caution, and consider seeking professional advice when venturing into cryptocurrency earning opportunities.</p>
               </div>
            </section>
            <section className="pb-4">
               <div className="container">
                  <h2>Conclusion</h2>
                  <h3>In conclusion, Non-Fungible Tokens (NFTs) have emerged as a groundbreaking innovation in the digital asset space. They provide a means to represent ownership and authenticity of unique digital items or content on blockchain platforms. Here are some key points to summarize the concept of NFTs:</h3>
                  <div className="row mt-5">
                  {data6 && data6.length > 0 ?
                        data6 && data6.map((item, index) => (
                           <div className="col-md-6 col-lg-4">
                              <div className="nft-inrbox">
                                 <h5>{HtmlParser(item.title)}</h5>
                                 <p>{HtmlParser(item.content_description)}</p>
                              </div>
                           </div>
                        ))
                        : <></>
                     }
                  </div>
                  <p>It's important to stay informed, exercise caution, and conduct thorough research when participating in the NFT space. NFTs present exciting opportunities for creators, collectors, and investors, but it's crucial to navigate the ecosystem responsibly and make informed decisions.</p>
               </div>
            </section>
         </div>
         <Footer />
      </Fragment>
   );
}
export default WhyNft;