import React, { Component, Fragment, useState, useRef, useEffect, createRef } from "react";
import ReactDOM from "react-dom";
import classnames from 'classnames';
import './scss/ecosystem.scss';
import Header from "../../common/Header/Header";
import Footer from "../../common/Footer/Footer";
/** Images  **/
import parkbnr from './images/parkbnr.png';
import ecoparkchain from './images/eco-parkchain.svg';
import ecoparkscan from './images/eco-parkscan.svg';
import ecokoinpark from './images/eco-koinpark.svg';
import ecokpkpark from './images/eco-kpkpark.svg';
import ecokparkwallet from './images/eco-parkwallet.svg';
import ecokparktools from './images/eco-parktools.svg';
import ecokparklands from './images/eco-parklands.svg';

import ios from '../../assets/images/appstore.svg';
import andriod from '../../assets/images/googleplayImg.svg';

import { useSelector, useDispatch } from 'react-redux';
import HtmlParser from "react-html-parser";

const Ecosystem = (props) => {
   let data = useSelector(state => (state.user));

   let data1, data2, data3, data4, data5, data6, data7, data8, data9, data10;

   if (data.data.status == 200) {
      data = (data.data.data)
      data1 = (data.find(elem => elem.title == 'New era of'))
      data2 = (data.find(elem => elem.title == 'Ecosystem'))
      data3 = (data.find(elem => elem.title == 'Parkscan'))
      data4 = (data.find(elem => elem.title == 'koinpark'))
      data5 = (data.find(elem => elem.title == 'KPK Park'))
      data6 = (data.find(elem => elem.title == 'Park wallet'))
      data7 = (data.find(elem => elem.title == 'Park Tools'))
      data8 = (data.find(elem => elem.title == 'Park Lands'))
      data9 = (data.find(elem => elem.title == 'Token Migration Tool'))

   }
   console.log(data5, "data1");

   return (
      <Fragment>
         <Header />
         <div className="parkhome-pagecontent">
            <section className="parkhome-bnrsec">
               <div className="container">
                  <div className="row align-items-center">
                     <div className="col-lg-5">
                        <div className="parkhome-leftcnt">
                           <div class="bnrTx">
                              <h2>{data1 ? HtmlParser(data1.title) : <></>}</h2>
                              <h1 className="text-white">{data1 ? HtmlParser(data1.subtitle) : <></>}</h1>
                              <p className="mt-5">{data1 ? HtmlParser(data1.content_description) : <></>}</p>
                              <p className="mt-4">{data1 ? HtmlParser(data1.subcontentdescription) : <></>}</p>
                           </div>
                        </div>
                     </div>
                     <div className="col-lg-7 text-center">
                        <div>
                           <img src={data1 ? HtmlParser(data1.image) : <></>} alt="" className="img-fluid" />
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <section className="ecosystem-sec ecokoinpark-sec">
               <div className="container">
                  <div className="ecosys-linearbg">
                     <div className="row align-items-center">
                        <div className="col-lg-4 text-center ecolinear-imgcol">
                           <div className="ecolinear-img">
                              <img src={data2 ? HtmlParser(data2.image) : <></>} alt="" className="img-fluid" />
                           </div>
                        </div>
                        <div className="col-lg-8">
                           <div className="ecosystem-cnt">
                              <h2 className="ecosys-subhead">{data2 ? HtmlParser(data2.title) : <></>}</h2>
                              <h5 className="ecosys-head">{data2 ? HtmlParser(data2.subtitle) : <></>}</h5>
                              <p className="ecosys-para mt-4">{data2 ? HtmlParser(data2.content_description) : <></>}</p>
                              <p className="ecosys-para">{data2 ? HtmlParser(data2.subcontentdescription) : <></>}</p>
                              <div class="text-center">
                                 <a href="https://www.parkscan.net/" target="_blank" className="btn btn-primary px-5 py-2">
                                    Explore
                                 </a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <section className="ecosystem-sec ecoparkscan-sec">
               <div className="container">
                  <div className="row">
                     <div className="col-lg-8">
                        <div className="ecosystem-cnt">
                           <h2 className="ecosys-subhead">{data2 ? HtmlParser(data2.title) : <></>}</h2>
                           <h5 className="ecosys-head">{data3 ? HtmlParser(data3.subtitle) : <></>}</h5>
                           <p className="ecosys-para mt-4">{data3 ? HtmlParser(data3.content_description) : <></>}</p>
                           <p className="ecosys-para">{data3 ? HtmlParser(data3.subcontentdescription) : <></>}</p>
                        </div>
                        <div class="">
                           <a href="https://koinpark.com/" target="_blank" className="btn btn-primary px-5 py-2">
                              Explore
                           </a>
                        </div>
                     </div>
                     <div className="col-lg-4 text-center">
                        <div>
                           <img src={data3 ? HtmlParser(data3.image) : <></>} alt="" className="img-fluid" />
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <section className="ecosystem-sec ecokoinpark-sec">
               <div className="container">
                  <div className="ecosys-linearbg">
                     <div className="row align-items-center">
                        <div className="col-lg-4 text-center ecolinear-imgcol">
                           <div className="ecolinear-img">
                              <img src={data4 ? HtmlParser(data4.image) : <></>} alt="" className="img-fluid" />
                           </div>
                        </div>
                        <div className="col-lg-8">
                           <div className="ecosystem-cnt">
                              <h2 className="ecosys-subhead">{data2 ? HtmlParser(data2.title) : <></>}</h2>
                              <h5 className="ecosys-head">{data4 ? HtmlParser(data4.subtitle) : <></>}</h5>
                              <p className="ecosys-para mt-4">{data4 ? HtmlParser(data4.content_description) : <></>}</p>
                              <p className="ecosys-para">{data4 ? HtmlParser(data4.subcontentdescription) : <></>}</p>
                              <div class="text-center">
                                 <a href="https://www.parkscan.net/" target="_blank" className="btn btn-primary px-5 py-2">
                                    Explore
                                 </a>
                                 <button className="playstore-btn mx-2"><a href="https://play.google.com/store/apps/details?id=com.application.koinpark" target="_blank"><img src={andriod} className="img-fluid" /></a></button>
                                 <button className="playstore-btn"><a href="https://apps.apple.com/in/app/koinpark/id6448510693" target="_blank"><img src={ios} className="img-fluid" /></a></button>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <section className="ecosystem-sec ecoparkscan-sec">
               <div className="container">
                  <div className="ecosys-linearbg">
                     <div className="row align-items-center">
                        <div className="col-lg-4 text-center ecolinear-imgcol">
                           <div className="ecolinear-img">
                              <img src={data5 ? HtmlParser(data5.image) : <></>} alt="" className="img-fluid" />
                           </div>
                        </div>
                        <div className="col-lg-8">
                           <div className="ecosystem-cnt">
                              <h2 className="ecosys-subhead">{data2 ? HtmlParser(data2.title) : <></>}</h2>
                              <h5 className="ecosys-head">{data5 ? HtmlParser(data5.subtitle) : <></>}</h5>
                              <p className="ecosys-para mt-4">{data5 ? HtmlParser(data5.content_description) : <></>}</p>
                              <p className="ecosys-para">{data5 ? HtmlParser(data5.subcontentdescription) : <></>}</p>
                              <div class="text-center">
                                 <a href="https://kpkpark.com/" target="_blank" className="btn btn-primary px-5 py-2">
                                    Explore
                                 </a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <section className="ecosystem-sec ecokoinpark-sec">
               <div className="container">
                  <div className="row">
                     <div className="col-lg-8">
                        <div className="ecosystem-cnt">
                           <h2 className="ecosys-subhead">{data2 ? HtmlParser(data2.title) : <></>}</h2>
                           <h5 className="ecosys-head">{data6 ? HtmlParser(data6.subtitle) : <></>}</h5>
                           <p className="ecosys-para mt-4">{data6 ? HtmlParser(data6.content_description) : <></>}</p>
                           <p className="ecosys-para">{data6 ? HtmlParser(data6.subcontentdescription) : <></>}</p>
                        </div>
                        <div class="playstorebtn-box">
                           <a href="https://play.google.com/store/apps/details?id=hr.ipt.parkwallet&hl=en&gl=US" target="_blank" className="btn btn-primary px-5 py-2">
                              Explore
                           </a>
                           <button className="playstore-btn mx-2"><a href="https://play.google.com/store/apps/details?id=hr.ipt.parkwallet&hl=en&gl=US" target="_blank"><img src={andriod} className="img-fluid" /></a></button>
                           {/* <button className="playstore-btn"><img src={ios} className="img-fluid" /></button> */}
                        </div>





                     </div>
                     <div className="col-lg-4 text-center">
                        <div>
                           <img src={data6 ? HtmlParser(data6.image) : <></>} alt="" className="img-fluid" />
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <section className="ecosystem-sec ecoparkscan-sec">
               <div className="container">
                  <div className="ecosys-linearbg">
                     <div className="row align-items-center">
                        <div className="col-lg-4 text-center ecolinear-imgcol">
                           <div className="ecolinear-img">
                              <img src={data7 ? HtmlParser(data7.image) : <></>} alt="" className="img-fluid" />
                           </div>
                        </div>
                        <div className="col-lg-8">
                           <div className="ecosystem-cnt">
                              <h2 className="ecosys-subhead">{data2 ? HtmlParser(data2.title) : <></>}</h2>
                              <h5 className="ecosys-head">{data7 ? HtmlParser(data7.subtitle) : <></>}</h5>
                              <p className="ecosys-para mt-4">{data7 ? HtmlParser(data7.content_description) : <></>}</p>
                              <p className="ecosys-para">{data7 ? HtmlParser(data7.subcontentdescription) : <></>}</p>
                           </div>
                        </div>
                     </div>
                     <div className="px-3">
                        <h6 className="subsub-head">{data9 ? HtmlParser(data9.title) : <></>}</h6>
                        <p className="ecosys-para">{data9 ? HtmlParser(data9.content_description) : <></>}</p>
                     </div>
                     <div className="px-3">
                        <h6 className="subsub-head">{data9 ? HtmlParser(data9.subtitle) : <></>}</h6>
                        <p className="ecosys-para">{data9 ? HtmlParser(data9.subcontentdescription) : <></>}</p>
                     </div>
                     <div className="px-3">
                        <h6 className="subsub-head">{data9 ? HtmlParser(data9.subtitle1) : <></>}</h6>
                        <p className="ecosys-para">{data9 ? HtmlParser(data9.sub_content) : <></>}</p>
                     </div>
                     <div class="text-center">
                        <a href="https://parktools.org" target="_blank" className="btn btn-primary px-5 py-2">
                           Explore
                        </a>
                     </div>
                  </div>

               </div>
            </section>
            <section className="ecosystem-sec ecokoinpark-sec">
               <div className="container">
                  <div className="row">
                     <div className="col-lg-8">
                        <div className="ecosystem-cnt">
                           <h2 className="ecosys-subhead">{data2 ? HtmlParser(data2.title) : <></>}</h2>
                           <h5 className="ecosys-head">{data8 ? HtmlParser(data8.subtitle) : <></>}</h5>
                           <p className="ecosys-para mt-4">{data8 ? HtmlParser(data8.content_description) : <></>}</p>
                           <p className="ecosys-para">{data8 ? HtmlParser(data8.subcontentdescription) : <></>}</p>
                        </div>
                     </div>
                     <div className="col-lg-4 text-center mt-4 mt-lg-0">
                        <div>
                           <img src={data8 ? HtmlParser(data8.image) : <></>} alt="" className="img-fluid" />
                        </div>
                     </div>
                  </div>
                  <div class="">
                     <a href="https://parklands.io/" target="_blank" className="btn btn-primary px-5 py-2">
                        Explore
                     </a>
                  </div>
               </div>
            </section>
         </div>
         <Footer />
      </Fragment>
   );
}
export default Ecosystem;