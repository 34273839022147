import React, { Component, Fragment, useState, useRef, useEffect, createRef } from "react";
import ReactDOM from "react-dom";
import classnames from 'classnames';
import Header from "../../common/Header/Header";
import Footer from "../../common/Footer/Footer";
/** Images  **/
import exchangert from '../../assets/images/kpkdigital/exchange-rt.png';
import parkwallet from '../../assets/images/kpkdigital/parkwallet.png';

import frms from '../../assets/images/kpkdigital/frms.png';
import pkcd from '../../assets/images/kpkdigital/pkcnd.png';
import cd1 from '../../assets/images/kpkdigital/cd1.png';
import cd2 from '../../assets/images/kpkdigital/cd2.png';
import cd3 from '../../assets/images/kpkdigital/cd3.png';
import ic1 from '../../assets/images/kpkdigital/icn1.png';
import ic2 from '../../assets/images/kpkdigital/icn2.png';
import ic3 from '../../assets/images/kpkdigital/icn3.png';
import ic4 from '../../assets/images/kpkdigital/icn4.png';
import ic5 from '../../assets/images/kpkdigital/icn5.png';
import ic6 from '../../assets/images/kpkdigital/icn6.png';
import ic7 from '../../assets/images/kpkdigital/icn7.png';
import ic8 from '../../assets/images/kpkdigital/icn8.png';

import { useSelector, useDispatch } from 'react-redux';
import HtmlParser from "react-html-parser";

const Parkwallet = (props) => {

    let data = useSelector(state => (state.user));
    console.log(data, "kpkpkdata");

    let data1, data2, data3, data4, data5, data6, data7, data8, data9, data10, data11, data12, data13, data14, data15, data16, data17,
    data18, data19, data20, data21, data22, data23, data24, data25, data26, data27, data28, data29;

    if (data.data.status == 200) {
        data = data.data.data;

        data1 = data.find(elem => elem.title == 'Parkchain Wallet Introduction');
        data2 = data.find(elem => elem.title == 'Why are crypto wallets important?');
        data3 = data.find(elem => elem.title == 'Paper wallets');
        data4 = data.find(elem => elem.title == 'Hardware wallets');
        data5 = data.find(elem => elem.title == 'Online wallets');
        data6 = data.find(elem => elem.title == 'body content');
        data7 = data.find(elem => elem.title == 'Manage');
        data8 = data.find(elem => elem.title == 'You deserve convenient access to digital currencies');
        data9 = data.find(elem => elem.title == 'Park Chain Wallet is for you if you want to1');
        data10 = data.find(elem => elem.title == 'Exchange Instantly');
        data11 = data.find(elem => elem.title == 'The Most trusted & secure crypto wallet');
   

    }
    return (
        <Fragment>
            <Header />
            <div className="kpkexchange-pagecont">
                <section className="kpkexchange-bnrsec">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-7">
                                <div className="kpkexchange-leftcnt">
                                    <h2>{data1 ? HtmlParser((data1.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</h2>
                                    <p>{data1 ? HtmlParser((data1.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                </div>
                            </div>
                            <div className="col-lg-5 text-center">
                                <div className="kpkexchange-rightcnt">
                                    <img src={data1 ? HtmlParser(data1.image) : <></>} alt="" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div class="kpndwaltl">
                        <div class="container">
                            <div class="crpsec">
                                <h2>{data2 ? HtmlParser(data2.title) : <></>}</h2>
                                <p>{data2 ? HtmlParser((data2.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                            </div>
                            <div class="pHdon">
                                <h2>{data2 ? HtmlParser(data2.subtitle) : <></>}</h2>
                                <p>{data2 ? HtmlParser((data2.subcontentdescription).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                <div class="row my-md-5 my-3 ">
                                    <div class="col-lg-4 mb-2">
                                        <div class="crd_pho">
                                            <h1>{data3 ? HtmlParser(data3.title) : <></>}</h1>
                                            <p>{data3 ? HtmlParser((data3.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                            <div class="inimg">
                                                <img src={data3 ? HtmlParser(data3.image) : <></>} class="img-fluid" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 mb-2">
                                        <div class="crd_pho">
                                            <h1>{data4 ? HtmlParser(data4.title) : <></>}</h1>
                                            <p>{data4 ? HtmlParser((data4.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                            <div class="inimg">
                                                <img src={data4 ? HtmlParser(data4.image) : <></>} class="img-fluid" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 mb-2">
                                        <div class="crd_pho">
                                            <h1>{data5 ? HtmlParser(data5.title) : <></>}</h1>
                                            <p>{data5 ? HtmlParser((data5.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                            <div class="inimg">
                                                <img src={data5 ? HtmlParser(data5.image) : <></>} class="img-fluid" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <p>Each type has its tradeoffs. Paper and hardware wallets are harder for malicious users to access
                                    because they are stored offline, but they are limited in function and risk being lost or destroyed.
                                    Online wallets offered by a major exchange like Koinpark are the simplest way to get started in</p>
                                <p>crypto and offer a balance of security and easy access. (Because your private info is online, your
                                    protection against hackers is only as good as your wallet provider's security ' so make sure you
                                    look for features like two-factor verification.)</p> */}
                                    <p>{data2 ? HtmlParser(data2.meta_description) : <></>}</p>
                            </div>
                            <div class="uplscd">
                                <h2>{data6 ? HtmlParser(data6.subtitle) : <></>}</h2>
                                <div class="row pt-5 align-items-center">
                                    <div class="col-lg-4 col-md-6 order-lg-1 order-2">
                                        <div class="text-md-right">
                                            <div>
                                                <h1>{data7 ? HtmlParser(data7.title) : <></>}</h1>
                                                <p>{data7 ? HtmlParser(data7.content_description) : <></>}</p>
                                            </div>
                                            <div>
                                                <h1>{data7 ? HtmlParser(data7.subtitle) : <></>}</h1>
                                                <p>{data7 ? HtmlParser(data7.subcontentdescription) : <></>}</p>
                                            </div>
                                            <div>
                                                <h1>{data7 ? HtmlParser(data7.subtitle1) : <></>}</h1>
                                                <p>{data7 ? HtmlParser(data7.meta_description) : <></>}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 order-lg-2 order-1">
                                        <div class="text-center">
                                            <img src={data6 ? HtmlParser(data6.image) : <></>} class="img-fluid" alt="" />
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 order-lg-3 order-3">
                                    <div class="text-left">
                                            <div>
                                                <h1>{data7 ? HtmlParser(data7.title) : <></>}</h1>
                                                <p>{data7 ? HtmlParser(data7.content_description) : <></>}</p>
                                            </div>
                                            <div>
                                                <h1>{data7 ? HtmlParser(data7.subtitle) : <></>}</h1>
                                                <p>{data7 ? HtmlParser(data7.subcontentdescription) : <></>}</p>
                                            </div>
                                            <div>
                                                <h1>{data7 ? HtmlParser(data7.subtitle1) : <></>}</h1>
                                                <p>{data7 ? HtmlParser(data7.meta_description) : <></>}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="Mtstl">
                                <h1>{data11 ? HtmlParser(data11.title) : <></>}</h1>
                                <div class="pt-5">
                                    <img src={data11 ? HtmlParser(data11.image) : <></>} class="img-fluid" alt="" />
                                </div>

                            </div>
                            <div class="DcAtl">
                                <h1>{data8 ? HtmlParser(data8.title) : <></>}</h1>
                                <h2>{data8 ? HtmlParser(data8.subtitle) : <></>}</h2>
                                <div class="">
                                    <div class="row justify-content-center pt-4">
                                        <div class="col-md-6 mb-4">
                                            <div class="d-flex align-items-">
                                                <div class="blt mr-2">
                                                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="15" cy="15" r="15" fill="url(#paint0_linear_802_4387)" />
                                                        <path
                                                            d="M13.3183 19.6424C12.9532 19.6424 12.5881 19.5079 12.2999 19.2197L9.4179 16.3377C8.8607 15.7805 8.8607 14.8582 9.4179 14.3011C9.97509 13.7439 10.8973 13.7439 11.4545 14.3011L13.3567 16.2032L18.5828 11.3806C19.1592 10.8426 20.0814 10.881 20.6194 11.4574C21.1574 12.0338 21.119 12.9561 20.5426 13.4941L14.2981 19.2582C14.0292 19.5079 13.6833 19.6424 13.3183 19.6424Z"
                                                            fill="#0066FF" />
                                                        <defs>
                                                            <linearGradient id="paint0_linear_802_4387" x1="31.2431" y1="24.5116"
                                                                x2="-8.54756" y2="22.0479" gradientUnits="userSpaceOnUse">
                                                                <stop stop-color="#0066FF" stop-opacity="0.08" />
                                                                <stop offset="1" stop-color="#0066FF" stop-opacity="0.28" />
                                                            </linearGradient>
                                                        </defs>
                                                    </svg>
                                                </div>
                                                <div>
                                                <h3> {data8 ? HtmlParser((data8.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 mb-4">
                                            <div class="d-flex align-items-">
                                                <div class="blt mr-2">
                                                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="15" cy="15" r="15" fill="url(#paint0_linear_802_4387)" />
                                                        <path
                                                            d="M13.3183 19.6424C12.9532 19.6424 12.5881 19.5079 12.2999 19.2197L9.4179 16.3377C8.8607 15.7805 8.8607 14.8582 9.4179 14.3011C9.97509 13.7439 10.8973 13.7439 11.4545 14.3011L13.3567 16.2032L18.5828 11.3806C19.1592 10.8426 20.0814 10.881 20.6194 11.4574C21.1574 12.0338 21.119 12.9561 20.5426 13.4941L14.2981 19.2582C14.0292 19.5079 13.6833 19.6424 13.3183 19.6424Z"
                                                            fill="#0066FF" />
                                                        <defs>
                                                            <linearGradient id="paint0_linear_802_4387" x1="31.2431" y1="24.5116"
                                                                x2="-8.54756" y2="22.0479" gradientUnits="userSpaceOnUse">
                                                                <stop stop-color="#0066FF" stop-opacity="0.08" />
                                                                <stop offset="1" stop-color="#0066FF" stop-opacity="0.28" />
                                                            </linearGradient>
                                                        </defs>
                                                    </svg>
                                                </div>
                                                <div>
                                                <h3> {data8 ? HtmlParser((data8.subcontentdescription).replace(/(<([^>]+)>)/gi, "")) : <></>}</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 mb-4">
                                            <div class="d-flex align-items-">
                                                <div class="blt mr-2">
                                                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="15" cy="15" r="15" fill="url(#paint0_linear_802_4387)" />
                                                        <path
                                                            d="M13.3183 19.6424C12.9532 19.6424 12.5881 19.5079 12.2999 19.2197L9.4179 16.3377C8.8607 15.7805 8.8607 14.8582 9.4179 14.3011C9.97509 13.7439 10.8973 13.7439 11.4545 14.3011L13.3567 16.2032L18.5828 11.3806C19.1592 10.8426 20.0814 10.881 20.6194 11.4574C21.1574 12.0338 21.119 12.9561 20.5426 13.4941L14.2981 19.2582C14.0292 19.5079 13.6833 19.6424 13.3183 19.6424Z"
                                                            fill="#0066FF" />
                                                        <defs>
                                                            <linearGradient id="paint0_linear_802_4387" x1="31.2431" y1="24.5116"
                                                                x2="-8.54756" y2="22.0479" gradientUnits="userSpaceOnUse">
                                                                <stop stop-color="#0066FF" stop-opacity="0.08" />
                                                                <stop offset="1" stop-color="#0066FF" stop-opacity="0.28" />
                                                            </linearGradient>
                                                        </defs>
                                                    </svg>
                                                </div>
                                                <div>
                                                   <h3> {data8 ? HtmlParser((data8.meta_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 mb-4">
                                            <div class="d-flex align-items-">
                                                <div class="blt mr-2">
                                                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="15" cy="15" r="15" fill="url(#paint0_linear_802_4387)" />
                                                        <path
                                                            d="M13.3183 19.6424C12.9532 19.6424 12.5881 19.5079 12.2999 19.2197L9.4179 16.3377C8.8607 15.7805 8.8607 14.8582 9.4179 14.3011C9.97509 13.7439 10.8973 13.7439 11.4545 14.3011L13.3567 16.2032L18.5828 11.3806C19.1592 10.8426 20.0814 10.881 20.6194 11.4574C21.1574 12.0338 21.119 12.9561 20.5426 13.4941L14.2981 19.2582C14.0292 19.5079 13.6833 19.6424 13.3183 19.6424Z"
                                                            fill="#0066FF" />
                                                        <defs>
                                                            <linearGradient id="paint0_linear_802_4387" x1="31.2431" y1="24.5116"
                                                                x2="-8.54756" y2="22.0479" gradientUnits="userSpaceOnUse">
                                                                <stop stop-color="#0066FF" stop-opacity="0.08" />
                                                                <stop offset="1" stop-color="#0066FF" stop-opacity="0.28" />
                                                            </linearGradient>
                                                        </defs>
                                                    </svg>
                                                </div>
                                                <div>
                                                <h3> {data9 ? HtmlParser((data9.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 mb-4">
                                            <div class="d-flex align-items-">
                                                <div class="blt mr-2">
                                                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="15" cy="15" r="15" fill="url(#paint0_linear_802_4387)" />
                                                        <path
                                                            d="M13.3183 19.6424C12.9532 19.6424 12.5881 19.5079 12.2999 19.2197L9.4179 16.3377C8.8607 15.7805 8.8607 14.8582 9.4179 14.3011C9.97509 13.7439 10.8973 13.7439 11.4545 14.3011L13.3567 16.2032L18.5828 11.3806C19.1592 10.8426 20.0814 10.881 20.6194 11.4574C21.1574 12.0338 21.119 12.9561 20.5426 13.4941L14.2981 19.2582C14.0292 19.5079 13.6833 19.6424 13.3183 19.6424Z"
                                                            fill="#0066FF" />
                                                        <defs>
                                                            <linearGradient id="paint0_linear_802_4387" x1="31.2431" y1="24.5116"
                                                                x2="-8.54756" y2="22.0479" gradientUnits="userSpaceOnUse">
                                                                <stop stop-color="#0066FF" stop-opacity="0.08" />
                                                                <stop offset="1" stop-color="#0066FF" stop-opacity="0.28" />
                                                            </linearGradient>
                                                        </defs>
                                                    </svg>
                                                </div>
                                                <div>
                                                <h3> {data9 ? HtmlParser((data9.subcontentdescription).replace(/(<([^>]+)>)/gi, "")) : <></>}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="byCEp">
                                <h1>Buy Crypto's</h1>
                                <div class="OwnCreatMrkplMain pt-3">
                                    <div class="OwnNftItm OwnNftItm1">
                                        <div class="OwnNftCnt">
                                            <h4>

                                                <img src={ic8} class="img-fluid mr-2" alt="" />
                                                KPK Coin</h4>
                                        </div>
                                    </div>
                                    <div class="OwnNftItm OwnNftItm2">
                                        <div class="OwnNftCnt">
                                            <h4>
                                                <img src={ic5} class="img-fluid mr-2" alt="" />
                                                Cardano
                                            </h4>
                                        </div>
                                    </div>
                                    <div class="OwnNftItm OwnNftItm3">
                                        <div class="OwnNftCnt">
                                            <h4>
                                                <img src={ic1} class="img-fluid mr-2" alt="" />
                                                Binance
                                            </h4>
                                        </div>
                                    </div>
                                    <div class="OwnNftItm OwnNftItm4">
                                        <div class="OwnNftCnt">
                                            <h4>
                                                <img src={ic4} class="img-fluid mr-2" alt="" />
                                                Bitcoin
                                            </h4>
                                        </div>
                                    </div>
                                    <div class="OwnNftItm OwnNftItm5">
                                        <div class="OwnNftCnt">
                                            <h4>

                                                <img src={ic2} class="img-fluid mr-2" alt="" />
                                                Polycon</h4>
                                        </div>
                                    </div>
                                    <div class="OwnNftItm OwnNftItm6">
                                        <div class="OwnNftCnt">
                                            <h4>
                                                <img src={ic3} class="img-fluid mr-2" alt="" />
                                                Shiba
                                            </h4>
                                        </div>
                                    </div>
                                    <div class="OwnNftItm OwnNftItm7">
                                        <div class="OwnNftCnt">
                                            <h4>
                                                <img src={ic6} class="img-fluid mr-2" alt="" />
                                                Ethereum
                                            </h4>
                                        </div>
                                    </div>
                                    <div class="OwnNftItm OwnNftItm8">
                                        <div class="OwnNftCnt">
                                            <h4>
                                                <img src={ic7} class="img-fluid mr-2" alt="" />
                                                Tron
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                                <div class="MrC">
                                    <h2>Many other Cryptocurrencies</h2>
                                </div>

                            </div>
                            <div class="EpE">
                                <div class="row">
                                    <div class="col-md-6 mb-4">
                                        <div class="cdsd">
                                            <h2> {data10 ? HtmlParser((data10.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</h2>
                                            <p> {data10 ? HtmlParser(data10.content_description) : <></>}</p>
                                        </div>
                                    </div>
                                    <div class="col-md-6 mb-4">
                                        <div class="cdsd">
                                            <h2> {data10 ? HtmlParser((data10.subtitle).replace(/(<([^>]+)>)/gi, "")) : <></>}</h2>
                                            <p> {data10 ? HtmlParser(data10.subcontentdescription) : <></>}</p>
                                        </div>
                                    </div>
                                    <div class="col-12 mb-4">
                                        <div class="cdsd">
                                            <h2> {data10 ? HtmlParser((data10.subtitle1).replace(/(<([^>]+)>)/gi, "")) : <></>}</h2>
                                            <p> {data10 ? HtmlParser(data10.meta_description) : <></>}</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>

                <div class="text-center">
                    <a href="https://play.google.com/store/apps/details?id=com.application.Parkwallet&pli=1" target="blank" class="btn btn-primary px-4 text-center">
                        Explore
                    </a>
                </div>


            </div>
            <Footer />
        </Fragment>
    );
}
export default Parkwallet;