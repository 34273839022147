import React, { Component, Fragment, useState, useRef, useEffect, createRef } from "react";
import ReactDOM from "react-dom";
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import Overview from '../Overview/Overview';
import NFTDocumentation from '../NFTDocumentation/NFTDocumentation';
import DeveloperProgram from '../DeveloperProgram/DeveloperProgram';
import {
    BrowserRouter, Navigate, Route, Routes, Link
} from 'react-router-dom';

import Header from "../../common/Header/Header";
import Footer from "../../common/Footer/Footer";

import defiimg from '../../assets/images/pics/defiimg.png';
import defiman from '../../assets/images/pics/defiman.png';
import newImg from '../../assets/images/gaming/defi.png'

import { useSelector } from "react-redux";
import HtmlParser from "react-html-parser";


const Home = (props) => {
    const [activeTab, setActiveTab] = useState('1');
    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }
    let data, data1, data2, data3, data4, data5;

    data = useSelector(state => state.user);

    if (data.data.status == 200) {
        data = data.data.data;
        data1 = data.find(elem => elem.title == 'DeFi made');
        data2 = data.find(elem => elem.title == 'Efficient, Streamlined and Accessible');
        data3 = data.find(elem => elem.title == 'defi - banner');
        data4 = data.find(elem => elem.title == 'Why Ethereum? - defi');
        data5 = data.find(elem => elem.title == 'Solidity framework - defi')
    }
    let siteDetails;
    let site_inform = useSelector(state => state.user)
    if (site_inform.data1.status == true) {
        siteDetails = site_inform.data1.data
    }

    const [classIsActive, setClassIsActive] = useState(true);
    const smClose = () => {
        setClassIsActive(!classIsActive);
        classIsActive ? document.body.classList.add('sbMenu') : document.body.classList.remove('sbMenu');
    }
    return (
        <Fragment>
            <Header />
            <div className="pkchinsec">
                <section className="defibanner">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-7">
                                <h5 className="defihd">{data1 ? HtmlParser((data1.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</h5>
                                <h2 className="kpgamehead">{data2 ? HtmlParser((data2.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</h2>
                                {/* <p className="kpgamepara">Rapid transactions, minimal fees, instant responsiveness, and optimal capital utilization. Ethereum empowers builders to redefine the landscape.</p> */}
                                <p className="kpgamepara">{data3 ? HtmlParser((data3.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                            </div>

                            <div className="col-lg-5">
                                <div><img src={defiimg} alt="" className="img-fluid" /></div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="container">
                    <div className="row my-3 align-items-center justify-content-center">
                        <div className="col-lg-9">
                            <div className="ethgamekit pt-3">
                                <div className="ethgamecnt pt-4">
                                    {/* <h2 className="kpkithead"> Why Ethereum?</h2>  */}
                                    {/* <p className="kpkitpara">Experience Ethereum's remarkable scalability, ensuring blazing speed and low transaction costs as the ecosystem expands. Rest assured, Ethereum upholds its commitment to censorship resistance and top-notch security, providing you with a powerful and secure platform for your decentralized endeavors.</p> */}
                                <h3 className="whytxt">
                                        {data4 ? HtmlParser(data4.content_description) : <></>}
                                </h3>
                                    
                                    
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="">
                    <div className="container">
                        <div className="row pdbtm mt-3 align-items-center">
                            <div className="col-lg-6">
                                <div className="lastCon">
                                    {/* <h5 className="xxframe">XXXX framework</h5>
                                    <h3 className="ignHead mb-3">Simplifying Smart Contract Development</h3>
                                    <p className="ignPara">Say goodbye to the complexities of writing smart contracts. XXXX combines the power of Solidity, web3.js, ethers.js, Truffle, and ganache into a comprehensive toolchain. Dive into Ethereum's ecosystem and start building functional programs with unparalleled ease and speed using XXXX Framework. Unlock the potential of DeFi primitives effortlessly.</p>
                                    <h3 className="ignHead mb-3">Unified Global State</h3>
                                    <p className="ignPara">Eliminating the need for capital-inefficient Layer 2 systems or sharded chains. Ethereum scales harmoniously with the advancements of Moore's Law, empowering you with a seamless and efficient decentralized finance experience.</p> */}
                                    {data5 ? HtmlParser(data5.content_description) : <></>}
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="text-center">
                                    <img src={newImg} alt="" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="text-center py-2">
                    <a href="https://parkwallet.net/" target="_blank" class="btn btn-primary px-4">
                        Explore
                    </a>

                    {/* <div class="mobMenu d-lg-none" >
                        <div class="smClose" onClick={smClose} >
                            <svg id="closeicon" viewBox="0 0 800 600">
                                <path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" id="top"></path>
                                <path d="M300,320 L460,320" id="middle"></path>
                                <path d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190" id="bottom" transform="translate(480, 320) scale(1, -1) translate(-480, -318) "></path>
                            </svg>
                        </div>
                    </div> */}

                </div>
            </div>
            <Footer />
        </Fragment>
    );

}

export default Home;