// import React, { Component, Fragment, useState, useRef, useEffect, createRef } from "react";
// import ReactDOM from "react-dom";
// import classnames from 'classnames';
// import Header from "../../common/Header/Header";
// import Footer from "../../common/Footer/Footer";
// /** Images  **/
// import stakebnr from '../../assets/images/kpkdigital/stakebnr.png';
// import structure from '../../assets/images/kpkdigital/structuress.png';
// import notess from '../../assets/images/kpkdigital/notesss.png';
// import vision from '../../assets/images/kpkdigital/vision.png';

// import cntrkt from '../../assets/images/kpkdigital/cntrrkt.png';
// import plan from '../../assets/images/kpkdigital/plan.png';

// import rewardd from '../../assets/images/kpkdigital/tradecont.png';

// import growth from '../../assets/images/kpkdigital/growth.png';
// import rewards from '../../assets/images/kpkdigital/rrr.png';



// import tradingtree from '../../assets/images/kpkdigital/tradingtree.png';
// import secure from '../../assets/images/kpkdigital/secure.png';
// import men from '../../assets/images/kpkdigital/men.png';
// import notes from '../../assets/images/kpkdigital/notes.png';
// import rocket from '../../assets/images/kpkdigital/rocket.png';
// import tradecont from '../../assets/images/kpkdigital/tradecont.png';
// import taskcentr from '../../assets/images/kpkdigital/taskcentr.png';
// import cussupport from '../../assets/images/kpkdigital/cussupport.png';



// const KPKStake = (props) => {
//    return (
//       <Fragment>
//          <Header />
//          <div className="kpkexchange-pagecont">
//             <section className="kpkexchange-bnrsec">
//                <div className="container">
//                   <div className="row mt-5">
//                      <div className="col-lg-7">
//                         <div className="kpkexchange-leftcnt">
//                            <h5 className="f45">KPK Park: Revolutionizing the Trading Industry with Staking Program Our Legitimate Staking Program</h5>
//                         </div>
//                      </div>
//                      <div className="col-lg-5 text-center">
//                         <div className="kpkexchange-rightcnt">
//                            <img src={stakebnr} alt="" className="img-fluid" />
//                         </div>
//                      </div>
//                   </div>
//                </div>
//             </section>
//             <section className="my-5">
//                <div className="container">
//                   <p className="text-center">Parkchain is our legitimate blockchain platform that has garnered significant attention in the financial world with its innovative staking program business strategy. The platform provides a unique opportunity for participants to earn commissions by promoting and selling our services, while also incentivizing the recruitment of new members into our staking ecosystem.</p>
//                </div>
//             </section>

//             <section className="exchangeleftright-sec">
//                <div className="container">
//                   <div className="exchange-inrbgcnt">
//                      <div className="row align-items-center">
//                         <div className="col-md-4 order-img text-center">
//                            <div>
//                               <img src={structure} alt="" className="img-fluid" />
//                            </div>
//                         </div>
//                         <div className="col-md-8">
//                            <h2>The Staking Program Compensation Structure</h2>
//                            <p>At Parkchain, the staking program compensation structure empowers members at various levels to earn commissions not only from their own referral but also from the referral generated by their recruited downline.</p>
//                            <p>This multi-level commission system encourages collaboration and fosters a sense of community among participants.</p>
//                         </div>
//                      </div>
//                   </div>
//                   <div className="exchange-inrtopcnt">
//                      <div className="row align-items-center">
//                         <div className="col-md-8">
//                            <h2>Commitment to Compliance and Transparency</h2>
//                            <p>One of Parkchain's key strengths lies in its commitment to regulatory compliance and transparency. Our platform operates within legal boundaries and adheres to strict FTC scrutiny.</p>
//                            <p>This ensures that the focus remains on genuine product offerings, providing peace of mind to both the company and the stakeholders.</p>
//                         </div>
//                         <div className="col-md-4 mt-3 text-center">
//                            <div>
//                               <img src={notess} alt="" className="img-fluid" />
//                            </div>
//                         </div>
//                      </div>
//                   </div>

//                </div>
//             </section>


//             <section className="exchangeleftright-sec">
//                <div className="container">
//                   <div className="exchange-inrbgcnt">
//                      <div className="row align-items-center">
//                         <div className="col-md-4 order-img text-center">
//                            <div>
//                               <img src={vision} alt="" className="img-fluid" />
//                            </div>
//                         </div>
//                         <div className="col-md-8">
//                            <h2>Vision for Seamless Experiences</h2>
//                            <p>Parkchain's vision centers around revolutionizing the trading industry by providing convenient and seamless trading experiences for our clients. Our platform places utmost importance on reliability and durability,</p>
//                            <p>implementing rigorous quality control measures to deliver customized trading solutions that cater to the unique requirements of our diverse clientele.</p>
//                         </div>
//                      </div>
//                   </div>
//                   <div className="exchange-inrtopcnt">
//                      <div className="row align-items-center">
//                         <div className="col-md-8">
//                            <h2>Customer-Centric Mission</h2>
//                            <p>Parkchain's mission revolves around prioritizing our customers' needs, building trust, and delivering innovative solutions. With a solid global presence, the platform aspires to create lasting relationships based on transparency and fairness with all our users.</p>
//                            <p>Upholding the highest ethical standards, Parkchain fosters an environment of mutual success and growth, investing in its people to create a supportive and collaborative community.</p>



//                         </div>
//                         <div className="col-md-4 mt-3 text-center">
//                            <div>
//                               <img src={cntrkt} alt="" className="img-fluid" />
//                            </div>
//                         </div>
//                      </div>
//                   </div>

//                </div>
//             </section>


//             <section className="exchangeleftright-sec">
//                <div className="container">
//                   <div className="exchange-inrbgcnt">
//                      <div className="row align-items-center">
//                         <div className="col-md-4 order-img text-center">
//                            <div>
//                               <img src={plan} alt="" className="img-fluid" />
//                            </div>
//                         </div>
//                         <div className="col-md-8">
//                            <h2>The Staking Plan</h2>
//                            <p>Parkchain's vision centers around revolutionizing the trading industry by providing convenient and seamless trading experiences for our clients. Our platform places utmost importance on reliability and durability,</p>
//                            <p>implementing rigorous quality control measures to deliver customized trading solutions that cater to the unique requirements of our diverse clientele.</p>
//                         </div>
//                      </div>
//                   </div>
//                   <div className="exchange-inrtopcnt">
//                      <div className="row align-items-center">
//                         <div className="col-md-8">
//                            <h2>Unlimited Referral Rewards</h2>
//                            <p>Parkchain's mission revolves around prioritizing our customers' needs, building trust, and delivering innovative solutions. With a solid global presence, the platform aspires to create lasting relationships based on transparency and fairness with all our users.</p>
//                            <p>Upholding the highest ethical standards, Parkchain fosters an environment of mutual success and growth, investing in its people to create a supportive and collaborative community.</p>



//                         </div>
//                         <div className="col-md-4 mt-3 text-center">
//                            <div>
//                               <img src={rewardd} alt="" className="img-fluid" />
//                            </div>
//                         </div>
//                      </div>
//                   </div>

//                </div>
//             </section>


//             <section className="exchangeleftright-sec">
//                <div className="container">
//                   <div className="exchange-inrbgcnt">
//                      <div className="row align-items-center">
//                         <div className="col-md-4 order-img text-center">
//                            <div>
//                               <img src={growth} alt="" className="img-fluid" />
//                            </div>
//                         </div>
//                         <div className="col-md-8">
//                            <h2>Growth Level Commission</h2>
//                            <p>Parkchain's vision centers around revolutionizing the trading industry by providing convenient and seamless trading experiences for our clients. Our platform places utmost importance on reliability and durability,</p>
//                            <p>implementing rigorous quality control measures to deliver customized trading solutions that cater to the unique requirements of our diverse clientele.</p>
//                         </div>
//                      </div>
//                   </div>
//                   <div className="exchange-inrtopcnt">
//                      <div className="row align-items-center">
//                         <div className="col-md-8">
//                            <h2>Rewards and Recognition Program</h2>
//                            <p>Parkchain's mission revolves around prioritizing our customers' needs, building trust, and delivering innovative solutions. With a solid global presence, the platform aspires to create lasting relationships based on transparency and fairness with all our users.</p>
//                            <p>Upholding the highest ethical standards, Parkchain fosters an environment of mutual success and growth, investing in its people to create a supportive and collaborative community.</p>



//                         </div>
//                         <div className="col-md-4 mt-3 text-center">
//                            <div>
//                               <img src={rewards} alt="" className="img-fluid" />
//                            </div>
//                         </div>
//                      </div>
//                   </div>

//                </div>
//             </section>

//             <div class="bnrLks text-center">
//                <a href="https://koinpark.com/staking" target="blank" class="btn btn-12040 btn-bluFl text-center">
//                   Explore
//                </a>
//             </div>


//          </div>
//          <Footer />
//       </Fragment>
//    );
// }
// export default KPKStake;


import React, { Component, Fragment, useState, useRef, useEffect, createRef } from "react";
import ReactDOM from "react-dom";
import classnames from 'classnames';
import Header from "../../common/Header/Header";
import Footer from "../../common/Footer/Footer";
/** Images  **/
import stakebnr from '../../assets/images/kpkdigital/stakebnr.png';
import structure from '../../assets/images/kpkdigital/structure.png';
import compliance from '../../assets/images/kpkdigital/compliance.png';
import vision from '../../assets/images/kpkdigital/vision.png';
import centricmission from '../../assets/images/kpkdigital/centricmission.png';
import stakeplan from '../../assets/images/kpkdigital/plan.png';
import referralreward from '../../assets/images/kpkdigital/referralreward.png';
import growth from '../../assets/images/kpkdigital/growth.png';
import rewardsprogram from '../../assets/images/kpkdigital/rewardprogram.png';


const KPKStake = (props) => {
   return (
      <Fragment>
         <Header />
         <div className="kpkexchange-pagecont">
            <section className="kpkexchange-bnrsec">
               <div className="container">
                  <div className="row align-items-center mt-3">
                     <div className="col-lg-7">
                        <div className="kpkexchange-leftcnt">
                           <h2>KPK Park</h2>
                           <p>Revolutionizing the Trading Industry with Staking Program Our Legitimate Staking Program</p>
                           <p>Parkchain is our legitimate blockchain platform that has garnered significant attention in the financial world with its innovative staking program business strategy. The platform provides a unique opportunity for participants to earn commissions by promoting and selling our services, while also incentivizing the recruitment of new members into our staking ecosystem.</p>
                        </div>
                     </div>
                     <div className="col-lg-5 text-center mt-4">
                        <div className="kpkexchange-rightcnt">
                           <img src={stakebnr} alt="" className="img-fluid" />
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <section className="exchangeleftright-sec">
               <div className="container">
                  <div className="exchange-inrbgcnt mt-0">
                     <div className="row align-items-center">
                        <div className="col-md-4 order-img text-center">
                           <div>
                              <img src={structure} alt="" className="img-fluid" />
                           </div>
                        </div>
                        <div className="col-md-8">
                           <h2>The Staking Program Compensation Structure</h2>
                           <p>At Parkchain, the staking program compensation structure empowers members at various levels to earn commissions not only from their own referral but also from the referral generated by their recruited downline.</p>
                           <p>This multi-level commission system encourages collaboration and fosters a sense of community among participants.</p>
                        </div>
                     </div>
                  </div>
                  <div className="exchange-inrtopcnt mt-5">
                     <div className="row align-items-center">
                        <div className="col-md-8">
                           <h2>Commitment to Compliance and Transparency</h2>
                           <p>One of Parkchain's key strengths lies in its commitment to regulatory compliance and transparency. Our platform operates within legal boundaries and adheres to strict FTC scrutiny.</p>
                           <p>This ensures that the focus remains on genuine product offerings, providing peace of mind to both the company and the stakeholders.</p>
                        </div>
                        <div className="col-md-4 mt-3 text-center">
                           <div>
                              <img src={compliance} alt="" className="img-fluid" />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <section className="exchangeleftright-sec">
               <div className="container">
                  <div className="exchange-inrbgcnt mt-0">
                     <div className="row align-items-center">
                        <div className="col-md-4 order-img text-center">
                           <div>
                              <img src={vision} alt="" className="img-fluid" />
                           </div>
                        </div>
                        <div className="col-md-8">
                           <h2>Vision for Seamless Experiences</h2>
                           <p>Parkchain's vision centers around revolutionizing the trading industry by providing convenient and seamless trading experiences for our clients. Our platform places utmost importance on reliability and durability,</p>
                           <p>implementing rigorous quality control measures to deliver customized trading solutions that cater to the unique requirements of our diverse clientele.</p>
                        </div>
                     </div>
                  </div>
                  <div className="exchange-inrtopcnt mt-5">
                     <div className="row align-items-center">
                        <div className="col-md-8">
                           <h2>Customer-Centric Mission</h2>
                           <p>Parkchain's mission revolves around prioritizing our customers' needs, building trust, and delivering innovative solutions. With a solid global presence, the platform aspires to create lasting relationships based on transparency and fairness with all our users.</p>
                           <p>Upholding the highest ethical standards, Parkchain fosters an environment of mutual success and growth, investing in its people to create a supportive and collaborative community.</p>
                        </div>
                        <div className="col-md-4 mt-3 text-center">
                           <div>
                              <img src={centricmission} alt="" className="img-fluid" />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <section className="exchangeleftright-sec">
               <div className="container">
                  <div className="exchange-inrbgcnt mt-0">
                     <div className="row align-items-center">
                        <div className="col-md-4 order-img text-center">
                           <div>
                              <img src={stakeplan} alt="" className="img-fluid" />
                           </div>
                        </div>
                        <div className="col-md-8">
                           <h2>The Staking Plan</h2>
                           <p>Earning Rewards with KPK Cryptocurrencies Parkchain's staking plan offers users the opportunity to earn rewards by holding KPK cryptocurrencies.</p>
                           <p>By participating in the Parkchain staking program, users can appreciate the value and benefits of being part of this thriving ecosystem.</p>
                        </div>
                     </div>
                  </div>
                  <div className="exchange-inrtopcnt mt-5">
                     <div className="row align-items-center">
                        <div className="col-md-8">
                           <h2>Unlimited Referral Rewards</h2>
                           <p>Participants in the staking program can earn rewards when they refer new members to join the Parkchain staking program.</p>
                           <p>There are no limitations on the number of referrals a user can make, providing ample opportunities for growth and increased earnings.</p>
                        </div>
                        <div className="col-md-4 mt-3 text-center">
                           <div>
                              <img src={referralreward} alt="" className="img-fluid" />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <section className="exchangeleftright-sec pb-3">
               <div className="container">
                  <div className="exchange-inrbgcnt mt-0">
                     <div className="row align-items-center">
                        <div className="col-md-4 order-img text-center">
                           <div>
                              <img src={growth} alt="" className="img-fluid" />
                           </div>
                        </div>
                        <div className="col-md-8">
                           <h2>Growth Level Commission</h2>
                           <p>Upon receiving staking rewards, users become eligible for the Growth Level Commission. To qualify for this additional reward, users need to introduce referrals who complete staking in Parkchain.</p>
                           <p>This encourages active engagement and expansion of the platform's community.</p>
                        </div>
                     </div>
                  </div>
                  <div className="exchange-inrtopcnt mt-5">
                     <div className="row align-items-center">
                        <div className="col-md-8">
                           <h2>Rewards and Recognition Program</h2>
                           <p>Parkchain has set specific targets for individual teams in its rewards and recognition program. This strategy serves as an incentive for users to stake and earn rewards. As participants reach certain targets, they are recognized and rewarded, fostering a competitive and supportive environment within the staking community.</p>
                           <p>Our dedication to innovation, compliance, and customer-centricity sets us apart from a transformative force in the trading industry. With a robust staking program and a commitment to transparency, Parkchain is well-positioned to shape the future of blockchain-based financial solutions, bringing greater convenience and value to our traders worldwide.</p>
                        </div>
                        <div className="col-md-4 mt-3 text-center">
                           <div>
                              <img src={rewardsprogram} alt="" className="img-fluid" />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <div class="bnrLks text-center">
               <a href="https://koinpark.com/staking" target="blank" class="btn btn-12040 btn-bluFl text-center">
                  Explore
               </a>
            </div>
         </div>
         <Footer />
      </Fragment>
   );
}
export default KPKStake;