import React, { Component, Fragment, useState, useRef, useEffect, createRef } from "react";
import ReactDOM from "react-dom";
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import Overview from '../Overview/Overview';
import NFTDocumentation from '../NFTDocumentation/NFTDocumentation';
import DeveloperProgram from '../DeveloperProgram/DeveloperProgram';
import {
    BrowserRouter, Navigate, Route, Routes, Link
} from 'react-router-dom';

import Header from "../../common/Header/Header";
import Footer from "../../common/Footer/Footer";

import kpdaoImg from '../../assets/images/kpdaoImg.png';
import { useSelector } from "react-redux";
import HtmlParser from "react-html-parser";

import newbaner from '../../assets/images/home-img/swpc.png'



const Home = (props) => {
    const [activeTab, setActiveTab] = useState('1');
    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }
    let data, data1, data2, data3, data4, data5, data6, data7, data8;

    data = useSelector(state => state.user);
    if (data.data.status == 200) {
        data = data.data.data;
        data1 = data.find(elem => elem.title == 'Effortless Governance in Action - daos');
        data2 = data.find(elem => elem.title == 'Effortless Governance');
        data3 = data.find(elem => elem.title == 'Why Ethereum? - daos');
        data4 = data.find(elem => elem.title == 'Engage in Cost-Free Voting');
        data5 = data.find(elem => elem.title == 'Experience Unparalleled Affordability')
        data6 = data.find(elem => elem.title == 'A single state, Engineered for Interoperability')
        data7 = data.find(elem => elem.title == 'Crafted to grow');
        data8 = data.find(elem => elem.title == 'last content - daos');
    }
    const [classIsActive, setClassIsActive] = useState(true);
    const smClose = () => {
        setClassIsActive(!classIsActive);
        classIsActive ? document.body.classList.add('sbMenu') : document.body.classList.remove('sbMenu');
    }
    return (
        <Fragment>
            <Header />
            <div className="pkchinsec cmsCnt cmsHome">

                <section className="cmsBannerSec kpdaoBanner d-flex align-items-center justify-content-start">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 firstCon">
                                {/* <h3 className="cmsTitle">Effortless Governance in Action</h3> */}
                                {data1 ? HtmlParser(data1.content_description) : <></>}
                                {/* <p>Unite with Ethereum's high transaction speed, efficiency, and affordability, creating the perfect environment to foster connection and collaboration among individuals.</p> */}
                                {data2 ? HtmlParser(data2.content_description) : <></>}
                            </div>
                        </div>
                    </div>
                </section>

                <section className="py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 mx-auto">
                                <div className="lastCon">
                                    <h4>Why Parkchain</h4>
                                    <h3>Expand Boundaries,Accelerate
                                        Growth, Innovate Smarter, and Harness powerful Tools</h3>

                                </div>

                            </div>
                        </div>
                    </div>
                </section>





                <div className="container">
                    <div className="row  mt-3">
                        <div className="col-lg-4  text-lg-right d-lg-flex flex-column justify-content-between">
                            <div className="midCon">
                                {/* <h3 className="cmsHeading">Engage in Cost-Free Voting</h3>
                                <p>Participate actively in your communities without the burden of spending substantial amounts on transaction fees. Exercise your voting rights on Ethereum without incurring significant costs.</p> */}
                                {data4 ? HtmlParser(data4.content_description) : <></>}
                            </div>
                            <div className="midCon">
                                <h3 className="cmsHeading">Experience Unparalleled Affordability</h3>
                                <p>No more exorbitant fees. With Ethereum, each transaction costs a mere fraction of a penny, enabling seamless participation in DAOs and making your journey in the ecosystem significantly more accessible.</p>
                                {/* {data5 ? HtmlParser(data5.content_description) : <></>} */}

                            </div>
                        </div>
                        <div className="col-lg-4  d-flex align-items-center justify-content-center my-3">
                            <img src={newbaner} className="img-fluid" />
                        </div>
                        <div className="col-lg-4  d-lg-flex flex-column justify-content-between">
                            <div className="midCon">
                                {/* <h3 className="cmsHeading">A single state, Engineered for Interoperability</h3>
                                <p>Ethereum is meticulously designed to ensure a shared state, promoting seamless interoperability. With horizontal scalability, Ethereum effortlessly connects with other systems, facilitating smooth integration and interaction within the network.</p> */}
                                {data6 ? HtmlParser(data6.content_description) : <></>}

                            </div>
                            <div className="midCon">
                                {/* <h3 className="cmsHeading">Crafted to grow</h3>
                                <p>The SPL governance program is meticulously crafted to prioritize extendability, plugins, and seamless integrations, empowering you to unlock endless possibilities and adaptability within the Ethereum ecosystem.</p> */}
                                {data7 ? HtmlParser(data7.content_description) : <></>}

                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center my-5">
                        <div className="col-lg-7 text-center">
                            {data8 ? HtmlParser(data8.content_description) : <></>}
                            {/* <p>Experience Ethereum's unrivaled speed and cost-efficiency, scaling seamlessly as the ecosystem expands, while maintaining robust censorship resistance and top-notch security measures.</p> */}
                        </div>
                    </div>
                </div>
                <div class="text-center py-2">
                    <a href="https://parkwallet.net/" target="_blank" class="btn btn-12040 btn-bluFll">
                        Explore
                    </a>

                    {/* <div class="mobMenu d-lg-none" >
                        <div class="smClose" onClick={smClose} >
                            <svg id="closeicon" viewBox="0 0 800 600">
                                <path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" id="top"></path>
                                <path d="M300,320 L460,320" id="middle"></path>
                                <path d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190" id="bottom" transform="translate(480, 320) scale(1, -1) translate(-480, -318) "></path>
                            </svg>
                        </div>
                    </div> */}

                </div>
            </div>
            <Footer />
        </Fragment>
    );

}

export default Home;